import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: true },
    { id: 7, isCorrect: true },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: true },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: false },
    { id: 16, isCorrect: true },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha a forma verbal adequada ao contexto:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            1. Я ка́ждое у́тро <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    бе́гаю
                                </button>/<button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    побе́гаю
                                </button>
                            </span> в па́рке.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            2. Я <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    бе́гала
                                </button>/<button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    побе́гала
                                </button>
                            </span> 15 мину́т, а пото́м пошла́ на рабо́ту...
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            3. Вчера́ я был на пля́же и немно́жко <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    пла́вал
                                </button>/<button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    попла́вал
                                </button>
                            </span>.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            4. Я бою́сь воды́, потому́ что я не уме́ю <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    пла́вать
                                </button>/<button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    попла́вать
                                </button>
                            </span>.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            5. Мы <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    ходи́ли
                                </button>/<button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    походи́ли
                                </button>
                            </span> по го́роду полчаса́, а пото́м сра́зу верну́лись в гости́ницу.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            6. Мы весь день <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    ходи́ли
                                </button>/<button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    походи́ли
                                </button>
                            </span> по го́роду и верну́лись в гости́ницу то́лько ве́чером.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            7. В про́шлом году́ она́ <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    е́здила
                                </button>/<button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    пое́здила
                                </button>
                            </span> домо́й авто́бусом, а сейча́с купи́ла себе́ маши́ну.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            8. По́сле ава́рии у маши́ны кака́я-то пробле́ма, как то́лько <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    е́здить
                                </button>/<button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    пое́здить
                                </button>
                            </span> мину́т 15-20, дви́гатель начина́ет стуча́ть.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;