import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson4/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson4/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson4/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson4/image4grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>МЕСТОИМЕНИE</p>
                    <p>СВОЙ</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="250px" />
                </div>
                <div className={classes.item2}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O pronome <b><span className={classes.orangeHighlight}>свой</span></b> é um pronome reflexo que muda em número, género e caso e que pode substituir os possessivos <b>мой</b>, <b>твой</b>, <b>его</b>, <b>ваш</b>, <b>наш</b>, <b>их</b>, sendo normalmente parte do complemento do verbo e <u>referindo-se sempre ao sujeito</u> da frase:
                    </p>
                    <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="250px" />
                </div>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Apesar de, neste tipo de frases ser <u>preferencial o uso do pronome reflexivo</u> <b><span className={classes.orangeHighlight}>свой</span></b>, quando o sujeito é a 1º ou a 2º pessoa, podemos ter o reflexivo ou um possessivo:
                    </p>
                    <ul>
                        <li>
                            Я сде́лала ремо́нт <b>в свое́й/ в мое́й</b> ко́мнате.
                            <p>
                                <i>Eu fiz obras em <b>minha</b> casa.</i>
                            </p>
                        </li>
                        <li>
                            Скажи́ мне <b>свóй/твóй</b> но́мер телефо́на.
                            <p>
                                <i>Diz-me o <b>teu</b> número de telefone.</i>
                            </p>
                        </li>
                    </ul>
                    <div className={classes.spacer}>
                        <p>
                            <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Mas quando o sujeito é a 3ª pessoa, o uso de <b><span className={classes.orangeHighlight}>свой</span></b> é indispensável para não se confundirem os referentes do pronome:
                        </p>
                        <ul>
                            <li>
                                Она́ взяла́ <b>свою́</b> су́мку. <b>≠</b> Она́ взяла́ <b>её</b> су́мку,
                                <p>
                                    Ela i trouxe a <b>sua<sup>i</sup></b> (própria) mala. <b>≠</b> Ela<sup>i</sup> trouxe a mala <b>dela<sup>x</sup></b> (de outra pessoa).
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.item4}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O uso do pronome <b><span className={classes.orangeHighlight}>свой</span></b> no nominativo é restrito, ou seja, usam-se os possessivos nas frases em que que o pronome que indica a posse <u>faz parte do sujeito</u> (do grupo nominal sujeito):
                    </p>
                    <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="250px" />
                </div>
                <div className={classes.item5}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Normalmente, o pronome pronome <b><span className={classes.orangeHighlight}>свой</span></b> não ocorre no Nominativo, quando tem valor reflexivo, no entanto, há situações em que o mesmo pode ocorrer com o nome que tem função de sujeito:
                    </p>
                    <div className={classes.spacer}>
                        <p>
                            <b><span className={classes.orangeHighlight}>1. СВОЙ = СОБСТВЕННЫЙ</span></b>
                        </p>
                        <p>
                            (próprio/ter na posse)
                        </p>
                    </div>
                    <div className={classes.spacer}>
                        <ul>
                            <li>
                                <b>Свой</b> би́знес - это здóрово!
                                <p>
                                    <i>Ter <b>o seu próprio</b> negócio é maravilhoso!</i>
                                </p>
                            </li>
                            <li>
                                У нас есть <b>свой</b> дом.
                                <p>
                                    <i>Nós temos a <b>nossa própria</b> casa</i>
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className={classes.spacer}>
                        <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O seu uso é muito frequente em provérbios:</p>
                        <ul>
                            <li>
                                <b>Свой</b> глаз – алма́з, чужо́й – стёклышко.
                                <p>
                                    <i>Ver (<b>com os próprios olhos</b>) para crer.</i>
                                </p>
                            </li>
                            <li>
                                В каждом доме <b>свой</b> запах.
                                <p>
                                    <i>Cada casa tem <b>seu (próprio)</b> cheiro.</i>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.item6}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Também vemos o pronome <b><span className={classes.orangeHighlight}>свой</span></b> em algumas frases impessoais:
                    </p>
                    <div className={classes.spacer}>
                        <ul>
                            <li>
                                Ва́жно знать <b>свой</b> ко́рни
                                <p>
                                    <i>É importante conhecer <b>as próprias</b> origens</i>
                                </p>
                            </li>
                            <li>
                                На́до уважа́ть <b>свои́х</b> роди́телей
                                <p>
                                    <i>É preciso respeitar <b>os nossos</b> pais</i>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classes.item7}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O pronome <b><span className={classes.orangeHighlight}>свой</span></b> não ocorre com múltiplos sujeitos, seja em duas frases (a) ou numa mesma frase (б):
                    </p>
                    <div className={classes.item7_1}>
                        <div>
                            <div className={classes.spacer}>
                                <p>(a) Па́вел говори́т, что его́ жена́ са́мая краси́вая!</p>
                                <p>O Paulo diz que a sua mulher é a mais linda!</p>
                            </div>
                            <div className={classes.spacer}>
                                <p>(б) А́нна и её сестра́ уже́ прие́хали.</p>
                                <p>A Ana e a sua irmã já chegaram.</p>
                            </div>
                        </div>
                        <p className={classes.curlyBrace}>
                            <font size="5">&#9758;</font>
                        </p>
                        <img id="image4Grammar" src={Image4Grammar} alt="table 4" width="50px" height="50px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;