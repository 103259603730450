import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson11 from '../../../../../../Assets/Images/A1/Lesson11/image1lesson11.png';
import Image2Lesson11 from '../../../../../../Assets/Images/A1/Lesson11/image2lesson11.png';
import Image3Lesson11 from '../../../../../../Assets/Images/A1/Lesson11/image3lesson11.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>II СПРЯЖЕ́НИЕ: 1. Нeправильные глаголы</p>
                    <p>II Conjugação: 1. Verbos Irregulares</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font><b> Verbos com alternância de consoantes</b></p>
                    <p>
                        Em alguns verbos observa-se alternância de consoantes que se realiza apenas na 1ª pessoa sg. através de acréscimo de <b><span className={classes.orangeHighlight}>Л</span></b> à raiz do verbo (Grupo I) ou através de troca de <b><span className={classes.orangeHighlight}>Д</span></b> por <b><span className={classes.orangeHighlight}>Ж</span></b> (Grupo II).
                    </p>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="EatVerbConjugationPresent" src={Image2Lesson11} alt="eat verb conjugation in present" width="200px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="GroupTwoDAndJExample" src={Image3Lesson11} alt="group two D and J example" width="200px" />
                        </div>
                    </div>
                </div>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="GroupOneLExample" src={Image1Lesson11} alt="group one L example" width="200px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A pronúncia do infinitivo do verbo <b><span className={classes.orangeHighlight}>ЕСТЬ</span></b> coincide com a 3.ª p. do verbo БЫТЬ (HÁ de HAVER)
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>НУЛЕВОЕ ПОДЛЕЖАЩЕЕ</p>
                    <p>Sujeito indeterminado</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div>
                        <p>
                            <b><span className={classes.orangeHighlight}>Ø</span></b> + VERBO 3ª p. pl.
                        </p>
                        <p>
                            <del>они́</del>  <b>говоря́т</b>  = <b><span className={classes.orangeHighlight}>Ø</span> говоря́т</b>
                        </p>
                        <p>
                            <i><b>Там всегда́ <span className={classes.orangeHighlight}>Ø</span> говоря́т о нау́ке.</b></i>
                        </p>
                        <p>
                            <i><b>Alí sempre <span className={classes.orangeHighlight}>Ø</span> falam da ciência.</b></i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;