import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>авто́бусная остано́вка</b> paragem de autocarro</p>
                        <p><b>беспоко́йство</b> preocupação</p>
                        <p><b>буты́лка</b> garrafa</p>
                        <p><b>ви́шни</b> (Gen.) de ginja</p>
                        <p><b>во́зле</b> perto de</p>
                        <p><b>грамм</b> (Gen.) gramas</p>
                        <p><b>дава́йте прове́рим</b> vamos verificar</p>
                        <p><b>далекó от</b> longe de</p>
                        <p><b>для</b> (prep.) para</p>
                        <p><b>за</b> (prep.) por</p>
                        <p><b>идти́</b> ir</p>
                        <p><b>колбасы́</b> (Gen.) de chouriço</p>
                        <p><b>копе́йка</b> copeque</p>
                        <p><b>корóбка</b> caixa</p>
                        <p><b>куплю́</b> (v. купи́ть) comprarei</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ку́рица</b> galinha/frango</p>
                        <p><b>лóжка</b> colher</p>
                        <p><b>мо́жно</b> (v. мочь) é possível/permitido</p>
                        <p><b>морко́ви</b> (Gen.) de cenoura</p>
                        <p><b>недалеко́ от</b> perto de</p>
                        <p><b>огурцы́</b> pepinos</p>
                        <p><b>отда́м</b> (v. отда́ть) devolverei</p>
                        <p><b>оши́блись</b> (v. ошиба́ться) erraram</p>
                        <p><b>пóлкилó</b> meio-quilo</p>
                        <p><b>па́чку</b> (Acus.) embalagem</p>
                        <p><b>пече́нья</b> (Gen.) bolachas</p>
                        <p><b>пирога́</b> (Gen.) empada/quiche</p>
                        <p><b>поменя́ть</b> trocar</p>
                        <p><b>по́рция</b> porção</p>
                        <p><b>проси́ла</b> (v. проси́ть) pedia</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>разгово́рник</b> guia de conversação</p>
                        <p><b>рубле́й</b> (Gen.) rublos</p>
                        <p><b>c вас (240 рубле́й)</b> deve (240 rublos)</p>
                        <p><b>Ско́лько с меня́?</b> Quanto é que eu devo?</p>
                        <p><b>салфе́тки</b> guardanapos</p>
                        <p><b>сда́ча</b> troco</p>
                        <p><b>сде́лать</b> fazer</p>
                        <p><b>Ско́лько стóят?</b> Quanto custam?</p>
                        <p><b>стака́н</b> copo</p>
                        <p><b>таре́лка</b> prato</p>
                        <p><b>ты́сяча</b> mil</p>
                        <p><b>у</b> (prep.) 1. junto a, junto de 2. perto de, ao lado de</p>
                        <p><b>ýтка</b> pato</p>
                        <p><b>хва́тит</b> (v. хва́тить) chega/ é suficiente</p>
                        <p><b>ча́шка</b> chávena</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;