import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Lesson32 from '../../../../../../Assets/Images/A1/Lesson32/image1lesson32.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ТВОРИТЕЛЬНЫЙ ПАДЕЖ С ПРЕДЛОГАМИ</p>
                    <p>Instrumental Preposicionado</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b>Предлоги (Тв. п.):<span className={classes.orangeHighlight}> &nbsp;&nbsp;&nbsp;&nbsp;ЗА &nbsp;&nbsp;&nbsp;&nbsp;НАД &nbsp;&nbsp;&nbsp;&nbsp;ПОД &nbsp;&nbsp;&nbsp;&nbsp;ПÉРЕД &nbsp;&nbsp;&nbsp;&nbsp;МЕ́ЖДУ &nbsp;&nbsp;&nbsp;&nbsp;РЯ́ДОМ С</span></b>
                    </p>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <div className={classes.gridItem1Item1}>
                                <p><b><span className={classes.orangeHighlight}>1.</span> ла́мпа ме́жду дива́ном и кре́слом</b></p>
                                <p><b><span className={classes.orangeHighlight}>2.</span> ковёр под сто́ликом</b></p>
                                <p><b><span className={classes.orangeHighlight}>3.</span> лю́стра над столо́м</b></p>
                                <p><b><span className={classes.orangeHighlight}>4.</span> корзи́на за кре́слом</b></p>
                                <p><b><span className={classes.orangeHighlight}>5.</span> зал ря́дом с ку́хней</b></p>
                                <p><b><span className={classes.orangeHighlight}>6.</span> ко́врик пе́ред две́рью</b></p>
                            </div>
                            <div className={classes.gridItem1Item2}>
                                <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Em alguns casos, quando a palavra que segue a preposição começa por duas consoantes, é acrescentada a letra <b><span className={classes.orangeHighlight}>O</span></b> à preposição.</p>
                                <p><i><b><span className={classes.orangeHighlight}>ПОД</span></b> землёй, но <b><span className={classes.orangeHighlight}>ПОДО́ </span>мн</b>ой</i></p>
                                <p><i><b><span className={classes.orangeHighlight}>НАД</span></b> землёй, но <b><span className={classes.orangeHighlight}>НА́ДО</span> мн</b>ой</i></p>
                                <p><i><b><span className={classes.orangeHighlight}>С</span></b> землёй, но <b><span className={classes.orangeHighlight}>СО</span> мн</b>ой</i></p>
                                <p><i><b><span className={classes.orangeHighlight}>ПЕ́РЕД</span> н</b>ей, но <b><span className={classes.orangeHighlight}>ПЕ́РЕДО</span> мн</b>ой</i></p>
                                <p><i><b><span className={classes.orangeHighlight}>В</span> ср</b>еду, но <b><span className={classes.orangeHighlight}>ВО</span> вт</b>о́рник</i></p>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="Image1Lesson32" src={Image1Lesson32} alt="lesson 32 illustrative example 1" width="300" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;