import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import video_aula_7 from './Video/video_aula_7.mp4';

const VerbList = [
    { id: 'голубые озёра', name: 'голубые озёра', matcher: 'голубые озёра' },
    { id: 'рыбалка', name: 'рыбалка', matcher: 'рыбалка' },
    { id: 'отдохнуть', name: 'отдохнуть', matcher: 'отдохнуть' },
    { id: 'получилось', name: 'получилось', matcher: 'получилось' },
    { id: 'езжу', name: 'езжу', matcher: 'езжу' },
    { id: 'едете', name: 'едете', matcher: 'едете' },
    { id: 'останавливаюсь', name: 'останавливаюсь', matcher: 'останавливаюсь' },
    { id: 'любуюсь', name: 'любуюсь', matcher: 'любуюсь' },
    { id: 'можно', name: 'можно', matcher: 'можно' },
    { id: 'хотели', name: 'хотели', matcher: 'хотели' },
    { id: 'вместе', name: 'вместе', matcher: 'вместе' },
    { id: 'езжайте', name: 'езжайте', matcher: 'езжайте' },
    { id: 'встречу', name: 'встречу', matcher: 'встречу' },
    { id: 'скучно', name: 'скучно', matcher: 'скучно' },
    { id: 'спальные', name: 'спальные', matcher: 'спальные' },
    { id: 'лодка', name: 'лодка', matcher: 'лодка' },
    { id: 'Поехали', name: 'Поехали', matcher: 'Поехали' },
    { id: 'совершенно', name: 'совершенно', matcher: 'совершенно' },
    { id: 'вдруг', name: 'вдруг', matcher: 'вдруг' },
    { id: 'вокзале', name: 'вокзале', matcher: 'вокзале' },
    { id: 'найдёте', name: 'найдёте', matcher: 'найдёте' },
    { id: 'рулём', name: 'рулём', matcher: 'рулём' },
    { id: 'Путешествие', name: 'Путешествие', matcher: 'Путешествие' },
    { id: 'конце', name: 'конце', matcher: 'конце' },
    { id: 'судьба', name: 'судьба', matcher: 'судьба' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Посмотрите фрагмент из фильма &quot;Путешествие будет приятным&quot; и заполните предложения:" />
                <a className={classes.downloadVideoLink} href={video_aula_7} download="video_aula_7">Bидео Урок 7 - Путешествие будет приятным (1982)</a>
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>- Иду в отпуск. Еду на <input
                                id="голубыеOзёраInput"
                                name="голубыеOзёраInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />. Чистая вода, <input
                                    id="рыбалкаInput"
                                    name="рыбалкаInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[1].matcher)}
                                />.</p>
                            <p>- Счастливо <input
                                id="отдохнутьInput"
                                name="отдохнутьInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> отдохнуть! Я бы тоже хотела. Да не <input
                                    id="получилосьInput"
                                    name="получилосьInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[3].matcher)}
                                />.</p>
                            <p>- A что? Путёвку не достали?</p>
                            <p>- Да...</p>
                            <p>- Я не <input
                                id="езжуInput"
                                name="езжуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> по путёвкам. Я не люблю организованный отдых…</p>
                            <p>- Дикарём <input
                                id="едетеInput"
                                name="едетеInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />?</p>
                            <p>- Самым моторизованным. У меня машина. Где хочу, там и <input
                                id="останавливаюсьInput"
                                name="останавливаюсьInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />. Брожу, <input
                                    id="любуюсьInput"
                                    name="любуюсьInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[7].matcher)}
                                /> окрестностями, вот…</p>
                            <p>- Вам <input
                                id="можноInput"
                                name="можноInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> позавидовать!</p>
                            <p>- А вы <input
                                id="хотелиInput"
                                name="хотелиInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            /> бы вот так отдохнуть?</p>
                            <p>- Хотела, да не получилось.</p>
                            <p>- Ну, так поедем <input
                                id="вместеInput"
                                name="вместеInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            />. Вы <input
                                    id="езжайтеInput"
                                    name="езжайтеInputInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[11].matcher)}
                                /> поездом, а я здесь вас <input
                                    id="встречуInput"
                                    name="встречуInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[12].matcher)}
                                /> и дальше поедем вместе.
                            </p>
                            <p>- Что вы! Спасибо!</p>
                            <p>- A что, Люда? Поедем! Мне одному <input
                                id="скучноInput"
                                name="скучноInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[13].matcher)}
                            />, честное слово! У меня палатка есть, ружьё, <input
                                    id="спальныеInput"
                                    name="спальныеInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[14].matcher)}
                                /> мешки, надувная <input
                                    id="лодкаInput"
                                    name="лодкаInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[15].matcher)}
                                />... отлично отдохнём! <input
                                    id="ПоехалиInput"
                                    name="ПоехалиInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[16].matcher)}
                                />, Люда!
                            </p>
                            <p>- Так, как же так? Мы <input
                                id="совершенноInput"
                                name="совершенноInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[17].matcher)}
                            /> не знаем друг друга... и <input
                                    id="вдругInput"
                                    name="вдругInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[18].matcher)}
                                /> вместе поедем?
                            </p>
                            <p>- Ну и что же? Тогда даже интереснее! А на <input
                                id="вокзалеInput"
                                name="вокзалеInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[19].matcher)}
                            /> мы найдем друг друга по моей машинe. Она у меня самодельная. Сразу заметите её на стоянке. Если <input
                                    id="найдётеInput"
                                    name="найдётеInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[20].matcher)}
                                /> третий номер журнала «За <input
                                    id="рулёмInput"
                                    name="рулёмInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[21].matcher)}
                                />», там есть её фотография. Подумайте, Люда! Соглашайтесь! <input
                                    id="ПутешествиеInput"
                                    name="ПутешествиеInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[22].matcher)}
                                /> будет приятным! A я позвоню вам в <input
                                    id="концеInput"
                                    name="концеInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[23].matcher)}
                                /> дня.
                            </p>
                            <p>- Езжай, Люда… это сама <input
                                id="судьбаInput"
                                name="судьбаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[24].matcher)}
                            />!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;