import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>Азо́рские острова́</b> Ilhas dos Açores</p>
                        <p><b>аку́ла</b> tubarão</p>
                        <p><b>(без) жела́ния</b> (sem) vontade</p>
                        <p><b>(в) ведре</b> (no) balde</p>
                        <p><b>(в) после́дний раз</b> (na) última vez</p>
                        <p><b>(во) сне</b> (no) sonho</p>
                        <p><b>вокру́г</b> em volta/ em torno de</p>
                        <p><b>волны́</b> ondas</p>
                        <p><b>вы́рос</b> (v. вы́расти) cresci</p>
                        <p><b>горе́ния</b> (Gen.) combustão</p>
                        <p><b>гори́т</b> (v. горе́ть) arde</p>
                        <p><b>дви́гается</b> (v. дви́гаться) movimenta-se</p>
                        <p><b>зева́ют</b> (v. зева́ть) bocejam</p>
                        <p><b>зелёная</b> verde (f.)</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>зе́млю</b> (Acus.) terra</p>
                        <p><b>из угла́ в у́гол</b> de canto a canto</p>
                        <p><b>кислоро́да</b> (Gen.) de oxigénio</p>
                        <p><b>когда́-нибу́дь</b> um dia destes</p>
                        <p><b>кры́лья</b> asas</p>
                        <p><b>ла́пки</b> patas</p>
                        <p><b>луна́</b> lua</p>
                        <p><b>ме́дленно</b> (adv.) devagar</p>
                        <p><b>мозг</b> cérebro</p>
                        <p><b>(на) подводной ло́дке</b> (de) submarino</p>
                        <p><b>(на) стене́</b> (na) parede</p>
                        <p><b>несовершенного вида</b> (Gen.) de imperfetivo</p>
                        <p><b>нехва́тку</b> (Acus.) falta</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>обма́нывать</b> enganar</p>
                        <p><b>(от) войны́</b> (Gen.) (da) guerra</p>
                        <p><b>па́дает</b> (v. па́дать) cai</p>
                        <p><b>па́дают</b> (v. па́дать) caem</p>
                        <p><b>(по) кру́гу</b> (Dat.) (em) círculo</p>
                        <p><b>пока́зывали</b> (v. пока́зывать) mostravam</p>
                        <p><b>по́мню</b> (v. по́мнить) lembro-me</p>
                        <p><b>пти́цы</b> pássaros</p>
                        <p><b>рак</b> caranguejo</p>
                        <p><b>смешно́й</b> (adj.) engraçado</p>
                        <p><b>стои́т</b> (v. стоя́ть) fica parado</p>
                        <p><b>трава́</b> relva</p>
                        <p><b>худéeшь</b> (v. худе́ть) estás a emagrecer</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;