import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бе́гает</b> (v. бе́гать) corre</p>
                        <p><b>бельги́ец</b> belga (m.)</p>
                        <p><b>бенга́льский</b>(adj.) bengali (m.)</p>
                        <p><b>буря́тский</b> (adj.) buriate (m.)</p>
                        <p><b>бы́стро</b> (adv.) rápido</p>
                        <p><b>(в) общежи́тии</b> (na) residência (estudantil)</p>
                        <p><b>вéсело</b> alegremente</p>
                        <p><b>везде́</b> em todo lado</p>
                        <p><b>вку́сно</b> deliciosamente</p>
                        <p><b>грузи́нский</b> (adj.) georgiano</p>
                        <p><b>да́тский</b> (adj.) dinamarquês</p>
                        <p><b>дорогóй</b> (adj.) caro</p>
                        <p><b>Здóрово!</b> Maravilhoso!</p>
                        <p><b>к сожале́нию</b> infelizmente</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>к сча́стью</b> felizmente</p>
                        <p><b>как две ка́пли воды́</b> como duas gotas de água</p>
                        <p><b>как нéбо и земля́</b> (lit. como o céu e a terra) como o dia e a noite</p>
                        <p><b>ме́дленно</b> devagar</p>
                        <p><b>непло́хо</b> não é mau</p>
                        <p><b>нигде́</b> em parte nenhuma</p>
                        <p><b>(о) здоро́вье</b> (acerca da) saúde</p>
                        <p><b>подру́га</b> amiga</p>
                        <p><b>по-испа́нски</b> em espanhol</p>
                        <p><b>по-кита́йски</b> em chinês</p>
                        <p><b>поле́зно</b> (adv.) útil</p>
                        <p><b>по-португа́льски</b> em português</p>
                        <p><b>по-ру́сски</b> em russo</p>
                        <p><b>по-францу́зки</b> em francês</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>похо́жи</b> parecidos/as</p>
                        <p><b>произноше́ние</b> pronúncia</p>
                        <p><b>рекоменду́ю</b> (v. рекомендова́ть) recomendo</p>
                        <p><b>румы́нский</b> (adj.) romeno</p>
                        <p><b>с друго́й стороны́</b> (Gen.) por outro lado</p>
                        <p><b>си́льно</b> fortemente</p>
                        <p><b>слове́нский</b> (adj.) esloveno</p>
                        <p><b>так себé</b> mais ou menos</p>
                        <p><b>удиви́тельно</b> surpreendentemente</p>
                        <p><b>украи́нский</b> (adj.) ucraniano</p>
                        <p><b>у́чится</b> (v. у́читься) estudar</p>
                        <p><b>фэ́нтэзи</b> fantasia (género lit.)</p>
                        <p><b>чили́ец</b> chileno</p>
                        <p><b>эскимóский</b> (adj.) esquimó (m.)</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;