import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'съезжаю', name: 'съезжаю', matcher: 'съезжаю' },
    { id: 'съездила', name: 'съездила', matcher: 'съездила' },
    { id: 'съезжайте', name: 'съезжайте', matcher: 'съезжайте' },
    { id: 'съездить', name: 'съездить', matcher: 'съездить' },
    { id: 'съехать', name: 'съехать', matcher: 'съехать' },
    { id: 'съехали', name: 'съехали', matcher: 'съехали' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="СЪЕ́ХАТЬ, СЪЕ́ЗДИТЬ или СЪЕЗЖА́ТЬ?" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>1.</b> Во время пробки я никогда не езжу по главной трассе. Я <input
                                id="съезжаюInput"
                                name="съезжаюInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> на просёлочные дороги.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>2.</b> Лена <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> за своими вещами.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>3.</b> Не <input
                                id="съезжайтеInput"
                                name="съезжайтеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> с дороги!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx}>
                        <div className={classes.inputWrapper}>
                            <b>4.</b> Как мне хочется <input
                                id="съездитьInput"
                                name="съездитьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> с вами на пляж.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>5.</b> Мы хотели <input
                                id="съехатьInput"
                                name="съехатьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> с квартиры, но не нашли другого подходящего варианта.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>6.</b> Они жили здесь раньше, но они <input
                                id="съехалиInput"
                                name="съехалиInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> 2 года назад!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;