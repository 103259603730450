import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: false },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: true },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: false },
    { id: 16, isCorrect: false },
    { id: 17, isCorrect: false },
    { id: 18, isCorrect: true },
    { id: 19, isCorrect: false },
    { id: 20, isCorrect: false },
    { id: 21, isCorrect: false },
    { id: 22, isCorrect: false },
    { id: 23, isCorrect: true },
    { id: 24, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="ВА́ЖНЫЕ ДА́ТЫ ПОРТУГА́ЛИИ. Selecione a opção correta." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>25.04. <span className={classes.orangeHighlight}>ДЕНЬ ОСВОБОЖДЕ́НИЯ</span></b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    двадца́тое пя́тое апре́ля
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    два́дцать пя́тый апре́ль
                                </button>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    два́дцать пять апре́ль
                                </button>
                                <button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    два́дцать пя́тое апре́ля
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>25.12. <span className={classes.orangeHighlight}>РОЖДЕСТВО́</span></b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    два́дцать пя́тое декабря́
                                </button>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    двадца́тый пя́тый дека́брь
                                </button>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    два́дцать пять дека́брe
                                </button>
                                <button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    двадца́тая пя́тая декабря́
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>10.06. <span className={classes.orangeHighlight}>ДЕНЬ ПОРТУГА́ЛИИ</span></b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    де́сять ию́ня
                                </button>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    деся́тое ию́ня
                                </button>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    деся́тый ию́нь
                                </button>
                                <button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    деся́тое ию́нь
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>05.10. <span className={classes.orangeHighlight}>ПРОВОЗГЛАШЕ́НИЕ РЕСПУ́БЛИКИ</span></b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    пя́тое октября́
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    пять октября́
                                </button>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    пя́тый октября́
                                </button>
                                <button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    пя́тый октя́брь
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>01.11. <span className={classes.orangeHighlight}>ДЕНЬ ВСЕХ СВЯТЫ́Х</span></b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    пе́рвый ноя́брь
                                </button>
                                <button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    пе́рвое ноября́
                                </button>
                                <button
                                    key={buttonsEx1[18].id}
                                    onClick={event => handleColor(event, buttonsEx1[18].isCorrect)}
                                >
                                    оди́н ноя́брь
                                </button>
                                <button
                                    key={buttonsEx1[19].id}
                                    onClick={event => handleColor(event, buttonsEx1[19].isCorrect)}
                                >
                                    оди́н ноября́
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>01.01. <span className={classes.orangeHighlight}>НО́ВЫЙ ГОД</span></b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[20].isCorrect)}
                                >
                                    пе́рвое янва́рь
                                </button>
                                <button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[21].isCorrect)}
                                >
                                    пе́рвая янва́рь
                                </button>
                                <button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[22].isCorrect)}
                                >
                                    пе́рвое января́
                                </button>
                                <button
                                    key={buttonsEx1[23].id}
                                    onClick={event => handleColor(event, buttonsEx1[23].isCorrect)}
                                >
                                    пе́рвый янва́рь
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;