import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';
import Plural from '../../../../../../Assets/Images/A1/Lesson4/plural.png';
import Singular from '../../../../../../Assets/Images/A1/Lesson4/singular.png';

const DragTypes = [
    { id: '1', type: 'singular', name: 'almofada', translation: 'поду́шка' },
    { id: '2', type: 'plural', name: 'cadeiras', translation: 'сту́лья' },
    { id: '3', type: 'plural', name: 'almofadas', translation: 'поду́шки' },
    { id: '4', type: 'singular', name: 'bola', translation: 'мяч' },
    { id: '5', type: 'singular', name: 'cadeira', translation: 'стул' },
    { id: '6', type: 'singular', name: 'quadro', translation: 'карти́на' },
    { id: '7', type: 'singular', name: 'livro', translation: 'кни́га' },
    { id: '8', type: 'plural', name: 'quadros', translation: 'карти́ны' },
    { id: '9', type: 'plural', name: 'livros', translation: 'кни́ги' },
    { id: '10', type: 'plural', name: 'bolas', translation: 'мячи́' },
    { id: '11', type: 'plural', name: 'folhas', translation: 'ли́стья' },
    { id: '12', type: 'singular', name: 'chávena', translation: 'ча́шка' },
    { id: '13', type: 'singular', name: 'núvem', translation: 'о́блако' },
    { id: '14', type: 'plural', name: 'chávenas', translation: 'ча́шки' },
    { id: '15', type: 'plural', name: 'nuvens', translation: 'облакá' },
    { id: '16', type: 'singular', name: 'folha', translation: 'лист' },
];

const DropTypes = [
    { id: '1', type: 'singular', name: 'Singular' },
    { id: '2', type: 'plural', name: 'Plural' },
];

const Exercises = props => {
    const [dropCards] = useState(DropTypes);
    const [dragCards] = useState(DragTypes);
    const [droppedListSingular, setDroppedListSingular] = useState([]);
    const [droppedListPlural, setDroppedListPlural] = useState([]);

    const DragCard = ({ item }) => {
        const [, drag] = useDrag({
            type: item.type,
            item: { ...item },
            collect: monitor => ({
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging()
            })
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {item.translation}
                </div>
            </div>);
    }

    const DisplayImage = ({ type }) => {
        const content = type === 'singular' ? (
            <img id="singular" src={Singular} alt="singular" width="220px" />
        ) : (
            <img id="plural" src={Plural} alt="plural" width="220px" />
        );

        return content;
    }

    const DroppedCardsSingular = () => {
        const cards = droppedListSingular.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DroppedCardsPlural = () => {
        const cards = droppedListPlural.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCard = ({ accepts: accept, item }) => {
        const [, drop] = useDrop(() => ({
            accept: item.type,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();
                const dropItem = monitor.getItem();

                if (didDrop) {
                    return;
                }

                if (dropItem.type === 'singular') {
                    if (!droppedListSingular.some(({ name }) => name === dropItem.name)) {
                        setDroppedListSingular([...droppedListSingular, dropItem]);
                    }
                } else {
                    if (!droppedListPlural.some(({ name }) => name === dropItem.name)) {
                        setDroppedListPlural([...droppedListPlural, dropItem]);
                    }
                }
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {item.type === 'singular' ?
                        <div>
                            <DisplayImage type={item.type} />
                            <DroppedCardsSingular />
                        </div> :
                        <div>
                            <DisplayImage type={item.type} />
                            <DroppedCardsPlural />
                        </div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort((a, b) => {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Arraste as palavras listadas para o respetivo desenho" />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards className={classes.dropCards} />
                </div>
            </div>
        </div>
    );
}

export default Exercises;