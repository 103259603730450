import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ва́жно</b> (adv.) importante</p>
                        <p><b>ве́рят</b> (v. ве́рить) acreditam</p>
                        <p><b>взад-вперёд</b> para frente e para trás</p>
                        <p><b>ви́шня</b> ginja</p>
                        <p><b>вниз</b> para baixo</p>
                        <p><b>(в) отве́т</b> (na) resposta</p>
                        <p><b>всю</b> (Acus.) toda</p>
                        <p><b>высо́кое давле́ние</b> hipertensão</p>
                        <p><b>гла́вное</b> o importante/o principal</p>
                        <p><b>гро́мко</b> (adv.) em alta voz</p>
                        <p><b>(до) у́тра</b> (até) de manhã</p>
                        <p><b>зо́нтик</b> guarda-chuva</p>
                        <p><b>икра́</b> caviar</p>
                        <p><b>исполнитель</b> intérprete (da canção)</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ка́жется</b> parece</p>
                        <p><b>клубни́ка</b> morango</p>
                        <p><b>конфе́ты</b> rebuçados</p>
                        <p><b>креве́тки</b> camarões</p>
                        <p><b>ма́ленького рóста</b> (Gen.) de baixa estatura</p>
                        <p><b>мандари́ны</b> tangerinas</p>
                        <p><b>(на) све́те</b> (no) mundo</p>
                        <p><b>наве́рное</b> talvez</p>
                        <p><b>непра́вильные</b> errados/as</p>
                        <p><b>нра́вится</b> (v. нра́виться) agrada</p>
                        <p><b>нра́вятся</b> (v. нра́виться) agradam</p>
                        <p><b>ну</b> então</p>
                        <p><b>оде́т</b> (adj. curto) (está) vestido</p>
                        <p><b>па́ра</b> par</p>
                        <p><b>па́рень</b> rapaz</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пе́сню</b> (Acus.) canção</p>
                        <p><b>пи́во</b> cerveja</p>
                        <p><b>пове́рь</b> (v. пове́рить) acredita (imperativo)</p>
                        <p><b>по́нял</b> (v. поня́ть) entendi (m.)</p>
                        <p><b>пообе́дать</b> almoçar</p>
                        <p><b>по́сле</b> depois</p>
                        <p><b>прóсто</b> simplesmente</p>
                        <p><b>пчёлы</b> abelhas</p>
                        <p><b>рекла́мная</b> publicitária</p>
                        <p><b>скри́пка</b> violino</p>
                        <p><b>сла́дкое</b> doce (n.)</p>
                        <p><b>сли́шком</b> demais</p>
                        <p><b>совсе́м</b> de todo</p>
                        <p><b>стихи́</b> 1. versos 2. poema</p>
                        <p><b>яйца</b> ovos</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;