import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import Subtitle from '../../../../../Shared/Subtitle/Subtitle';
import classes from './InternationalWords.css';
import { useDrag, useDrop } from 'react-dnd';
import ImageSlider from '../../../../../Shared/ImageSlider/ImageSlider';
import imagem1 from '../../../../../../Assets/Images/InternationalWords/imagem1.jpg';
import imagem2 from '../../../../../../Assets/Images/InternationalWords/imagem2.jpg';
import imagem3 from '../../../../../../Assets/Images/InternationalWords/imagem3.jpg';
import imagem4 from '../../../../../../Assets/Images/InternationalWords/imagem4.jpg';
import imagem5 from '../../../../../../Assets/Images/InternationalWords/imagem5.jpg';
import imagem6 from '../../../../../../Assets/Images/InternationalWords/imagem6.jpg';
import imagem7 from '../../../../../../Assets/Images/InternationalWords/imagem7.jpg';
import imagem8 from '../../../../../../Assets/Images/InternationalWords/imagem8.jpg';
import imagem9 from '../../../../../../Assets/Images/InternationalWords/imagem9.jpg';
import imagem10 from '../../../../../../Assets/Images/InternationalWords/imagem10.jpg';
import imagem11 from '../../../../../../Assets/Images/InternationalWords/imagem11.jpg';
import imagem12 from '../../../../../../Assets/Images/InternationalWords/imagem12.jpg';
import imagem13 from '../../../../../../Assets/Images/InternationalWords/imagem13.jpg';
import imagem14 from '../../../../../../Assets/Images/InternationalWords/imagem14.jpg';
import imagem15 from '../../../../../../Assets/Images/InternationalWords/imagem15.jpg';
import imagem16 from '../../../../../../Assets/Images/InternationalWords/imagem16.jpg';
import imagem17 from '../../../../../../Assets/Images/InternationalWords/imagem17.jpg';
import imagem18 from '../../../../../../Assets/Images/InternationalWords/imagem18.jpg';
import imagem19 from '../../../../../../Assets/Images/InternationalWords/imagem19.jpg';
import imagem20 from '../../../../../../Assets/Images/InternationalWords/imagem20.jpg';
import imagem21 from '../../../../../../Assets/Images/InternationalWords/imagem21.jpg';
import imagem22 from '../../../../../../Assets/Images/InternationalWords/imagem22.jpg';


const ItemTypes = [
    { id: 'banana', name: 'banana', translation: 'банан' },
    { id: 'ananas', name: 'ananás', translation: 'ананас' },
    { id: 'sopa', name: 'sopa', translation: 'суп' },
    { id: 'cosmos', name: 'cosmos', translation: 'космос' },
    { id: 'eco', name: 'eco', translation: 'эхо' },
    { id: 'aeroporto', name: 'aeroporto', translation: 'аэропорт' },
    { id: 'wisky', name: 'wisky', translation: 'виски' },
    { id: 'vodka', name: 'vodka', translation: 'водка' },
    { id: 'taxi', name: 'táxi', translation: 'такси' },
    { id: 'wc', name: 'wc', translation: 'туалет' },
];

const slideImages = [
    { image: imagem1, alt: 'компьютеры' },
    { image: imagem2, alt: 'Европа' },
    { image: imagem3, alt: 'Университет' },
    { image: imagem4, alt: 'метро' },
    { image: imagem5, alt: 'ресторан' },
    { image: imagem6, alt: 'раит гипермаркет' },
    { image: imagem7, alt: 'супермаркет электроники' },
    { image: imagem8, alt: 'продукты' },
    { image: imagem9, alt: 'континент' },
    { image: imagem10, alt: 'такси' },
    { image: imagem11, alt: 'коктейль-бар кофе' },
    { image: imagem12, alt: 'телефон' },
    { image: imagem13, alt: 'аэропорт' },
    { image: imagem14, alt: 'сувениры' },
    { image: imagem15, alt: 'казино' },
    { image: imagem16, alt: 'туалет' },
    { image: imagem17, alt: 'Отель' },
    { image: imagem18, alt: 'театр' },
    { image: imagem19, alt: 'фото 1 час' },
    { image: imagem20, alt: 'Банк' },
    { image: imagem21, alt: 'Спорт клуб' },
    { image: imagem22, alt: 'Информация' },
];

const InternationalWords = props => {
    const [dropCards] = useState(ItemTypes);

    const DragCard = ({ name, type }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div ref={drop} className={classes.dropCard}>
                    {hasDropped ?
                        <div>
                            <p>{item.translation}</p>
                            <p>{item.name}</p>
                        </div> :
                        <p>{item.translation}</p>
                    }
                </div>
            </div>);
    }

    const DragCards = () => {
        const cards = dropCards.map(item => {
            return <DragCard key={item.id} name={item.name} type={item.id} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const sortedCards = dropCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <Subtitle className={classes.subtitle}>1. Associe as palavras à tradução correspondente</Subtitle>
            <DragCards />
            <DropCards />
            <Subtitle className={classes.subtitle}>2. Tente ler as palavras das imagens</Subtitle>
            <ImageSlider slides={slideImages} />
        </div>
    );
}

export default InternationalWords;