import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>анги́ной</b> (instr.) (ter) angina</p>
                        <p><b>библиоте́карем</b> (Instr.) bibliotecário/a</p>
                        <p><b>взро́слым</b> (Instr.) adulto</p>
                        <p><b>го́рло</b> garganta</p>
                        <p><b>грибни́к</b> homem que colhe cogumelos</p>
                        <p><b>грибы́</b> cogumelos</p>
                        <p><b>живо́тных</b> (Acus.) animais</p>
                        <p><b>занима́ться</b> 1. ocupar-se de… 2. estudar</p>
                        <p><b>зоо́логом</b> (Instr.) zoólogo</p>
                        <p><b>(из) серебра́</b> (de) prata</p>
                        <p><b>интересова́ться</b> interessar-se</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>испо́лнилось</b> (v. испо́лниться) fez ( … anos)</p>
                        <p><b>космона́втом</b> (Instr.) astronauta</p>
                        <p><b>ку́хней</b> (Instr.) cozinhas</p>
                        <p><b>лекáрство</b> medicamento</p>
                        <p><b>ма́гия</b> magia</p>
                        <p><b>ма́рки</b> selos</p>
                        <p><b>мечта́ла</b> (v. мечта́ть) sonhava (f.)</p>
                        <p><b>(о) звёздах</b> (acerca de) estrelas</p>
                        <p><b>(о) ко́смосе</b> (acerca de) espaço</p>
                        <p><b>по́варом</b> (Instr.) cozinheiro</p>
                        <p><b>пока́зы</b> demonstrações</p>
                        <p><b>помо́щником</b> (Instr.) ajudante</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>по́сле стажиро́вки</b> (Gen.) depois do estágio</p>
                        <p><b>продавцо́м</b> (Instr.) vendedor</p>
                        <p><b>ра́зные</b> diferentes</p>
                        <p><b>реклами́руют</b> (v. реклами́ровать) fazem publicidade</p>
                        <p><b>реши́ла</b> (v. реши́ть) decidiu (f.)</p>
                        <p><b>собира́ть</b> apanhar</p>
                        <p><b>стать</b> tornar-se</p>
                        <p><b>тре́нером</b> (Instr.) treinador</p>
                        <p><b>увлека́ться</b> interessar-se</p>
                        <p><b>украше́ния</b> (Acus.) bijutaria</p>
                        <p><b>фанáт</b> fã</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;