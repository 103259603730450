import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'тa', name: 'тa', translation: 'тa' },
    { id: 'тот', name: 'тот', translation: 'тот' },
    { id: 'э́тот', name: 'э́тот', translation: 'э́тот' },
    { id: 'э́то', name: 'э́то', translation: 'э́то' },
    { id: 'э́та', name: 'э́та', translation: 'э́та' },
    { id: 'то', name: 'то', translation: 'то' },
    { id: 'э́ти', name: 'э́ти', translation: 'э́ти' },
    { id: 'те', name: 'те', translation: 'те' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = (
            <div className={classes.questionsWrapper}>
                <div className={classes.question}>1) Вам нра́вится э́то пальто́? Да, <DropCard key={dropCards[6].id} item={dropCards[6]} /> нравится, а <DropCard key={dropCards[2].id} item={dropCards[2]} /> нет.</div>
                <div className={classes.question}>2) Вам нра́вится э́та кни́га? Да, <DropCard key={dropCards[4].id} item={dropCards[4]} /> нравится, а <DropCard key={dropCards[0].id} item={dropCards[0]} /> нет.</div>
                <div className={classes.question}>3) Вам нра́вятся э́ти джи́нсы? Да, <DropCard key={dropCards[5].id} item={dropCards[5]} /> нравится, а <DropCard key={dropCards[1].id} item={dropCards[1]} /> нет.</div>
                <div className={classes.question}>4) Вам нра́вится э́тот расска́з? Да, <DropCard key={dropCards[7].id} item={dropCards[7]} /> нравится, а <DropCard key={dropCards[3].id} item={dropCards[3]} /> нет.</div>
            </div>
        );

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha os demonstrativos apropriados a cada contexto." />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;