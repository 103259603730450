import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import PrepositiveCaseTable from '../../../../../../Assets/Images/A1/Lesson5/prepositiveCaseTable.png';
import BHAExamples from '../../../../../../Assets/Images/A1/Lesson5/BHAExamples.png';
import HomeWordUseCaseExample from '../../../../../../Assets/Images/A1/Lesson5/homeWordUseCaseExample.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРЕДЛОЖНЫЙ ПАДЕЖ</p>
                    <p>Caso Prepositivo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="prepositiveCaseTable" src={PrepositiveCaseTable} alt="prepositive case table" width="650px" />
                </div>
                <div className={classes.item2}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O Caso Prepositivo, quando denomina “lugar onde”, usa-se sempre com as preposições <b><span className={classes.orangeHighlight}>В</span></b> e <b><span className={classes.orangeHighlight}>НА</span></b>. A primeira para espaços fechados (dentro de…) e a segunda para espaços abertos (em…/à superfície…). Por vezes, a escolha da preposição é simplesmente determinada pelo substantivo que se lhe segue:
                    </p>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="BHAExamples" src={BHAExamples} alt="B и на examples" width="350px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Quando se trata de <b>atividades</b> ou <b>eventos</b>, normalmente,
                                usa-se a preposição <b><span className={classes.orangeHighlight}>HA</span></b>.
                            </p>
                            <p><b><span className={classes.orangeHighlight}>на</span></b> собра́нии</p>
                            <p><b><span className={classes.orangeHighlight}>на</span></b> уро́ке</p>
                            <p><b><span className={classes.orangeHighlight}>на</span></b> конце́рте</p>
                            <p><b><span className={classes.orangeHighlight}>на</span></b> рабо́те</p>
                        </div>
                    </div>
                    <div className={classes.item3}>
                        <p>
                            <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A palavra <i><span className={classes.orangeHighlight}>дом</span></i> (casa) no
                            entanto, quando significa “em casa”, no sentido de <b>no lar</b>, não é acompanhada de preposição e desinência é -
                            <span className={classes.orangeHighlight}>a</span>:
                        </p>
                    </div>
                    <div className={classes.item4}>
                        <img id="HomeWordUseCaseExample" src={HomeWordUseCaseExample} alt="Home Word Use Case Example" width="450px" />
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>VERBO “БЫТЬ”</p>
                </GrammarSubtitle>
                <div className={classes.item5}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O verbo <b><span className={classes.orangeHighlight}>БЫТЬ</span></b> praticamente não se emprega no presente quando
                                significa ser e estar. Com o significado de haver emprega-se usando-se uma só forma para todas as pessoas (tal como o
                                verbo haver em português):
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><span className={classes.orangeHighlight}>ЕСТЬ</span> = <span className={classes.orangeHighlight}>HÁ</span> (3.ª pess. pl.) </p>
                            <p>В го́роде <span className={classes.orangeHighlight}>есть</span> зоопа́рк. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;