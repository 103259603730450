import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'белый лист', name: 'белый лист', translation: 'белый лист' },
    { id: 'сахар', name: 'сахар', translation: 'сахар' },
    { id: 'без шоколада', name: 'без шоколада', translation: 'без шоколада' },
    { id: 'сливки', name: 'сливки', translation: 'сливки' },
    { id: 'вата', name: 'вата', translation: 'вата' },
    { id: 'порошок', name: 'порошок', translation: 'порошок' },
    { id: 'зубы', name: 'зубы', translation: 'зубы' },
    { id: 'творожок', name: 'творожок', translation: 'творожок' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <span className={classes.cardWrapper}>
                <span ref={drag} className={classes.dragCard}>
                    {name}
                </span>
            </span>
        );
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <span className={classes.cardWrapper}>
                <span ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <DragCard key={item.id} type={item.id} name={item.name} /> :
                        <span className={classes.dropCard} type={item.id}>largar aqui</span>
                    }
                </span>
            </span>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = (
            <div className={classes.questionsWrapper}>
                <div className={classes.question}>
                    <span>
                        <p>Первый снег, как <DropCard key={dropCards[1].id} item={dropCards[1]} />– сладкий.</p>
                        <p>Словно <DropCard key={dropCards[0].id} item={dropCards[0]} /> тетрадки.</p>
                        <p>Он кружит несмело, тихо,</p>
                        <p>Словно бабочка-трусиха.</p>
                    </span>
                    <span>
                        <p>Посмотрите-ка, ребята,</p>
                        <p>Всё вокруг покрыла <DropCard key={dropCards[4].id} item={dropCards[4]} />!</p>
                        <p>А в ответ раздался смех:</p>
                        <p>- Это выпал первый снег.</p>
                    </span>
                    <span>
                        <p>Серебрит дома и лужи…</p>
                        <p>Снег – на завтрак, снег – на ужин,</p>
                        <p>Эскимо <DropCard key={dropCards[2].id} item={dropCards[2]} />.</p>
                        <p>Вот десерт, какой нам надо!</p>
                    </span>
                    <span>
                        <p>Не согласна только Люба:</p>
                        <p>- Это вовсе не снежок.</p>
                        <p>- Дед Мороз почистил <DropCard key={dropCards[6].id} item={dropCards[6]} /></p>
                        <p>И рассыпал <DropCard key={dropCards[5].id} item={dropCards[5]} />.</p>
                    </span>
                    <span>
                        <p>Пышный, словно <DropCard key={dropCards[3].id} item={dropCards[3]} /> к кофе,</p>
                        <p>Снег хорош в анфас и в профиль.</p>
                        <p>Вата сладкая в ладонях.</p>
                        <p>Не набрал ещё он силу,</p>
                        <p>Чтобы вихрем закружило,</p>
                        <p>Вальс танцует аккуратно.</p>
                        <p>Первый снег, и всем приятно!</p>
                    </span>
                    <span>
                        <p>Легкий беленький снежок</p>
                        <p>Так похож на <DropCard key={dropCards[7].id} item={dropCards[7]} />!</p>
                        <p>Только есть его не буду,</p>
                        <p>Ведь снежинки - это чудо!</p>
                    </span>
                </div>
            </div>
        );

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Вставьте недостающие слова." />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;