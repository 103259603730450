import React, { useState } from 'react';
import centerColumn from '../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Repetition.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'Но моя самая любимая пора года — это лето!', name: 'Но моя самая любимая пора года — это лето!', translation: 'Когда мне в школе задали написать сочинение о моём любимом времени года, я колебался недолго. Да, мне нравится золотая осень, белоснежная зима. Я люблю также весну, когда цветут деревья.' },
    { id: 'Не сидится дома, хочется гулять, веселиться. И люди вокруг тоже чувствуют что-то подобное: у всех праздничное, приподнятое настроение.', name: 'Не сидится дома, хочется гулять, веселиться. И люди вокруг тоже чувствуют что-то подобное: у всех праздничное, приподнятое настроение.', translation: 'Это время, когда жара и духота еще не властвуют над городами и сёлами. Вокруг много цветов. И даже белые «свечечки» еще светятся на каштанах. Вечерами тепло, но дует свежий ветерок.' },
    { id: 'Ведь на дворе стоит прекрасная пора года, нельзя терять ни минуты!', name: 'Ведь на дворе стоит прекрасная пора года, нельзя терять ни минуты!', translation: 'Во время летних каникул можно вволю порадоваться жизни: путешествовать, купаться, играть в футбол, баскетбол или бадминтон, кататься на велосипеде. Есть много времени читать книги, которые захочется, а не только по школьной программе. Есть время свободно посидеть за компьютером, но этим не стоит увлекаться.' },
    { id: 'Ни за что не хочу влезать в дублёнку и сапоги!', name: 'Ни за что не хочу влезать в дублёнку и сапоги!', translation: 'Конечно, летом часто бывает жарко и душно, особенно в большом пыльном городе. Но я ни за что не променяю это тепло на зимние холода!' },
    { id: 'Затем созреет тёмная сладкая вишня, а дальше — яблочки, виноград и сливы.', name: 'Затем созреет тёмная сладкая вишня, а дальше — яблочки, виноград и сливы.', translation: 'Лето мне нравится не только потому, что летом каникулы. В это время года природа так щедра на дары: овощи, фрукты, ягоды. Первой поспеет черешня.' },
];

const Repetition = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div className={classes.translation}>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Прочитайте текст и дополните его подходящими по смыслу предложениями." />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                    <p className={classes.justifiedText}>Правда, я люблю лето за то, что среди других времен года оно самое щедрое к нам!</p>
                    <div className={classes.sourceLink}>
                        <p className={classes.justifiedText}>Fonte: https://ps.1sept.ru/article.php?ID=200405509 РОДИТЕЛЬСКАЯ ГАЗЕТА</p>
                        <p className={classes.justifiedText}>ПОДСКАЗКА Autor do texto: Александра БОЧАВЕР</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Repetition;