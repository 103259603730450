import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
    width: '240px',
    height: '240px',
    position: 'relative'
};

export class MapContainer extends Component {
    render() {
        return (
            <Map
                google={this.props.google}
                zoom={15}
                style={mapStyles}
                initialCenter={{
                    lat: 38.7536,
                    lng: -9.1572
                }}>
                <Marker position={{ lat: 38.7536, lng: -9.1572 }} />
            </Map>
        );
    }
}

MapContainer.defaultProps = mapStyles;

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBErC__NwXDp7trOhEbzkjT2YEFEzcjadk'
})(MapContainer);