import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import NominativeCaseTable from '../../../../../../Assets/Images/A1/Lesson3/nominativeCaseTable.png';
import PossessivePronounsTable from '../../../../../../Assets/Images/A1/Lesson3/possessivePronounsTable.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>РОД</p>
                    <p>Género</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Em Russo há três géneros: masculino, feminino e neutro. No geral, o género associa-se à terminação das palavras.</p>
                </div>
                <div className={classes.item2}>
                    <img id="nominativeCaseTable" src={NominativeCaseTable} alt="nominative case table" />
                </div>
                <GrammarSubtitle>
                    <p>ПРИТЯЖА́ТЕЛЬНЫЕ МЕСТОИМЕ́НИЯ</p>
                    <p>Pronomes Possessivos</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <img id="possessivePronounsTable" src={PossessivePronounsTable} alt="possessive pronouns table" />
                </div>
                <div className={classes.item4}>
                    <p><font size="5"><span className={classes.greenHighlight}>&#9758;</span></font> Os pronomes possessivos indicam a posse. À semelhança do português,
                        quando a posse se refere às pessoas Я, ТЫ, МЫ e ВЫ, tem forma de nominativo e o pronome concorda com o objeto e varia
                        em género e número:</p>
                    <p> Это <u><span className={classes.orangeHighlight}> мой брат </span></u> и
                        <u><span className={classes.orangeHighlight}> моя сестра</span></u>.</p>
                    <p>Este é <u><span className={classes.orangeHighlight}>o irmão dele</span></u> e <u><span className={classes.orangeHighlight}>a irmã dele</span></u>.</p>
                    <p><font size="5"><span className={classes.greenHighlight}>&#9758;</span></font> Quando se refere a ОН, ОНА, ОНИ, o pronome possessivo concorda com
                        a pessoa a quem pertence o objeto e tem a forma de pronome pessoal no genitivo:
                    </p>
                    <p>Это <u><span className={classes.orangeHighlight}> его брат</span></u> и
                        <u><span className={classes.orangeHighlight}> его сестра</span></u>.</p>
                    <p>Este é <u><span className={classes.orangeHighlight}>o irmão dele</span></u> e <u><span className={classes.orangeHighlight}>a irmã dele</span></u>.</p>
                    <p><font size="5"><span className={classes.greenHighlight}>&#9758;</span></font> A particularidade do russo é que o pronome interrogativo de posse
                        concorda em género e número com o objeto a que se refere a pergunta:</p>
                    <p><span className={classes.orangeHighlight}>Чей</span><sub>masc.</sub> (это) карандаш<sub>masc.</sub>?</p>
                    <p><span className={classes.orangeHighlight}>De quem</span> é (este) lápis?</p>
                    <p><span className={classes.orangeHighlight}>Чья</span><sub>neutro</sub> (это) ручка<sub>neutro</sub>?</p>
                    <p><span className={classes.orangeHighlight}>De quem</span> é (esta) caneta?</p>
                </div>
            </div>
        </div >);
}

export default Grammar;