import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПОРЯДКОВЫЕ ЧИСЛИТЕЛЬН</p>
                    <p>Numerais Ordinais</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Os numerais ordinais em russo têm forma de adjetivos, portanto, têm desinências de género, número e caso.
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Quando o numeral é composto por dois ou mais algarismos, apenas o último tem a forma de adjetivo, enquanto o(s) outro(s) mantêm a forma do numeral cardinal: двадца́тый, mas два́дцать пе́рвый/сто два́дцать пе́рвый.
                    </p>
                </div>
                <GrammarSubtitle>
                    <p>ДАТЫ</p>
                    <p>Datas</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <p>
                        Сего́дня <b><span className={classes.orangeHighlight}>пя́тое января́</span></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>&#8592; Número ordinal</b> (neutro) <b>+ mês</b> (Genitivo)
                    </p>
                    <p>
                        Hoje é <b><span className={classes.orangeHighlight}>cinco de janeiro</span></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>&#8592; Número cardinal + de + mês</b>
                    </p>
                </div>
                <GrammarSubtitle>
                    <p>СРАВНИТЕЛЬНЫЕ КОНСТРУКЦИИ</p>
                    <p>Construções Comparativas</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Há duas possibilidades de construir construções comparativas de superioridade/inferioridade:
                    </p>
                    <div className={classes.item3Title}>
                        <p>1. ADJETIVO DE SUPERIORIDADE/INFERIORIDADE + GENITIVO</p>
                    </div>
                    <p>
                        <i>Я люблю́ чита́ть но́вости <b>бо́льше <span className={classes.orangeHighlight}>тебя́</span></b>.</i>
                    </p>
                    <p>
                        <i>Ты лю́бишь чита́ть но́вости <b>ме́ньше <span className={classes.orangeHighlight}>меня́</span></b>.</i>
                    </p>
                    <div className={classes.item3Title}>
                        <p>
                            2. ADJETIVO DE SUPERIORIDADE/INFERIORIDADE + COMPLEMENTADOR ЧЕМ + NOMINATIVO
                        </p>
                    </div>
                    <p><i>Я люблю́ чита́ть но́вости <b>бо́льше, чем <span className={classes.orangeHighlight}>ты</span>.</b></i>
                    </p>
                    <p>
                        <i>Ты лю́бишь чита́ть но́вости <b>ме́ньше, чем <span className={classes.orangeHighlight}>я</span>.</b></i>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Grammar;