import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson10 from '../../../../../../Assets/Images/A1/Lesson10/image1lesson10.png';
import Image2Lesson10 from '../../../../../../Assets/Images/A1/Lesson10/image2lesson10.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ВИНИТЕЛЬНЫЙ ПАДЕЖ</p>
                    <p>Caso Acusativo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="ILikePorridgeDirectComplement" src={Image1Lesson10} alt="I like porridge direct complement" width="350px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O Acusativo não preposicionado indica
                                geralmente o complemento/objeto
                                direto de um verbo transitivo, como resposta às perguntas <b><span className={classes.orangeHighlight}>Что́?/ Кого́?</span></b>
                            </p>
                            <p>
                                - <b><span className={classes.orangeHighlight}>Что́</span></b> ты чита́ешь? - Я чита́ю кни́гу.
                            </p>
                            <p>
                                - <b><span className={classes.orangeHighlight}>Кого́</span></b> ты лю́бишь? - Я люблю́ А́нну.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <GrammarSubtitle>
                <p>ХОТЕ́ТЬ</p>
                <p>Conjugação mista</p>
            </GrammarSubtitle>
            <div className={classes.item2}>
                <div className={classes.gridItems}>
                    <div className={classes.gridItem1}>
                        <img id="WantVerbConjugationPresent" src={Image2Lesson10} alt="want verb conjugation present" width="200px" />
                    </div>
                    <div className={classes.gridItem2}>
                        <p>
                            <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> o verbo <b>хоте́ть</b> segue o
                            paradigma da <b>I Conjugação</b> no singular, e o da <b>II Conjugação</b> no plural:
                        </p>
                        <p><b>I Conjugação</b></p>
                        <p><b>II Conjugação</b></p>
                    </div>
                </div>
            </div>
            <div className={classes.item3}>
                <p>
                    <b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ОБЫ́ЧНО</span> / <span className={classes.orangeHighlight}>СЕЙЧА́С</span></b> Em russo, os verbos no Presente podem designar uma ação pontual ou habitual. Advérbios como <b><span className={classes.orangeHighlight}>ОБЫ́ЧНО</span> (habitualmente)</b> e <b><span className={classes.orangeHighlight}>СЕЙЧА́С</span> (agora)</b> ajudam a desambiguar o significado das frases:
                </p>
                <div className={classes.gridItems}>
                    <div className={classes.gridItem1}>
                        <p>Что́ ты <b><span className={classes.orangeHighlight}>обы́чно</span> чита́ешь? </b></p>
                        <p>
                            <b><span className={classes.orangeHighlight}>Обы́чно</span></b> я чита́ю журна́л “ Сноб”.
                        </p>
                    </div>
                    <div className={classes.gridItem2}>
                        <p>Что́ ты <b><span className={classes.orangeHighlight}>сейча́с</span> чита́ешь? </b></p>
                        <p>
                            <b><span className={classes.orangeHighlight}>Сейча́с</span></b> я чита́ю журна́л “ Сноб”.
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Grammar;