import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: true },
    { id: 7, isCorrect: true },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: true },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: false },
    { id: 12, isCorrect: true },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: true },
    { id: 15, isCorrect: true },
    { id: 16, isCorrect: false },
    { id: 17, isCorrect: false },
    { id: 18, isCorrect: true },
    { id: 19, isCorrect: true },
    { id: 20, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    const exerciseQuestion = (
        <p>Seleccione: <i>Что или что́бы?</i></p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            1. Он знал, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    побе́гаю
                                </button>
                            </span> мне не нра́вится моро́женое.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            2. Он сказа́л, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> я съе́ла моро́женое.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            3. Моя́ ма́ма хоте́ла, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> я поступи́л в университе́т.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            4. Я ду́маю, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> он хо́чет поступи́ть в университе́т.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            5. Они́ обеща́ли, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> приду́т ра́ньше.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            6. Они́ проси́ли, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> за́втра маши́на была́ гото́ва.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            7. Гла́вное, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> мы бы́ли вме́сте.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            8. Я рад, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> мы вчера́ бы́ли вме́сте.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            9. Преподава́тель написа́л, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> мы прие́хали в пя́тницу.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            10. Моя́ сестра́ написа́ла, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[18].id}
                                    onClick={event => handleColor(event, buttonsEx1[18].isCorrect)}
                                >
                                    что
                                </button>/<button
                                    key={buttonsEx1[19].id}
                                    onClick={event => handleColor(event, buttonsEx1[19].isCorrect)}
                                >
                                    что́бы
                                </button>
                            </span> она́ ско́ро прие́дет.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;