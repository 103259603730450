import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: '1', type: 'type6', name: 'мы бу́дем бо́льше путеше́ствовать.' },
    { id: '2', type: 'type4', name: 'мы бы ему́ подари́ли пиани́но.' },
    { id: '3', type: 'type2', name: 'мы ку́пим но́вую кварти́ру.' },
    { id: '4', type: 'type1', name: 'мы купи́ли бы но́вую кварти́ру.' },
    { id: '5', type: 'type5', name: 'мы бы бо́льше путеше́ствовали.' },
    { id: '6', type: 'type3', name: 'ему́ ну́жно бу́дет купи́ть пиани́но.' },
];

const DropTypes = [
    { id: '1', type: 'type1', name: '1. Е́сли бы у нас бы́ли де́ньги,' },
    { id: '2', type: 'type2', name: '2. Е́сли мы за́втра полу́чим де́ньги,' },
    { id: '3', type: 'type3', name: '3. Е́сли наш сын поступи́т в консервато́рию,' },
    { id: '4', type: 'type4', name: '4. Е́сли бы наш сын поступи́л в консервато́рию,' },
    { id: '5', type: 'type5', name: '5. Е́сли бы у нас бы́ло бо́льше вре́мени,' },
    { id: '6', type: 'type6', name: '6. Е́сли у нас бу́дет бо́льше вре́мени,' },
];

const Exercises = props => {
    const [dropCards, setDropCards] = useState(DropTypes);
    const [dragCards, setDragCards] = useState(ItemTypes);

    const DragCard = ({ item }) => {
        const [, drag] = useDrag({
            type: item.type,
            item: { ...item },
            collect: monitor => ({
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging()
            })
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {item.name}
                </div>
            </div>
        );
    }

    const handleRemoveItem = (name, type) => {
        setDragCards(dragCards.filter(item => item.name !== name));
        setDropCards(dropCards.filter(item => item.type !== type));
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [, drop] = useDrop(() => ({
            accept: item.type,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const canDrop = monitor.canDrop();
                const dragItem = monitor.getItem();

                if (canDrop) {
                    handleRemoveItem(dragItem.name, dragItem.type);
                }
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    <div className={classes.dropCard} type={item.id}>{item.name}</div>
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard item={item} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Faça a correspondência:" />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;