import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'в А́нглии', name: 'в А́нглии', translation: 'Ма́рта у́чится' },
    { id: 'ребёнка', name: 'ребёнка', translation: 'Па́па купа́ет' },
    { id: 'ка́ждый день', name: 'ка́ждый день', translation: 'Ви́ктор бре́ется' },
    { id: 'кра́ситься', name: 'кра́ситься', translation: 'Моя́ подру́га не лю́бит' },
    { id: 'дом', name: 'дом', translation: 'Вита́лий сейча́с кра́сит' },
    { id: 'в школе', name: 'в школе', translation: 'Пе́дру у́чит дете́й' },
    { id: 'купа́ться в реке́', name: 'купа́ться в реке́', translation: '7) Мы ча́сто хо́дим' },
    { id: 'посу́ду?', name: 'посу́ду?', translation: 'Ты не хо́чешь мыть' },
    { id: 'поча́ще', name: 'поча́ще', translation: 'Тебе́ ну́жно мыться' },
    { id: 'одева́ется', name: 'одева́ется', translation: 'Она́ о́чень роско́шно' },
    { id: 'ку́клу', name: 'ку́клу', translation: 'Де́вочка одева́ет' },
    { id: 'меня́ расчёсывает', name: 'меня́ расчёсывает', translation: 'Я не люблю́, когда́ ма́ма' },
    { id: 'расчёсываться', name: 'расчёсываться', translation: 'Где зе́ркало? Я хочу́' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Ligue as frases corretamente" />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;