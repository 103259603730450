import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бо́ли</b> (Gen.) de dor</p>
                        <p><b>боро́ться</b> lutar/combater</p>
                        <p><b>боя́ться</b> ter medo</p>
                        <p><b>брить</b> barbear</p>
                        <p><b>бри́ться</b> barbear-se</p>
                        <p><b>вести себя́</b> comportar-se</p>
                        <p><b>волк</b> lobo</p>
                        <p><b>вы́летит</b> (v. вы́лететь) voará</p>
                        <p><b>высоты́</b> (Gen.) de altura</p>
                        <p><b>гото́ва</b> preparada</p>
                        <p><b>до́чка</b> filhinha</p>
                        <p><b>кра́сить</b> pintar</p>
                        <p><b>кра́ситься</b> pintar-se</p>
                        <p><b>купа́ть</b> dar banho</p>
                        <p><b>купа́ться</b> tomar banho</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ла́ска</b> carinho</p>
                        <p><b>люби́ть себя́</b> amar a si próprio</p>
                        <p><b>люби́мая</b> amada</p>
                        <p><b>любо́вь</b> amor</p>
                        <p><b>мыть</b> lavar</p>
                        <p><b>мы́ться</b> lavar-se</p>
                        <p><b>настрое́ние</b> 1. disposição 2. estado de espírito</p>
                        <p><b>не́жность</b> ternura</p>
                        <p><b>ненави́деть себя́</b> odiar a si próprio</p>
                        <p><b>одева́ть</b> vestir</p>
                        <p><b>одева́ться</b> vestir-se</p>
                        <p><b>перси́дский язы́к</b> língua persa</p>
                        <p><b>поднима́ется</b> (v. поднима́ться) sobe/melhora</p>
                        <p><b>поле́зно</b> (adv.) útil</p>
                        <p><b>пти́чка</b> passarinho</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>расчёсывать</b> pentear</p>
                        <p><b>расчёсываться</b> pentear-se</p>
                        <p><b>са́мая</b> (pron.) o mais (f.)</p>
                        <p><b>себя́</b> (pron. reflexivo) a si próprio</p>
                        <p><b>смеёшься</b> (v. смея́ться) ris</p>
                        <p><b>смея́ться</b> rir</p>
                        <p><b>спра́шиваешь</b> (v. спра́шивать) perguntas</p>
                        <p><b>улыба́емся</b> (v. улыба́ться) sorrimos</p>
                        <p><b>улыба́ешься</b> (v. улыба́ться) sorris</p>
                        <p><b>улыба́ться</b> sorrir</p>
                        <p><b>улыба́юсь</b> (v. улыба́ться) sorrio</p>
                        <p><b>улы́бку</b> (Acus.) sorriso</p>
                        <p><b>учи́ться</b> estudar</p>
                        <p><b>це́лый</b> inteiro</p>
                        <p><b>чу́вствовать себя́</b> sentir-se</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;