import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ве́чер</b> tarde</p>
                        <p><b>волшéбные словá</b> palavras mágicas</p>
                        <p><b>Всего́ до́брого!</b> Tudo de bom!</p>
                        <p><b>Вы você</b>/ vocês</p>
                        <p><b>день</b> dia</p>
                        <p><b>До свида́ния!</b> 1. Adeus 2. Até logo</p>
                        <p><b>Добро́ пожа́ловать!</b> Bem-vindo! / Bem-vindos!</p>
                        <p><b>До́брое у́тро!</b> Bom dia!</p>
                        <p><b>До́брый ве́чер!</b> Boa noite!</p>
                        <p><b>До́брый день!</b> Boa tarde!</p>
                        <p><b>друг</b> amigo</p>
                        <p><b>есть</b> há</p>
                        <p><b>Здра́вствуй!</b> Olá!</p>
                        <p><b>Здра́вствуйте!</b> Olá! (1. registo formal 2. dirigido a várias pessoas)</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>извини́те (v. извини́ть)</b> desculpe</p>
                        <p><b>Как делá?</b> Como está? / Como estás?</p>
                        <p><b>кто</b> quem</p>
                        <p><b>меня́ зову́т</b> chamo-me</p>
                        <p><b>мы</b> nós</p>
                        <p><b>не</b> não (aparece antes de nomes, verbos, adjetivos)</p>
                        <p><b>нет</b> não (aparece isoladamente)</p>
                        <p><b>ничего́</b> nada</p>
                        <p><b>оди́н</b> um</p>
                        <p><b>одно́</b> um (neutro)</p>
                        <p><b>он</b> ele</p>
                        <p><b>онá</b> ela</p>
                        <p><b>они́</b> eles/ elas</p>
                        <p><b>онó</b> pronome pessoal neutro</p>
                        <p><b>О́чень прия́тно!</b> Muito prazer!</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пожа́луйста</b> por favor</p>
                        <p><b>Пока́!</b> Até logo!</p>
                        <p><b>Приве́т!</b> Olá! (informal)</p>
                        <p><b>рубле́й</b>(Gen. pl. de рубль) rublos</p>
                        <p><b>сего́дня</b> hoje</p>
                        <p><b>сосе́д</b> vizinho</p>
                        <p><b>спаси́бо</b> obrigado/obrigada</p>
                        <p><b>Споко́йной нóчи!</b> Boa noite! (despedida)</p>
                        <p><b>там</b> ali/ aí</p>
                        <p><b>ты</b> tu</p>
                        <p><b>у́тро</b> manhã</p>
                        <p><b>чай</b> chá</p>
                        <p><b>что</b> que</p>
                        <p><b>чай</b> chá</p>
                        <p><b>что</b> que</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;