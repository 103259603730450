import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'зовут', name: 'зовут', matcher: 'зовут' },
    { id: 'зовёт', name: 'зовёт', matcher: 'зовёт' },
    { id: 'зовёт2', name: 'зовёт', matcher: 'зовёт' },
    { id: 'Eё', name: 'Eё', matcher: 'Eё' },
    { id: 'их', name: 'их', matcher: 'их' },
    { id: 'Меня', name: 'Меня', matcher: 'Меня' },
    { id: 'вас', name: 'вас', matcher: 'вас' },
    { id: 'Tебя', name: 'Tебя', matcher: 'Tебя' },
    { id: 'его', name: 'его', matcher: 'его' },
    { id: 'нас', name: 'нас', matcher: 'нас' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    const exerciseQuestion = (
        <p>Preencha com o verbo <i>ЗВАТЬ</i> na forma correta.</p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            1) - Как тебя́ <input
                                id="зовутInput"
                                name="зовутInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />?
                        </div>
                        - Мари́я.
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            - Меня́ зову́т Ла́ра, но ма́ма <input
                                id="зовётInput"
                                name="зовётInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> меня́ за́йчик.
                        </div>
                    </div>
                    <p></p>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            2) - Кто э́то тебя́ <input
                                id="зовётInput"
                                name="зовётInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />?
                        </div>
                        - Не зна́ю. Наве́рное, Али́на.
                    </div>
                </div>
                <ExercisesQuestions number="2" question="Preencha com os pronomes na forma correta." />
                <div className={classes.questionsContainerEx2}>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            1. <input
                                id="еёInput"
                                name="еёInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> (она́) зову́т Со́ня.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            2. Па́па <input
                                id="ихInput"
                                name="ихInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (они́) зовёт.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            3. <input
                                id="МеняInput"
                                name="МеняInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> (я) зову́т Е́ва.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            4. Как <input
                                id="васInput"
                                name="васInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> (вы) зову́т?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            5. <input
                                id="TебяInput"
                                name="TебяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> (ты) зову́т Ко́ля?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            6. Я зна́ю, как <input
                                id="егоInput"
                                name="егоInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> (он) зову́т.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            7. Почему́ он <input
                                id="насInput"
                                name="насInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            /> (мы) не зовёт на обе́д?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;