import React from 'react';
import * as classes from './LessonWrapper.css';
import { Link } from 'react-router-dom';

const lessonWrapper = ({ lesson, lessonText, route }) => {
    return (
        <Link to={route} className={classes.LessonWrapper}>
            <div className={classes.Lesson}>
                {lesson}
            </div>
            <div className={classes.LessonText}>
                {lessonText}
            </div>
        </Link>
    );
};

export default lessonWrapper;