import React from 'react';
import MainHeader from '../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../CenterColumn/CenterColumn.css';
import * as Constants from '../../../Constants/Constants';
import { Link } from 'react-router-dom';
import * as classes from './A1.css';
import LessonWrapper from '../../../Shared/LessonWrapper/LessonWrapper';

const a1 = props => {
    const lesson17Title = <div className={classes.lessonWithParagraph}><p>ТЫ ГОВОРИ́ШЬ</p><p>ПО-РУ́ССКИ?</p></div>;

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} />
            <div className={classes.IntroAndBookWrapper}>
                <Link to="/Levels/A1/Introduction" className={classes.IntroWrapper}>
                    {Constants.introduction}
                </Link>
                <Link to="/Levels/A1/Book" className={classes.BookWrapper}>
                    {Constants.livroA1}
                </Link>
            </div>
            <div className={classes.LessonsWrapper}>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson1} lessonText={Constants.ВОЛШЕБНЫЕСЛОВА} route="/Levels/A1/Lesson1" />
                    <LessonWrapper lesson={Constants.lesson2} lessonText={Constants.КТОЭ́ТО} route="/Levels/A1/Lesson2" />
                    <LessonWrapper lesson={Constants.lesson3} lessonText={Constants.МЫ} route="/Levels/A1/Lesson3" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson4} lessonText={Constants.ЭТОМОЁ} route="/Levels/A1/Lesson4" />
                    <LessonWrapper lesson={Constants.lesson5} lessonText={Constants.ГДЕТЫ} route="/Levels/A1/Lesson5" />
                    <LessonWrapper lesson={Constants.lesson6} lessonText={Constants.ЯВСАДУ́} route="/Levels/A1/Lesson6" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson7} lessonText={Constants.СТЕРЕОТИ́ПЫ} route="/Levels/A1/Lesson7" />
                    <LessonWrapper lesson={Constants.lesson8} lessonText={Constants.ОЧЁМРАЗГОВО́Р} route="/Levels/A1/Lesson8" />
                    <LessonWrapper lesson={Constants.lesson9} lessonText={Constants.ОНА́МНО́ГОГОВОРИ́Т} route="/Levels/A1/Lesson9" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson10} lessonText={Constants.ЧТО́ТЫДЕ́ЛАЕШЬ} route="/Levels/A1/Lesson10" />
                    <LessonWrapper lesson={Constants.lesson11} lessonText={Constants.ЛЮ́БИШЬНЕЛЮ́БИШЬ} route="/Levels/A1/Lesson11" />
                    <LessonWrapper lesson={Constants.lesson12} lessonText={Constants.МОЁРАСПИСА́НИЕ} route="/Levels/A1/Lesson12" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson13} lessonText={Constants.ВЧЕРА́} route="/Levels/A1/Lesson13" />
                    <LessonWrapper lesson={Constants.lesson14} lessonText={Constants.КОТÓРЫЙЧАС} route="/Levels/A1/Lesson14" />
                    <LessonWrapper lesson={Constants.lesson15} lessonText={Constants.Э́ТАКНИ́ГАТОТФИЛЬМ} route="/Levels/A1/Lesson15" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson16} lessonText={Constants.ТЫКУДА́} route="/Levels/A1/Lesson16" />
                    <LessonWrapper lesson={Constants.lesson17} lessonText={lesson17Title} route="/Levels/A1/Lesson17" />
                    <LessonWrapper lesson={Constants.lesson18} lessonText={Constants.СИ́НИЙКРÁСНЫЙГОЛУБÓЙ} route="/Levels/A1/Lesson18" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson19} lessonText={Constants.КАКТЫСЕБЯ́ЧУ́ВСТВУЕШЬ} route="/Levels/A1/Lesson19" />
                    <LessonWrapper lesson={Constants.lesson20} lessonText={Constants.СКО́ЛЬКОСТÓИТ} route="/Levels/A1/Lesson20" />
                    <LessonWrapper lesson={Constants.lesson21} lessonText={Constants.ТЫОТКУ́ДА} route="/Levels/A1/Lesson21" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson22} lessonText={Constants.ТЕПЛО́ХО́ЛОДНО} route="/Levels/A1/Lesson22" />
                    <LessonWrapper lesson={Constants.lesson23} lessonText={Constants.СКО́ЛЬКОТЕБЕ́ЛЕТ} route="/Levels/A1/Lesson23" />
                    <LessonWrapper lesson={Constants.lesson24} lessonText={Constants.ПОЧЕМУ́НÉБОГОЛУБО́Е} route="/Levels/A1/Lesson24" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson25} lessonText={Constants.АТЫСЕГО́ДНЯУЛЫБА́ЛСЯ} route="/Levels/A1/Lesson25" />
                    <LessonWrapper lesson={Constants.lesson26} lessonText={Constants.АОНМНЕНРА́ВИТСЯ} route="/Levels/A1/Lesson26" />
                    <LessonWrapper lesson={Constants.lesson27} lessonText={Constants.НА́ДОЗНА́ЧИТНА́ДО} route="/Levels/A1/Lesson27" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson28} lessonText={Constants.СЕГО́ДНЯПЯ́ТОЕЯНВАРЯ́} route="/Levels/A1/Lesson28" />
                    <LessonWrapper lesson={Constants.lesson29} lessonText={Constants.ЧЕМЕДЯ́ТРИС} route="/Levels/A1/Lesson29" />
                    <LessonWrapper lesson={Constants.lesson30} lessonText={Constants.ЯХОЧУ́БЫТЬСТОБО́Й} route="/Levels/A1/Lesson30" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson31} lessonText={Constants.КЕМТЫРАБО́ТАЕШЬ} route="/Levels/A1/Lesson31" />
                    <LessonWrapper lesson={Constants.lesson32} lessonText={Constants.ТЫЛЮ́БИШЬГУЛЯ́ТЬПОДДОЖДЁМ} route="/Levels/A1/Lesson32" />
                    <LessonWrapper lesson={Constants.lesson33} lessonText={Constants.ПОСМОТРИТЕНАПРА́ВО} route="/Levels/A1/Lesson33" />
                </div>
            </div>
        </div>
    );
}

export default a1;