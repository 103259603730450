import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>aвстрaли́ец</b> australiano</p>
                        <p><b>австрaли́йка</b> australiana</p>
                        <p><b>австрaли́йцы</b> australianos</p>
                        <p><b>америка́нец</b> americano</p>
                        <p><b>англича́не</b> ingleses</p>
                        <p><b>англича́нин</b> inglês</p>
                        <p><b>англича́нка</b> inglesa</p>
                        <p><b>ангольцы</b> angolanos</p>
                        <p><b>белору́ска</b> bielorrussa</p>
                        <p><b>Бе́льгия</b> Bélgica</p>
                        <p><b>болга́рин</b> búlgaro</p>
                        <p><b>(в) ней</b> (Prepos.) nela</p>
                        <p><b>(в) Португа́лии</b> (Prepos.) (em) Portugal</p>
                        <p><b>(в) Росси́и</b> (Prepos.) (na) Rússia</p>
                        <p><b>ва́рят ко́фе</b>(v. ва́рить) fazem café</p>
                        <p><b>венге́рка</b> húngara</p>
                        <p><b>венгр</b> húngaro</p>
                        <p><b>Ве́нгрия</b> Hungria</p>
                        <p><b>ве́нгры</b> húngaros</p>
                        <p><b>вьетна́мцы</b> vietnamitas</p>
                        <p><b>Гвинея</b> Guiné</p>
                        <p><b>Герма́ния</b> Alemanha</p>
                        <p><b>говоря́т</b>(v. говори́ть) dizem</p>
                        <p><b>голла́ндец</b> holandês</p>
                        <p><b>Голландия</b> Holanda</p>
                        <p><b>грек</b> grego</p>
                        <p><b>гре́ки</b> gregos</p>
                        <p><b>Гре́ция</b> Grécia</p>
                        <p><b>греча́нка</b> grega</p>
                        <p><b>живёте</b>(v. жить) vivem</p>
                        <p><b>живу́</b>(v. жить) vivo</p>
                        <p><b>зна́ю</b>(v. знать) sei</p>
                        <p><b>изве́стно</b> (adv.) conhecido</p>
                        <p><b>изуча́ете</b>(v. изуча́ть) aprendem</p>
                        <p><b>инде́йка</b> perua</p>
                        <p><b>индиа́нка</b> indiana</p>
                        <p><b>индю́к</b> peru</p>
                        <p><b>иностра́нец</b> estrangeiro</p>
                        <p><b>иностра́нка</b> estrangeira</p>
                        <p><b>иностра́нцы</b> estrangeiros</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>иска́ть</b> procurar</p>
                        <p><b>исключе́ние</b> exceção</p>
                        <p><b>испа́нец</b> espanhol</p>
                        <p><b>италья́нец</b> italiano</p>
                        <p><b>италья́нка</b> italiana</p>
                        <p><b>италья́нцы</b> italianos</p>
                        <p><b>Кита́й</b> China</p>
                        <p><b>кита́йка</b> kitaika (tipo de maçã)</p>
                        <p><b>кита́янка</b> chinesa</p>
                        <p><b>коре́ец</b> coreano</p>
                        <p><b>коре́йка</b> lombo fumado</p>
                        <p><b>коре́йцы</b> coreanos</p>
                        <p><b>корея́нка</b> coreana</p>
                        <p><b>крупá</b> sémola de cereal</p>
                        <p><b>Ла́твия</b> Letonia</p>
                        <p><b>лезги́н</b> lezguiano (homem do Cáucaso)</p>
                        <p><b>любо́вник</b> amante</p>
                        <p><b>любо́вница</b> amante (f)</p>
                        <p><b>мозамбикáнец</b> moçambicano</p>
                        <p><b>мозамбикáнка</b> moçambicana</p>
                        <p><b>мозамбикáнцы</b> moçambicanos</p>
                        <p><b>мя́со</b> carne</p>
                        <p><b>настоя́щее вре́мя</b> tempo presente</p>
                        <p><b>не́мец</b> alemão</p>
                        <p><b>не́мка</b> alemã</p>
                        <p><b>не́мцы</b> alemães</p>
                        <p><b>но</b> mas</p>
                        <p><b>Норве́гия</b> Noruega</p>
                        <p><b>норве́жец</b> norueguês</p>
                        <p><b>норве́жка</b> norueguesa</p>
                        <p><b>норве́жцы</b> noruegueses</p>
                        <p><b>о́бувь</b> calçado</p>
                        <p><b>одно́</b> um (neutro)</p>
                        <p><b>отдыха́ем</b> descansamos</p>
                        <p><b>петь</b> cantar</p>
                        <p><b>пе́чка</b> forno a lenha</p>
                        <p><b>писа́ть</b> escrever</p>
                        <p><b>пить</b> beber</p>
                        <p><b>пла́кать</b> chorar</p>
                        <p><b>по́вар</b> cozinheiro</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>поля́к</b> polaco</p>
                        <p><b>поля́ки</b> polacos</p>
                        <p><b>поля́чка</b> polaca</p>
                        <p><b>понима́ете</b> (v. понима́ть) entendem</p>
                        <p><b>португа́лец</b> português</p>
                        <p><b>португа́лка</b> portuguesa</p>
                        <p><b>португа́лцы</b> portugueses</p>
                        <p><b>по-ру́сски</b> em russo</p>
                        <p><b>рисова́ть</b> 1. pintar 2. desenhar</p>
                        <p><b>руководи́тели</b> chefes/diretores</p>
                        <p><b>румы́н</b> romeno</p>
                        <p><b>румы́нка</b> romena</p>
                        <p><b>румы́ны</b> romenos</p>
                        <p><b>ру́сские</b> russos</p>
                        <p><b>Русь</b> Rus (arcaico)</p>
                        <p><b>сли́ва</b> ameixa</p>
                        <p><b>сорт я́блок</b> variedade (Gen.) de maçãs</p>
                        <p><b>танцева́ть</b> dançar</p>
                        <p><b>ту́рка</b> cafeteira</p>
                        <p><b>ту́рки</b> turcos</p>
                        <p><b>ту́рок</b> turco</p>
                        <p><b>Ту́рция</b> Turquia</p>
                        <p><b>турча́нка</b> turca</p>
                        <p><b>фи́нка</b> finlandesa</p>
                        <p><b>финн</b> finlandês</p>
                        <p><b>фи́нны</b> finlandeses</p>
                        <p><b>францу́женка</b> francesa</p>
                        <p><b>францу́з</b> francês</p>
                        <p><b>францу́зы</b> franceses</p>
                        <p><b>Хорва́тия</b> Croácia</p>
                        <p><b>челове́к</b> pessoa</p>
                        <p><b>чех</b> checo</p>
                        <p><b>че́хи</b> checos</p>
                        <p><b>Че́хия</b> República Checa</p>
                        <p><b>че́шка</b> checa</p>
                        <p><b>швед</b> sueco</p>
                        <p><b>шве́дка</b> sueca</p>
                        <p><b>шве́ды</b> suecos</p>
                        <p><b>Швейца́рия</b> Suíça</p>
                        <p><b>швейца́рцы</b> suíços</p>
                        <p><b>Шве́ция</b> Suécia</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;