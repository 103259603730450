import React from 'react';
import MainHeader from '../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../CenterColumn/CenterColumn.css';
import * as Constants from '../../../Constants/Constants';
import { Link } from 'react-router-dom';
import * as classes from './A2.css';
import LessonWrapper from '../../../Shared/LessonWrapper/LessonWrapper';

const a2 = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} />
            <div className={classes.IntroAndBookWrapper}>
                <Link to="/Levels/A2/Repetition" className={classes.IntroWrapper}>
                    {Constants.repetition}
                </Link>
                <Link to="/Levels/A2/Book" className={classes.BookWrapper}>
                    {Constants.livroA2}
                </Link>
            </div>
            <div className={classes.LessonsWrapper}>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson1} lessonText={Constants.ЧТОТЫЛЮ́БИШЬ} route="/Levels/A2/Lesson1" />
                    <LessonWrapper lesson={Constants.lesson2} lessonText={Constants.НЕМНО́ГООФУТБО́ЛЕ} route="/Levels/A2/Lesson2" />
                    <LessonWrapper lesson={Constants.lesson3} lessonText={Constants.ВОНТОТГА́ЛСТУК} route="/Levels/A2/Lesson3" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson4} lessonText={Constants.ДОММОЕ́ЙМЕЧТЫ́} route="/Levels/A2/Lesson4" />
                    <LessonWrapper lesson={Constants.lesson5} lessonText={Constants.КУДА́ПОЕ́ХАТЬВО́ТПУСК} route="/Levels/A2/Lesson5" />
                    <LessonWrapper lesson={Constants.lesson6} lessonText={Constants.КАКА́ЯРА́ЗНИЦА} route="/Levels/A2/Lesson6" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson7} lessonText={Constants.ОНМА́СТЕРСВОЕГО́ДЕ́ЛА} route="/Levels/A2/Lesson7" />
                    <LessonWrapper lesson={Constants.lesson8} lessonText={Constants.АСКО́ЛЬКО} route="/Levels/A2/Lesson8" />
                    <LessonWrapper lesson={Constants.lesson9} lessonText={Constants.МОИ́ПРИВЫ́ЧКИ} route="/Levels/A2/Lesson9" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson10} lessonText={Constants.ДОИПО́СЛЕ} route="/Levels/A2/Lesson10" />
                    <LessonWrapper lesson={Constants.lesson11} lessonText={Constants.ПÓЛВТОРÓГО} route="/Levels/A2/Lesson11" />
                    <LessonWrapper lesson={Constants.lesson12} lessonText={Constants.НИЧЕГО́НЕВИ́ЖУ} route="/Levels/A2/Lesson12" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson13} lessonText={Constants.ВСЁКРО́МЕ} route="/Levels/A2/Lesson13" />
                    <LessonWrapper lesson={Constants.lesson14} lessonText={Constants.ПОПОНЕДЕ́ЛЬНИКАМ} route="/Levels/A2/Lesson14" />
                    <LessonWrapper lesson={Constants.lesson15} lessonText={Constants.ПОСЕКРЕ́ТУВСЕМУ́СВЕ́ТУ} route="/Levels/A2/Lesson15" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson16} lessonText={Constants.НАДОÉЛО} route="/Levels/A2/Lesson16" />
                    <LessonWrapper lesson={Constants.lesson17} lessonText={Constants.СЧАСТЛИВОЕРОЖДЕСТВО} route="/Levels/A2/Lesson17" />
                    <LessonWrapper lesson={Constants.lesson18} lessonText={Constants.ЯМЕЛАНХО́ЛИК} route="/Levels/A2/Lesson18" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson19} lessonText={Constants.ВКАКÓМГОДÝ} route="/Levels/A2/Lesson19" />
                    <LessonWrapper lesson={Constants.lesson20} lessonText={Constants.СА́МЫЙСА́МЫЙ} route="/Levels/A2/Lesson20" />
                    <LessonWrapper lesson={Constants.lesson21} lessonText={Constants.ПОГУЛЯ́ЕМПОГÓРОДУ} route="/Levels/A2/Lesson21" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson22} lessonText={Constants.ТРИЧЁРНЫХСОБА́КИНАЧЁМТОСИДЕ́ЛИ} route="/Levels/A2/Lesson22" />
                    <LessonWrapper lesson={Constants.lesson23} lessonText={Constants.ЧТОДЕ́ЛАТЬЕ́СЛИ} route="/Levels/A2/Lesson23" />
                    <LessonWrapper lesson={Constants.lesson24} lessonText={Constants.ЯХОЧУЧТО́БЫ} route="/Levels/A2/Lesson24" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson25} lessonText={Constants.СОБЛЮДА́ЙТЕТИШИНУ́} route="/Levels/A2/Lesson25" />
                    <LessonWrapper lesson={Constants.lesson26} lessonText={Constants.ПУСТЬВСЕГДА́БУД́ЕТСО́ЛНЦЕ} route="/Levels/A2/Lesson26" />
                    <LessonWrapper lesson={Constants.lesson27} lessonText={Constants.ПРИШЁЛЗАШЁЛВЫ́ШЕЛПОШЁЛ} route="/Levels/A2/Lesson27" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson28} lessonText={Constants.МНЕНÉКОГДА} route="/Levels/A2/Lesson28" />
                </div>
            </div>
        </div>
    );
}

export default a2;