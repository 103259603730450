import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';
import OCemiterioDosAnimaisDomesticos from '../../../../../../Assets/Images/A2/Lesson8/image1exercise.png';
import CinquentaDiasDePrimavera from '../../../../../../Assets/Images/A2/Lesson8/image2exercise.png';
import AIlhaDosCaes from '../../../../../../Assets/Images/A2/Lesson8/image3exercise.png';
import CinquentaSombrasDeCinzento from '../../../../../../Assets/Images/A2/Lesson8/image4exercise.png';
import AGuerraDosMundos from '../../../../../../Assets/Images/A2/Lesson8/image5exercise.png';
import AcademiaDePrincesas from '../../../../../../Assets/Images/A2/Lesson8/image6exercise.png';
import RainhaDosGuerreiros from '../../../../../../Assets/Images/A2/Lesson8/image7exercise.png';

const ItemTypes = [
    { id: 'OCemiterioDosAnimaisDomesticos', name: 'O Cemitério dos animais domésticos', image: OCemiterioDosAnimaisDomesticos },
    { id: 'CinquentaDiasDePrimavera', name: '50 Dias de primavera', image: CinquentaDiasDePrimavera },
    { id: 'AIlhaDosCaes', name: 'A ilha dos cães', image: AIlhaDosCaes },
    { id: 'CinquentaSombrasDeCinzento', name: '50 Sombras de cinzento', image: CinquentaSombrasDeCinzento },
    { id: 'AGuerraDosMundos', name: 'A guerra dos mundos', image: AGuerraDosMundos },
    { id: 'AcademiaDePrincesas', name: 'Academia de princesas', image: AcademiaDePrincesas },
    { id: 'RainhaDosGuerreiros', name: 'Rainha dos guerreiros', image: RainhaDosGuerreiros },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <img id={item.id} src={item.image} alt={item.name} height="100px" width="100px" type={item.id} />
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <img id={item.id} src={item.image} alt={item.name} height="150px" width="100px" type={item.id} />
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const cards = [].concat(dragCards)
            .sort((a, b) => a.itemM > b.itemM ? 1 : -1)
            .map(item => {
                return <DragCard key={item.id} type={item.id} name={item.name} />
            });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Associe os títulos dos filmes às capas:" />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;