import React from 'react';
import MainHeader from '../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../CenterColumn/CenterColumn.css';
import * as Constants from '../../../Constants/Constants';
import { Link } from 'react-router-dom';
import * as classes from './B1.css';
import LessonWrapper from '../../../Shared/LessonWrapper/LessonWrapper';

const b1 = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} />
            <div className={classes.IntroAndBookWrapper}>
                <Link to="/Levels/B1/Repetition" className={classes.IntroWrapper}>
                    {Constants.repetition}
                </Link>
                <Link to="/Levels/B1/Book" className={classes.BookWrapper}>
                    {Constants.livroB1}
                </Link>
            </div>
            <div className={classes.LessonsWrapper}>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson1} lessonText={Constants.ЧЕТЫ́РЕЦВЕ́ТАГО́ДА} route="/Levels/B1/Lesson1" />
                    <LessonWrapper lesson={Constants.lesson2} lessonText={Constants.ЧЕМОДÁННОЕНАСТРОÉНИЕ} route="/Levels/B1/Lesson2" />
                    <LessonWrapper lesson={Constants.lesson3} lessonText={Constants.МОЛОКО́УБЕЖА́ЛО} route="/Levels/B1/Lesson3" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson4} lessonText={Constants.ЧТОПРИВЕЗТИ́} route="/Levels/B1/Lesson4" />
                    <LessonWrapper lesson={Constants.lesson5} lessonText={Constants.СОВЕ́ТЫПУТЕШЕ́СТВЕННИКАМ} route="/Levels/B1/Lesson5" />
                    <LessonWrapper lesson={Constants.lesson6} lessonText={Constants.ОТДЫХА́ТЬМО́ЖНОПОРА́ЗНОМУ} route="/Levels/B1/Lesson6" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson7} lessonText={Constants.КУДА́БЫПОЕ́ХАТЬ} route="/Levels/B1/Lesson7" />
                    <LessonWrapper lesson={Constants.lesson8} lessonText={Constants.ПОСМОТРИ́ТЕНАЭ́ТУКАРТИ́НУ} route="/Levels/B1/Lesson8" />
                    <LessonWrapper lesson={Constants.lesson9} lessonText={Constants.НА́ЙДЕНШЕДЕ́ВР} route="/Levels/B1/Lesson9" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson10} lessonText={Constants.ПОСЛЕДÁМ} route="/Levels/B1/Lesson10" />
                    <LessonWrapper lesson={Constants.lesson11} lessonText={Constants.ПОДЗÉМНЫЙМИР} route="/Levels/B1/Lesson11" />
                    <LessonWrapper lesson={Constants.lesson12} lessonText={Constants.ИВОТМЫВАЭРОПОРТУ́} route="/Levels/B1/Lesson12" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson13} lessonText={Constants.НАБОРТУ́} route="/Levels/B1/Lesson13" />
                    <LessonWrapper lesson={Constants.lesson14} lessonText={Constants.ВСЁСУ́ПЕР} route="/Levels/B1/Lesson14" />
                    <LessonWrapper lesson={Constants.lesson15} lessonText={Constants.ТРАНССИ́Б} route="/Levels/B1/Lesson15" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson16} lessonText={Constants.МОИ́УНИВЕРСИТЕ́ТЫ} route="/Levels/B1/Lesson16" />
                    <LessonWrapper lesson={Constants.lesson17} lessonText={Constants.ПРИЛЕТÉЛИ} route="/Levels/B1/Lesson17" />
                    <LessonWrapper lesson={Constants.lesson18} lessonText={Constants.СЪЕ́ЗДИТЬСЪЕ́ХАТЬИСЪЕЗЖА́ТЬ} route="/Levels/B1/Lesson18" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson19} lessonText={Constants.СЛЕТА́ТЬНАЛУНУ́} route="/Levels/B1/Lesson19" />
                    <LessonWrapper lesson={Constants.lesson20} lessonText={Constants.ЗАЧÉМНАМКÓСМОС} route="/Levels/B1/Lesson20" />
                    <LessonWrapper lesson={Constants.lesson21} lessonText={Constants.ИСКУ́ССТВОНАЦЫ́ПОЧКАХ} route="/Levels/B1/Lesson21" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson22} lessonText={Constants.ИТАКСОЙДЁТ} route="/Levels/B1/Lesson22" />
                    <LessonWrapper lesson={Constants.lesson23} lessonText={Constants.БЛАГОДАРЯ́РОДИ́ТЕЛЯМ} route="/Levels/B1/Lesson23" />
                    <LessonWrapper lesson={Constants.lesson24} lessonText={Constants.КРАСОТА́СПАСЁТМИР} route="/Levels/B1/Lesson24" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson25} lessonText={Constants.ЧЁРНЫЙКОТ} route="/Levels/B1/Lesson25" />
                    <LessonWrapper lesson={Constants.lesson26} lessonText={Constants.МИМИМИ́ШНЫЙЯЗЫ́Ќ} route="/Levels/B1/Lesson26" />
                    <LessonWrapper lesson={Constants.lesson27} lessonText={Constants.НАЧТОПОХО́ЖСНЕГ} route="/Levels/B1/Lesson27" />
                </div>
                <div className={classes.Lessons}>
                    <LessonWrapper lesson={Constants.lesson28} lessonText={Constants.ПТИ́ЧИЙЯЗЫ́К} route="/Levels/B1/Lesson28" />
                </div>
            </div>
        </div>
    );
}

export default b1;