import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson6 from '../../../../../../Assets/Images/A1/Lesson6/image1lesson6.png';
import Image2Lesson6 from '../../../../../../Assets/Images/A1/Lesson6/image2lesson6.png';
import Image3Lesson6 from '../../../../../../Assets/Images/A1/Lesson6/image3lesson6.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРЕДЛОЖНЫЙ ПАДЕЖ</p>
                    <p>Caso Prepositivo (Irregular)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="NominativePrepositiveExample" src={Image1Lesson6} alt="nominative and prepositive example" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Alguns substantivos do género masculino, normalmente compostos por uma sílaba, podem terminar em <b><span className={classes.orangeHighlight}>–У</span></b>.
                            </p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A terminação <b><span className={classes.orangeHighlight}>–У</span></b> ocorre apenas quando o substantivo é usado com as preposições <b><span className={classes.orangeHighlight}>В</span></b> ou <b><span className={classes.orangeHighlight}>HA</span></b>.
                            </p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> As palavras no Caso Prepositivo terminadas em <b><span className={classes.orangeHighlight}>–У</span></b>, têm sempre a última sílaba tónica.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><b>Algumas palavras que terminam em <span className={classes.orangeHighlight}>–У</span> no Prepositivo Locativo</b></p>
                            <p>бéрег – margem (do rio/mar/…)</p>
                            <p>год – ano</p>
                            <p>лес – floresta</p>
                            <p>пол – chão</p>
                            <p>сад – jardim</p>
                            <p>снег – neve</p>
                            <p>у́гол – esquina, canto, ângulo</p>
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="LocativePrepositionExample" src={Image2Lesson6} alt="locative preposition example" width="300px" />
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>I СПРЯЖЕНИЕ: 1. Правильные глаголы”</p>
                    <p>I CONJUGAÇÃO: 1. Verbos Regulares</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="VerbWorkConjugationPresent" src={Image3Lesson6} alt="verb 'work' conjugation in the present" width="300px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Apesar de a maior parte dos verbos da <b>I Conjugação</b> terminar em <span className={classes.orangeHighlight}>-АТЬ</span> no infinitivo, não é isso que distingue a conjugação, mas sim:
                            </p>
                            <p>o tema em <span className={classes.orangeHighlight}>-E/-Ë </span></p>
                            <p>3.ª pessoa do pl. <span className={classes.orangeHighlight}> -ЮТ/- УТ </span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;