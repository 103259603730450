import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1 from '../../../../../../Assets/Images/A1/Lesson16/image1exercise.png';
import Image2 from '../../../../../../Assets/Images/A1/Lesson16/image2exercise.png';
import Image3 from '../../../../../../Assets/Images/A1/Lesson16/image3exercise.png';
import Image4 from '../../../../../../Assets/Images/A1/Lesson16/image4exercise.png';
import Image5 from '../../../../../../Assets/Images/A1/Lesson16/image5exercise.png';
import Image6 from '../../../../../../Assets/Images/A1/Lesson16/image6exercise.png';
import Image7 from '../../../../../../Assets/Images/A1/Lesson16/image7exercise.png';
import Image8 from '../../../../../../Assets/Images/A1/Lesson16/image8exercise.png';

const VerbList = [
    { id: 'skate', name: 'едут', matcher: 'едут' },
    { id: 'metro', name: 'едут', matcher: 'едут' },
    { id: 'carro', name: 'едет', matcher: 'едет' },
    { id: 'bicicleta', name: 'еду', matcher: 'еду' },
    { id: 'autocarro', name: 'едет', matcher: 'едет' },
    { id: 'biblioteca', name: 'идёт', matcher: 'идёт' },
    { id: 'comboio', name: 'еду', matcher: 'еду' },
    { id: 'andar', name: 'идём', matcher: 'идём' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value === matcher) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    const exerciseQuestion = (
        <p>Complete as frases com os verbos <i>ИДТИ́</i> ou <i>Е́ХАТЬ</i> devidamente conjugados</p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <div className={classes.questionsContainerEx2}>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Они́ <input
                                id="skateInput"
                                name="skateInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> домо́й на скейтбо́рде.
                        </div>
                        <img id="image1" src={Image1} alt="skate" width="220px" />
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Онá <input
                                id="metroInput"
                                name="metroInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />
                        </div>
                        <img id="image2" src={Image2} alt="metro" width="220px" />
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Мы <input
                                id="carroInput"
                                name="carroInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />
                        </div>
                        <img id="image3" src={Image3} alt="carro" width="220px" />
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Я <input
                                id="bicicletaInput"
                                name="bicicletaInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />
                        </div>
                        <img id="image4" src={Image4} alt="bicicleta" width="220px" />
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Они́ <input
                                id="autocarroInput"
                                name="autocarroInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />
                        </div>
                        <img id="image5" src={Image5} alt="autocarro" width="220px" />
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Он <input
                                id="bibliotecaInput"
                                name="bibliotecaInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />
                        </div>
                        <img id="image6" src={Image6} alt="biblioteca" width="220px" />
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Ты хорошо́ <input
                                id="comboioInput"
                                name="comboioInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />
                        </div>
                        <img id="image7" src={Image7} alt="comboio" width="220px" />
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <div className={classes.inputWrapper}>
                            Вы <input
                                id="andarInput"
                                name="andarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            />
                            (информа́цию)?
                        </div>
                        <img id="image8" src={Image8} alt="andar" width="220px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;