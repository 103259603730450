import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';
import гото́вить from '../../../../../../Assets/Images/A1/Lesson11/image1exercise.png';
import ви́деть from '../../../../../../Assets/Images/A1/Lesson11/image2exercise.png';
import ненави́деть from '../../../../../../Assets/Images/A1/Lesson11/image3exercise.png';
import спать from '../../../../../../Assets/Images/A1/Lesson11/image4exercise.png';
import сиде́ть from '../../../../../../Assets/Images/A1/Lesson11/image5exercise.png';
import ходи́ть from '../../../../../../Assets/Images/A1/Lesson11/image6exercise.png';
import эконо́мить from '../../../../../../Assets/Images/A1/Lesson11/image7exercise.png';
import люби́ть from '../../../../../../Assets/Images/A1/Lesson11/image8exercise.png';
import есть from '../../../../../../Assets/Images/A1/Lesson11/image9exercise.png';
import лови́ть from '../../../../../../Assets/Images/A1/Lesson11/image10exercise.png';

const ItemTypes = [
    { id: 'гото́вить', name: 'гото́вить', image: гото́вить },
    { id: 'ви́деть', name: 'ви́деть', image: ви́деть },
    { id: 'ненави́деть', name: 'ненави́деть', image: ненави́деть },
    { id: 'спать', name: 'спать', image: спать },
    { id: 'сиде́ть', name: 'сиде́ть', image: сиде́ть },
    { id: 'ходи́ть', name: 'ходи́ть', image: ходи́ть },
    { id: 'эконо́мить', name: 'эконо́мить', image: эконо́мить },
    { id: 'люби́ть', name: 'люби́ть', image: люби́ть },
    { id: 'есть', name: 'есть', image: есть },
    { id: 'лови́ть', name: 'лови́ть', image: лови́ть },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <img id={item.id} src={item.image} alt={item.name} width="220px" type={item.id} />
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <img id={item.id} src={item.image} alt={item.name} width="220px" type={item.id} />
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Associe os verbos às imagens correspondentes." />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;