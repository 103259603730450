import React from 'react';
import classes from './Book.css';
import MainHeader from '../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../CenterColumn/CenterColumn.css';

const bookA1 = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={'Livro A1'} goBack={props.goBack} goToLessons />
            <div className={classes.containerA1Book}>
                <div className={classes.containerA1Book_1}>
                    <p className={classes.a1BookSubtitles}>Manual</p>
                    <a href="https://livraria.ruigracio.com/produto/dialog-a1-manual-de-russo/" target="_blank" rel="noopener noreferrer">Comprar livro A1</a>
                </div>
                <div className={classes.containerA1Book_2}>
                    <p className={classes.a1BookSubtitles}>Áudio e vídeo</p>
                    <a href='../../../../../../public/Assets/AudioZipFiles/AudioExercicios.zip' download>Ficheiros de áudio e vídeo (.zip)</a>
                </div>
                <div className={classes.containerA1Book_2}>
                    <p className={classes.a1BookSubtitles}>Transcrição de áudio</p>
                    <a href='../../../../../../public/Assets/A1_textos_de_audio.pdf' download>Transcrição de áudio (.pdf)</a>
                </div>
            </div>
        </div>
    );
}

export default bookA1;