import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import audio1 from './Audio/aula19aud1.mp3';

const VerbList = [
    { id: 'словно', name: 'словно', matcher: 'словно' },
    { id: 'вольных', name: 'вольных', matcher: 'вольных' },
    { id: 'больно', name: 'больно', matcher: 'больно' },
    { id: 'думать', name: 'думать', matcher: 'думать' },
    { id: 'кровь', name: 'кровь', matcher: 'кровь' },
    { id: 'человечий', name: 'человечий', matcher: 'человечий' },
    { id: 'залижу', name: 'залижу', matcher: 'залижу' },
    { id: 'слаб', name: 'слаб', matcher: 'слаб' },
    { id: 'луну', name: 'луну', matcher: 'луну' },
    { id: 'бросился', name: 'бросился', matcher: 'бросился' },
    { id: 'должен', name: 'должен', matcher: 'должен' },
    { id: 'задумал', name: 'задумал', matcher: 'задумал' },
    { id: 'тишину', name: 'тишину', matcher: 'тишину' },
    { id: 'ночном', name: 'ночном', matcher: 'ночном' },
    { id: 'Возьми', name: 'Возьми', matcher: 'Возьми' },
    { id: 'луна', name: 'луна', matcher: 'луна' },
];

const Exercises = props => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [player] = useState(new Audio(audio1));

    const playAudio = player => {
        if (isPlaying) {
            player.pause();
            setIsPlaying(false);
        } else {
            player.play();
            setIsPlaying(true);
        }
    }

    const MusicAudio = () => {
        return (
            <div className={classes.musicAudio}>
                <button className={classes.audioButton} onClick={() => playAudio(player)}>
                    Áudio &#9658; &#10074;&#10074;
                </button>
            </div>
        );
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <MusicAudio />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Послушайте песню «Луна» (Любэ) и завершите текст" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1_1}>
                        <div className={classes.inputWrapper}>
                            <p>Да, стая, я старик</p>
                            <p>Я <input
                                id="словноInput"
                                name="словноInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> стёртый клык</p>
                            <p>Не перегрызть мне память <input
                                id="вольныхInput"
                                name="вольныхInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> снов</p>
                            <p>В них пыл давно затих</p>
                            <p>И <input
                                id="больноInput"
                                name="больноInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> бьют поддых</p>
                            <p>Глаза моих друзей</p>
                            <p>Глаза моих друзей</p>
                            <p>Волков</p>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1_2}>
                        <div className={classes.inputWrapper}>
                            <p>Да, стая, я старик</p>
                            <p>Но <input
                                id="думатьInput"
                                name="думатьInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> не отвык</p>
                            <p>Я носом чую там, где льется <input
                                id="кровьInput"
                                name="кровьInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /></p>
                            <p>И голод нас давил</p>
                            <p>В тугую даль манил</p>
                            <p>Но был обманкой <input
                                id="человечийInput"
                                name="человечийInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> кров</p>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1_3}>
                        <div className={classes.inputWrapper}>
                            <p>Я раны <input
                                id="залижуInput"
                                name="залижуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /></p>
                            <p>Я прошлым завяжу</p>
                            <p>Капканы вижу, и с тропы сверну</p>
                            <p>Не потому что <input
                                id="слабInput"
                                name="слабInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /></p>
                            <p>А потому что кровь не греет старых лап</p>
                            <p>Ночами долго-долго вою на <input
                                id="лунуInput"
                                name="лунуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /></p>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1_4}>
                        <div className={classes.inputWrapper}>
                            <p>Я <input
                                id="бросилсяInput"
                                name="бросилсяInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            /> во мрак</p>
                            <p>Я знал, что только так</p>
                            <p>Из кожи вон</p>
                            <p>Я <input
                                id="долженInput"
                                name="долженInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            /> вас спасать</p>
                            <p>Но молодой вожак</p>
                            <p>Поставил точку так</p>
                            <p>Уже <input
                                id="задумалInput"
                                name="задумалInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[11].matcher)}
                            /> как меня убрать</p>
                        </div>
                        <p><b>ПРИПЕВ</b></p>
                    </div>
                    <div className={classes.cardContainerEx1_5}>
                        <div className={classes.inputWrapper}>
                            <p>Луна, луна, луна</p>
                            <p>Взрывая воем <input
                                id="тишинуInput"
                                name="тишинуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[12].matcher)}
                            /></p>
                            <p>Луна, луна, луна</p>
                            <p>Луна и волк в <input
                                id="ночномInput"
                                name="ночномInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[13].matcher)}
                            /> лесу</p>
                            <p><input
                                id="ВозьмиInput"
                                name="ВозьмиInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[14].matcher)}
                            /> к себе меня, <input
                                    id="лунаInput"
                                    name="лунаInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[15].matcher)}
                                /></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;