import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson13/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson13/image2grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>Preposição</p>
                    <p><i>кроме</i></p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="500px" />
                </div>
                <GrammarSubtitle>
                    <p>МЕСТОИМЕНИE</p>
                    <p>Родительный падеж</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Os demonstrativos e possessivos masculinos e neutros no Genitivo têm a mesma forma dos demonstrativos e possessivos no Acusativo animado.</p>
                    <div>
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="500px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;