import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import audio1 from './Audio/aula31aud1.mp3';

const VerbList = [
    { id: 'больнице', name: 'больнице', matcher: 'больнице' },
    { id: 'медсестрой', name: 'медсестрой', matcher: 'медсестрой' },
    { id: 'институте', name: 'институте', matcher: 'институте' },
    { id: 'училась', name: 'училась', matcher: 'училась' },
    { id: 'медсестрой', name: 'медсестрой', matcher: 'медсестрой' },
];

const Exercises = props => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [player] = useState(new Audio(audio1));

    const playAudio = player => {
        if (isPlaying) {
            player.pause();
            setIsPlaying(false);
        } else {
            player.play();
            setIsPlaying(true);
        }
    }

    const MusicAudio = () => {
        return (
            <div className={classes.musicAudio}>
                <button className={classes.audioButton} onClick={() => playAudio(player)}>
                    Áudio &#9658; &#10074;&#10074;
                </button>
            </div>
        );
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Ouça o áudio e complete o diálogo." />
                <MusicAudio />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>– Где вы рабо́таете?</p>
                            <p>– В <input
                                id="input1"
                                name="input 1"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />.
                            </p>
                            <p>– А кем вы рабо́таете?.</p>
                            <p>
                                – <input
                                    id="input2"
                                    name="input 2"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[1].matcher)}
                                />.
                            </p>
                            <p>
                                – Вы учи́лись в медици́нском <input
                                    id="input3"
                                    name="input 3"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[2].matcher)}
                                />?
                            </p>
                            <p>
                                – Нет, я <input
                                    id="input4"
                                    name="input 4"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[3].matcher)}
                                /> в учи́лище.
                            </p>
                            <p>
                                – Вы всегда́ хоте́ли быть <input
                                    id="input5"
                                    name="input 5"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[4].matcher)}
                                /> ?
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;