import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const DragTypes = [
    { id: '1', type: 'GREEN', name: 'мыть зубы после еды', translation: 'мыть зубы после еды' },
    { id: '2', type: 'GREEN', name: 'выпивать 2 литра воды в день', translation: 'выпивать 2 литра воды в день' },
    { id: '3', type: 'GREEN', name: 'заниматься спортом', translation: 'заниматься спортом' },
    { id: '4', type: 'GREEN', name: 'смеяться', translation: 'смеяться' },
    { id: '5', type: 'GREEN', name: 'завтракать', translation: 'завтракать' },
    { id: '18', type: 'RED', name: 'cлишком мало спать', translation: 'cлишком мало спать' },
    { id: '19', type: 'RED', name: 'проводить слишком много времени в интернете', translation: 'проводить слишком много времени в интернете' },
    { id: '20', type: 'RED', name: 'cлишком много есть сладкого', translation: 'cлишком много есть сладкого' },
    { id: '21', type: 'RED', name: 'курить', translation: 'курить' },
    { id: '22', type: 'RED', name: 'грызть ногти', translation: 'грызть ногти' },
];

const DropTypes = [
    { id: '1', type: 'RED', name: 'RED' },
    { id: '2', type: 'GREEN', name: 'GREEN' },
];

const Exercises = props => {
    const [dropCards] = useState(DropTypes);
    const [dragCards, setDragCards] = useState(DragTypes);
    const [droppedListRED, setDroppedListRED] = useState([]);
    const [droppedListGREEN, setDroppedListGREEN] = useState([]);

    const DragCard = ({ item }) => {
        const [, drag] = useDrag({
            type: item.type,
            item: { ...item },
            collect: monitor => ({
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging()
            })
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {item.translation}
                </div>
            </div>);
    }

    const DisplayCard = ({ type }) => {
        const content = type === 'RED' ? (
            <div className={classes.displayCardRed}>Вредные привычки:</div>
        ) : (
            <div className={classes.displayCardGreen}>Полезные привычки:</div>
        );

        return content;
    }

    const DroppedCardsRED = () => {
        const cards = droppedListRED.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DroppedCardsGREEN = () => {
        const cards = droppedListGREEN.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCard = ({ accepts: accept, item }) => {
        const [, drop] = useDrop(() => ({
            accept: item.type,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();
                const canDrop = monitor.canDrop();
                const dropItem = monitor.getItem();

                if (didDrop) {
                    return;
                }

                if (canDrop) {
                    setDragCards(dragCards.filter(item => item.name !== dropItem.name))
                }

                if (dropItem.type === 'RED') {
                    if (!droppedListRED.some(({ name }) => name === dropItem.name)) {
                        setDroppedListRED([...droppedListRED, dropItem]);
                    }
                } else {
                    if (!droppedListGREEN.some(({ name }) => name === dropItem.name)) {
                        setDroppedListGREEN([...droppedListGREEN, dropItem]);
                    }
                }
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                {item.type === 'RED' ?
                    <div ref={drop} className={classes.dropContainer}>
                        <DisplayCard type={item.type} />
                        <DroppedCardsRED /></div> :
                    <div ref={drop} className={classes.dropContainer}>
                        <DisplayCard type={item.type} />
                        <DroppedCardsGREEN />
                    </div>
                }
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort((a, b) => {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} item={item} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Selecione as expressões e arraste-as para a coluna apropriada:"></ExercisesQuestions>
                <div className={classes.gridItems}>
                    <DropCards className={classes.dropCards} />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;