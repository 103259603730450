import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson19 from '../../../../../../Assets/Images/A1/Lesson19/image1lesson19.png';
import Image2Lesson19 from '../../../../../../Assets/Images/A1/Lesson19/image2lesson19.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ЛИЧНЫЕ МЕСТОИМЕНИЯ</p>
                    <p>родительный падеж</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image1Lesson19" src={Image1Lesson19} alt="pronouns table third person" width="250px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> sempre que o pronome da 3ª pessoa é usado com uma preposição é acrescentada a letra н ao pronome:
                            </p>
                            <p>
                                <b><i>У <span className={classes.orangeHighlight}>н</span>его́ боли́т голова́</i>.</b>
                            </p>
                            <p>
                                <b>Я не могу́ без <span className={classes.orangeHighlight}>н</span>её рабо́тать.</b></p>
                            <p>
                                <b><i>Мы живём о́коло <span className={classes.orangeHighlight}>н</span>их.</i></b>
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>БУДУЩЕЕ ВРEМЯ "БЫТЬ" + ИНФИНИТИВ</p>
                    <p>Futuro</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image2Lesson19" src={Image2Lesson19} alt="past tense on verb to be" width="200px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Existem duas formas de expressar o Futuro em Russo:
                            </p>
                            <p>
                                1) Futuro Composto: verbo auxiliar БЫТЬ + verbo principal no infinitivo (imperfetivo):
                            </p>
                            <p>
                                За́втра я <b><span className={classes.orangeHighlight}>бу́ду чита́ть</span></b>.
                            </p>
                            <p>
                                2) Futuro Simples: verbo principal na sua forma perfetiva (cf. Уро́к 29):
                            </p>
                            <p>
                                За́втра я <b><span className={classes.orangeHighlight}>прочита́ю</span></b> кни́гу.
                            </p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Geralmente, o Futuro Composto usa-se para expressar uma intenção/plano de realizar uma acção no futuro, o Futuro simples denota uma intenção de terminar/concluir essa mesma ação.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;