import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Thinking from '../../../../../../Assets/Images/A1/Lesson8/image1exercise.png';
import Reading from '../../../../../../Assets/Images/A1/Lesson8/image2exercise.png';
import Writing from '../../../../../../Assets/Images/A1/Lesson8/image3exercise.png';
import Telling from '../../../../../../Assets/Images/A1/Lesson8/image4exercise.png';
import Conversating from '../../../../../../Assets/Images/A1/Lesson8/image5exercise.png';
import Knowing from '../../../../../../Assets/Images/A1/Lesson8/image6exercise.png';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: true },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: true },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: true },
    { id: 15, isCorrect: false },
    { id: 16, isCorrect: true },
    { id: 17, isCorrect: false },
    { id: 18, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.backgroundColor = 'green' : event.currentTarget.style.backgroundColor = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Selecione a forma correta da preposição: О, ОБ ou ОБО" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <img id="thinking" src={Thinking} alt="thinking" width="220px" />
                        <p>Она́ ду́мает ______ óтпуске.</p>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[0].id}
                                onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                            >
                                обo
                            </button>
                            <button
                                key={buttonsEx1[1].id}
                                onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                            >
                                o
                            </button>
                            <button
                                key={buttonsEx1[2].id}
                                onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                            >
                                об
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="reading" src={Reading} alt="reading" width="220px" />
                        <p>Она́ чита́ет ______ спо́рте.</p>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[3].id}
                                onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                            >
                                обo
                            </button>
                            <button
                                key={buttonsEx1[4].id}
                                onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                            >
                                o
                            </button>
                            <button
                                key={buttonsEx1[5].id}
                                onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                            >
                                об
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="writing" src={Writing} alt="writing" width="220px" />
                        <p>Он пи́шет ______ му́зыке.</p>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[6].id}
                                onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                            >
                                обo
                            </button>
                            <button
                                key={buttonsEx1[7].id}
                                onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                            >
                                o
                            </button>
                            <button
                                key={buttonsEx1[8].id}
                                onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                            >
                                об
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="telling" src={Telling} alt="telling" width="220px" />
                        <p>Он расска́зывает ___ па́мятникe.</p>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[9].id}
                                onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                            >
                                обo
                            </button>
                            <button
                                key={buttonsEx1[10].id}
                                onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                            >
                                o
                            </button>
                            <button
                                key={buttonsEx1[11].id}
                                onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                            >
                                об
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="conversating" src={Conversating} alt="conversating" width="220px" />
                        <p>Они́ разгова́ривают ___ языке́ и культу́ре.</p>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[12].id}
                                onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                            >
                                обo
                            </button>
                            <button
                                key={buttonsEx1[13].id}
                                onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                            >
                                o
                            </button>
                            <button
                                key={buttonsEx1[14].id}
                                onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                            >
                                об
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="knowing" src={Knowing} alt="knowing" width="220px" />
                        <p>Он всё _____ всём знáет.</p>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[15].id}
                                onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                            >
                                обo
                            </button>
                            <button
                                key={buttonsEx1[16].id}
                                onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                            >
                                o
                            </button>
                            <button
                                key={buttonsEx1[17].id}
                                onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                            >
                                об
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;