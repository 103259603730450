import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: true },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: true },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: true },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: true },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: true },
    { id: 16, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.backgroundColor = 'green' : event.currentTarget.style.backgroundColor = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Complete a frase russa com o pronome mais apropriado, de acordo com as frases dadas em português:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <p>1. O meu irmão fala alemão.</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    Мой
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    Свой
                                </button>
                            </div>
                            ... брат говори́т по-неме́цки.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p>2. Ele não se lembra do seu número de telefone?</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            Он не помнит ...
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    его́
                                </button>
                                <button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    свой
                                </button>
                            </div>
                            ... номер телефона?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p>3. Cada família tem os seus problemas.</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            В кáждой семьé ...
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    её
                                </button>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    свои́
                                </button>
                            </div>
                            ... проблéмы.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p>4. A Lena e o seu pai já estiveram em Lisboa.</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            Ле́на и ...
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    её
                                </button>
                                <button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    свои́
                                </button>
                            </div>
                            …па́па уже́ бы́ли в Лиссабо́не.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p>5. O Sasha não sabe que a sua mulher lhe está a preparar uma surpresa.</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            Са́ша не зна́ет, что...
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    своя́
                                </button>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    её
                                </button>
                            </div>
                            ... жена́ гото́вит ему́ сюрпри́з.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p>6. Tudo se planta a seu tempo!</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            Сажа́ть ну́жно всё в...
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    своё
                                </button>
                                <button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    его́
                                </button>
                            </div>
                            ... вре́мя.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p>7. Como é que tu perdeste a tua irmã?</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            Как ты потеря́л...
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    твою́
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    свою́
                                </button>
                            </div>
                            ... сестру́?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p>8. Esta não é a tua irmã!</p>
                        <div className={classes.buttonsAndTextContainerEx1}>
                            Э́то не...
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    твоя́
                                </button>
                                <button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    своя́
                                </button>
                            </div>
                            ... сестра́!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;