import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Futebol from '../../../../../../Assets/Images/A1/Lesson10/image1exercise.png';
import Livro from '../../../../../../Assets/Images/A1/Lesson10/image2exercise.png';
import Relatorio from '../../../../../../Assets/Images/A1/Lesson10/image3exercise.png';
import Cha from '../../../../../../Assets/Images/A1/Lesson10/image4exercise.png';
import Cigarro from '../../../../../../Assets/Images/A1/Lesson10/image5exercise.png';
import Filme from '../../../../../../Assets/Images/A1/Lesson10/image6exercise.png';
import Mozart from '../../../../../../Assets/Images/A1/Lesson10/image7exercise.png';
import Pasternak from '../../../../../../Assets/Images/A1/Lesson10/image8exercise.png';
import Professor from '../../../../../../Assets/Images/A1/Lesson10/image9exercise.png';
import Amalia from '../../../../../../Assets/Images/A1/Lesson10/image10exercise.png';
import Akhmátova from '../../../../../../Assets/Images/A1/Lesson10/image11exercise.png';
import Filha from '../../../../../../Assets/Images/A1/Lesson10/image12exercise.png';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: true },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: false },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: true },
    { id: 16, isCorrect: false },
    { id: 17, isCorrect: false },
    { id: 18, isCorrect: true },
    { id: 19, isCorrect: false },
    { id: 20, isCorrect: false },
    { id: 21, isCorrect: true },
    { id: 22, isCorrect: true },
    { id: 23, isCorrect: false },
    { id: 24, isCorrect: false },
    { id: 25, isCorrect: false },
    { id: 26, isCorrect: true },
    { id: 27, isCorrect: false },
    { id: 28, isCorrect: false },
    { id: 29, isCorrect: true },
    { id: 30, isCorrect: false },
    { id: 31, isCorrect: true },
    { id: 32, isCorrect: false },
    { id: 33, isCorrect: false },
    { id: 34, isCorrect: false },
    { id: 35, isCorrect: false },
    { id: 36, isCorrect: true },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha a resposta certa." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <img id="futebol" src={Futebol} alt="futebol" width="220px" />
                        <div className={classes.questionBallon}>ЧТО́ ТЫ ДЕ́ЛАЕШЬ?</div>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Я смотрю́...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    футбо́л
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    футбо́лa
                                </button>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    футбо́лy
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="livro" src={Livro} alt="livro" width="220px" />
                        <div className={classes.questionBallon}>ЧТО́ ОН ДЕ́ЛАЕТ?</div>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Он чита́ет...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    кни́гa
                                </button>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    кни́гу
                                </button>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    книг
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="relatorio" src={Relatorio} alt="relatorio" width="220px" />
                        <div className={classes.questionBallon}>ЧТО́ ОНА́ ДЕ́ЛАЕТ?</div>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Она́ пи́шет...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    докла́дa
                                </button>
                                <button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    докла́дy
                                </button>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    докла́д
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="cha" src={Cha} alt="cha" width="220px" />
                        <div className={classes.questionBallon}>ЧТО́ ТЫ ДЕ́ЛАЕШЬ?</div>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Я покупа́ю...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    чай
                                </button>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    чáю
                                </button>
                                <button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    чáя
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="cigarro" src={Cigarro} alt="cigarro" width="220px" />
                        <div className={classes.questionBallon}>ЧТО́ ВЫ ДЕ́ЛАЕТЕ?</div>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Я курю́...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    сигаре́та
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    сигаре́т
                                </button>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    сигаре́ту
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="filme" src={Filme} alt="filme" width="220px" />
                        <div className={classes.questionBallon}>ЧТО́ ВЫ ДЕ́ЛАЕТЕ?</div>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Я покупа́ю...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    фи́льму
                                </button>
                                <button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    фи́льма
                                </button>
                                <button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    фильм
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="Mozart" src={Mozart} alt="Mozart" width="220px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Они́ слу́шают...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[18].id}
                                    onClick={event => handleColor(event, buttonsEx1[18].isCorrect)}
                                >
                                    Моцарт
                                </button>
                                <button
                                    key={buttonsEx1[19].id}
                                    onClick={event => handleColor(event, buttonsEx1[19].isCorrect)}
                                >
                                    Моцарту
                                </button>
                                <button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[20].isCorrect)}
                                >
                                    Моцарта
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="Pasternak" src={Pasternak} alt="Pasternak" width="220px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Он чита́ет...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[21].isCorrect)}
                                >
                                    Пастерна́ка
                                </button>
                                <button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[22].isCorrect)}
                                >
                                    Пастерна́ку
                                </button>
                                <button
                                    key={buttonsEx1[23].id}
                                    onClick={event => handleColor(event, buttonsEx1[23].isCorrect)}
                                >
                                    Пастерна́к
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="professor" src={Professor} alt="professor" width="220px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Они́ слу́шают...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[24].id}
                                    onClick={event => handleColor(event, buttonsEx1[24].isCorrect)}
                                >
                                    преподава́тель
                                </button>
                                <button
                                    key={buttonsEx1[25].id}
                                    onClick={event => handleColor(event, buttonsEx1[25].isCorrect)}
                                >
                                    преподава́теля
                                </button>
                                <button
                                    key={buttonsEx1[26].id}
                                    onClick={event => handleColor(event, buttonsEx1[26].isCorrect)}
                                >
                                    преподава́телю
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="Amalia" src={Amalia} alt="Amalia" width="220px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Мы любим...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[27].id}
                                    onClick={event => handleColor(event, buttonsEx1[27].isCorrect)}
                                >
                                    Амалия
                                </button>
                                <button
                                    key={buttonsEx1[28].id}
                                    onClick={event => handleColor(event, buttonsEx1[28].isCorrect)}
                                >
                                    Амалию
                                </button>
                                <button
                                    key={buttonsEx1[29].id}
                                    onClick={event => handleColor(event, buttonsEx1[29].isCorrect)}
                                >
                                    Амалии
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="Akhmátova" src={Akhmátova} alt="Akhmátova" width="220px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Я хорошо́ зна́ю...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[30].id}
                                    onClick={event => handleColor(event, buttonsEx1[30].isCorrect)}
                                >
                                    Ахматову
                                </button>
                                <button
                                    key={buttonsEx1[31].id}
                                    onClick={event => handleColor(event, buttonsEx1[31].isCorrect)}
                                >
                                    Ахматова
                                </button>
                                <button
                                    key={buttonsEx1[32].id}
                                    onClick={event => handleColor(event, buttonsEx1[32].isCorrect)}
                                >
                                    Ахматовы
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="filha" src={Filha} alt="filha" width="220px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Ма́ма лю́бит...</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[33].id}
                                    onClick={event => handleColor(event, buttonsEx1[33].isCorrect)}
                                >
                                    до́чери
                                </button>
                                <button
                                    key={buttonsEx1[34].id}
                                    onClick={event => handleColor(event, buttonsEx1[34].isCorrect)}
                                >
                                    до́черью
                                </button>
                                <button
                                    key={buttonsEx1[35].id}
                                    onClick={event => handleColor(event, buttonsEx1[35].isCorrect)}
                                >
                                    дочь
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;