import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import audio1 from './Audio/aula15aud1.mp3';

const VerbList = [
    { id: 'поезд', name: 'поезд', matcher: 'поезд' },
    { id: 'маршруту', name: 'маршруту', matcher: 'маршруту' },
    { id: 'шторки', name: 'шторки', matcher: 'шторки' },
    { id: 'пути', name: 'пути', matcher: 'пути' },
    { id: 'плыла', name: 'плыла', matcher: 'плыла' },
    { id: 'судьба', name: 'судьба', matcher: 'судьба' },
    { id: 'летают', name: 'летают', matcher: 'летают' },
    { id: 'столе', name: 'столе', matcher: 'столе' },
    { id: 'стихи', name: 'стихи', matcher: 'стихи' },
    { id: 'скучаю', name: 'скучаю', matcher: 'скучаю' },
    { id: 'бежит', name: 'бежит', matcher: 'бежит' },
    { id: 'Стук', name: 'Стук', matcher: 'Стук' },
    { id: 'окном', name: 'окном', matcher: 'окном' },
    { id: 'свободы', name: 'свободы', matcher: 'свободы' },
    { id: 'поэма', name: 'поэма', matcher: 'поэма' },
    { id: 'дилемма', name: 'дилемма', matcher: 'дилемма' },
    { id: 'небесной', name: 'небесной', matcher: 'небесной' },
    { id: 'моей', name: 'моей', matcher: 'моей' },
];

const Exercises = props => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [player] = useState(new Audio(audio1));

    const playAudio = player => {
        if (isPlaying) {
            player.pause();
            setIsPlaying(false);
        } else {
            player.play();
            setIsPlaying(true);
        }
    }

    const MusicAudio = () => {
        return (
            <div className={classes.musicAudio}>
                <button className={classes.audioButton} onClick={() => playAudio(player)}>
                    Áudio &#9658; &#10074;&#10074;
                </button>
            </div>
        );
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Послушайте песню «Рельсы,шпалы» (Фактор-2) и вставьте недостающие слова" />
                <MusicAudio />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Рельсы-рельсы, шпалы-шпалы,</p>
                            <p>Ехал <input
                                id="поездInput"
                                name="поездInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> запоздалый</p>
                            <p>По <input
                                id="маршрутуInput"
                                name="маршрутуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> три шестёрки,</p>
                            <p>Надо б занавесить <input
                                id="шторкиInput"
                                name="шторкиInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />.</p>
                            <p>Рельсы-рельсы, шпалы-шпалы,</p>
                            <p>Ехал поезд запоздалый,</p>
                            <p>И объявляют мне в <input
                                id="путиInput"
                                name="путиInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />:</p>
                            <p>Надо бы тебе сойти.</p>
                        </div>
                        <p className={classes.textCentered}><b>ПРИПЕВ (x2)</b></p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Жизнь прошла, а что успел я сделать?</p>
                            <p>Жизнь моя, она <input
                                id="плылаInput"
                                name="плылаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />, как лебедь.</p>
                            <p>Пусть <input
                                id="судьбаInput"
                                name="судьбаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> осколки собирает</p>
                            <p>Тех идей, что в голове <input
                                id="летаютInput"
                                name="летаютInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />.</p>
                            <p>На <input
                                id="столеInput"
                                name="столеInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> раскиданы тетради,</p>
                            <p>В них <input
                                id="стихиInput"
                                name="стихиInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> - не рви их, бога ради!</p>
                            <p>И не злись на то, что я летаю</p>
                            <p>Над землёй, я по тебе <input
                                id="скучаюInput"
                                name="скучаюInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            /></p>
                        </div>
                        <p className={classes.textCentered}><b>ПРИПЕВ (x1)</b></p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Белый лист, строка <input
                                id="бежитInput"
                                name="бежитInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            /> за строчкой,</p>
                            <p><input
                                id="СтукInput"
                                name="СтукInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[11].matcher)}
                            /> колёс, я ставлю снова точку</p>
                            <p>За <input
                                id="окномInput"
                                name="окномInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[12].matcher)}
                            /> все прожитые годы,</p>
                            <p>Жизнь - кино, а не глоток <input
                                id="свободыInput"
                                name="свободыInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[13].matcher)}
                            />.</p>
                            <p>Вот и всё (вот и всё),</p>
                            <p>Закончилась <input
                                id="поэмаInput"
                                name="поэмаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[14].matcher)}
                            />,</p>
                            <p>Но меня всё мучает <input
                                id="дилеммаInput"
                                name="дилеммаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[15].matcher)}
                            />,</p>
                            <p>Как мне быть - летать в <input
                                id="небеснойInput"
                                name="небеснойInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[16].matcher)}
                            />глади</p>
                            <p>Или стать стихом в <input
                                id="моейInput"
                                name="моейInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[17].matcher)}
                            /> тетради.</p>
                        </div>
                        <p className={classes.textCentered}><b>ПРИПЕВ (x2)</b></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;