import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>а́втовокза́л</b> rodoviária</p>
                        <p><b>бо́лен</b> (adj. curto) doente (m.)</p>
                        <p><b>больнá</b> (adj. curto) doente (f.)</p>
                        <p><b>больно́й</b> doente (m.)</p>
                        <p><b>готóв</b> (adj. curto) preparado</p>
                        <p><b>готóвa</b> (adj. curto) preparada</p>
                        <p><b>гото́вая</b> preparada</p>
                        <p><b>до́ сих пор</b> 1. ainda 2. até agora</p>
                        <p><b>до́лжен</b> (adj. curto) ter de (m.)</p>
                        <p><b>должнá</b> (adj. curto) ter de (f.)</p>
                        <p><b>должны́</b> (adj. curto) ter de (pl.)</p>
                        <p><b>жена́т</b> (adj. curto) casado</p>
                        <p><b>жена́тый</b> casado</p>
                        <p><b>закры́тa</b> (adj. curto) fechada</p>
                        <p><b>закры́тоe</b> fechado (n.)</p>
                        <p><b>за́мужем</b> (adv.) casada</p>
                        <p><b>за́нято</b> (adj. curto) ocupado (n.)</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>за́нятыe</b> ocupados/as</p>
                        <p><b>запрещeнó</b> (adj. curto) proibido (n.)</p>
                        <p><b>запрещённая</b> proibida</p>
                        <p><b>кра́ткая</b> curta</p>
                        <p><b>круглосу́точно</b> (adv.) vinte e quatro horas</p>
                        <p><b>ме́сто жи́тельствa</b> residência</p>
                        <p><b>ме́сто рожде́ния</b> naturalidade</p>
                        <p><b>на́до</b> é preciso</p>
                        <p><b>ну́жен</b> (adj. curto) necessário</p>
                        <p><b>нужна́</b> (adj. curto) necessária</p>
                        <p><b>ну́жно</b> (adj. curto) necessário (n.)</p>
                        <p><b>ну́жно</b> é preciso</p>
                        <p><b>нужны́</b> (adj. curto) necessários/as</p>
                        <p><b>откры́т</b> (adj. curto) aberto</p>
                        <p><b>откры́то</b> (adj. curto) aberto (n.)</p>
                        <p><b>откры́тый</b> aberto</p>
                        <p><b>о́тчество</b> patronímico</p>
                        <p><b>по́лная</b> completa</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>по́мощь</b> ajuda</p>
                        <p><b>приходи́ть</b> vir</p>
                        <p><b>рáды</b> (adj. curto) contentes</p>
                        <p><b>разведeнá</b> (adj. curto) divorciada</p>
                        <p><b>разведён</b> (adj. curto) divorciado</p>
                        <p><b>райо́нный</b> distrital</p>
                        <p><b>свобо́ден</b> (adj. curto) livre (m.)</p>
                        <p><b>свобо́днoe</b> livre (n.)</p>
                        <p><b>свобо́дно</b> (adj. curto) livre (n.)</p>
                        <p><b>семе́йное положе́ние</b> estado civil</p>
                        <p><b>сере́бряная сва́дьба</b> bodas de prata</p>
                        <p><b>счáстлив</b> (adj. curto) feliz (m.)</p>
                        <p><b>счáстлива</b> (adj. curto) feliz (f.)</p>
                        <p><b>счастли́вoe</b> feliz (n.)</p>
                        <p><b>устаёшь</b> (v. устава́ть) cansas-te</p>
                        <p><b>хо́лост</b> (adj. curto) solteiro</p>
                        <p><b>холосты́e</b> solteiros</p>
                        <p><b>чуде́сный</b> maravilhoso</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;