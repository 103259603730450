// Titles
export const dialog = 'ДИАЛОГ';
export const letsLearnRussian = 'Vamos aprender Russo';

// Main content
export const aboutThisProject = 'sobre o projeto';
export const levels = 'níveis';
export const team = 'equipa';
export const contacts = 'contactos';

// Modules
export const grammar = 'gramática';
export const exercises = 'exercícios';
export const vocabulary = 'vocabulário';

// Levels
export const a1 = 'a1';
export const a2 = 'a2';
export const b1 = 'b1';

//A1
export const index = 'índice';
export const a1ElementarLevel = 'A1 - Nível elementar';

export const introduction = 'introdução';
export const introductionRU = 'АЗЫ';
export const russianLanguage = 'língua russa';
export const phonetics = 'fonética';
export const alphabet = 'alfabeto';
export const internationalWords = 'palavras internacionais';

export const livroA1 = 'Livro A1';
export const livroA2 = 'Livro A2';
export const livroB1 = 'Livro B1';
export const audio = 'áudio';
export const audioRU = 'АУДИОТЕКСТЫ';

//A2
export const a2IntermediateLevel = 'A2 - Nível Intermédio';
export const repetition = 'ПОВТОРЕНИЕ';
export const Немногообомне = 'Немного обо мне…';


//Lessons number
export const lesson1 = 'урок 1';
export const lesson2 = 'урок 2';
export const lesson3 = 'урок 3';
export const lesson4 = 'урок 4';
export const lesson5 = 'урок 5';
export const lesson6 = 'урок 6';
export const lesson7 = 'урок 7';
export const lesson8 = 'урок 8';
export const lesson9 = 'урок 9';
export const lesson10 = 'урок 10';
export const lesson11 = 'урок 11';
export const lesson12 = 'урок 12';
export const lesson13 = 'урок 13';
export const lesson14 = 'урок 14';
export const lesson15 = 'урок 15';
export const lesson16 = 'урок 16';
export const lesson17 = 'урок 17';
export const lesson18 = 'урок 18';
export const lesson19 = 'урок 19';
export const lesson20 = 'урок 20';
export const lesson21 = 'урок 21';
export const lesson22 = 'урок 22';
export const lesson23 = 'урок 23';
export const lesson24 = 'урок 24';
export const lesson25 = 'урок 25';
export const lesson26 = 'урок 26';
export const lesson27 = 'урок 27';
export const lesson28 = 'урок 28';
export const lesson29 = 'урок 29';
export const lesson30 = 'урок 30';
export const lesson31 = 'урок 31';
export const lesson32 = 'урок 32';
export const lesson33 = 'урок 33';

//Lessons text A1
export const ВОЛШЕБНЫЕСЛОВА = 'ВОЛШЕБНЫЕ СЛОВА';
export const КТОЭ́ТО = 'КТО Э́ТО?';
export const МЫ = 'МЫ';
export const ЭТОМОЁ = 'ЭТО МОЁ';
export const ГДЕТЫ = 'ГДЕ ТЫ?';
export const ЯВСАДУ́ = 'Я В САДУ́';
export const СТЕРЕОТИ́ПЫ = 'СТЕРЕОТИ́ПЫ';
export const ОЧЁМРАЗГОВО́Р = 'О ЧЁМ РАЗГОВО́Р?';
export const ОНА́МНО́ГОГОВОРИ́Т = 'ОНА́ МНО́ГО ГОВОРИ́Т';
export const ЧТО́ТЫДЕ́ЛАЕШЬ = 'ЧТО́ ТЫ ДЕ́ЛАЕШЬ?';
export const ЛЮ́БИШЬНЕЛЮ́БИШЬ = 'ЛЮ́БИШЬ-НЕ ЛЮ́БИШЬ';
export const МОЁРАСПИСА́НИЕ = 'МОЁ РАСПИСА́НИЕ';
export const ВЧЕРА́ = 'ВЧЕРА́';
export const КОТÓРЫЙЧАС = 'КОТÓРЫЙ ЧАС?';
export const Э́ТАКНИ́ГАТОТФИЛЬМ = 'Э́ТА КНИ́ГА, ТОТ ФИЛЬМ';
export const ТЫКУДА́ = 'ТЫ КУДА́?';
export const ТЫГОВОРИ́ШЬПОРУ́ССКИ = 'ТЫ ГОВОРИ́ШЬ ПО-РУ́ССКИ?';
export const СИ́НИЙКРÁСНЫЙГОЛУБÓЙ = 'СИ́НИЙ, КРÁСНЫЙ, ГОЛУБÓЙ';
export const КАКТЫСЕБЯ́ЧУ́ВСТВУЕШЬ = 'КАК ТЫ СЕБЯ́ ЧУ́ВСТВУЕШЬ?';
export const СКО́ЛЬКОСТÓИТ = 'СКО́ЛЬКО СТÓИТ?';
export const ТЫОТКУ́ДА = 'ТЫ ОТКУ́ДА?';
export const ТЕПЛО́ХО́ЛОДНО = 'ТЕПЛО́ – ХО́ЛОДНО';
export const СКО́ЛЬКОТЕБЕ́ЛЕТ = 'СКО́ЛЬКО ТЕБЕ́ ЛЕТ?';
export const ПОЧЕМУ́НÉБОГОЛУБО́Е = 'ПОЧЕМУ́ НÉБО ГОЛУБО́Е?';
export const АТЫСЕГО́ДНЯУЛЫБА́ЛСЯ = 'А ТЫ СЕГО́ДНЯ УЛЫБА́ЛСЯ?';
export const АОНМНЕНРА́ВИТСЯ = 'А ОН МНЕ НРА́ВИТСЯ';
export const НА́ДОЗНА́ЧИТНА́ДО = 'НА́ДО – ЗНА́ЧИТ, НА́ДО!';
export const СЕГО́ДНЯПЯ́ТОЕЯНВАРЯ́ = 'СЕГО́ДНЯ ПЯ́ТОЕ ЯНВАРЯ́';
export const ЧЕМЕДЯ́ТРИС = 'ЧЕМ ЕДЯ́Т РИС?';
export const ЯХОЧУ́БЫТЬСТОБО́Й = 'Я ХОЧУ́ БЫТЬ С ТОБО́Й!';
export const КЕМТЫРАБО́ТАЕШЬ = 'КЕМ ТЫ РАБО́ТАЕШЬ?';
export const ТЫЛЮ́БИШЬГУЛЯ́ТЬПОДДОЖДЁМ = 'ТЫ ЛЮ́БИШЬ ГУЛЯ́ТЬ ПОД ДОЖДЁМ?';
export const ПОСМОТРИТЕНАПРА́ВО = 'ПОСМОТРИТЕ НАПРА́ВО!';

//Lessons text A2
export const ЧТОТЫЛЮ́БИШЬ = 'ЧТО ТЫ ЛЮ́БИШЬ?';
export const НЕМНО́ГООФУТБО́ЛЕ = 'НЕМНО́ГО О ФУТБО́ЛЕ';
export const ВОНТОТГА́ЛСТУК = 'ВОН ТОТ ГА́ЛСТУК';
export const ДОММОЕ́ЙМЕЧТЫ́ = 'ДОМ МОЕ́Й МЕЧТЫ́';
export const КУДА́ПОЕ́ХАТЬВО́ТПУСК = 'КУДА́ ПОЕ́ХАТЬ В О́ТПУСК?';
export const КАКА́ЯРА́ЗНИЦА = 'КАКА́Я РА́ЗНИЦА!';
export const ОНМА́СТЕРСВОЕГО́ДЕ́ЛА = 'ОН МА́СТЕР СВОЕГО́ ДЕ́ЛА!';
export const АСКО́ЛЬКО = 'А СКО́ЛЬКО...?';
export const МОИ́ПРИВЫ́ЧКИ = 'МОИ́ ПРИВЫ́ЧКИ';
export const ДОИПО́СЛЕ = 'ДО И ПО́СЛЕ';
export const ПÓЛВТОРÓГО = 'ПÓЛВТОРÓГО';
export const НИЧЕГО́НЕВИ́ЖУ = 'НИЧЕГО́ НЕ ВИ́ЖУ';
export const ВСЁКРО́МЕ = 'ВСЁ, КРО́МЕ';
export const ПОПОНЕДЕ́ЛЬНИКАМ = 'ПО ПОНЕДЕ́ЛЬНИКАМ';
export const ПОСЕКРЕ́ТУВСЕМУ́СВЕ́ТУ = 'ПО СЕКРЕ́ТУ ВСЕМУ́ СВЕ́ТУ';
export const НАДОÉЛО = 'НАДОÉЛО';
export const СЧАСТЛИВОЕРОЖДЕСТВО = 'СЧАСТЛИВОЕ РОЖДЕСТВО';
export const ЯМЕЛАНХО́ЛИК = 'Я МЕЛАНХО́ЛИК';
export const ВКАКÓМГОДÝ = 'В КАКÓМ ГОДÝ...?';
export const СА́МЫЙСА́МЫЙ = 'СА́МЫЙ-СА́МЫЙ';
export const ПОГУЛЯ́ЕМПОГÓРОДУ = 'ПОГУЛЯ́ЕМ ПО ГÓРОДУ';
export const ТРИЧЁРНЫХСОБА́КИНАЧЁМТОСИДЕ́ЛИ = 'ТРИ ЧЁРНЫХ СОБА́КИ НА ЧЁМ-ТО СИДЕ́ЛИ...';
export const ЧТОДЕ́ЛАТЬЕ́СЛИ = 'ЧТО ДЕ́ЛАТЬ, Е́СЛИ...';
export const ЯХОЧУЧТО́БЫ = 'Я ХОЧУ,́ ЧТО́БЫ...';
export const СОБЛЮДА́ЙТЕТИШИНУ́ = 'СОБЛЮДА́ЙТЕ ТИШИНУ́!';
export const ПУСТЬВСЕГДА́БУД́ЕТСО́ЛНЦЕ = 'ПУСТЬ ВСЕГДА́ БУД́ЕТ СО́ЛНЦЕ!';
export const ПРИШЁЛЗАШЁЛВЫ́ШЕЛПОШЁЛ = 'ПРИШЁЛ. ЗАШЁЛ, ВЫ́ШЕЛ, ПОШЁЛ';
export const МНЕНÉКОГДА = 'МНЕ НÉКОГДА';

//B1
export const b1IntermediateLevel = 'B1 - Nível Intermédio';
export const Какяпровёлсвоёлéто = 'Как я провёл своё лéто';
export const ЧЕТЫ́РЕЦВЕ́ТАГО́ДА = 'ЧЕТЫ́РЕ ЦВЕ́ТА ГО́ДА';
export const ЧЕМОДÁННОЕНАСТРОÉНИЕ = 'ЧЕМОДÁННОЕ НАСТРОÉНИЕ';
export const МОЛОКО́УБЕЖА́ЛО = 'МОЛОКО́ УБЕЖА́ЛО';
export const ЧТОПРИВЕЗТИ́ = 'ЧТО ПРИВЕЗТИ́?';
export const СОВЕ́ТЫПУТЕШЕ́СТВЕННИКАМ = 'СОВЕ́ТЫ ПУТЕШЕ́СТВЕННИКАМ';
export const ОТДЫХА́ТЬМО́ЖНОПОРА́ЗНОМУ = 'ОТДЫХА́ТЬ МО́ЖНО ПО-РА́ЗНОМУ!';
export const КУДА́БЫПОЕ́ХАТЬ = 'КУДА́ БЫ ПОЕ́ХАТЬ?';
export const ПОСМОТРИ́ТЕНАЭ́ТУКАРТИ́НУ = 'ПОСМОТРИ́ТЕ НА Э́ТУ КАРТИ́НУ';
export const НА́ЙДЕНШЕДЕ́ВР = 'НА́ЙДЕН ШЕДЕ́ВР!';
export const ПОСЛЕДÁМ = 'ПО СЛЕДÁМ';
export const ПОДЗÉМНЫЙМИР = 'ПОДЗÉМНЫЙ МИР';
export const ИВОТМЫВАЭРОПОРТУ́ = 'И ВОТ МЫ В АЭРОПОРТУ́';
export const НАБОРТУ́ = 'НА БОРТУ́';
export const ВСЁСУ́ПЕР = 'ВСЁ СУ́ПЕР!';
export const ТРАНССИ́Б = 'ТРАНССИ́Б';
export const МОИ́УНИВЕРСИТЕ́ТЫ = 'МОИ́ УНИВЕРСИТЕ́ТЫ';
export const ПРИЛЕТÉЛИ = 'ПРИЛЕТÉЛИ!';
export const СЪЕ́ЗДИТЬСЪЕ́ХАТЬИСЪЕЗЖА́ТЬ = 'СЪЕ́ЗДИТЬ, СЪЕ́ХАТЬ И СЪЕЗЖА́ТЬ';
export const СЛЕТА́ТЬНАЛУНУ́ = 'СЛЕТА́ТЬ НА ЛУНУ́';
export const ЗАЧÉМНАМКÓСМОС = 'ЗАЧÉМ НАМ КÓСМОС?';
export const ИСКУ́ССТВОНАЦЫ́ПОЧКАХ = 'ИСКУ́ССТВО НА ЦЫ́ПОЧКАХ';
export const ИТАКСОЙДЁТ = 'И ТАК СОЙДЁТ!';
export const БЛАГОДАРЯ́РОДИ́ТЕЛЯМ = 'БЛАГОДАРЯ́ РОДИ́ТЕЛЯМ';
export const КРАСОТА́СПАСЁТМИР = 'КРАСОТА́ СПАСЁТ МИР';
export const ЧЁРНЫЙКОТ = 'ЧЁРНЫЙ КОТ';
export const МИМИМИ́ШНЫЙЯЗЫ́Ќ = 'МИМИМИ́ШНЫЙ ЯЗЫ́Ќ';
export const НАЧТОПОХО́ЖСНЕГ = 'НА ЧТО ПОХО́Ж СНЕГ?';
export const ПТИ́ЧИЙЯЗЫ́К = 'ПТИ́ЧИЙ ЯЗЫ́К ';



