import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson21 from '../../../../../../Assets/Images/A1/Lesson21/image1lesson21.png';
import Image2Lesson21 from '../../../../../../Assets/Images/A1/Lesson21/image2lesson21.png';
import Image3Lesson21 from '../../../../../../Assets/Images/A1/Lesson21/image3lesson21.png';
import Image4Lesson21 from '../../../../../../Assets/Images/A1/Lesson21/image4lesson21.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>РОДИТЕЛЬНЫЙ ПАДЕЖ: НАПРАВЛЕНИЕ</p>
                    <p>Genitivo de direção</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image1Lesson21" src={Image1Lesson21} alt="lesson 21 illustrative example 1" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>ОТ &#8594; ДО</p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p>
                                <b><span className={classes.orangeHighlight}>от</span></b> до́м<b><span className={classes.orangeHighlight}>а</span></b> <b><span className={classes.orangeHighlight}>до</span></b>                пля́ж<b><span className={classes.orangeHighlight}>а</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>от</span></b> го́род<b><span className={classes.orangeHighlight}>а</span></b>                <b><span className={classes.orangeHighlight}>до</span></b> дере́вн<b><span className={classes.orangeHighlight}>и</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>от</span></b> о́зер<b><span className={classes.orangeHighlight}>а</span></b> <b><span className={classes.orangeHighlight}>до</span></b>                доро́г<b><span className={classes.orangeHighlight}>и</span></b>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image2Lesson21" src={Image2Lesson21} alt="lesson 21 illustrative example 2" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p> О́КОЛО</p>
                            <p>У</p>
                            <p>ВО́ЗЛЕ</p>
                            <p>НЕДАЛЕКО́ ОТ</p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p>университе́т<b><span className={classes.orangeHighlight}>а</span></b></p>
                            <p>шко́л<b><span className={classes.orangeHighlight}>ы</span></b></p>
                            <p>окн<b><span className={classes.orangeHighlight}>á</span></b></p>
                        </div>
                    </div>
                </div>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image3Lesson21" src={Image3Lesson21} alt="lesson 21 illustrative example 3" />
                        </div>
                        <div className={classes.gridItem2}>
                            <div>
                                <p>
                                    ИЗ
                                </p>
                            </div>
                            <div>
                                <p>C</p>
                            </div>
                        </div>
                        <div className={classes.gridItem3}>
                            <div>
                                <p>университе́т<b><span className={classes.orangeHighlight}>а</span></b></p>
                                <p>шко́л<b><span className={classes.orangeHighlight}>ы</span></b></p>
                                <p>метро́</p>
                            </div>
                            <div>
                                <p>пля́ж<b><span className={classes.orangeHighlight}>а</span></b></p>
                                <p>ле́кци<b><span className={classes.orangeHighlight}>и</span></b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ГЛАГОЛЫ ДВИЖЕНИЯ НЕСОВЕРШЕННОГО ВИДА</p>
                    <p>настоящее время</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <img id="Image4Lesson21" src={Image4Lesson21} alt="lesson 21 illustrative example 4" />
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                Advérbios e expressões do grupo I:
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>сейча́с, в да́нный моме́нт, за́втра, …</span></b>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                Advérbios e expressões do grupo II:</p>
                            <p>
                                <b><span className={classes.orangeHighlight}>обы́чно, всегдá, чáсто, ка́ждый день, …</span></b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;