import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'сказать', name: 'сказать', matcher: 'сказать' },
    { id: 'поймать', name: 'поймать', matcher: 'поймать' },
    { id: 'взять', name: 'взять', matcher: 'взять' },
    { id: 'сесть', name: 'сесть', matcher: 'сесть' },
    { id: 'лечь', name: 'лечь', matcher: 'лечь' },
    { id: 'найти', name: 'найти', matcher: 'найти' },
    { id: 'положить', name: 'положить', matcher: 'положить' },
    { id: 'стать', name: 'стать', matcher: 'стать' },
    { id: 'начать', name: 'начать', matcher: 'начать' },
    { id: 'понять', name: 'понять', matcher: 'понять' },
    { id: 'надоесть', name: 'надоесть', matcher: 'надоесть' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escreva os pares aspectuais perfetivos dos seguintes verbos:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1_first}>
                        <div className={classes.wordWrapper}>
                            Imperfetivo
                        </div>
                        <div className={classes.inputWrapper}>
                            Perfetivo
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            говори́ть
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            лови́ть
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            брать
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            сади́ться
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            ложи́ться
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            находи́ть
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            класть
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            станови́ться
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            начина́ть
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            понима́ть
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.wordWrapper}>
                            надоеда́ть
                        </div>
                        <div className={classes.inputWrapper}>
                            <input
                                id="perfeitoInput"
                                name="perfeitoInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;