import React from 'react';
import classes from './Team.css';
import MainHeader from '../../Header/MainHeader/MainHeader';
import centerColumn from '../../CenterColumn/CenterColumn.css';
import noImage from '../../../Assets/Images/Team/noImage.jpg';
import nailiaImage from '../../../Assets/Images/Team/nailiaImage.jpg';
import jayantiImage from '../../../Assets/Images/Team/jayantiImage.jpg';
import leahImage from '../../../Assets/Images/Team/leahImage.jpg';
import inesDuarteImage from '../../../Assets/Images/Team/inesDuarteImage.jpg';
import anaCImage from '../../../Assets/Images/Team/anaCImage.jpg';
import dmitriImage from '../../../Assets/Images/Team/dmitriImage.jpg';
import esperancaImage from '../../../Assets/Images/Team/esperancaImage.jpg';
import gueorguiImage from '../../../Assets/Images/Team/gueorguiImage.jpg';
import igorImage from '../../../Assets/Images/Team/igorImage.jpg';
import nhimaImage from '../../../Assets/Images/Team/nhimaImage.jpg';
import suseteImage from '../../../Assets/Images/Team/suseteImage.jpg';
import yanaImage from '../../../Assets/Images/Team/yanaImage.jpg';
import marioImage from '../../../Assets/Images/Team/marioImage.jpg';

const team = props => {
    return (
        <div class={centerColumn.CenterColumn}>
            <MainHeader title={props.title} />
            <div className={classes.Team}>
                <div className={classes.Person}><img src={nailiaImage} alt="img" /><p className={classes.Name}>Nailia Baldé</p><p className={classes.Role}>Autor/ Paginação</p></div>
                <div className={classes.Person}><img src={anaCImage} alt="img" /><p className={classes.Name}>Ana Carina Prokopyshyn</p><p className={classes.Role}>Autor</p></div>
                <div className={classes.Person}><img src={jayantiImage} alt="img" /><p className={classes.Name}>Jayanti Dutta</p><p className={classes.Role}>Autor</p></div>
                <div className={classes.Person}><img src={esperancaImage} alt="img" /><p className={classes.Name}>Esperança Cardeira</p><p className={classes.Role}>Coordenação do Projeto</p></div>
                <div className={classes.Person}><img src={gueorguiImage} alt="img" /><p className={classes.Name}>Gueorgui Nenov Hristovsky</p><p className={classes.Role}>Coordenação Científica</p></div>
                <div className={classes.Person}><img src={inesDuarteImage} alt="img" /><p className={classes.Name}>Inês Duarte</p><p className={classes.Role}>Coordenação Pedagógica</p></div>
                <div className={classes.Person}><img src={leahImage} alt="img" /><p className={classes.Name}>Leah Lyubomirskaya</p><p className={classes.Role}>Consultora geral/Gravação Áudio</p></div>
                <div className={classes.Person}><img src={marioImage} alt="img" /><p className={classes.Name}>Mário Baldé</p><p className={classes.Role}>Construção de conteúdos web</p></div>
                <div className={classes.Person}><img src={suseteImage} alt="img" /><p className={classes.Name}>Susete Lopes Ferreira Bruno</p><p className={classes.Role}>Ilustração/ Paginação</p></div>
                <div className={classes.Person}><img src={dmitriImage} alt="img" /><p className={classes.Name}>Dmitri Osokin</p><p className={classes.Role}>Gravação Áudio</p></div>
                <div className={classes.Person}><img src={yanaImage} alt="img" /><p className={classes.Name}>Yana Osokina</p><p className={classes.Role}>Gravação Áudio</p></div>
                <div className={classes.Person}><img src={nhimaImage} alt="img" /><p className={classes.Name}>Nhima Baldé</p><p className={classes.Role}>Gravação Áudio</p></div>
                <div className={classes.Person}><img src={igorImage} alt="img" /><p className={classes.Name}>Igor Vasilievitch Ruzhitskiy</p><p className={classes.Role}>Revisão de Conteúdos</p></div>
                <div className={classes.Person}><img src={noImage} alt="img" /><p className={classes.Name}>Lidia Vasilievna Krasilnikova</p><p className={classes.Role}>Revisão de Conteúdos</p></div>
                <div className={classes.Person}><img src={noImage} alt="img" /><p className={classes.Name}>Mikhail Stupnikov</p><p className={classes.Role}>Gravação Áudio</p></div>
            </div>
        </div>
    );
}

export default team;