import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Lesson29 from '../../../../../../Assets/Images/A1/Lesson29/image1lesson29.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ТВОРИТЕЛЬНЫЙ ПАДЕЖ</p>
                    <p>Caso Instrumental</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="Image1Lesson29" src={Image1Lesson29} alt="lesson 29 illustrative example 1" />
                </div>
                <div className={classes.item2}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Entre outras funções, o Caso Instrumental pode ter as funções de:
                    </p>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <b><span className={classes.whiteHighlight}>INSTRUMENTO</span></b>
                            </p>
                            <p>
                                <b><span className={classes.whiteHighlight}>Чем</span></b> едя́т рис?
                            </p>
                            <p>ви́лкой</p>
                            <p>ло́жкой</p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <b><span className={classes.whiteHighlight}>COMPANHIA</span></b>
                            </p>
                            <p>
                                <b><span className={classes.whiteHighlight}>С чем</span></b> едя́т рис?
                            </p>
                            <p><b><span className={classes.whiteHighlight}>с</span></b> ры́бой</p>
                            <p><b><span className={classes.whiteHighlight}>с</span></b> со́усом</p>
                        </div>
                    </div>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A função de “Instrumento” significa que o nome é usado como meio pelo/com qual a acção é realizada.
                    </p>
                    <p>
                        <i><b>Я ем рис <span className={classes.orangeHighlight}>ло́жкой</span>. = Eu como arroz com colher.</b></i>
                    </p>
                    <p>
                        <i><b>Он рису́ет <span className={classes.orangeHighlight}>ру́чкой</span>. = Ele desenha com a caneta.</b></i>
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Como o próprio nome indica, a função de “Companhia” aponta para a ocorrência conjunta de um nome com outro. Gramaticalmente, este Caso é chamado Comitativo e ocorre sempre com a preposição C.
                    </p>
                    <p>
                        <i><b>Я ем рис <span className={classes.orangeHighlight}>с мя́сом</span>. = Eu como arroz com carne.</b></i>
                    </p>
                    <p>
                        <i><b>Он встреча́ется <span className={classes.orangeHighlight}>с дру́гом</span>. = Ele encontra-se/tem um encontro com um amigo.</b></i>
                    </p>
                </div>
                <GrammarSubtitle>
                    <p>ВИД</p>
                    <p>Aspeto</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Aspeto é uma categoria gramatical ligada ao desenvolvimento (início/duração/repetição/conclusão) da ação verbal.
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Quase todos os verbos russos têm um par aspetual (perfetivo/imperfetivo), geralmente formado por afixos.
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Os verbos perfetivos nunca têm valor de Tempo Presente.
                    </p>
                </div>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p className={classes.textAlignCenter}><b><span className={classes.greenHighlight}>НЕСОВЕРШЕННЫЙ ВИД</span></b></p>
                            <p className={classes.textAlignCenter}><b>Imperfetivo</b></p>
                            <div className={classes.gridItem1gridItems}>
                                <div className={classes.gridItem1}>
                                    <p>- Factos em geral</p>
                                    <p>- Processo contínuo</p>
                                    <p>- Repetição</p>
                                    <p>- Ações paralelas</p>
                                </div>
                                <div className={classes.gridItem2}>
                                    <p><b>Я всегда́ де́лаю уро́ки.</b></p>
                                    <p><b>Я сейча́с де́лаю уро́ки.</b></p>
                                    <p><b>Я ка́ждый день де́лаю уро́ки.</b></p>
                                    <p><b>Когда́ я де́лаю уро́ки, я мно́го ду́маю.</b></p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <p className={classes.textAlignCenter}><b><span className={classes.orangeHighlight}>СОВЕРШЕННЫЙ ВИД</span></b></p>
                            <p className={classes.textAlignCenter}><b>Perfetivo</b></p>
                            <div className={classes.gridItem2gridItems}>
                                <div className={classes.gridItem1}>
                                    <p>- Factos concretos</p>
                                    <p>- Ação concluída</p>
                                    <p>- Ação pontual</p>
                                    <p>- Ações sequenciais</p>
                                </div>
                                <div className={classes.gridItem2}>
                                    <p><b>Я прочита́л в интерне́те, что бу́дет снег.</b></p>
                                    <p><b>Я сде́лал уро́ки.</b></p>
                                    <p><b>Я начал де́лать уро́ки.</b></p>
                                    <p><b>Я сде́лал уро́ки и пошёл гуля́ть.</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.item5}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>делать</p>
                            <p>писать</p>
                            <p>читать</p>
                            <p>учить</p>
                            <p>смотреть</p>
                            <p>видеть</p>
                            <p>готовить</p>
                            <p>платить</p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b><span className={classes.orangeHighlight}>c</span></b>делать</p>
                            <p><b><span className={classes.orangeHighlight}>на</span></b>писать</p>
                            <p><b><span className={classes.orangeHighlight}>про</span></b>читать</p>
                            <p><b><span className={classes.orangeHighlight}>вы</span></b>учить</p>
                            <p><b><span className={classes.orangeHighlight}>по</span></b>смотреть</p>
                            <p><b><span className={classes.orangeHighlight}>у</span></b>видеть</p>
                            <p><b><span className={classes.orangeHighlight}>при</span></b>готовить</p>
                            <p><b><span className={classes.orangeHighlight}>за</span></b>платить</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;