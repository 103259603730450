import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: '4', name: '4', matcher: '4' },
    { id: '3', name: '3', matcher: '3' },
    { id: '7', name: '7', matcher: '7' },
    { id: '1', name: '1', matcher: '1' },
    { id: '6', name: '6', matcher: '6' },
    { id: '2', name: '2', matcher: '2' },
    { id: '5', name: '5', matcher: '5' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
            event.currentTarget.style.background = 'white';
        } else {
            event.currentTarget.style.color = 'red';
            event.currentTarget.style.background = 'white';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Найдите объяснение суеверию (укажите номер)." />
                <div className={classes.questionsContainerEx1}>
                    <div>
                        <p>1. Не свисти в доме...</p>
                        <p>2. Надо присесть...</p>
                        <p>3. Сплюнь «Тьфу-тьфу-тьфу» и постучи три раза по дереву...</p>
                        <p>4. Никогда не убивай паука в доме...</p>
                        <p>5. Здороваться и прощаться на пороге...</p>
                        <p>6. Муха в суп попала...</p>
                        <p>7. Когда не узнал знакомого человека...</p>
                    </div>
                    <div className={classes.inputsContainer}>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input1"
                                    name="input1"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[0].matcher)} /> ... он к деньгам.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input2"
                                    name="input2"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[1].matcher)} /> ... чтобы не сглазить...
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input3"
                                    name="input3"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[2].matcher)} /> ... быть ему богатым.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input4"
                                    name="input4"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[3].matcher)} /> ... иначе денег не будет.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input5"
                                    name="input5"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[4].matcher)} /> ... жди скорого подарка.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input6"
                                    name="input6"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[5].matcher)} /> ... на дорожку.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input7"
                                    name="input7"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[6].matcher)} /> ... к ссоре.
                            </div>
                        </div>
                    </div>
                    <div className={classes.textContent}>
                        <b>Больше о суевериях в России:</b> <a href="https://rg.ru/2010/09/10/rbth-primety.html"
                            target="_blank"
                            rel="noopener noreferrer">
                            https://rg.ru/2010/09/10/rbth-primety.html
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;