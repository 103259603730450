import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson19/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson19/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson19/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson19/image4grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ОДНОВИДОВЫЕ ГЛАГОЛЫ СВ</p>
                    <p>Perfetivos (sem par)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Alguns verbos perfetivos não têm par imperfetivo. Normalmente, são verbos formados por prefixação. O prefixo já atribui ao verbo um determinado significado que se traduz na expressão de diferentes ações: início/fim da ação, ação durativa, etc.</p>
                    <ul>
                        <li><i>пое́хать</i></li>
                        <li><i>заблуди́ться</i></li>
                        <li><i>состоя́ться</i></li>
                        <li><i>пойти́</i></li>
                        <li><i>полетéть</i></li>
                        <li><i>запéть</i></li>
                        <li><i>побежа́ть</i></li>
                        <li><i>засмея́ться</i></li>
                    </ul>
                </div>
                <GrammarSubtitle>
                    <p>DATAS</p>
                    <p>Даты</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Ao indicar uma data (um ano), apenas o último numeral muda (o número ordinal).</p>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Como resposta à pergunta <b><span className={classes.orangeHighlight}>“КОГДÁ?”</span></b>, se referimos apenas o ano, o ordinal e a palavra “ano” estão no Prepositivo:</p>
                    <div className={classes.item2_1}>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="300px" />
                    </div>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Se referimos a data completa (dia e ano), o ordinal e a palavra “ano” estão no Genitivo:</p>
                    <div className={classes.item2_2}>
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="300px" />
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>КОТÓРЫЙ ≠ ИМ. П.</p>
                    <p>Frase Relativa (declinação do pronome)</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O pronome relativo declina, consoante a sua função na frase:</p>
                    <p><b>Este é o meu amigo...</b></p>
                    <div className={classes.item3_1}>
                        <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="250px" />
                        <div>
                            <p><b>... o qual</b> <u>nunca tem dinheiro</u>.</p>
                            <p><b>... ao qual</b> os pais <u>ofereceram</u> um carro.</p>
                            <p><b>... o qual</b> tu <u>viste</u> ontem na universidade.</p>
                            <p><b>... com o qual</b> <u>divertimo-nos</u> sempre.</p>
                            <p><b>... sobre o qual</b> eu te <u>falei</u>.</p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p className={classes.smallText}>VERBOS DE MOVIMENTO PREFIXADOS</p>
                    <p>ГЛАГОЛЫ ДВИЖЕНИЯ С ПРИСТАВКАМИ:</p>
                    <p>ПО-</p>
                    <p>совершенный вид (grupo I)</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Os verbos de movimento perfetivos são formados por prefixação. Os do grupo I indicam o início do movimento (no Passado) ou uma intenção (no Futuro).</p>
                    <div className={classes.item4_1}>
                        <img id="image4Grammar" src={Image4Grammar} alt="table 4" width="200px" />
                    </div>
                    <div className={classes.item4_2}>
                        <p>За́втра мы <b>пойдём</b> на конце́рт. (Amanhã <b>vamos</b> a um concerto.)</p>
                        <p>– Я то́же хочу́ <b>пойду́</b>. (Eu também <b>vou</b>.)</p>
                    </div>
                    <div>
                        <p>Я опозда́л, ло́дка уже́ <b>поплылá</b> на друго́й бе́рег.</p>
                        <p>Eu atrasei-me e o barco já <b>saiu/foi</b> para a outra margem.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;