import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'у меня́ нет ру́чки', name: 'у меня́ нет ру́чки', translation: '1) Почему́ ты не пи́шешь?' },
    { id: 'по доро́ге', name: 'по доро́ге', translation: '2) По чему́ е́дет машина?' },
    { id: 'по не́бу', name: 'по не́бу', translation: '3) По чему́ лети́т самолёт?' },
    { id: 'ей гру́стно', name: 'ей гру́стно', translation: '4) Почему́ она́ пла́чет?' },
    { id: 'по реке́', name: 'по реке́', translation: '5) По чему́ плывёт кора́бль?' },
    { id: 'он опа́здывает на рабо́ту', name: 'он опа́здывает на рабо́ту', translation: '6) Почему́ он бежи́т?' },
];

const VerbList = [
    { id: 'Нора так хорошо говорит по-венгерски', name: 'Нора так хорошо говорит по-венгерски', matcher: 'Нора так хорошо говорит по-венгерски' },
    { id: 'Мы едем на автобусе', name: 'Мы едем на автобусе', matcher: 'Мы едем на автобусе' },
    { id: 'Я принимаю анальгин', name: 'Я принимаю анальгин', matcher: 'Я принимаю анальгин' },
    { id: 'Ты не работаешь, как папа', name: 'Ты не работаешь, как папа', matcher: 'Ты не работаешь, как папа' },
    { id: 'Дяди Ивана нет дома', name: 'Дяди Ивана нет дома', matcher: 'Дяди Ивана нет дома' },
    { id: 'Ночью включаем свет', name: 'Ночью включаем свет', matcher: 'Ночью включаем свет' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true);
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Complete as frases." />
                <b>Образец: Почему́ сего́дня па́па не рабо́тает? Па́па сего́дня не работает, потому́ что у него́ сего́дня выходно́й день.</b>
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            а) Почему́ Но́ра так хорошо́ говори́т по-венге́рски? <input
                                id="еёInput"
                                name="еёInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> , потому́ что она́ до́лго жила́ в Ве́нгрии.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            б) Почему́ мы е́дем на авто́бусе? <input
                                id="ихInput"
                                name="ихInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> , потому́ что у нас нет маши́ны.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            в) Почему́ ты принима́ешь анальги́н? <input
                                id="МеняInput"
                                name="МеняInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> , потому́ что у меня́ боли́т голова́.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            г) Почему́ я не рабо́таю, как па́па? <input
                                id="васInput"
                                name="васInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> , потому́ что ты ещё ма́ленький.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            д) Почему́ дя́ди Ива́на нет дома? <input
                                id="TебяInput"
                                name="TебяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> , потому́ что он сейча́с на рабо́те.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            е) Почему́ но́чью включа́ем свет, а днём нет? <input
                                id="егоInput"
                                name="егоInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> , потому́ что но́чью нет со́лнца. Со́лнце днём освеща́ет зе́млю.
                        </div>
                    </div>
                </div>
                <ExercisesQuestions number="2" question="Associe as frases" />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;