import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'пейте', name: 'пейте', matcher: 'пейте' },
    { id: 'ешьте', name: 'ешьте', matcher: 'ешьте' },
    { id: 'курите', name: 'курите', matcher: 'курите' },
    { id: 'занимайтесь', name: 'занимайтесь', matcher: 'занимайтесь' },
    { id: 'спите', name: 'спите', matcher: 'спите' },
    { id: 'будьте', name: 'будьте', matcher: 'будьте' },
    { id: 'ешьте', name: 'ешьте', matcher: 'ешьте' },
    { id: 'нервничайте', name: 'нервничайте', matcher: 'нервничайте' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Complete as frases com o verbo na forma imperativa:" />
                <p><i>Е́сли <u>вы</u> хоти́те быть здоро́вым, то…</i></p>
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            1. ... <input
                                id="пейтеInput"
                                name="пейтеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> (пить) 2 ли́тра воды́ в день!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            2. ... не <input
                                id="ешьтеInput"
                                name="ешьтеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> (есть) мно́го сла́дкого!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            3. ... не <input
                                id="куритеInput"
                                name="куритеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> (кури́ть)!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx}>
                        <div className={classes.inputWrapper}>
                            4. ... <input
                                id="занимайтесьInput"
                                name="занимайтесьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> (занимáться) спóртом!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            5. ... <input
                                id="спитеInput"
                                name="спитеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (спать) 8 часо́в в день!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            6. ... <input
                                id="будьтеInput"
                                name="будьтеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> (быть) организо́ванны!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            7. ... <input
                                id="ешьтеInput"
                                name="ешьтеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> (есть) 5 раз в день!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            8. ... <input
                                id="нервничайтеInput"
                                name="нервничайтеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> (не́рвничать)!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;