import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const questions = [
    {
        text: "говори́ть",
        options: [
            { id: 0, text: "телеви́зор", isCorrect: false },
            { id: 1, text: "по-португа́льски", isCorrect: true },
            { id: 2, text: "журна́л", isCorrect: false },
        ],
    },
    {
        text: "лежа́ть",
        options: [
            { id: 0, text: "о пробле́ме", isCorrect: false },
            { id: 1, text: "телеви́зор", isCorrect: false },
            { id: 2, text: "на крова́ти", isCorrect: true },
        ],
    },
    {
        text: "молча́ть",
        options: [
            { id: 0, text: "на конце́ртe", isCorrect: true },
            { id: 1, text: "журна́л", isCorrect: false },
            { id: 2, text: "всё", isCorrect: false },
        ],
    },
    {
        text: "ду́мать",
        options: [
            { id: 0, text: "всё", isCorrect: false },
            { id: 1, text: "тру́бку", isCorrect: false },
            { id: 2, text: "о пробле́ме", isCorrect: true },
        ],
    },
    {
        text: "знать",
        options: [
            { id: 0, text: "на крова́ти", isCorrect: false },
            { id: 1, text: "всё", isCorrect: true },
            { id: 2, text: "на конце́ртe", isCorrect: false },
        ],
    },
    {
        text: "смотре́ть",
        options: [
            { id: 0, text: "по-португа́льски", isCorrect: false },
            { id: 1, text: "телеви́зор", isCorrect: true },
            { id: 2, text: "тру́бку", isCorrect: false },
        ],
    },
    {
        text: "кури́ть",
        options: [
            { id: 0, text: "журна́л", isCorrect: false },
            { id: 1, text: "тру́бку", isCorrect: true },
            { id: 2, text: "o пробле́ме", isCorrect: false },
        ],
    },
    {
        text: "стоя́ть",
        options: [
            { id: 0, text: "по-португа́льски", isCorrect: false },
            { id: 1, text: "журна́л", isCorrect: false },
            { id: 2, text: "на конце́ртe", isCorrect: true },
        ],
    },
    {
        text: "чита́ть",
        options: [
            { id: 0, text: "телеви́зор", isCorrect: false },
            { id: 1, text: "журна́л", isCorrect: true },
            { id: 2, text: "тру́бку", isCorrect: false },
        ],
    },
];

const Exercises = props => {
    const [showResults, setShowResults] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);

    const optionClicked = (isCorrect) => {
        // Increment the score
        if (isCorrect) {
            setScore(score + 1);
        }

        if (currentQuestion + 1 < questions.length) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowResults(true);
        }
    };

    /* Resets the game back to default */
    const restartGame = () => {
        setScore(0);
        setCurrentQuestion(0);
        setShowResults(false);
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Associe as palavras. Clique na caixa que corresponde ao verbo." />
                <h2 className={classes.score}>Pontuação: {score}</h2>
                {showResults ? (
                    /* 4. Final Results */
                    <div className={classes.finalResults}>
                        <h1>Resultado Final</h1>
                        <h2>
                            {score} de {questions.length} corretas - (
                            {(score / questions.length) * 100}%)
                        </h2>
                        <button className={classes.resetGame} onClick={() => restartGame()}>Reiniciar jogo</button>
                    </div>
                ) : (
                    /* 5. Question Card  */
                    <div className={classes.questionCard}>
                        {/* Current Question  */}
                        <h2>
                            Pergunta: {currentQuestion + 1} de {questions.length}
                        </h2>
                        <h3 className={classes.questionText}>{questions[currentQuestion].text}</h3>

                        {/* List of possible answers  */}
                        <ul className={classes.listStyleUl}>
                            {questions[currentQuestion].options.map((option) => {
                                return (
                                    <li
                                        key={option.id}
                                        onClick={() => optionClicked(option.isCorrect)}
                                        className={classes.listStyleLi}
                                    >
                                        {option.text}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Exercises;