import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson9 from '../../../../../../Assets/Images/A1/Lesson9/image1lesson9.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>II СПРЯЖЕНИЕ: 1. Правильные глаголы</p>
                    <p>II CONJUGAÇÃO: 1. Verbos Regulares</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="TalkStayQuietVerbConjugation" src={Image1Lesson9} alt="talk and stay quiet verb conjugation" width="300px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <div id="div1">
                                <p>
                                    <font size="4"><span className={classes.orangeHighlight}>&#9758;</span></font>Apesar de muitos verbos da <b>II Conjugação</b> terminarem em <b><span className={classes.orangeHighlight}>-И́ТЬ</span></b> no infinitivo, não é isso que distingue a conjugação, mas sim:
                                </p>
                            </div>
                            <div className={classes.gridItems}>
                                <div className={classes.gridItem1}>
                                    <p>o tema em <span className={classes.orangeHighlight}>-И </span></p>
                                    <div>
                                        <p>3.ª pessoa do pl. <span className={classes.orangeHighlight}> -ЮТ/- УТ </span></p>
                                    </div>
                                </div>
                                <div className={classes.gridItem2}>
                                    <p>
                                        <span className={classes.orangeHighlight}>&#9758;</span><b> Ч  Ж  Ш  Щ</b>
                                    </p>
                                    <p>
                                        мол<b>Ч<span className={classes.greenHighlight}>у́</span></b>, ле<b>Ж<span className={classes.greenHighlight}>у́</span></b>
                                    </p>
                                    <p>
                                        <b><span className={classes.redHighlight}>&#9746;</span></b> Ю<b><span className={classes.greenHighlight}> &#9745;</span></b> У
                                    </p>
                                    <p>
                                        мол<b>Ч<span className={classes.greenHighlight}>а́</span></b>т, ле<b>Ж<span className={classes.greenHighlight}>а́</span></b>т
                                    </p>
                                    <p>
                                        <b><span className={classes.redHighlight}>&#9746;</span></b> Я<b><span className={classes.greenHighlight}> &#9745;</span></b> А
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Grammar;