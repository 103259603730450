import React from 'react';
import classes from './SideColumn.css';
import Logo1 from '../../Assets/Images/logo1.png';
import Logo2 from '../../Assets/Images/logo2.jpg';
import Logo3 from '../../Assets/Images/logo3.jpg';
import CapaSiteA1 from '../../Assets/Images/capa-site-a1.jpg';
import CapaSiteA2 from '../../Assets/Images/capa-site-a2.jpg';
import CapaSiteB1 from '../../Assets/Images/capa-site-b1.jpg';
import { Link } from 'react-router-dom';

const sideColumn = (props) => {
    let content = null;

    if (props.showSideColumn) {
        content = (
            <>
                <a href="http://dialog.letras.ulisboa.pt/" target="_blank" rel="noopener noreferrer">
                    <img className={classes.logo1} src={Logo1} alt="Logo 1" />
                </a>
                <a href="http://www.letras.ulisboa.pt/pt/" target="_blank" rel="noopener noreferrer">
                    <img className={classes.logo2} src={Logo2} alt="Logo 2" />
                </a>
                <a href="http://russkiymir.ru/en/" target="_blank" rel="noopener noreferrer">
                    <img className={classes.logo3} src={Logo3} alt="Logo 3" />
                </a>
                <Link to='/Levels/A1/Book'>
                    <img className={classes.logo4} src={CapaSiteA1} alt="Comprar livro A1" title="Comprar livro A1" />
                </Link>
                <Link to='/Levels/A2/Book'>
                    <img className={classes.logo5} src={CapaSiteA2} alt="Comprar livro A2" title="Comprar livro A2" />
                </Link>
                <Link to='/Levels/B1/Book'>
                    <img className={classes.logo6} src={CapaSiteB1} alt="Comprar livro B1" title="Comprar livro B1" />
                </Link>
            </>
        );
    }

    return (
        <div className={classes.SideColumn}>
            {content}
        </div>);
}

export default sideColumn;

