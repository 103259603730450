import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/B1/Lesson8/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/B1/Lesson8/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/B1/Lesson8/image3grammar.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.container}>
                <div className={classes.imageContainer}>
                    <img id="image1Grammar" src={Image1Grammar} alt="Grammar 1" className={classes.image} />
                    <img id="image2Grammar" src={Image2Grammar} alt="Grammar 2" className={classes.image} />
                    <img id="image3Grammar" src={Image3Grammar} alt="Grammar 3" className={classes.image} />
                </div>
            </div>
        </div>
    );
}

export default Grammar;