import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const VerbList = [
    { id: 'делал', name: 'делал', matcher: 'делал' },
    { id: 'ел', name: 'ел', matcher: 'ел' },
    { id: 'ел2', name: 'ел', matcher: 'ел' },
    { id: 'спaл', name: 'спaл', matcher: 'спaл' },
    { id: 'ел3', name: 'ел', matcher: 'ел' },
    { id: 'спaл2', name: 'спaл', matcher: 'спaл' },
    { id: 'играл', name: 'играл', matcher: 'играл' },
    { id: 'гулял', name: 'гулял', matcher: 'гулял' },
    { id: 'делал2', name: 'делал', matcher: 'делал' },
];

const ItemTypes = [
    { id: 'неде́лю наза́д', name: 'неде́лю наза́д', translation: 'há uma semana' },
    { id: 'позавчера́', name: 'позавчера́', translation: 'anteontem' },
    { id: 'ме́сяц наза́д', name: 'ме́сяц наза́д', translation: 'há um mês' },
    { id: 'вчера́', name: 'вчера́', translation: 'ontem' },
    { id: 'час наза́д', name: 'час наза́д', translation: 'há uma hora' },
    { id: 'год наза́д', name: 'год наза́д', translation: 'há um ano' },
    { id: 'сейча́с', name: 'сейча́с', translation: 'agora' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Ponha os verbos no passado." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1_1}>
                        <div className={classes.inputWrapper}>
                            На э́той неде́лe ты ничего́ не <input
                                id="делалInput"
                                name="делалInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> (де́лать)!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1_2}>
                        <div className={classes.inputWrapper}>
                            Непра́вда! Час наза́д я <input
                                id="елInput"
                                name="елInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> (есть), вчера́ я <input
                                id="елInput"
                                name="елInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> (есть) и <input
                                id="спaлInput"
                                name="спaлInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> (спать), а позавчера́ я <input
                                id="елInput"
                                name="елInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (есть), <input
                                id="спaлInput"
                                name="спaлInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> (спать) <input
                                id="игралInput"
                                name="игралInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> и (игра́ть) на компью́тере. А на про́шлой неде́ле я ещё и <input
                                id="гулялInput"
                                name="гулялInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> (гуля́ть).
                            <p>А ты говори́шь, что я ничего́ не <input
                                id="делалInput"
                                name="делалInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> (де́лать)!</p>
                        </div>
                    </div>
                </div>
                <ExercisesQuestions number="2" question="Associe as palavras ao seu significado em português." />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;