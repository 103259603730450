import React from 'react';
import Footer from '../Footer/Footer';
import classes from './CenterColumn.css';

const centerColumn = (props) => {
    return (<div className={classes.CenterColumn}>
        {props.children}
        <Footer />
    </div>);
}

export default centerColumn;