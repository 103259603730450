import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1 from '../../../../../../Assets/Images/B1/Lesson5/image1exercise.png';
import Image2 from '../../../../../../Assets/Images/B1/Lesson5/image2exercise.png';
import Image3 from '../../../../../../Assets/Images/B1/Lesson5/image3exercise.png';
import Image4 from '../../../../../../Assets/Images/B1/Lesson5/image4exercise.png';
import Image5 from '../../../../../../Assets/Images/B1/Lesson5/image5exercise.png';
import Image6 from '../../../../../../Assets/Images/B1/Lesson5/image6exercise.png';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: true },
    { id: 3, isCorrect: true },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: true },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: true },
    { id: 9, isCorrect: true },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.backgroundColor = 'green' : event.currentTarget.style.backgroundColor = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="где можно прочитать следующие объявления?" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <img id="image1" src={Image1} alt="1" width="220px" />
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[0].id}
                                onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                            >
                                В магазине
                            </button>
                            <button
                                key={buttonsEx1[1].id}
                                onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                            >
                                В гостинице
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="image2" src={Image2} alt="2" width="220px" />
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[2].id}
                                onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                            >
                                В магазине
                            </button>
                            <button
                                key={buttonsEx1[3].id}
                                onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                            >
                                В гостинице
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="image3" src={Image3} alt="3" width="220px" />
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[4].id}
                                onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                            >
                                В магазине
                            </button>
                            <button
                                key={buttonsEx1[5].id}
                                onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                            >
                                В гостинице
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="image4" src={Image4} alt="4" width="220px" />
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[6].id}
                                onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                            >
                                В магазине
                            </button>
                            <button
                                key={buttonsEx1[7].id}
                                onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                            >
                                В гостинице
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="image5" src={Image5} alt="5" width="220px" />
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[8].id}
                                onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                            >
                                В магазине
                            </button>
                            <button
                                key={buttonsEx1[9].id}
                                onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                            >
                                В гостинице
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="image6" src={Image6} alt="6" width="220px" />
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[10].id}
                                onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                            >
                                В магазине
                            </button>
                            <button
                                key={buttonsEx1[11].id}
                                onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                            >
                                В гостинице
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;