import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: true },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: true },
    { id: 15, isCorrect: false },
    { id: 16, isCorrect: false },
    { id: 17, isCorrect: true },
    { id: 18, isCorrect: false },
    { id: 19, isCorrect: false },
    { id: 20, isCorrect: false },
    { id: 21, isCorrect: false },
    { id: 22, isCorrect: false },
    { id: 23, isCorrect: true },
    { id: 24, isCorrect: false },
    { id: 25, isCorrect: false },
    { id: 26, isCorrect: true },
    { id: 27, isCorrect: false },
    { id: 28, isCorrect: false },
    { id: 29, isCorrect: false },
    { id: 30, isCorrect: true },
    { id: 31, isCorrect: false },
    { id: 32, isCorrect: false },
    { id: 33, isCorrect: false },
    { id: 34, isCorrect: false },
    { id: 35, isCorrect: false },
    { id: 36, isCorrect: true },
    { id: 37, isCorrect: false },
    { id: 38, isCorrect: false },
    { id: 39, isCorrect: false },
    { id: 40, isCorrect: true },
    { id: 41, isCorrect: false },
    { id: 42, isCorrect: true },
    { id: 43, isCorrect: false },
    { id: 44, isCorrect: false },
    { id: 45, isCorrect: false },
    { id: 46, isCorrect: false },
    { id: 47, isCorrect: true },
    { id: 48, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Selecione a forma do pronome adequada a cada frase:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            1) Я знáю человéка, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    у котóрого
                                </button>/<button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    котóрый
                                </button>/<button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    котóрому
                                </button>/<button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    o котóром
                                </button>
                            </span> оди́н глаз голубо́й, а друго́й ка́рий.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            2) Помоги́те челове́ку, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    у котóрого
                                </button>/<button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    котóрый
                                </button>/<button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    котóрому
                                </button>/<button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    o котóром
                                </button>
                            </span> упа́л.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            3) Я знáю мáльчика, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    у котóрого
                                </button>/<button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    котóрый
                                </button>/<button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    котóрому
                                </button>/<button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    o котóром
                                </button>
                            </span> вы помогли́.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            4) Вы́берите ба́нку, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    кото́рую
                                </button>/<button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    в кото́рой
                                </button>/<button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    кото́рая
                                </button>/<button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    с кото́рой
                                </button>
                            </span> есть мёд.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            5) Э́то кни́га, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    кото́рую
                                </button>/<button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    в кото́рой
                                </button>/<button
                                    key={buttonsEx1[18].id}
                                    onClick={event => handleColor(event, buttonsEx1[18].isCorrect)}
                                >
                                    кото́рая
                                </button>/<button
                                    key={buttonsEx1[19].id}
                                    onClick={event => handleColor(event, buttonsEx1[19].isCorrect)}
                                >
                                    с кото́рой
                                </button>
                            </span> мы чита́ли в про́шлом году́.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            6) Я нашла́ кни́гу, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[20].isCorrect)}
                                >
                                    кото́рую
                                </button>/<button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[21].isCorrect)}
                                >
                                    в кото́рой
                                </button>/<button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[22].isCorrect)}
                                >
                                    кото́рая
                                </button>/<button
                                    key={buttonsEx1[23].id}
                                    onClick={event => handleColor(event, buttonsEx1[23].isCorrect)}
                                >
                                    с кото́рой
                                </button>
                            </span> была́ поте́ряна.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            7) Я не понимáю людéй, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[24].id}
                                    onClick={event => handleColor(event, buttonsEx1[24].isCorrect)}
                                >
                                    кото́рых
                                </button>/<button
                                    key={buttonsEx1[25].id}
                                    onClick={event => handleColor(event, buttonsEx1[25].isCorrect)}
                                >
                                    котóрые
                                </button>/<button
                                    key={buttonsEx1[26].id}
                                    onClick={event => handleColor(event, buttonsEx1[26].isCorrect)}
                                >
                                    с котóрыми
                                </button>/<button
                                    key={buttonsEx1[27].id}
                                    onClick={event => handleColor(event, buttonsEx1[27].isCorrect)}
                                >
                                    кото́рым
                                </button>
                            </span> не лю́бят пи́ццу.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            8) Нам ну́жен челове́к, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[28].id}
                                    onClick={event => handleColor(event, buttonsEx1[28].isCorrect)}
                                >
                                    у кото́рого
                                </button>/<button
                                    key={buttonsEx1[29].id}
                                    onClick={event => handleColor(event, buttonsEx1[29].isCorrect)}
                                >
                                    кото́рый
                                </button>/<button
                                    key={buttonsEx1[30].id}
                                    onClick={event => handleColor(event, buttonsEx1[30].isCorrect)}
                                >
                                    кото́рому
                                </button>/<button
                                    key={buttonsEx1[31].id}
                                    onClick={event => handleColor(event, buttonsEx1[31].isCorrect)}
                                >
                                    в кото́ром
                                </button>
                            </span> уме́ет игра́ть на саксофо́не.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            9) Музыка́нт, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[32].id}
                                    onClick={event => handleColor(event, buttonsEx1[32].isCorrect)}
                                >
                                    у которого
                                </button>/<button
                                    key={buttonsEx1[33].id}
                                    onClick={event => handleColor(event, buttonsEx1[33].isCorrect)}
                                >
                                    который
                                </button>/<button
                                    key={buttonsEx1[34].id}
                                    onClick={event => handleColor(event, buttonsEx1[34].isCorrect)}
                                >
                                    которому
                                </button>/<button
                                    key={buttonsEx1[35].id}
                                    onClick={event => handleColor(event, buttonsEx1[35].isCorrect)}
                                >
                                    о кото́ром
                                </button>
                            </span> уе́хал в Пари́ж.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            10) Я ви́дел колле́гу, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[36].id}
                                    onClick={event => handleColor(event, buttonsEx1[36].isCorrect)}
                                >
                                    кото́рую
                                </button>/<button
                                    key={buttonsEx1[37].id}
                                    onClick={event => handleColor(event, buttonsEx1[37].isCorrect)}
                                >
                                    в кото́рой
                                </button>/<button
                                    key={buttonsEx1[38].id}
                                    onClick={event => handleColor(event, buttonsEx1[38].isCorrect)}
                                >
                                    кото́рая
                                </button>/<button
                                    key={buttonsEx1[39].id}
                                    onClick={event => handleColor(event, buttonsEx1[39].isCorrect)}
                                >
                                    с кото́рой
                                </button>
                            </span> я ра́ньше рабо́тал.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            11) Я встре́тил друзе́й, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[40].id}
                                    onClick={event => handleColor(event, buttonsEx1[40].isCorrect)}
                                >
                                    кото́рых
                                </button>/<button
                                    key={buttonsEx1[41].id}
                                    onClick={event => handleColor(event, buttonsEx1[41].isCorrect)}
                                >
                                    котóрые
                                </button>/<button
                                    key={buttonsEx1[42].id}
                                    onClick={event => handleColor(event, buttonsEx1[42].isCorrect)}
                                >
                                    с котóрыми
                                </button>/<button
                                    key={buttonsEx1[43].id}
                                    onClick={event => handleColor(event, buttonsEx1[43].isCorrect)}
                                >
                                    кото́рым
                                </button>
                            </span> ходи́ли со мной в шко́лу.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            12) Э́то де́ти, <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[44].id}
                                    onClick={event => handleColor(event, buttonsEx1[44].isCorrect)}
                                >
                                    кото́рых
                                </button>/<button
                                    key={buttonsEx1[45].id}
                                    onClick={event => handleColor(event, buttonsEx1[45].isCorrect)}
                                >
                                    котóрые
                                </button>/<button
                                    key={buttonsEx1[46].id}
                                    onClick={event => handleColor(event, buttonsEx1[46].isCorrect)}
                                >
                                    с котóрыми
                                </button>/<button
                                    key={buttonsEx1[47].id}
                                    onClick={event => handleColor(event, buttonsEx1[47].isCorrect)}
                                >
                                    кото́рым
                                </button>
                            </span> мой сын игра́ет во дворе́.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;