import React from 'react';
import { withRouter } from 'react-router-dom';
import RoutingPage from './components/RoutingPage/RoutingPage';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

const RoutingPageWithRouter = withRouter(RoutingPage);

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        <RoutingPageWithRouter />
      </div>
    </DndProvider>
  );
}

export default App;
