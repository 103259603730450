import React from 'react';
import classes from './Footer.css';

const footer = () => {
    return (<div className={classes.Footer}>
        <p><b> CENTRO DE LÍNGUAS E CULTURAS ESLAVAS </b></p>
        <p> Faculdade de Letras da Universidade de Lisboa </p>
        <p> Alameda da Universidade </p>
        <p> 1600-214 Lisboa, Portugal </p>
    </div>);
}

export default footer;