import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson24/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson24/image2grammar.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>СОСЛАГАТЕЛЬНОЕ НАКЛОНЕНИЕ</p>
                    <p>Conjuntivo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O Conjuntivo em russo é formado a partir do verbo que tem forma do <b>Pretérito</b>, não fixando, no entanto a semântica do Tempo Passado, e a partícula <b><span className={classes.orangeHighlight}><i>БЫ</i></span></b>. Compare:</p>
                    </div>
                    <div className={classes.item1_2}>
                        <div className={classes.item1_2_1}>
                            <div>
                                <b><span className={classes.orangeHighlight}>MODO INDICATIVO:</span></b>
                            </div>
                            <div>
                                <p>Сего́дня я <b>хоте́ла сходи́ть</b> в кино́ (но ужé не хочý).</p>
                                <p>Hoje <b>apeteceu-me</b> ir ao cinema (mas já não me apetece).</p>
                            </div>
                        </div>
                        <div className={classes.item1_2_2}>
                            <div>
                                <b><span className={classes.orangeHighlight}>MODO CONJUNTIVO:</span></b>
                            </div>
                            <div>
                                <p>Сего́дня я <b>хоте́ла <span className={classes.orangeHighlight}>бы</span> сходи́ть</b> в кино́ (и всё ещё хочý).</p>
                                <p>Hoje <b>apetecia-me</b> ir ao cinema (e ainda me apetece).</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O modo pode designar diferentes intenções:</p>
                    </div>
                    <div className={classes.item1_3}>
                        <div className={classes.item1_3_1}>
                            <p><b><span className={classes.orangeHighlight}>ПРÓСЬБА</span></b></p>
                            <p>pedido</p>
                        </div>
                        <div>
                            <p><b><span className={classes.orangeHighlight}>MODO INDICATIVO:</span></b></p>
                            <p><i>i.Ты <b>мо́жешь сходи́ть</b> за хле́бом?</i></p>
                            <p><b>Podes ir</b> ao pão?</p>
                        </div>
                        <div>
                            <p><b><span className={classes.orangeHighlight}>MODO CONJUNTIVO:</span></b></p>
                            <p><i>ii. Ты (не) <b>мог бы</b> сходи́ть за хле́бом?</i></p>
                            <p>Tu (não) <b>poderias</b> ir ao pão?</p>
                        </div>
                    </div>
                    <div className={classes.item1_4}>
                        <div className={classes.item1_4_1}>
                            <p><b><span className={classes.orangeHighlight}>ЖЕЛÁНИЕ</span></b></p>
                            <p>desejo</p>
                        </div>
                        <div>
                            <p><i>iii. Я <b>хочу́ уви́деть</b> Ниага́рский водопа́д.</i></p>
                            <p>Eu <b>quero ver</b> a cascata do Nicarágua.</p>
                        </div>
                        <div>
                            <p><i>iv. Я <b>хоте́л бы уви́деть</b> Ниага́рский водопа́д.</i></p>
                            <p>Eu <b>queria/gostaria de ver</b> a cascata do Nicarágua.</p>
                        </div>
                    </div>
                    <div className={classes.item1_5}>
                        <div className={classes.item1_5_1}>
                            <p><b><span className={classes.orangeHighlight}>СОВÉТ</span></b></p>
                            <p>conselho</p>
                        </div>
                        <div>
                            <p><i>v. <b>Тебе́ на́до почита́ть</b> э́тих а́второв.</i></p>
                            <p><b>Tu precisas de ler</b> estes autores.</p>
                        </div>
                        <div>
                            <p><i>vi. <b>Тебе́ на́до бы почита́ть</b> э́тих а́второв.</i></p>
                            <p><b>Tu precisavas de ler</b> estes autores</p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>СОСЛАГАТЕЛЬНОЕ НАКЛОНЕНИЕ</p>
                    <p><i>чтóбы…</i></p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.item2_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Nas orações compostas, a oração subordinada é introduzida através da conjunção <b><span className={classes.orangeHighlight}><i>ЧТÓБЫ</i></span></b> que combina com o verbo no Passado.</p>
                    </div>
                    <div className={classes.item2_2}>
                        <div className={classes.item2_2_1}>
                            <div className={classes.item2_2_1_1}><p><b><span className={classes.orangeHighlight}>Um sujeito:</span></b></p></div>
                            <div className={classes.item2_2_1_2}>
                                <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="250px" />
                            </div>
                            <div className={classes.item2_2_1_3}><p><b><span className={classes.orangeHighlight}>Facto/declaração/constatação:</span></b></p></div>
                            <div className={classes.item2_2_1_4}>
                                <div className={classes.item2_2_1_4_1}>
                                    ЧТO
                                </div>
                                <div className={classes.item2_2_1_4_2}>
                                    ФАКТ
                                </div>
                            </div>
                            <div className={classes.item2_2_1_5}>
                                <li>Я сказа́л, <b><i>что</i></b> мне подари́ли кни́гу. (Eu disse que me ofereceram o livro.)</li>
                                <li>Он сказа́л, <b><i>что</i></b> всё бы́ло гото́во в 9 ч. (Ele disse que tudo ficou pronto às 9h.)</li>
                            </div>
                            <div className={classes.item2_2_1_6}>
                                <li>рассказа́ть, говори́ть, обеща́ть, переда́ть, ...</li>
                                <li>ду́мать, знать, понима́ть, по́мнить, наде́яться, ...</li>
                                <li>ви́деть, слы́шать, чу́вствовать, рад, случи́ться, жале́ть ...</li>
                                <li>хорошо́, пло́хо, ва́жно, (не)пра́вильно, гла́вное, стра́нно, ...</li>
                            </div>
                        </div>
                        <div className={classes.item2_2_2}>
                            <div className={classes.item2_2_2_1}><p><b><span className={classes.orangeHighlight}>Sujeitos diferentes:</span></b></p></div>
                            <div className={classes.item2_2_2_2}>
                                <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="300px" />
                            </div>
                            <div className={classes.item2_2_2_3}><p><b><span className={classes.orangeHighlight}>Desejo/ pedido/ ordem:</span></b></p></div>
                            <div className={classes.item2_2_2_4}>
                                <div className={classes.item2_2_2_4_1}>
                                    ЧТÓБЫ
                                </div>
                                <div className={classes.item2_2_2_4_2}>
                                    <p>ПРО́СЬБА,</p>
                                    <p>ПРИКА́З…</p>
                                </div>
                            </div>
                            <div className={classes.item2_2_2_5}>
                                <li>Я сказа́л, <b><i>чтóбы</i></b> мне подари́ли кни́гу. (Eu disse que me oferecessem o livro)</li>
                                <li>Он сказа́л, <b><i>чтóбы</i></b> всё бы́ло гото́во в 9 ч. (Ele disse para estar tudo pronto às 9h)</li>
                            </div>
                            <div className={classes.item2_2_2_6}>
                                <li>хоте́ть, жела́ть, мечта́ть, предпочита́ть, ...</li>
                                <li>проси́ть, тре́бовать, сове́товать, сказа́ть, прика́зывать, ...</li>
                                <li>на́до, ну́жно, необходи́мо, нельзя́, ...</li>
                                <li>стара́ться, смотре́ть, де́лать, ...</li>
                                <li>хорошо́, ва́жно, гла́вное, ...</li>
                            </div>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ПРЯМАЯ и КОСВЕННАЯ РЕЧЬ</p>
                    <p>Discurso Direto e Indireto</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> <b><span className={classes.orangeHighlight}>что</span></b> + INDICATIVO</p>
                    </div>
                    <div className={classes.item3_2}>
                        <p><i>Ма́ма пи́шет, говори́т, сообща́ет...</i></p>
                    </div>
                    <div className={classes.item3_3}>
                        <p><b><i>...что вернётся</i></b> по́здно.</p>
                        <p>que vem tarde.</p>
                    </div>
                    <div className={classes.item3_4}>
                        <p><b><i>...что</i></b> у́жин (<b><i>нахóдится</i></b>) в духо́вке.</p>
                        <p>que o jantar (está) no forno.</p>
                    </div>

                    <div className={classes.item3_5}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> <b><span className={classes.orangeHighlight}>что́бы</span></b> + CONJUNTIVO</p>
                    </div>
                    <div className={classes.item3_6}>
                        <p><i>Ма́ма пи́шет, говори́т, сообща́ет, про́сит...</i></p>
                    </div>
                    <div className={classes.item3_7}>
                        <p><b><i>...что́бы я у́жинал</i></b> без неё.</p>
                        <p>para que eu jantasse sem ela.</p>
                    </div>
                    <div className={classes.item3_8}>
                        <p><b><i>...что́бы я разогре́л</i></b> у́жин в микроволно́вке.</p>
                        <p>para que eu aquecesse o jantar no microondas.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;