import React from 'react';
import classes from './Modules.css';
import { Link } from 'react-router-dom';

const Modules = ({ route1, route2, route3 }) => {
    return (
        <div className={classes.Modules}>
            {route1 && <div>
                <Link to={route1[0]}>
                    {route1[1]}
                </Link>
            </div>
            }
            {route2 && <div>
                <Link to={route2[0]}>
                    {route2[1]}
                </Link>
            </div>
            }
            {route3 && <div>
                <Link to={route3[0]}>
                    {route3[1]}
                </Link>
            </div>
            }
        </div>
    );
}

export default Modules;