import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson13 from '../../../../../../Assets/Images/A1/Lesson13/image1lesson13.png';
import Image2Lesson13 from '../../../../../../Assets/Images/A1/Lesson13/image2lesson13.png';
import Image3Lesson13 from '../../../../../../Assets/Images/A1/Lesson13/image3lesson13.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРОШЕДШЕЕ ВРЕМЯ</p>
                    <p>Passado</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="DoVerbTable" src={Image1Lesson13} alt="do verb table" width="250px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <div>
                                <p>
                                    <tab1><b><span className={classes.orangeHighlight}> В ПОСЛЕ́ДНЕЕ ВРЕ́МЯ </span></b> ≠ <b><span className={classes.orangeHighlight}>РА́НЬШЕ </span></b></tab1>
                                </p>
                                <p>
                                    <tab1><i>nos últimos tempos &emsp;&emsp;&emsp;&emsp;antigamente</i></tab1>
                                </p>
                            </div>
                            <p>
                                <b><span className={classes.orangeHighlight}>РА́НЬШЕ </span></b> + Passado
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}> В ПОСЛЕ́ДНЕЕ ВРЕ́МЯ </span></b>+ Passado/Presente</p>
                            <p>
                                <b>&rarr; Ра́ньше</b> я хорошо́ <b><span className={classes.orangeHighlight}>спал</span></b>, а <b>в после́днее вре́мя</b> я пло́хо <b><span className={classes.orangeHighlight}>сплю</span></b>.
                            </p>
                            <p>
                                <b>&rarr; В после́днее вре́мя</b> я ничего́ <b><span className={classes.orangeHighlight}>не де́лал</span></b>.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.item2}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font><b>Conjugação no Passado</b></p>
                    <p>
                        Em Russo, o Passado não tem desinências de pessoa. Assim, a forma do verbo da 1.ª/2.ª/3.ª pessoa do Passado depende apenas do género e do número.
                    </p>
                    <p>
                        <b><span className={classes.orangeHighlight}>Я</span></b> Маша. <b><span className={classes.orangeHighlight}>Я</span></b> вам вчера́ звони́л<b><span className={classes.orangeHighlight}>а</span></b>.
                    </p>
                    <p>
                        <b><span className={classes.orangeHighlight}>Я</span></b> Ми́ша. <b><span className={classes.orangeHighlight}>Я</span></b> вам вчера́ звони́л <b><span className={classes.orangeHighlight}>▢</span></b>.
                    </p>
                    <p>
                        <b><span className={classes.orangeHighlight}>Мы</span> / <span className={classes.orangeHighlight}>вы</span> / <span className={classes.orangeHighlight}>oни́</span></b> мне вчера́ звони́л<b><span className={classes.orangeHighlight}>и</span></b>.
                    </p>
                </div>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Been" src={Image2Lesson13} alt="been" width="150px" height="140px" />
                            <div className={classes.gridItem1Item1}>
                                <p>
                                    <b>1. um processo que teve lugar no Passado</b>
                                </p>
                                <p>
                                    <b>2. um resultado que já foi atingido</b>
                                </p>
                            </div>
                            <div className={classes.gridItem1Item2}>
                                <p>
                                    – Вы <b><span className={classes.orangeHighlight}>ужé</span></b> бы́ли в Росси́и?
                                </p>
                                <p>
                                    – Да, <b><span className={classes.orangeHighlight}>ужé</span></b> был.
                                </p>
                                <p>
                                    – Ты <b><span className={classes.orangeHighlight}>ужé</span></b> чита́л “Вре́мя сéконд хэнд”?
                                </p>
                                <p>
                                    – Да, <b><span className={classes.orangeHighlight}>ужé</span></b> чита́л.
                                </p>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="Is" src={Image3Lesson13} alt="is" width="150px" height="140px" />
                            <div className={classes.gridItem2Item1}>
                                <p>
                                    <b>1. uma acção que continua no momento da fala.</b>
                                </p>
                                <p>
                                    <b>2. um resultado que ainda não foi atingido</b>
                                </p>
                            </div>
                            <div className={classes.gridItem2Item2}>
                                <p>
                                    – Ты <b><span className={classes.orangeHighlight}>eщё</span></b> рабо́таешь?
                                </p>
                                <p>
                                    – Да, я <b><span className={classes.orangeHighlight}>eщё</span></b> рабо́таю.
                                </p>
                                <p>
                                    – Вы <b><span className={classes.orangeHighlight}>ужé</span></b> бы́ли в Росси́и?
                                </p>
                                <p>
                                    – Нет, <b><span className={classes.orangeHighlight}>eщё</span></b> не был.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>КОГДА́?</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font><span className={classes.orangeHighlight}> DIAS DE SEMANA</span>: <span className={classes.greenHighlight}>В</span></b> + ACUSATIVO
                            </p>
                            <p>
                                <b><span className={classes.greenHighlight}>в</span></b> срéд<b><span className={classes.greenHighlight}>у</span></b>, <b><span className={classes.greenHighlight}>во</span></b>                вто́рник, …
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font><span className={classes.orangeHighlight}> MESES DO ANO</span>: <span className={classes.greenHighlight}>В</span></b> + PREPOSITIVO
                            </p>
                            <p>
                                <b><span className={classes.greenHighlight}>в</span></b> ма́рт<b><span className={classes.greenHighlight}>е</span></b>, <b><span className={classes.greenHighlight}>в</span></b> декабр<b><span className={classes.greenHighlight}>é</span></b>...
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;