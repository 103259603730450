import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson18/image1grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ОДНОВИДОВЫЕ ГЛАГОЛЫ НСВ</p>
                    <p>Imperfetivos (sem par)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Existem verbos imperfetivos que, quando têm um determinado significado, não têm par perfetivo. Assim, o verbo <i>явля́ться</i>, por exemplo, não tem par quando significa <i>ser/estar</i>, mas tem par, <i>яви́ться</i>, quando significa <i>aparecer</i>. Alguns verbos imperfetivos sem par são:</p>
                    <ul>
                        <li><i>име́ть</i></li>
                        <li><i>назывáться</i></li>
                        <li><i>относи́ться</i></li>
                        <li><i>отлича́ться</i></li>
                        <li><i>преподава́ть</i></li>
                        <li><i>зави́сеть</i></li>
                        <li><i>существова́ть</i></li>
                        <li><i>отсу́тствовать</i></li>
                        <li><i>сто́ить</i></li>
                        <li><i>прису́тствовать</i></li>
                        <li><i>боле́ть</i></li>
                        <li><i>страдáть</i></li>
                        <li><i>уважа́ть</i></li>
                        <li><i>лежа́ть</i></li>
                        <li><i>стоя́ть</i></li>
                        <li><i>висе́ть</i></li>
                    </ul>
                </div>
                <GrammarSubtitle>
                    <p>КОТÓРЫЙ (ИМ. П.)</p>
                    <p>Pronome Relativo (Nominativo)</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O pronome relativo <b><span className={classes.orangeHighlight}>КОТÓРЫЙ</span></b> corresponde, em português, ao pronome relativo <b>que</b>. Em russo, o pronome relativo tem a forma de adjetivo e muda em género, número e caso. O género e o número dependem da palavra a que o pronome se refere; o caso depende da função que o pronome tem na frase subordinada. Aqui vemos o seu uso no nominativo:</p>
                    <div className={classes.item2_1}>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="500px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;