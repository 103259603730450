import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Lesson30 from '../../../../../../Assets/Images/A1/Lesson30/image1lesson30.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ЛИЧНЫЕ МЕСТОИМЕНИЯ</p>
                    <p>Творительный падеж</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image1Lesson30" src={Image1Lesson30} alt="lesson 30 illustrative example 1" />
                        </div>
                        <div className={classes.gridItem2}>
                            <div>
                                <p>
                                    <font size="6"><span className={classes.orangeHighlight}>&#9758;</span></font> Às formas da 3ª pessoa acrescenta-se um н quando o pronome é usado com preposições.
                                </p>
                            </div>
                            <p><b><span className={classes.orangeHighlight}>МЫ С ТОБО́Й</span></b>/с ней/с ним/...</p>
                            <div className={classes.textAlignCenter}>
                                <div className={classes.greenHighlightedCenter}>
                                    <p><b><span className={classes.whiteHighlight}>МЫ</span> с тобо́й опoздáем.</b></p>
                                </div>
                            </div>
                            <p><b><span className={classes.orangeHighlight}>МЫ</span></b> pode significar:</p>
                            <div className={classes.gridItem2gridItems}>
                                <div className={classes.gridItem1}>
                                    <p>
                                        <b>1) <span className={classes.orangeHighlight}>Я</span> + <span className={classes.orangeHighlight}>ТЫ</span></b>
                                    </p>
                                    <p><b>Nós <u>(eu e tu)</u></b> vamos chegar atrasadas.</p>
                                </div>
                                <div className={classes.gridItem2}>
                                    <p>
                                        <b>2) <span className={classes.orangeHighlight}>МЫ</span> + <span className={classes.orangeHighlight}>ТЫ</span></b>
                                    </p>
                                    <p><b>Nós <u>(eu, ela, ele e tu)</u></b> vamos chegar atrasados.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.item2}>
                    <p><b>MEIO DE TRANSPORTE:</b></p>
                    <p className={classes.textAlignCenter}>
                        <b><span className={classes.orangeHighlight}>INSTRUMENTAL</span></b> vs. <b><span className={classes.orangeHighlight}>НА</span> + <span className={classes.orangeHighlight}>PREPOSITIVO</span></b>
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Quando estamos a falar do meio de transporte, normalmente, usamos o Caso Instrumental. O uso da preposição <b>на +</b> Caso Prepositivo restringe-se ao registo oral. Contudo, as duas possibilidades são frequentes e sinonímicas.
                    </p>
                    <div className={classes.gridContainer}>
                        <p>
                            <b><span className={classes.orangeHighlight}>КАК? / КАКИ́М О́БРАЗОМ? / ЧЕМ?</span></b>
                        </p>
                        <div className={classes.gridItems}>
                            <div className={classes.gridItem1}>
                                <p>на самолёт<b><span className={classes.orangeHighlight}>е</span></b></p>
                                <p>на маши́н<b><span className={classes.orangeHighlight}>е</span></b></p>
                                <p>на метр<b><span className={classes.orangeHighlight}>о́</span></b></p>
                            </div>
                            <div className={classes.gridItem2}>
                                <p>самолёт<b><span className={classes.orangeHighlight}>ом</span></b></p>
                                <p>маши́н<b><span className={classes.orangeHighlight}>ой</span></b></p>
                                <p>метр<b><span className={classes.orangeHighlight}>о́</span></b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>СРАВНИТЕЛЬНАЯ СТЕПЕНЬ</p>
                    <p>ЛУЧШЕ</p>
                    <p>Grau de superioridade</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O grau comparativo de superioridade do adjetivo <b><span className={classes.orangeHighlight}>ХОРО́ШИЙ</span></b> é <b><span className={classes.orangeHighlight}>ЛУЧШЕ</span></b>. A palavra <b><span className={classes.orangeHighlight}>ЛУЧШЕ</span></b> (melhor) pode entrar em construções predicativas:
                    </p>
                    <div className={classes.textAlignCenter}>
                        <div className={classes.greenHighlightedCenter}>
                            <p><b>ЛУЧШЕ + БЫТЬ + INFINITIVO</b></p>
                        </div>
                        <div>
                            <p>
                                <b><span className={classes.orangeHighlight}>Лу́чше</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;было&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;е́хать авто́бусом.</b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>Лу́чше</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ---&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;идти́ пешко́м.</b>
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>СРАВНИТЕЛЬНАЯ СТЕПЕНЬ</p>
                    <p>ТАКО́Й ЖЕ …, КА́К И …</p>
                    <p>Grau de igualdade</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A estrutura comparativa de igualdade é construída com a expressão <b><span className={classes.orangeHighlight}>ТАКО́Й ЖЕ …, КА́К И …</span></b>
                    </p>
                    <div className={classes.textAlignCenter}>
                        <div className={classes.greenHighlightedCenter}>
                            <p>
                                nome&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>ТАКО́Й ЖЕ</b>&nbsp;&nbsp;&nbsp;&nbsp;adjetivo,&nbsp;&nbsp;&nbsp;&nbsp;<b>КА́К И</b> nome
                            </p>
                        </div>
                        <p>
                            <i>самолёт <b><span className={classes.orangeHighlight}>ТАКО́Й ЖЕ</span></b> удо́бный, <b><span className={classes.orangeHighlight}>КА́К И</span></b> по́езд</i>
                        </p>
                        <p>
                            <i>o avião é <b><span className={classes.orangeHighlight}>TÃO</span></b>confortável <b><span className={classes.orangeHighlight}>COMO</span></b> o comboio</i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;