import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>автомоби́ль</b> automóvel</p>
                        <p><b>(в) каки́х стра́нах</b> (em) que países</p>
                        <p><b>вéсело</b> (adv.) alegre</p>
                        <p><b>весёлый</b> (adj.) alegre (m.)</p>
                        <p><b>вечера́</b> tardinhas/ noites</p>
                        <p><b>во́зраст</b> idade</p>
                        <p><b>глагóлы движéния</b> verbos de movimento</p>
                        <p><b>да́тельный паде́ж</b> caso dativo</p>
                        <p><b>дли́нная</b> longa</p>
                        <p><b>жи́ли</b> (v. жить) vivíamos</p>
                        <p><b>зи́мние</b> invernosos/as</p>
                        <p><b>иллюмина́тор</b> escotilha</p>
                        <p><b>лет</b> (Gen.) anos</p>
                        <p><b>ли́чные местоиме́ния</b> pronomes pessoais</p>
                        <p><b>ло́дка</b> barco</p>
                        <p><b>лу́чший</b> (adj.) melhor (m.)</p>
                        <p><b>моро́з</b> geada</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>(на) се́вере</b> (no) norte</p>
                        <p><b>несовершéнного ви́да</b> (Gen.) de imperfetivo</p>
                        <p><b>ни́зко</b> (adv.) baixo</p>
                        <p><b>облакá</b> nuvens</p>
                        <p><b>(от) бе́рега</b> (da) margem</p>
                        <p><b>отве́тить</b> responder</p>
                        <p><b>откро́й</b> (v. откры́ть) abre</p>
                        <p><b>пи́ли</b> (v. пить) bebíamos</p>
                        <p><b>(по) доро́ге</b> (pela) estrada</p>
                        <p><b>(по) реке́</b> (pelo) rio</p>
                        <p><b>(по) сторона́м</b> (pelos) lados</p>
                        <p><b>(по) тебе́</b> (de) ti</p>
                        <p><b>(по) чему́?</b> (por) quê?</p>
                        <p><b>поговори́ть</b> conversar</p>
                        <p><b>пого́да</b> tempo (condições atmosféricas)</p>
                        <p><b>прóсто</b> simplesmente</p>
                        <p><b>пра́здник</b> festa</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>придёшь</b> (v. прийти́) vens</p>
                        <p><b>прошéдшее врéмя</b> tempo passado</p>
                        <p><b>ра́дугу</b> (Acus.) arco iris</p>
                        <p><b>ска́жешь</b> (v. сказа́ть) dizes</p>
                        <p><b>ска́зки</b> contos de fadas/ populares</p>
                        <p><b>Ско́лько тебе́ лет?</b> Quantos anos tens?</p>
                        <p><b>скуча́ю</b> (v. скуча́ть) tenho saudades</p>
                        <p><b>ску́чно</b> (adv.) aborrecido</p>
                        <p><b>смешно́</b> (adv.) engraçado</p>
                        <p><b>(мне) стра́шно</b> (adv.) tenho pavor</p>
                        <p><b>съел</b> (v. съесть) comeste</p>
                        <p><b>тогда́</b> naquela altura</p>
                        <p><b>тёмная</b> escura</p>
                        <p><b>тру́дно</b> (adv.) difícil</p>
                        <p><b>хокке́й</b> hóquei</p>
                        <p><b>чуде́сная</b> maravilhosa</p>
                        <p><b>шу́тка</b> brincadeira</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;