import React from 'react';
import classes from './Book.css';
import MainHeader from '../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../CenterColumn/CenterColumn.css';

const bookB1 = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={'Livro B1'} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.containerB1Book}>
                <div className={classes.containerB1Book_1}>
                    <p className={classes.b1BookSubtitles}>Áudio</p>
                    <a href='../../../../../../public/Assets/AudioZipFiles/AudioExerciciosB1.zip' download>Ficheiros de áudio (.zip)</a>
                </div>
                <div className={classes.containerB1Book_2}>
                    <p className={classes.b1BookSubtitles}>Vídeo</p>
                    <a href='../../../../../../public/Assets/VideoZipFiles/VideoExerciciosB1.zip' download>Ficheiros de vídeo (.zip)</a>
                </div>
                <div className={classes.containerB1Book_2}>
                    <p className={classes.b1BookSubtitles}>Transcrição de áudio e vídeo</p>
                    <a href='../../../../../../public/Assets/B1_textos_de_audio_e_video.pdf' download>Transcrição de áudio e vídeo (.pdf)</a>
                </div>
            </div>
        </div>
    );
}

export default bookB1;