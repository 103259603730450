import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson3/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson3/image2grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРИТЯЖАТЕЛЬНЫE МЕСТОИМЕНИE (Винительный падеж)</p>
                    <p>POSSESSIVOS (Acusativo)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="image1Grammar" src={Image1Grammar} alt="who what table" width="600px" />
                </div>
                <GrammarSubtitle>
                    <p>УКАЗАТЕЛЬНЫЕ МЕСТОИМЕНИЯ (Винительный падеж)</p>
                    <p>DEMONSTRATIVOS (Acusativo)</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <img id="image2Grammar" src={Image2Grammar} alt="who what table" width="250px" />
                </div>
                <div className={classes.item3}>
                    <div className={classes.item3_1}><p><b>ВОТ/ВОН + Demonstrativo</b></p></div>
                    <p>As particulas <b>вот</b> e <b>вон</b> podem ocorrer antes de um determinante demonstrativo e têm um valor que reforça a relação de lugar, expressando a proximidade quanto ao objeto/pessoa de que se fala. Apesar de o uso de <b>вот/вон</b> não ser obrigatório, torna, no entanto, o discurso oral mais natural.</p>
                </div>
                <GrammarSubtitle>
                    <p>Глаголы</p>
                    <p>Verbos</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div>
                        <p>
                            <b>Verbo <span className={classes.orangeHighlight}>chamar-se</span> em russo</b>
                        </p>
                        <p>
                            Dependendo do <b>objecto direto</b> do verbo, usa-se:
                        </p>
                        <div className={classes.spacer}>
                            <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>ЗВАТЬ</span> + OD animado</b></p>
                            <p><i>Как <b>зовут</b> твою <b>сестру́</b></i></p>
                            <p>"Como se chama a tua irmã"</p>
                        </div>
                        <div className={classes.spacer}>
                            <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>НАЗЫВА́ТЬСЯ</span> + OD não animado</b></p>
                            <p><i>Как <b><span className={classes.orangeHighlight}>называ́eтся</span></b> твой <b>прое́кт?</b></i></p>
                            <p>"Como se chama o teu projeto?"</p>
                        </div>
                    </div>
                </div>
                <div className={classes.item5}>
                    <div>
                        <p>
                            <b>Verbos relacionados com festividades:</b>
                        </p>
                        <div className={classes.spacer}>
                            <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>ДАРИ́ТЬ НА</span> + Винительный падеж</b></p>
                            <p><i>Что тебе́ подари́ли на <b>день рожде́ния?</b></i></p>
                            <p>"O que te ofereceram no <b>aniversário</b>?"</p>
                        </div>
                        <div className={classes.spacer}>
                            <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>ПОЗДРАВЛЯ́ТЬ С</span> + Творительный падеж</b></p>
                            <p><i>Мы поздравля́ем вас <b>с прáздником!</b></i></p>
                            <p>"Bom <b>feriado!</b>"</p>
                        </div>
                        <div className={classes.spacer}>
                            <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>ЖЕЛÁТЬ</span> + тебé/вам + Родительный падеж</b></p>
                            <p><i>Желáю <b>тебé рáдости и теплá!</b></i></p>
                            <p>"Desejo-te <b>alegria e bem-estar</b>"</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Grammar;