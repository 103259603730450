import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson22/image1grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>НЕОПРЕДЕЛЁННЫЕ МЕСТОИМЕНИЯ</p>
                    <p>Pronomes Indefinidos</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Ao passo que os pronomes negativos são formados através de um prefixo de negação <b><i><span className={classes.orangeHighlight}>НИ</span></i></b> ligado a uma palavra <b>wh-</b> (<b><i><span className={classes.orangeHighlight}>ни</span>кто́</i></b>/<b><i><span className={classes.orangeHighlight}>ни</span>чего́</i></b>/<b><i><span className={classes.orangeHighlight}>ни</span>где́</i></b>/<b><i><span className={classes.orangeHighlight}>ни</span>како́й</i></b>), os pronomes indefinidos são compostos por uma palavra <b>wh-</b> (<b>ex.: кто́/чего́/где́/како́й</b>) que é ou precedida por uma partícula prefixal <b><span className={classes.orangeHighlight}>КО́Е-</span></b> (<b><i><span className={classes.orangeHighlight}>ко́е-</span> кто</i></b>/<b><i><span className={classes.orangeHighlight}>ко́е-</span>что</i></b>/<b><i><span className={classes.orangeHighlight}>ко́е-</span>где́</i></b>/<b><i><span className={classes.orangeHighlight}>ко́е-</span>како́й</i></b>/...) ou é seguida por uma partícula sufixal <b><i><span className={classes.orangeHighlight}>-ТО</span></i></b> ou <b><i><span className={classes.orangeHighlight}>-НИБУ́ДЬ</span></i></b> (<b><i>кто<span className={classes.orangeHighlight}>-то</span></i></b>/<b><i>чего́<span className={classes.orangeHighlight}>-нибу́дь</span></i></b>/<b><i>како́й<span className={classes.orangeHighlight}>-нибу́дь</span></i></b>).</p>
                    <div className={classes.item1_1}>
                        <div className={classes.item1_1_1}>
                            <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="150px" />
                        </div>
                        <div className={classes.item1_1_2}>
                            <div className={classes.item1_1_2_1}>
                                <div><p><b><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b> O falante sabe/conhece o referente, mas não o explicita:</span></b></p></div>
                                <div>
                                    <p className={classes.smallText}>Тебе́ ко́е-кто звони́л. (Я зна́ю, кто звони́л.)</p>
                                    <p className={classes.smallText}>Uma certa pessoa ligou para ti. (Eu sei quem foi.)</p>
                                </div>
                                <div>
                                    <p className={classes.smallText}>Был на кни́жной я́рмарке. Купи́л кое-каки́е кни́ги.</p>
                                    <p className={classes.smallText}>Estive na feira do livro. Comprei uns certos livros.</p>
                                </div>
                            </div>
                            <div className={classes.item1_1_2_2}>
                                <div><p><b><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b> Não é claro se o falante sabe/conhece o referente:</span></b></p></div>
                                <div>
                                    <p className={classes.smallText}>Тебе́ кто-то звони́л. (Hо я не зна́ю, кто)</p>
                                    <p className={classes.smallText}>Alguém te ligou. (mas não sei quem foi.)</p>
                                </div>
                                <div>
                                    <p className={classes.smallText}>Мне кто-то звони́л? (Я жду звонка.)</p>
                                    <p className={classes.smallText}>Alguém me ligou? (Estou à espera de uma chamada.)</p>
                                </div>
                            </div>
                            <div className={classes.item1_1_2_3}>
                                <div><p><b><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b> O falante não sabe/não conhece o referente:</span></b></p></div>
                                <div>
                                    <p className={classes.smallText}>Я забыл телефон. Мне кто-нибудь звонил? (Я не зна́ю, звонил ли мне кто-нибудь.)</p>
                                    <p className={classes.smallText}>Eu esqueci-me do telefone. Alguém me ligou? (Não sei se alguém me terá ligado.)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.item2}>
                    <div className={classes.item2_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Quando o pronome indefinido composto com a partícula <b><span className={classes.orangeHighlight}>КО́Е-</span></b> ocorre com uma preposição, esta <u>preposição</u> é inserida entre a partícula e a palavra <b>wh-</b> (interrogativa).</p>
                    </div>
                    <div className={classes.item2_2}>
                        <p>Я уже́ <span className={classes.orangeHighlight}>ко́е <b><u>с</u></b> кем</span> поговори́ла.</p>
                        <p>Já falei com uma pessoa.</p>
                    </div>
                    <div className={classes.item2_3}>
                        <p>Мне ну́жно сходи́ть в магази́н <span className={classes.orangeHighlight}>ко́е <b><u>за</u></b> чем</span>.</p>
                        <p>Tenho de ir à loja buscar uma coisa.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;