import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>(в) аэропорту́</b> (Prepos.) (no) aeroporto</p>
                        <p><b>(в) доро́ге</b> (Prepos.) (no) caminho</p>
                        <p><b>(в) шкафу́</b> (Prepos.) (no) armário</p>
                        <p><b>(на) берегу́</b> (Prepos.) (na) margem/à beira (rio/mar)</p>
                        <p><b>(на) полу</b> (Prepos.) (no) chão</p>
                        <p><b>(на) рабо́те</b> (Prepos.) (no) trabalho</p>
                        <p><b>англи́йский</b> (adj.) inglês</p>
                        <p><b>бе́гать</b> correr</p>
                        <p><b>боле́ть</b> ficar doente</p>
                        <p><b>(в) саду́</b> (Prepos.) (no) jardim</p>
                        <p><b>ва́жно</b> (adv.) importante</p>
                        <p><b>везде́</b> em todo o lado</p>
                        <p><b>всегда́</b> sempre</p>
                        <p><b>год</b> ano</p>
                        <p><b>гуля́ть</b> passear</p>
                        <p><b>дéлаю</b>(v. дéлать) faço</p>
                        <p><b>де́вушку</b> (Acus.) <b>зову́т</b> a rapariga chama-se</p>
                        <p><b>де́лаешь</b> (v. дéлать) fazes</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>дру́га</b> (Acus.) <b>зову́т</b> o amigo chama-se</p>
                        <p><b>ду́мать</b> pensar</p>
                        <p><b>за́втракать</b> tomar o pequeno-almoço</p>
                        <p><b>знать</b> saber</p>
                        <p><b>игра́ть</b> tocar (instrumento musical)</p>
                        <p><b>изуча́ть</b> aprender</p>
                        <p><b>иногда́</b> às vezes</p>
                        <p><b>кошелёк</b> carteira</p>
                        <p><b>лес</b> floresta</p>
                        <p><b>лёд</b> gelo</p>
                        <p><b>ло́дка</b> barco</p>
                        <p><b>лю́ди</b> pessoas</p>
                        <p><b>мышь</b> rato</p>
                        <p><b>о чём</b> sobre/acerca de quê</p>
                        <p><b>обе́дать</b> almoçar</p>
                        <p><b>отдыха́ть</b> descansar</p>
                        <p><b>понима́ть</b> entender/ perceber</p>
                        <p><b>португа́льский</b> (adj.) português</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>потому́ что</b> porque</p>
                        <p><b>пра́во</b> direito</p>
                        <p><b>рабóта</b> trabalho</p>
                        <p><b>рабóтаешь</b> (v. рабóтать) trabalhas</p>
                        <p><b>рабóтаю</b> (v. рабóтать) (eu) trabalho</p>
                        <p><b>pе́дко</b> raramente</p>
                        <p><b>реша́ть</b> decidir/resolver</p>
                        <p><b>ры́ба</b> peixe</p>
                        <p><b>собира́ю</b> (v. собира́ть) colho/ apanho</p>
                        <p><b>спра́шивать</b> perguntar</p>
                        <p><b>трудогóлик</b> viciado/a em trabalho</p>
                        <p><b>тогда́</b> então</p>
                        <p><b>у́жинать</b> jantar</p>
                        <p><b>уче́бник</b> manual</p>
                        <p><b>чита́ть</b> ler</p>
                        <p><b>что́бы</b> para que</p>
                        <p><b>япо́нский</b> (adj.) japonês</p>
                        <p><b>я́хта</b> iate</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;