import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson21/image1grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ГЛАГОЛЫ ДВИЖЕНИЯ С ПРИСТАВКАМИ</p>
                    <p>Verbos de Movimento com prefixos</p>
                    <p>ПО-</p>
                    <p><i>совершенный вид (grupo II)</i></p>
                    <p>Perfetivos</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O prefixo <b><span className={classes.orangeHighlight}>по-</span></b> ligado ao verbo de movimento do grupo II dá valor perfetivo ao verbo e indica uma ação acabada ou que terá lugar durante um período limitado de tempo.</p>
                    <div className={classes.item1_1}>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="200px" />
                    </div>
                    <div className={classes.item1_2}>
                        <p>Де́ти <b>попла́вали</b> в бассе́йне мину́т де́сять, а пото́м пошли́ в парк.</p>
                        <p>Os meninos <b>nadaram</b> uns dez minutos na piscina e depois foram para casa</p>
                    </div>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O prefixo <b><span className={classes.orangeHighlight}>по-</span></b> ligado a outro tipo de verbos também pode ter o valor de uma ação que ocorre durante um período limitado de tempo.</p>
                    <div className={classes.item1_3}>
                        <p>Мы <b>погуля́ли</b> немно́го и пошли́ домо́й.</p>
                        <p>Nós <b>passeámos</b> um pouco e fomos para casa.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;