import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson27/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson27/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson27/image3grammar.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ГЛАГОЛЫ ДВИЖЕНИЯ С ПРИСТАВКАМИ</p>
                    <p>Grupo I: СВ</p>
                    <p>Verbos de Movimento C/Prefixos (Perfetivos)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="400px" />
                    </div>
                    <div>
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="400px" />
                    </div>
                </div>
                <div className={classes.item2}>
                    <div className={classes.item2_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O movimento é feito a partir de um lugar (<b><span className={classes.orangeHighlight}>ОТКУ́ДА?</span></b>) e em direcção a outro (<b><span className={classes.orangeHighlight}>КУДÁ?</span></b>). O percurso pode ser indicado com recurso a várias preposições, as quais regem determinados casos:</p>
                    </div>
                    <div className={classes.item2_2}>
                        <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="400px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;