import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1 from '../../../../../../Assets/Images/B1/Lesson16/image1exercise.png';


const Exercises = props => {
    const exerciseTitle = (
        <p className={classes.exerciseQuestion}>Познакомьтесь с учебным городком МГУ на Воробьевых Горах: <a href="http://tour.vrmsu.ru/"
            target="_blank"
            rel="noopener noreferrer">
            http://yap.vm.culture.ru/main/
        </a>
        </p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseTitle} />
                <div className={classes.ex1Container}>
                    <img id="image1" src={Image1} alt="1" width="750px" />
                </div>
            </div>
        </div>
    );
}

export default Exercises;