import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../hoc/Layout/Layout';
import SideColumn from '../SideColumn/SideColumn';
import CenterColumn from '../CenterColumn/CenterColumn';
import MainContent from '../MainContent/MainContent';
import AboutThisProject from '../Pages/AboutThisProject/AboutThisProject';
import Levels from '../Pages/Levels/Levels';
import Contacts from '../Pages/Contacts/Contacts';
import Team from '../Pages/Team/Team';
import * as Constants from '../Constants/Constants';
import A1 from '../Pages/Levels/A1/A1';
import A2 from '../Pages/Levels/A2/A2';
import B1 from '../Pages/Levels/B1/B1';
import IntroductionA1 from '../Pages/Levels/A1/IntroductionA1/IntroductionA1';
import A1Book from '../Pages/Levels/A1/A1Book/Book';
import A2Book from '../Pages/Levels/A2/A2Book/Book';
import B1Book from '../Pages/Levels/B1/B1Book/Book';
import RussianLanguage from '../Pages/Levels/A1/IntroductionA1/RussianLanguage/RussianLanguage';
import Phonetics from '../Pages/Levels/A1/IntroductionA1/Phonetics/Phonetics';
import Alphabet from '../Pages/Levels/A1/IntroductionA1/Alphabet/Alphabet';
import InternationalWords from '../Pages/Levels/A1/IntroductionA1/InternationalWords/InternationalWords';
import Lesson1 from '../Pages/Levels/A1/Lesson1/Lesson1';
import { default as GrammarLesson1 } from '../Pages/Levels/A1/Lesson1/Grammar/Grammar';
import { default as ExercisesLesson1 } from '../Pages/Levels/A1/Lesson1/Exercises/Exercises';
import { default as VocabularyLesson1 } from '../Pages/Levels/A1/Lesson1/Vocabulary/Vocabulary';
import Lesson2 from '../Pages/Levels/A1/Lesson2/Lesson2';
import { default as GrammarLesson2 } from '../Pages/Levels/A1/Lesson2/Grammar/Grammar';
import { default as ExercisesLesson2 } from '../Pages/Levels/A1/Lesson2/Exercises/Exercises';
import { default as VocabularyLesson2 } from '../Pages/Levels/A1/Lesson2/Vocabulary/Vocabulary';
import Lesson3 from '../Pages/Levels/A1/Lesson3/Lesson3';
import { default as GrammarLesson3 } from '../Pages/Levels/A1/Lesson3/Grammar/Grammar';
import { default as ExercisesLesson3 } from '../Pages/Levels/A1/Lesson3/Exercises/Exercises';
import { default as VocabularyLesson3 } from '../Pages/Levels/A1/Lesson3/Vocabulary/Vocabulary';
import Lesson4 from '../Pages/Levels/A1/Lesson4/Lesson4';
import { default as GrammarLesson4 } from '../Pages/Levels/A1/Lesson4/Grammar/Grammar';
import { default as ExercisesLesson4 } from '../Pages/Levels/A1/Lesson4/Exercises/Exercises';
import { default as VocabularyLesson4 } from '../Pages/Levels/A1/Lesson4/Vocabulary/Vocabulary';
import Lesson5 from '../Pages/Levels/A1/Lesson5/Lesson5';
import { default as GrammarLesson5 } from '../Pages/Levels/A1/Lesson5/Grammar/Grammar';
import { default as ExercisesLesson5 } from '../Pages/Levels/A1/Lesson5/Exercises/Exercises';
import { default as VocabularyLesson5 } from '../Pages/Levels/A1/Lesson5/Vocabulary/Vocabulary';
import Lesson6 from '../Pages/Levels/A1/Lesson6/Lesson6';
import { default as GrammarLesson6 } from '../Pages/Levels/A1/Lesson6/Grammar/Grammar';
import { default as ExercisesLesson6 } from '../Pages/Levels/A1/Lesson6/Exercises/Exercises';
import { default as VocabularyLesson6 } from '../Pages/Levels/A1/Lesson6/Vocabulary/Vocabulary';
import Lesson7 from '../Pages/Levels/A1/Lesson7/Lesson7';
import { default as GrammarLesson7 } from '../Pages/Levels/A1/Lesson7/Grammar/Grammar';
import { default as ExercisesLesson7 } from '../Pages/Levels/A1/Lesson7/Exercises/Exercises';
import { default as VocabularyLesson7 } from '../Pages/Levels/A1/Lesson7/Vocabulary/Vocabulary';
import Lesson8 from '../Pages/Levels/A1/Lesson8/Lesson8';
import { default as GrammarLesson8 } from '../Pages/Levels/A1/Lesson8/Grammar/Grammar';
import { default as ExercisesLesson8 } from '../Pages/Levels/A1/Lesson8/Exercises/Exercises';
import { default as VocabularyLesson8 } from '../Pages/Levels/A1/Lesson8/Vocabulary/Vocabulary';
import Lesson9 from '../Pages/Levels/A1/Lesson9/Lesson9';
import { default as GrammarLesson9 } from '../Pages/Levels/A1/Lesson9/Grammar/Grammar';
import { default as ExercisesLesson9 } from '../Pages/Levels/A1/Lesson9/Exercises/Exercises';
import { default as VocabularyLesson9 } from '../Pages/Levels/A1/Lesson9/Vocabulary/Vocabulary';
import Lesson10 from '../Pages/Levels/A1/Lesson10/Lesson10';
import { default as GrammarLesson10 } from '../Pages/Levels/A1/Lesson10/Grammar/Grammar';
import { default as ExercisesLesson10 } from '../Pages/Levels/A1/Lesson10/Exercises/Exercises';
import { default as VocabularyLesson10 } from '../Pages/Levels/A1/Lesson10/Vocabulary/Vocabulary';
import Lesson11 from '../Pages/Levels/A1/Lesson11/Lesson11';
import { default as GrammarLesson11 } from '../Pages/Levels/A1/Lesson11/Grammar/Grammar';
import { default as ExercisesLesson11 } from '../Pages/Levels/A1/Lesson11/Exercises/Exercises';
import { default as VocabularyLesson11 } from '../Pages/Levels/A1/Lesson11/Vocabulary/Vocabulary';
import Lesson12 from '../Pages/Levels/A1/Lesson12/Lesson12';
import { default as GrammarLesson12 } from '../Pages/Levels/A1/Lesson12/Grammar/Grammar';
import { default as ExercisesLesson12 } from '../Pages/Levels/A1/Lesson12/Exercises/Exercises';
import { default as VocabularyLesson12 } from '../Pages/Levels/A1/Lesson12/Vocabulary/Vocabulary';
import Lesson13 from '../Pages/Levels/A1/Lesson13/Lesson13';
import { default as GrammarLesson13 } from '../Pages/Levels/A1/Lesson13/Grammar/Grammar';
import { default as ExercisesLesson13 } from '../Pages/Levels/A1/Lesson13/Exercises/Exercises';
import { default as VocabularyLesson13 } from '../Pages/Levels/A1/Lesson13/Vocabulary/Vocabulary';
import Lesson14 from '../Pages/Levels/A1/Lesson14/Lesson14';
import { default as GrammarLesson14 } from '../Pages/Levels/A1/Lesson14/Grammar/Grammar';
import { default as ExercisesLesson14 } from '../Pages/Levels/A1/Lesson14/Exercises/Exercises';
import { default as VocabularyLesson14 } from '../Pages/Levels/A1/Lesson14/Vocabulary/Vocabulary';
import Lesson15 from '../Pages/Levels/A1/Lesson15/Lesson15';
import { default as GrammarLesson15 } from '../Pages/Levels/A1/Lesson15/Grammar/Grammar';
import { default as ExercisesLesson15 } from '../Pages/Levels/A1/Lesson15/Exercises/Exercises';
import { default as VocabularyLesson15 } from '../Pages/Levels/A1/Lesson15/Vocabulary/Vocabulary';
import Lesson16 from '../Pages/Levels/A1/Lesson16/Lesson16';
import { default as GrammarLesson16 } from '../Pages/Levels/A1/Lesson16/Grammar/Grammar';
import { default as ExercisesLesson16 } from '../Pages/Levels/A1/Lesson16/Exercises/Exercises';
import { default as VocabularyLesson16 } from '../Pages/Levels/A1/Lesson16/Vocabulary/Vocabulary';
import Lesson17 from '../Pages/Levels/A1/Lesson17/Lesson17';
import { default as GrammarLesson17 } from '../Pages/Levels/A1/Lesson17/Grammar/Grammar';
import { default as ExercisesLesson17 } from '../Pages/Levels/A1/Lesson17/Exercises/Exercises';
import { default as VocabularyLesson17 } from '../Pages/Levels/A1/Lesson17/Vocabulary/Vocabulary';
import Lesson18 from '../Pages/Levels/A1/Lesson18/Lesson18';
import { default as GrammarLesson18 } from '../Pages/Levels/A1/Lesson18/Grammar/Grammar';
import { default as ExercisesLesson18 } from '../Pages/Levels/A1/Lesson18/Exercises/Exercises';
import { default as VocabularyLesson18 } from '../Pages/Levels/A1/Lesson18/Vocabulary/Vocabulary';
import Lesson19 from '../Pages/Levels/A1/Lesson19/Lesson19';
import { default as GrammarLesson19 } from '../Pages/Levels/A1/Lesson19/Grammar/Grammar';
import { default as ExercisesLesson19 } from '../Pages/Levels/A1/Lesson19/Exercises/Exercises';
import { default as VocabularyLesson19 } from '../Pages/Levels/A1/Lesson19/Vocabulary/Vocabulary';
import Lesson20 from '../Pages/Levels/A1/Lesson20/Lesson20';
import { default as GrammarLesson20 } from '../Pages/Levels/A1/Lesson20/Grammar/Grammar';
import { default as ExercisesLesson20 } from '../Pages/Levels/A1/Lesson20/Exercises/Exercises';
import { default as VocabularyLesson20 } from '../Pages/Levels/A1/Lesson20/Vocabulary/Vocabulary';
import Lesson21 from '../Pages/Levels/A1/Lesson21/Lesson21';
import { default as GrammarLesson21 } from '../Pages/Levels/A1/Lesson21/Grammar/Grammar';
import { default as ExercisesLesson21 } from '../Pages/Levels/A1/Lesson21/Exercises/Exercises';
import { default as VocabularyLesson21 } from '../Pages/Levels/A1/Lesson21/Vocabulary/Vocabulary';
import Lesson22 from '../Pages/Levels/A1/Lesson22/Lesson22';
import { default as GrammarLesson22 } from '../Pages/Levels/A1/Lesson22/Grammar/Grammar';
import { default as ExercisesLesson22 } from '../Pages/Levels/A1/Lesson22/Exercises/Exercises';
import { default as VocabularyLesson22 } from '../Pages/Levels/A1/Lesson22/Vocabulary/Vocabulary';
import Lesson23 from '../Pages/Levels/A1/Lesson23/Lesson23';
import { default as GrammarLesson23 } from '../Pages/Levels/A1/Lesson23/Grammar/Grammar';
import { default as ExercisesLesson23 } from '../Pages/Levels/A1/Lesson23/Exercises/Exercises';
import { default as VocabularyLesson23 } from '../Pages/Levels/A1/Lesson23/Vocabulary/Vocabulary';
import Lesson24 from '../Pages/Levels/A1/Lesson24/Lesson24';
import { default as GrammarLesson24 } from '../Pages/Levels/A1/Lesson24/Grammar/Grammar';
import { default as ExercisesLesson24 } from '../Pages/Levels/A1/Lesson24/Exercises/Exercises';
import { default as VocabularyLesson24 } from '../Pages/Levels/A1/Lesson24/Vocabulary/Vocabulary';
import Lesson25 from '../Pages/Levels/A1/Lesson25/Lesson25';
import { default as GrammarLesson25 } from '../Pages/Levels/A1/Lesson25/Grammar/Grammar';
import { default as ExercisesLesson25 } from '../Pages/Levels/A1/Lesson25/Exercises/Exercises';
import { default as VocabularyLesson25 } from '../Pages/Levels/A1/Lesson25/Vocabulary/Vocabulary';
import Lesson26 from '../Pages/Levels/A1/Lesson26/Lesson26';
import { default as GrammarLesson26 } from '../Pages/Levels/A1/Lesson26/Grammar/Grammar';
import { default as ExercisesLesson26 } from '../Pages/Levels/A1/Lesson26/Exercises/Exercises';
import { default as VocabularyLesson26 } from '../Pages/Levels/A1/Lesson26/Vocabulary/Vocabulary';
import Lesson27 from '../Pages/Levels/A1/Lesson27/Lesson27';
import { default as GrammarLesson27 } from '../Pages/Levels/A1/Lesson27/Grammar/Grammar';
import { default as ExercisesLesson27 } from '../Pages/Levels/A1/Lesson27/Exercises/Exercises';
import { default as VocabularyLesson27 } from '../Pages/Levels/A1/Lesson27/Vocabulary/Vocabulary';
import Lesson28 from '../Pages/Levels/A1/Lesson28/Lesson28';
import { default as GrammarLesson28 } from '../Pages/Levels/A1/Lesson28/Grammar/Grammar';
import { default as ExercisesLesson28 } from '../Pages/Levels/A1/Lesson28/Exercises/Exercises';
import { default as VocabularyLesson28 } from '../Pages/Levels/A1/Lesson28/Vocabulary/Vocabulary';
import Lesson29 from '../Pages/Levels/A1/Lesson29/Lesson29';
import { default as GrammarLesson29 } from '../Pages/Levels/A1/Lesson29/Grammar/Grammar';
import { default as ExercisesLesson29 } from '../Pages/Levels/A1/Lesson29/Exercises/Exercises';
import { default as VocabularyLesson29 } from '../Pages/Levels/A1/Lesson29/Vocabulary/Vocabulary';
import Lesson30 from '../Pages/Levels/A1/Lesson30/Lesson30';
import { default as GrammarLesson30 } from '../Pages/Levels/A1/Lesson30/Grammar/Grammar';
import { default as ExercisesLesson30 } from '../Pages/Levels/A1/Lesson30/Exercises/Exercises';
import { default as VocabularyLesson30 } from '../Pages/Levels/A1/Lesson30/Vocabulary/Vocabulary';
import Lesson31 from '../Pages/Levels/A1/Lesson31/Lesson31';
import { default as GrammarLesson31 } from '../Pages/Levels/A1/Lesson31/Grammar/Grammar';
import { default as ExercisesLesson31 } from '../Pages/Levels/A1/Lesson31/Exercises/Exercises';
import { default as VocabularyLesson31 } from '../Pages/Levels/A1/Lesson31/Vocabulary/Vocabulary';
import Lesson32 from '../Pages/Levels/A1/Lesson32/Lesson32';
import { default as GrammarLesson32 } from '../Pages/Levels/A1/Lesson32/Grammar/Grammar';
import { default as ExercisesLesson32 } from '../Pages/Levels/A1/Lesson32/Exercises/Exercises';
import { default as VocabularyLesson32 } from '../Pages/Levels/A1/Lesson32/Vocabulary/Vocabulary';
import Lesson33 from '../Pages/Levels/A1/Lesson33/Lesson33';
import { default as GrammarLesson33 } from '../Pages/Levels/A1/Lesson33/Grammar/Grammar';
import { default as ExercisesLesson33 } from '../Pages/Levels/A1/Lesson33/Exercises/Exercises';
import { default as VocabularyLesson33 } from '../Pages/Levels/A1/Lesson33/Vocabulary/Vocabulary';
import { default as RepetitionA2 } from '../Pages/Levels/A2/Repetition/Repetition';
import { default as Lesson1A2 } from '../Pages/Levels/A2/Lesson1/Lesson1';
import { default as GrammarLesson1A2 } from '../Pages/Levels/A2/Lesson1/Grammar/Grammar';
import { default as ExercisesLesson1A2 } from '../Pages/Levels/A2/Lesson1/Exercises/Exercises';
import { default as Lesson2A2 } from '../Pages/Levels/A2/Lesson2/Lesson2';
import { default as GrammarLesson2A2 } from '../Pages/Levels/A2/Lesson2/Grammar/Grammar';
import { default as ExercisesLesson2A2 } from '../Pages/Levels/A2/Lesson2/Exercises/Exercises';
import { default as Lesson3A2 } from '../Pages/Levels/A2/Lesson3/Lesson3';
import { default as GrammarLesson3A2 } from '../Pages/Levels/A2/Lesson3/Grammar/Grammar';
import { default as ExercisesLesson3A2 } from '../Pages/Levels/A2/Lesson3/Exercises/Exercises';
import { default as Lesson4A2 } from '../Pages/Levels/A2/Lesson4/Lesson4';
import { default as GrammarLesson4A2 } from '../Pages/Levels/A2/Lesson4/Grammar/Grammar';
import { default as ExercisesLesson4A2 } from '../Pages/Levels/A2/Lesson4/Exercises/Exercises';
import { default as Lesson5A2 } from '../Pages/Levels/A2/Lesson5/Lesson5';
import { default as GrammarLesson5A2 } from '../Pages/Levels/A2/Lesson5/Grammar/Grammar';
import { default as ExercisesLesson5A2 } from '../Pages/Levels/A2/Lesson5/Exercises/Exercises';
import { default as Lesson6A2 } from '../Pages/Levels/A2/Lesson6/Lesson6';
import { default as GrammarLesson6A2 } from '../Pages/Levels/A2/Lesson6/Grammar/Grammar';
import { default as ExercisesLesson6A2 } from '../Pages/Levels/A2/Lesson6/Exercises/Exercises';
import { default as Lesson7A2 } from '../Pages/Levels/A2/Lesson7/Lesson7';
import { default as GrammarLesson7A2 } from '../Pages/Levels/A2/Lesson7/Grammar/Grammar';
import { default as ExercisesLesson7A2 } from '../Pages/Levels/A2/Lesson7/Exercises/Exercises';
import { default as Lesson8A2 } from '../Pages/Levels/A2/Lesson8/Lesson8';
import { default as GrammarLesson8A2 } from '../Pages/Levels/A2/Lesson8/Grammar/Grammar';
import { default as ExercisesLesson8A2 } from '../Pages/Levels/A2/Lesson8/Exercises/Exercises';
import { default as Lesson9A2 } from '../Pages/Levels/A2/Lesson9/Lesson9';
import { default as GrammarLesson9A2 } from '../Pages/Levels/A2/Lesson9/Grammar/Grammar';
import { default as ExercisesLesson9A2 } from '../Pages/Levels/A2/Lesson9/Exercises/Exercises';
import { default as Lesson10A2 } from '../Pages/Levels/A2/Lesson10/Lesson10';
import { default as GrammarLesson10A2 } from '../Pages/Levels/A2/Lesson10/Grammar/Grammar';
import { default as ExercisesLesson10A2 } from '../Pages/Levels/A2/Lesson10/Exercises/Exercises';
import { default as Lesson11A2 } from '../Pages/Levels/A2/Lesson11/Lesson11';
import { default as GrammarLesson11A2 } from '../Pages/Levels/A2/Lesson11/Grammar/Grammar';
import { default as ExercisesLesson11A2 } from '../Pages/Levels/A2/Lesson11/Exercises/Exercises';
import { default as Lesson12A2 } from '../Pages/Levels/A2/Lesson12/Lesson12';
import { default as GrammarLesson12A2 } from '../Pages/Levels/A2/Lesson12/Grammar/Grammar';
import { default as ExercisesLesson12A2 } from '../Pages/Levels/A2/Lesson12/Exercises/Exercises';
import { default as Lesson13A2 } from '../Pages/Levels/A2/Lesson13/Lesson13';
import { default as GrammarLesson13A2 } from '../Pages/Levels/A2/Lesson13/Grammar/Grammar';
import { default as ExercisesLesson13A2 } from '../Pages/Levels/A2/Lesson13/Exercises/Exercises';
import { default as Lesson14A2 } from '../Pages/Levels/A2/Lesson14/Lesson14';
import { default as GrammarLesson14A2 } from '../Pages/Levels/A2/Lesson14/Grammar/Grammar';
import { default as ExercisesLesson14A2 } from '../Pages/Levels/A2/Lesson14/Exercises/Exercises';
import { default as Lesson15A2 } from '../Pages/Levels/A2/Lesson15/Lesson15';
import { default as GrammarLesson15A2 } from '../Pages/Levels/A2/Lesson15/Grammar/Grammar';
import { default as ExercisesLesson15A2 } from '../Pages/Levels/A2/Lesson15/Exercises/Exercises';
import { default as Lesson16A2 } from '../Pages/Levels/A2/Lesson16/Lesson16';
import { default as GrammarLesson16A2 } from '../Pages/Levels/A2/Lesson16/Grammar/Grammar';
import { default as ExercisesLesson16A2 } from '../Pages/Levels/A2/Lesson16/Exercises/Exercises';
import { default as Lesson17A2 } from '../Pages/Levels/A2/Lesson17/Lesson17';
import { default as GrammarLesson17A2 } from '../Pages/Levels/A2/Lesson17/Grammar/Grammar';
import { default as ExercisesLesson17A2 } from '../Pages/Levels/A2/Lesson17/Exercises/Exercises';
import { default as Lesson18A2 } from '../Pages/Levels/A2/Lesson18/Lesson18';
import { default as GrammarLesson18A2 } from '../Pages/Levels/A2/Lesson18/Grammar/Grammar';
import { default as ExercisesLesson18A2 } from '../Pages/Levels/A2/Lesson18/Exercises/Exercises';
import { default as Lesson19A2 } from '../Pages/Levels/A2/Lesson19/Lesson19';
import { default as GrammarLesson19A2 } from '../Pages/Levels/A2/Lesson19/Grammar/Grammar';
import { default as ExercisesLesson19A2 } from '../Pages/Levels/A2/Lesson19/Exercises/Exercises';
import { default as Lesson20A2 } from '../Pages/Levels/A2/Lesson20/Lesson20';
import { default as GrammarLesson20A2 } from '../Pages/Levels/A2/Lesson20/Grammar/Grammar';
import { default as ExercisesLesson20A2 } from '../Pages/Levels/A2/Lesson20/Exercises/Exercises';
import { default as Lesson21A2 } from '../Pages/Levels/A2/Lesson21/Lesson21';
import { default as GrammarLesson21A2 } from '../Pages/Levels/A2/Lesson21/Grammar/Grammar';
import { default as ExercisesLesson21A2 } from '../Pages/Levels/A2/Lesson21/Exercises/Exercises';
import { default as Lesson22A2 } from '../Pages/Levels/A2/Lesson22/Lesson22';
import { default as GrammarLesson22A2 } from '../Pages/Levels/A2/Lesson22/Grammar/Grammar';
import { default as ExercisesLesson22A2 } from '../Pages/Levels/A2/Lesson22/Exercises/Exercises';
import { default as Lesson23A2 } from '../Pages/Levels/A2/Lesson23/Lesson23';
import { default as GrammarLesson23A2 } from '../Pages/Levels/A2/Lesson23/Grammar/Grammar';
import { default as ExercisesLesson23A2 } from '../Pages/Levels/A2/Lesson23/Exercises/Exercises';
import { default as Lesson24A2 } from '../Pages/Levels/A2/Lesson24/Lesson24';
import { default as GrammarLesson24A2 } from '../Pages/Levels/A2/Lesson24/Grammar/Grammar';
import { default as ExercisesLesson24A2 } from '../Pages/Levels/A2/Lesson24/Exercises/Exercises';
import { default as Lesson25A2 } from '../Pages/Levels/A2/Lesson25/Lesson25';
import { default as GrammarLesson25A2 } from '../Pages/Levels/A2/Lesson25/Grammar/Grammar';
import { default as ExercisesLesson25A2 } from '../Pages/Levels/A2/Lesson25/Exercises/Exercises';
import { default as Lesson26A2 } from '../Pages/Levels/A2/Lesson26/Lesson26';
import { default as GrammarLesson26A2 } from '../Pages/Levels/A2/Lesson26/Grammar/Grammar';
import { default as ExercisesLesson26A2 } from '../Pages/Levels/A2/Lesson26/Exercises/Exercises';
import { default as Lesson27A2 } from '../Pages/Levels/A2/Lesson27/Lesson27';
import { default as GrammarLesson27A2 } from '../Pages/Levels/A2/Lesson27/Grammar/Grammar';
import { default as ExercisesLesson27A2 } from '../Pages/Levels/A2/Lesson27/Exercises/Exercises';
import { default as Lesson28A2 } from '../Pages/Levels/A2/Lesson28/Lesson28';
import { default as GrammarLesson28A2 } from '../Pages/Levels/A2/Lesson28/Grammar/Grammar';
import { default as ExercisesLesson28A2 } from '../Pages/Levels/A2/Lesson28/Exercises/Exercises';
import { default as RepetitionB1 } from '../Pages/Levels/B1/Repetition/Repetition';
import { default as Lesson1B1 } from '../Pages/Levels/B1/Lesson1/Lesson1';
import { default as ExercisesLesson1B1 } from '../Pages/Levels/B1/Lesson1/Exercises/Exercises';
import { default as Lesson2B1 } from '../Pages/Levels/B1/Lesson2/Lesson2';
import { default as GrammarLesson2B1 } from '../Pages/Levels/B1/Lesson2/Grammar/Grammar';
import { default as ExercisesLesson2B1 } from '../Pages/Levels/B1/Lesson2/Exercises/Exercises';
import { default as Lesson3B1 } from '../Pages/Levels/B1/Lesson3/Lesson3';
import { default as GrammarLesson3B1 } from '../Pages/Levels/B1/Lesson3/Grammar/Grammar';
import { default as ExercisesLesson3B1 } from '../Pages/Levels/B1/Lesson3/Exercises/Exercises';
import { default as Lesson4B1 } from '../Pages/Levels/B1/Lesson4/Lesson4';
import { default as ExercisesLesson4B1 } from '../Pages/Levels/B1/Lesson4/Exercises/Exercises';
import { default as Lesson5B1 } from '../Pages/Levels/B1/Lesson5/Lesson5';
import { default as GrammarLesson5B1 } from '../Pages/Levels/B1/Lesson5/Grammar/Grammar';
import { default as ExercisesLesson5B1 } from '../Pages/Levels/B1/Lesson5/Exercises/Exercises';
import { default as Lesson6B1 } from '../Pages/Levels/B1/Lesson6/Lesson6';
import { default as ExercisesLesson6B1 } from '../Pages/Levels/B1/Lesson6/Exercises/Exercises';
import { default as Lesson7B1 } from '../Pages/Levels/B1/Lesson7/Lesson7';
import { default as GrammarLesson7B1 } from '../Pages/Levels/B1/Lesson7/Grammar/Grammar';
import { default as ExercisesLesson7B1 } from '../Pages/Levels/B1/Lesson7/Exercises/Exercises';
import { default as Lesson8B1 } from '../Pages/Levels/B1/Lesson8/Lesson8';
import { default as GrammarLesson8B1 } from '../Pages/Levels/B1/Lesson8/Grammar/Grammar';
import { default as ExercisesLesson8B1 } from '../Pages/Levels/B1/Lesson8/Exercises/Exercises';
import { default as Lesson9B1 } from '../Pages/Levels/B1/Lesson9/Lesson9';
import { default as GrammarLesson9B1 } from '../Pages/Levels/B1/Lesson9/Grammar/Grammar';
import { default as ExercisesLesson9B1 } from '../Pages/Levels/B1/Lesson9/Exercises/Exercises';
import { default as Lesson10B1 } from '../Pages/Levels/B1/Lesson10/Lesson10';
import { default as GrammarLesson10B1 } from '../Pages/Levels/B1/Lesson10/Grammar/Grammar';
import { default as ExercisesLesson10B1 } from '../Pages/Levels/B1/Lesson10/Exercises/Exercises';
import { default as Lesson11B1 } from '../Pages/Levels/B1/Lesson11/Lesson11';
import { default as GrammarLesson11B1 } from '../Pages/Levels/B1/Lesson11/Grammar/Grammar';
import { default as ExercisesLesson11B1 } from '../Pages/Levels/B1/Lesson11/Exercises/Exercises';
import { default as Lesson12B1 } from '../Pages/Levels/B1/Lesson12/Lesson12';
import { default as GrammarLesson12B1 } from '../Pages/Levels/B1/Lesson12/Grammar/Grammar';
import { default as ExercisesLesson12B1 } from '../Pages/Levels/B1/Lesson12/Exercises/Exercises';
import { default as Lesson13B1 } from '../Pages/Levels/B1/Lesson13/Lesson13';
import { default as ExercisesLesson13B1 } from '../Pages/Levels/B1/Lesson13/Exercises/Exercises';
import { default as Lesson14B1 } from '../Pages/Levels/B1/Lesson14/Lesson14';
import { default as GrammarLesson14B1 } from '../Pages/Levels/B1/Lesson14/Grammar/Grammar';
import { default as ExercisesLesson14B1 } from '../Pages/Levels/B1/Lesson14/Exercises/Exercises';
import { default as Lesson15B1 } from '../Pages/Levels/B1/Lesson15/Lesson15';
import { default as GrammarLesson15B1 } from '../Pages/Levels/B1/Lesson15/Grammar/Grammar';
import { default as ExercisesLesson15B1 } from '../Pages/Levels/B1/Lesson15/Exercises/Exercises';
import { default as Lesson16B1 } from '../Pages/Levels/B1/Lesson16/Lesson16';
import { default as GrammarLesson16B1 } from '../Pages/Levels/B1/Lesson16/Grammar/Grammar';
import { default as ExercisesLesson16B1 } from '../Pages/Levels/B1/Lesson16/Exercises/Exercises';
import { default as Lesson17B1 } from '../Pages/Levels/B1/Lesson17/Lesson17';
import { default as ExercisesLesson17B1 } from '../Pages/Levels/B1/Lesson17/Exercises/Exercises';
import { default as Lesson18B1 } from '../Pages/Levels/B1/Lesson18/Lesson18';
import { default as GrammarLesson18B1 } from '../Pages/Levels/B1/Lesson18/Grammar/Grammar';
import { default as ExercisesLesson18B1 } from '../Pages/Levels/B1/Lesson18/Exercises/Exercises';
import { default as Lesson19B1 } from '../Pages/Levels/B1/Lesson19/Lesson19';
import { default as GrammarLesson19B1 } from '../Pages/Levels/B1/Lesson19/Grammar/Grammar';
import { default as ExercisesLesson19B1 } from '../Pages/Levels/B1/Lesson19/Exercises/Exercises';
import { default as Lesson20B1 } from '../Pages/Levels/B1/Lesson20/Lesson20';
import { default as ExercisesLesson20B1 } from '../Pages/Levels/B1/Lesson20/Exercises/Exercises';
import { default as Lesson21B1 } from '../Pages/Levels/B1/Lesson21/Lesson21';
import { default as GrammarLesson21B1 } from '../Pages/Levels/B1/Lesson21/Grammar/Grammar';
import { default as ExercisesLesson21B1 } from '../Pages/Levels/B1/Lesson21/Exercises/Exercises';
import { default as Lesson22B1 } from '../Pages/Levels/B1/Lesson22/Lesson22';
import { default as ExercisesLesson22B1 } from '../Pages/Levels/B1/Lesson22/Exercises/Exercises';
import { default as Lesson23B1 } from '../Pages/Levels/B1/Lesson23/Lesson23';
import { default as GrammarLesson23B1 } from '../Pages/Levels/B1/Lesson23/Grammar/Grammar';
import { default as ExercisesLesson23B1 } from '../Pages/Levels/B1/Lesson23/Exercises/Exercises';
import { default as Lesson24B1 } from '../Pages/Levels/B1/Lesson24/Lesson24';
import { default as ExercisesLesson24B1 } from '../Pages/Levels/B1/Lesson24/Exercises/Exercises';
import { default as Lesson25B1 } from '../Pages/Levels/B1/Lesson25/Lesson25';
import { default as GrammarLesson25B1 } from '../Pages/Levels/B1/Lesson25/Grammar/Grammar';
import { default as ExercisesLesson25B1 } from '../Pages/Levels/B1/Lesson25/Exercises/Exercises';
import { default as Lesson26B1 } from '../Pages/Levels/B1/Lesson26/Lesson26';
import { default as GrammarLesson26B1 } from '../Pages/Levels/B1/Lesson26/Grammar/Grammar';
import { default as ExercisesLesson26B1 } from '../Pages/Levels/B1/Lesson26/Exercises/Exercises';
import { default as Lesson27B1 } from '../Pages/Levels/B1/Lesson27/Lesson27';
import { default as GrammarLesson27B1 } from '../Pages/Levels/B1/Lesson27/Grammar/Grammar';
import { default as ExercisesLesson27B1 } from '../Pages/Levels/B1/Lesson27/Exercises/Exercises';
import { default as Lesson28B1 } from '../Pages/Levels/B1/Lesson28/Lesson28';
import { default as GrammarLesson28B1 } from '../Pages/Levels/B1/Lesson28/Grammar/Grammar';
import { default as ExercisesLesson28B1 } from '../Pages/Levels/B1/Lesson28/Exercises/Exercises';

class RoutingPage extends React.Component {
    render() {
        const showSideColumn = this.props.location.pathname === '/';
        return (
            <div className="App">
                <Layout>
                    <SideColumn showSideColumn={showSideColumn} />
                    <CenterColumn>
                        <Switch>
                            <Route path="/" exact render={() => <MainContent title={Constants.dialog} subtitle={Constants.letsLearnRussian} />} />
                            <Route path="/AboutThisProject" exact render={() => <AboutThisProject title={Constants.aboutThisProject} />} />
                            <Route path="/Levels" exact render={() => <Levels title={Constants.levels} route1={["/Levels/A1", Constants.a1]} route2={["/Levels/A2", Constants.a2]} route3={["/Levels/B1", Constants.b1]} />} />
                            <Route path="/Team" exact render={() => <Team title={Constants.team} />} />
                            <Route path="/Contacts" exact render={() => <Contacts title={Constants.contacts} />} />
                            <Route path="/Levels/A1" exact render={() => <A1 title={Constants.a1} subtitle={Constants.a1ElementarLevel} goBack={this.props.history.goBack} />} />
                            <Route path="/Levels/A1/Introduction" exact render={() => <IntroductionA1 title={Constants.introduction} subtitle={Constants.introductionRU} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Book" exact render={() => <A1Book title={Constants.livroA1} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Introduction/RussianLanguage" exact render={() => <RussianLanguage title={Constants.russianLanguage} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Introduction/Phonetics" exact render={() => <Phonetics title={Constants.phonetics} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Introduction/Alphabet" exact render={() => <Alphabet title={Constants.alphabet} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Introduction/InternationalWords" exact render={() => <InternationalWords title={Constants.internationalWords} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson1" exact render={() => <Lesson1 title={Constants.lesson1} subtitle={Constants.ВОЛШЕБНЫЕСЛОВА} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson1/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson1/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson1/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson1/Grammar" exact render={() => <GrammarLesson1 title={Constants.lesson1} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson1/Exercises" exact render={() => <ExercisesLesson1 title={Constants.lesson1} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson1/Vocabulary" exact render={() => <VocabularyLesson1 title={Constants.lesson1} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson2" exact render={() => <Lesson2 title={Constants.lesson2} subtitle={Constants.КТОЭ́ТО} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson2/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson2/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson2/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson2/Grammar" exact render={() => <GrammarLesson2 title={Constants.lesson2} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson2/Exercises" exact render={() => <ExercisesLesson2 title={Constants.lesson2} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson2/Vocabulary" exact render={() => <VocabularyLesson2 title={Constants.lesson2} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson3" exact render={() => <Lesson3 title={Constants.lesson3} subtitle={Constants.МЫ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson3/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson3/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson3/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson3/Grammar" exact render={() => <GrammarLesson3 title={Constants.lesson3} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson3/Exercises" exact render={() => <ExercisesLesson3 title={Constants.lesson3} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson3/Vocabulary" exact render={() => <VocabularyLesson3 title={Constants.lesson3} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson4" exact render={() => <Lesson4 title={Constants.lesson4} subtitle={Constants.ЭТОМОЁ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson4/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson4/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson4/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson4/Grammar" exact render={() => <GrammarLesson4 title={Constants.lesson4} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson4/Exercises" exact render={() => <ExercisesLesson4 title={Constants.lesson4} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson4/Vocabulary" exact render={() => <VocabularyLesson4 title={Constants.lesson4} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson5" exact render={() => <Lesson5 title={Constants.lesson5} subtitle={Constants.ГДЕТЫ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson5/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson5/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson5/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson5/Grammar" exact render={() => <GrammarLesson5 title={Constants.lesson5} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson5/Exercises" exact render={() => <ExercisesLesson5 title={Constants.lesson5} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson5/Vocabulary" exact render={() => <VocabularyLesson5 title={Constants.lesson5} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson6" exact render={() => <Lesson6 title={Constants.lesson6} subtitle={Constants.ЯВСАДУ́} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson6/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson6/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson6/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson6/Grammar" exact render={() => <GrammarLesson6 title={Constants.lesson6} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson6/Exercises" exact render={() => <ExercisesLesson6 title={Constants.lesson6} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson6/Vocabulary" exact render={() => <VocabularyLesson6 title={Constants.lesson6} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson7" exact render={() => <Lesson7 title={Constants.lesson7} subtitle={Constants.СТЕРЕОТИ́ПЫ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson7/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson7/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson7/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson7/Grammar" exact render={() => <GrammarLesson7 title={Constants.lesson7} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson7/Exercises" exact render={() => <ExercisesLesson7 title={Constants.lesson7} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson7/Vocabulary" exact render={() => <VocabularyLesson7 title={Constants.lesson7} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson8" exact render={() => <Lesson8 title={Constants.lesson8} subtitle={Constants.ОЧЁМРАЗГОВО́Р} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson8/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson8/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson8/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson8/Grammar" exact render={() => <GrammarLesson8 title={Constants.lesson8} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson8/Exercises" exact render={() => <ExercisesLesson8 title={Constants.lesson8} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson8/Vocabulary" exact render={() => <VocabularyLesson8 title={Constants.lesson8} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson9" exact render={() => <Lesson9 title={Constants.lesson9} subtitle={Constants.ОНА́МНО́ГОГОВОРИ́Т} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson9/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson9/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson9/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson9/Grammar" exact render={() => <GrammarLesson9 title={Constants.lesson9} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson9/Exercises" exact render={() => <ExercisesLesson9 title={Constants.lesson9} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson9/Vocabulary" exact render={() => <VocabularyLesson9 title={Constants.lesson9} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson10" exact render={() => <Lesson10 title={Constants.lesson10} subtitle={Constants.ЧТО́ТЫДЕ́ЛАЕШЬ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson10/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson10/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson10/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson10/Grammar" exact render={() => <GrammarLesson10 title={Constants.lesson10} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson10/Exercises" exact render={() => <ExercisesLesson10 title={Constants.lesson10} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson10/Vocabulary" exact render={() => <VocabularyLesson10 title={Constants.lesson10} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson11" exact render={() => <Lesson11 title={Constants.lesson11} subtitle={Constants.ЛЮ́БИШЬНЕЛЮ́БИШЬ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson11/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson11/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson11/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson11/Grammar" exact render={() => <GrammarLesson11 title={Constants.lesson11} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson11/Exercises" exact render={() => <ExercisesLesson11 title={Constants.lesson11} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson11/Vocabulary" exact render={() => <VocabularyLesson11 title={Constants.lesson11} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson12" exact render={() => <Lesson12 title={Constants.lesson12} subtitle={Constants.МОЁРАСПИСА́НИЕ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson12/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson12/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson12/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson12/Grammar" exact render={() => <GrammarLesson12 title={Constants.lesson12} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson12/Exercises" exact render={() => <ExercisesLesson12 title={Constants.lesson12} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson12/Vocabulary" exact render={() => <VocabularyLesson12 title={Constants.lesson12} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson13" exact render={() => <Lesson13 title={Constants.lesson13} subtitle={Constants.ВЧЕРА́} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson13/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson13/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson13/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson13/Grammar" exact render={() => <GrammarLesson13 title={Constants.lesson13} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson13/Exercises" exact render={() => <ExercisesLesson13 title={Constants.lesson13} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson13/Vocabulary" exact render={() => <VocabularyLesson13 title={Constants.lesson13} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson14" exact render={() => <Lesson14 title={Constants.lesson14} subtitle={Constants.КОТÓРЫЙЧАС} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson14/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson14/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson14/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson14/Grammar" exact render={() => <GrammarLesson14 title={Constants.lesson14} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson14/Exercises" exact render={() => <ExercisesLesson14 title={Constants.lesson14} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson14/Vocabulary" exact render={() => <VocabularyLesson14 title={Constants.lesson14} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson15" exact render={() => <Lesson15 title={Constants.lesson15} subtitle={Constants.Э́ТАКНИ́ГАТОТФИЛЬМ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson15/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson15/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson15/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson15/Grammar" exact render={() => <GrammarLesson15 title={Constants.lesson15} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson15/Exercises" exact render={() => <ExercisesLesson15 title={Constants.lesson15} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson15/Vocabulary" exact render={() => <VocabularyLesson15 title={Constants.lesson15} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson16" exact render={() => <Lesson16 title={Constants.lesson16} subtitle={Constants.ТЫКУДА́} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson16/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson16/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson16/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson16/Grammar" exact render={() => <GrammarLesson16 title={Constants.lesson16} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson16/Exercises" exact render={() => <ExercisesLesson16 title={Constants.lesson16} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson16/Vocabulary" exact render={() => <VocabularyLesson16 title={Constants.lesson16} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson17" exact render={() => <Lesson17 title={Constants.lesson17} subtitle={Constants.ТЫГОВОРИ́ШЬПОРУ́ССКИ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson17/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson17/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson17/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson17/Grammar" exact render={() => <GrammarLesson17 title={Constants.lesson17} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson17/Exercises" exact render={() => <ExercisesLesson17 title={Constants.lesson17} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson17/Vocabulary" exact render={() => <VocabularyLesson17 title={Constants.lesson17} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson18" exact render={() => <Lesson18 title={Constants.lesson18} subtitle={Constants.СИ́НИЙКРÁСНЫЙГОЛУБÓЙ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson18/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson18/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson18/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson18/Grammar" exact render={() => <GrammarLesson18 title={Constants.lesson18} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson18/Exercises" exact render={() => <ExercisesLesson18 title={Constants.lesson18} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson18/Vocabulary" exact render={() => <VocabularyLesson18 title={Constants.lesson18} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson19" exact render={() => <Lesson19 title={Constants.lesson19} subtitle={Constants.КАКТЫСЕБЯ́ЧУ́ВСТВУЕШЬ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson19/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson19/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson19/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson19/Grammar" exact render={() => <GrammarLesson19 title={Constants.lesson19} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson19/Exercises" exact render={() => <ExercisesLesson19 title={Constants.lesson19} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson19/Vocabulary" exact render={() => <VocabularyLesson19 title={Constants.lesson19} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson20" exact render={() => <Lesson20 title={Constants.lesson20} subtitle={Constants.СКО́ЛЬКОСТÓИТ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson20/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson20/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson20/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson20/Grammar" exact render={() => <GrammarLesson20 title={Constants.lesson20} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson20/Exercises" exact render={() => <ExercisesLesson20 title={Constants.lesson20} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson20/Vocabulary" exact render={() => <VocabularyLesson20 title={Constants.lesson20} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson21" exact render={() => <Lesson21 title={Constants.lesson21} subtitle={Constants.ТЫОТКУ́ДА} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson21/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson21/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson21/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson21/Grammar" exact render={() => <GrammarLesson21 title={Constants.lesson21} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson21/Exercises" exact render={() => <ExercisesLesson21 title={Constants.lesson21} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson21/Vocabulary" exact render={() => <VocabularyLesson21 title={Constants.lesson21} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson22" exact render={() => <Lesson22 title={Constants.lesson22} subtitle={Constants.ТЕПЛО́ХО́ЛОДНО} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson22/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson22/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson22/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson22/Grammar" exact render={() => <GrammarLesson22 title={Constants.lesson22} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson22/Exercises" exact render={() => <ExercisesLesson22 title={Constants.lesson22} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson22/Vocabulary" exact render={() => <VocabularyLesson22 title={Constants.lesson22} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson23" exact render={() => <Lesson23 title={Constants.lesson23} subtitle={Constants.СКО́ЛЬКОТЕБЕ́ЛЕТ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson23/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson23/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson23/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson23/Grammar" exact render={() => <GrammarLesson23 title={Constants.lesson23} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson23/Exercises" exact render={() => <ExercisesLesson23 title={Constants.lesson23} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson23/Vocabulary" exact render={() => <VocabularyLesson23 title={Constants.lesson23} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson24" exact render={() => <Lesson24 title={Constants.lesson24} subtitle={Constants.ПОЧЕМУ́НÉБОГОЛУБО́Е} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson24/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson24/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson24/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson24/Grammar" exact render={() => <GrammarLesson24 title={Constants.lesson24} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson24/Exercises" exact render={() => <ExercisesLesson24 title={Constants.lesson24} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson24/Vocabulary" exact render={() => <VocabularyLesson24 title={Constants.lesson24} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson25" exact render={() => <Lesson25 title={Constants.lesson25} subtitle={Constants.АТЫСЕГО́ДНЯУЛЫБА́ЛСЯ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson25/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson25/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson25/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson25/Grammar" exact render={() => <GrammarLesson25 title={Constants.lesson25} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson25/Exercises" exact render={() => <ExercisesLesson25 title={Constants.lesson25} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson25/Vocabulary" exact render={() => <VocabularyLesson25 title={Constants.lesson25} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson26" exact render={() => <Lesson26 title={Constants.lesson26} subtitle={Constants.АОНМНЕНРА́ВИТСЯ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson26/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson26/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson26/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson26/Grammar" exact render={() => <GrammarLesson26 title={Constants.lesson26} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson26/Exercises" exact render={() => <ExercisesLesson26 title={Constants.lesson26} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson26/Vocabulary" exact render={() => <VocabularyLesson26 title={Constants.lesson26} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson27" exact render={() => <Lesson27 title={Constants.lesson27} subtitle={Constants.НА́ДОЗНА́ЧИТНА́ДО} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson27/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson27/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson27/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson27/Grammar" exact render={() => <GrammarLesson27 title={Constants.lesson27} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson27/Exercises" exact render={() => <ExercisesLesson27 title={Constants.lesson27} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson27/Vocabulary" exact render={() => <VocabularyLesson27 title={Constants.lesson27} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson28" exact render={() => <Lesson28 title={Constants.lesson28} subtitle={Constants.СЕГО́ДНЯПЯ́ТОЕЯНВАРЯ́} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson28/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson28/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson28/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson28/Grammar" exact render={() => <GrammarLesson28 title={Constants.lesson28} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson28/Exercises" exact render={() => <ExercisesLesson28 title={Constants.lesson28} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson28/Vocabulary" exact render={() => <VocabularyLesson28 title={Constants.lesson28} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson29" exact render={() => <Lesson29 title={Constants.lesson29} subtitle={Constants.ЧЕМЕДЯ́ТРИС} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson29/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson29/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson29/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson29/Grammar" exact render={() => <GrammarLesson29 title={Constants.lesson29} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson29/Exercises" exact render={() => <ExercisesLesson29 title={Constants.lesson29} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson29/Vocabulary" exact render={() => <VocabularyLesson29 title={Constants.lesson29} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson30" exact render={() => <Lesson30 title={Constants.lesson30} subtitle={Constants.ЯХОЧУ́БЫТЬСТОБО́Й} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson30/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson30/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson30/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson30/Grammar" exact render={() => <GrammarLesson30 title={Constants.lesson30} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson30/Exercises" exact render={() => <ExercisesLesson30 title={Constants.lesson30} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson30/Vocabulary" exact render={() => <VocabularyLesson30 title={Constants.lesson30} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson31" exact render={() => <Lesson31 title={Constants.lesson31} subtitle={Constants.КЕМТЫРАБО́ТАЕШЬ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson31/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson31/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson31/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson31/Grammar" exact render={() => <GrammarLesson31 title={Constants.lesson31} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson31/Exercises" exact render={() => <ExercisesLesson31 title={Constants.lesson31} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson31/Vocabulary" exact render={() => <VocabularyLesson31 title={Constants.lesson31} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson32" exact render={() => <Lesson32 title={Constants.lesson32} subtitle={Constants.ТЫЛЮ́БИШЬГУЛЯ́ТЬПОДДОЖДЁМ} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson32/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson32/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson32/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson32/Grammar" exact render={() => <GrammarLesson32 title={Constants.lesson32} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson32/Exercises" exact render={() => <ExercisesLesson32 title={Constants.lesson32} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson32/Vocabulary" exact render={() => <VocabularyLesson32 title={Constants.lesson32} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson33" exact render={() => <Lesson33 title={Constants.lesson33} subtitle={Constants.ПОСМОТРИТЕНАПРА́ВО} goBack={this.props.history.goBack} goToLessonsA1 route1={["/Levels/A1/Lesson33/Grammar", Constants.grammar]} route2={["/Levels/A1/Lesson33/Exercises", Constants.exercises]} route3={["/Levels/A1/Lesson33/Vocabulary", Constants.vocabulary]} />} />
                            <Route path="/Levels/A1/Lesson33/Grammar" exact render={() => <GrammarLesson33 title={Constants.lesson33} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson33/Exercises" exact render={() => <ExercisesLesson33 title={Constants.lesson33} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A1/Lesson33/Vocabulary" exact render={() => <VocabularyLesson33 title={Constants.lesson33} subtitle={Constants.vocabulary} goBack={this.props.history.goBack} goToLessonsA1 />} />
                            <Route path="/Levels/A2" exact render={() => <A2 title={Constants.a2} subtitle={Constants.a2IntermediateLevel} goBack={this.props.history.goBack} />} />
                            <Route path="/Levels/A2/Repetition" exact render={() => <RepetitionA2 title={Constants.Немногообомне} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Book" exact render={() => <A2Book title={Constants.livroA2} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson1" exact render={() => <Lesson1A2 title={Constants.lesson1} subtitle={Constants.ЧТОТЫЛЮ́БИШЬ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson1/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson1/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson1/Grammar" exact render={() => <GrammarLesson1A2 title={Constants.lesson1} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson1/Exercises" exact render={() => <ExercisesLesson1A2 title={Constants.lesson1} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson2" exact render={() => <Lesson2A2 title={Constants.lesson2} subtitle={Constants.НЕМНО́ГООФУТБО́ЛЕ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson2/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson2/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson2/Grammar" exact render={() => <GrammarLesson2A2 title={Constants.lesson2} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson2/Exercises" exact render={() => <ExercisesLesson2A2 title={Constants.lesson2} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson3" exact render={() => <Lesson3A2 title={Constants.lesson3} subtitle={Constants.ВОНТОТГА́ЛСТУК} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson3/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson3/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson3/Grammar" exact render={() => <GrammarLesson3A2 title={Constants.lesson3} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson3/Exercises" exact render={() => <ExercisesLesson3A2 title={Constants.lesson3} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson4" exact render={() => <Lesson4A2 title={Constants.lesson4} subtitle={Constants.ДОММОЕ́ЙМЕЧТЫ́} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson4/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson4/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson4/Grammar" exact render={() => <GrammarLesson4A2 title={Constants.lesson4} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson4/Exercises" exact render={() => <ExercisesLesson4A2 title={Constants.lesson4} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson5" exact render={() => <Lesson5A2 title={Constants.lesson5} subtitle={Constants.КУДА́ПОЕ́ХАТЬВО́ТПУСК} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson5/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson5/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson5/Grammar" exact render={() => <GrammarLesson5A2 title={Constants.lesson5} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson5/Exercises" exact render={() => <ExercisesLesson5A2 title={Constants.lesson5} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson6" exact render={() => <Lesson6A2 title={Constants.lesson6} subtitle={Constants.КАКА́ЯРА́ЗНИЦА} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson6/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson6/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson6/Grammar" exact render={() => <GrammarLesson6A2 title={Constants.lesson6} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson6/Exercises" exact render={() => <ExercisesLesson6A2 title={Constants.lesson6} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson7" exact render={() => <Lesson7A2 title={Constants.lesson7} subtitle={Constants.ОНМА́СТЕРСВОЕГО́ДЕ́ЛА} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson7/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson7/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson7/Grammar" exact render={() => <GrammarLesson7A2 title={Constants.lesson7} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson7/Exercises" exact render={() => <ExercisesLesson7A2 title={Constants.lesson7} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson8" exact render={() => <Lesson8A2 title={Constants.lesson8} subtitle={Constants.АСКО́ЛЬКО} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson8/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson8/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson8/Grammar" exact render={() => <GrammarLesson8A2 title={Constants.lesson8} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson8/Exercises" exact render={() => <ExercisesLesson8A2 title={Constants.lesson8} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson9" exact render={() => <Lesson9A2 title={Constants.lesson9} subtitle={Constants.МОИ́ПРИВЫ́ЧКИ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson9/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson9/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson9/Grammar" exact render={() => <GrammarLesson9A2 title={Constants.lesson9} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson9/Exercises" exact render={() => <ExercisesLesson9A2 title={Constants.lesson9} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson10" exact render={() => <Lesson10A2 title={Constants.lesson10} subtitle={Constants.ДОИПО́СЛЕ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson10/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson10/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson10/Grammar" exact render={() => <GrammarLesson10A2 title={Constants.lesson10} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson10/Exercises" exact render={() => <ExercisesLesson10A2 title={Constants.lesson10} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson11" exact render={() => <Lesson11A2 title={Constants.lesson11} subtitle={Constants.ПÓЛВТОРÓГО} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson11/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson11/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson11/Grammar" exact render={() => <GrammarLesson11A2 title={Constants.lesson11} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson11/Exercises" exact render={() => <ExercisesLesson11A2 title={Constants.lesson11} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson12" exact render={() => <Lesson12A2 title={Constants.lesson12} subtitle={Constants.НИЧЕГО́НЕВИ́ЖУ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson12/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson12/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson12/Grammar" exact render={() => <GrammarLesson12A2 title={Constants.lesson12} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson12/Exercises" exact render={() => <ExercisesLesson12A2 title={Constants.lesson12} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson13" exact render={() => <Lesson13A2 title={Constants.lesson13} subtitle={Constants.ВСЁКРО́МЕ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson13/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson13/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson13/Grammar" exact render={() => <GrammarLesson13A2 title={Constants.lesson13} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson13/Exercises" exact render={() => <ExercisesLesson13A2 title={Constants.lesson13} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson14" exact render={() => <Lesson14A2 title={Constants.lesson14} subtitle={Constants.ПОПОНЕДЕ́ЛЬНИКАМ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson14/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson14/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson14/Grammar" exact render={() => <GrammarLesson14A2 title={Constants.lesson14} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson14/Exercises" exact render={() => <ExercisesLesson14A2 title={Constants.lesson14} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson15" exact render={() => <Lesson15A2 title={Constants.lesson15} subtitle={Constants.ПОСЕКРЕ́ТУВСЕМУ́СВЕ́ТУ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson15/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson15/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson15/Grammar" exact render={() => <GrammarLesson15A2 title={Constants.lesson15} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson15/Exercises" exact render={() => <ExercisesLesson15A2 title={Constants.lesson15} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson16" exact render={() => <Lesson16A2 title={Constants.lesson16} subtitle={Constants.НАДОÉЛО} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson16/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson16/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson16/Grammar" exact render={() => <GrammarLesson16A2 title={Constants.lesson16} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson16/Exercises" exact render={() => <ExercisesLesson16A2 title={Constants.lesson16} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson17" exact render={() => <Lesson17A2 title={Constants.lesson17} subtitle={Constants.СЧАСТЛИВОЕРОЖДЕСТВО} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson17/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson17/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson17/Grammar" exact render={() => <GrammarLesson17A2 title={Constants.lesson17} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson17/Exercises" exact render={() => <ExercisesLesson17A2 title={Constants.lesson17} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson18" exact render={() => <Lesson18A2 title={Constants.lesson18} subtitle={Constants.ЯМЕЛАНХО́ЛИК} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson18/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson18/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson18/Grammar" exact render={() => <GrammarLesson18A2 title={Constants.lesson18} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson18/Exercises" exact render={() => <ExercisesLesson18A2 title={Constants.lesson18} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson19" exact render={() => <Lesson19A2 title={Constants.lesson19} subtitle={Constants.ВКАКÓМГОДÝ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson19/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson19/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson19/Grammar" exact render={() => <GrammarLesson19A2 title={Constants.lesson19} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson19/Exercises" exact render={() => <ExercisesLesson19A2 title={Constants.lesson19} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson20" exact render={() => <Lesson20A2 title={Constants.lesson20} subtitle={Constants.СА́МЫЙСА́МЫЙ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson20/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson20/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson20/Grammar" exact render={() => <GrammarLesson20A2 title={Constants.lesson20} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson20/Exercises" exact render={() => <ExercisesLesson20A2 title={Constants.lesson20} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson21" exact render={() => <Lesson21A2 title={Constants.lesson21} subtitle={Constants.ПОГУЛЯ́ЕМПОГÓРОДУ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson21/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson21/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson21/Grammar" exact render={() => <GrammarLesson21A2 title={Constants.lesson21} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson21/Exercises" exact render={() => <ExercisesLesson21A2 title={Constants.lesson21} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson22" exact render={() => <Lesson22A2 title={Constants.lesson22} subtitle={Constants.ТРИЧЁРНЫХСОБА́КИНАЧЁМТОСИДЕ́ЛИ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson22/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson22/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson22/Grammar" exact render={() => <GrammarLesson22A2 title={Constants.lesson22} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson22/Exercises" exact render={() => <ExercisesLesson22A2 title={Constants.lesson22} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson23" exact render={() => <Lesson23A2 title={Constants.lesson23} subtitle={Constants.ЧТОДЕ́ЛАТЬЕ́СЛИ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson23/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson23/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson23/Grammar" exact render={() => <GrammarLesson23A2 title={Constants.lesson23} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson23/Exercises" exact render={() => <ExercisesLesson23A2 title={Constants.lesson23} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson24" exact render={() => <Lesson24A2 title={Constants.lesson24} subtitle={Constants.ЯХОЧУЧТО́БЫ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson24/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson24/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson24/Grammar" exact render={() => <GrammarLesson24A2 title={Constants.lesson24} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson24/Exercises" exact render={() => <ExercisesLesson24A2 title={Constants.lesson24} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson25" exact render={() => <Lesson25A2 title={Constants.lesson25} subtitle={Constants.СОБЛЮДА́ЙТЕТИШИНУ́} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson25/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson25/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson25/Grammar" exact render={() => <GrammarLesson25A2 title={Constants.lesson25} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson25/Exercises" exact render={() => <ExercisesLesson25A2 title={Constants.lesson25} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson26" exact render={() => <Lesson26A2 title={Constants.lesson26} subtitle={Constants.ПУСТЬВСЕГДА́БУД́ЕТСО́ЛНЦЕ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson26/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson26/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson26/Grammar" exact render={() => <GrammarLesson26A2 title={Constants.lesson26} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson26/Exercises" exact render={() => <ExercisesLesson26A2 title={Constants.lesson26} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson27" exact render={() => <Lesson27A2 title={Constants.lesson27} subtitle={Constants.ПРИШЁЛЗАШЁЛВЫ́ШЕЛПОШЁЛ} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson27/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson27/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson27/Grammar" exact render={() => <GrammarLesson27A2 title={Constants.lesson27} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson27/Exercises" exact render={() => <ExercisesLesson27A2 title={Constants.lesson27} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson28" exact render={() => <Lesson28A2 title={Constants.lesson28} subtitle={Constants.МНЕНÉКОГДА} goBack={this.props.history.goBack} goToLessonsA2 route1={["/Levels/A2/Lesson28/Grammar", Constants.grammar]} route2={["/Levels/A2/Lesson28/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/A2/Lesson28/Grammar" exact render={() => <GrammarLesson28A2 title={Constants.lesson28} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/A2/Lesson28/Exercises" exact render={() => <ExercisesLesson28A2 title={Constants.lesson28} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsA2 />} />
                            <Route path="/Levels/B1/Repetition" exact render={() => <RepetitionB1 title={Constants.Какяпровёлсвоёлéто} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1" exact render={() => <B1 title={Constants.b1} subtitle={Constants.b1IntermediateLevel} goBack={this.props.history.goBack} />} />
                            <Route path="/Levels/B1/Book" exact render={() => <B1Book title={Constants.livroB1} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson1" exact render={() => <Lesson1B1 title={Constants.lesson1} subtitle={Constants.ЧЕТЫ́РЕЦВЕ́ТАГО́ДА} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson1/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson1/Exercises" exact render={() => <ExercisesLesson1B1 title={Constants.lesson1} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson2" exact render={() => <Lesson2B1 title={Constants.lesson2} subtitle={Constants.ЧЕМОДÁННОЕНАСТРОÉНИЕ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson2/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson2/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson2/Grammar" exact render={() => <GrammarLesson2B1 title={Constants.lesson2} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson2/Exercises" exact render={() => <ExercisesLesson2B1 title={Constants.lesson2} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson3" exact render={() => <Lesson3B1 title={Constants.lesson3} subtitle={Constants.МОЛОКО́УБЕЖА́ЛО} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson3/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson3/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson3/Grammar" exact render={() => <GrammarLesson3B1 title={Constants.lesson3} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson3/Exercises" exact render={() => <ExercisesLesson3B1 title={Constants.lesson3} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson4" exact render={() => <Lesson4B1 title={Constants.lesson4} subtitle={Constants.ЧТОПРИВЕЗТИ́} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson4/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson4/Exercises" exact render={() => <ExercisesLesson4B1 title={Constants.lesson4} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson5" exact render={() => <Lesson5B1 title={Constants.lesson5} subtitle={Constants.СОВЕ́ТЫПУТЕШЕ́СТВЕННИКАМ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson5/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson5/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson5/Grammar" exact render={() => <GrammarLesson5B1 title={Constants.lesson5} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson5/Exercises" exact render={() => <ExercisesLesson5B1 title={Constants.lesson5} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson6" exact render={() => <Lesson6B1 title={Constants.lesson6} subtitle={Constants.ОТДЫХА́ТЬМО́ЖНОПОРА́ЗНОМУ} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson6/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson6/Exercises" exact render={() => <ExercisesLesson6B1 title={Constants.lesson6} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson7" exact render={() => <Lesson7B1 title={Constants.lesson7} subtitle={Constants.КУДА́БЫПОЕ́ХАТЬ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson7/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson7/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson7/Grammar" exact render={() => <GrammarLesson7B1 title={Constants.lesson7} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson7/Exercises" exact render={() => <ExercisesLesson7B1 title={Constants.lesson7} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson8" exact render={() => <Lesson8B1 title={Constants.lesson8} subtitle={Constants.ПОСМОТРИ́ТЕНАЭ́ТУКАРТИ́НУ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson8/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson8/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson8/Grammar" exact render={() => <GrammarLesson8B1 title={Constants.lesson8} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson8/Exercises" exact render={() => <ExercisesLesson8B1 title={Constants.lesson8} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson9" exact render={() => <Lesson9B1 title={Constants.lesson9} subtitle={Constants.НА́ЙДЕНШЕДЕ́ВР} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson9/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson9/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson9/Grammar" exact render={() => <GrammarLesson9B1 title={Constants.lesson9} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson9/Exercises" exact render={() => <ExercisesLesson9B1 title={Constants.lesson9} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson10" exact render={() => <Lesson10B1 title={Constants.lesson10} subtitle={Constants.ПОСЛЕДÁМ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson10/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson10/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson10/Grammar" exact render={() => <GrammarLesson10B1 title={Constants.lesson10} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson10/Exercises" exact render={() => <ExercisesLesson10B1 title={Constants.lesson10} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson11" exact render={() => <Lesson11B1 title={Constants.lesson11} subtitle={Constants.ПОДЗÉМНЫЙМИР} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson11/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson11/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson11/Grammar" exact render={() => <GrammarLesson11B1 title={Constants.lesson11} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson11/Exercises" exact render={() => <ExercisesLesson11B1 title={Constants.lesson11} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson12" exact render={() => <Lesson12B1 title={Constants.lesson12} subtitle={Constants.ИВОТМЫВАЭРОПОРТУ́} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson12/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson12/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson12/Grammar" exact render={() => <GrammarLesson12B1 title={Constants.lesson12} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson12/Exercises" exact render={() => <ExercisesLesson12B1 title={Constants.lesson12} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson13" exact render={() => <Lesson13B1 title={Constants.lesson13} subtitle={Constants.НАБОРТУ́} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson13/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson13/Exercises" exact render={() => <ExercisesLesson13B1 title={Constants.lesson13} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson14" exact render={() => <Lesson14B1 title={Constants.lesson14} subtitle={Constants.ВСЁСУ́ПЕР} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson14/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson14/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson14/Grammar" exact render={() => <GrammarLesson14B1 title={Constants.lesson14} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson14/Exercises" exact render={() => <ExercisesLesson14B1 title={Constants.lesson14} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson15" exact render={() => <Lesson15B1 title={Constants.lesson15} subtitle={Constants.ТРАНССИ́Б} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson15/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson15/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson15/Grammar" exact render={() => <GrammarLesson15B1 title={Constants.lesson15} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson15/Exercises" exact render={() => <ExercisesLesson15B1 title={Constants.lesson15} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson16" exact render={() => <Lesson16B1 title={Constants.lesson16} subtitle={Constants.МОИ́УНИВЕРСИТЕ́ТЫ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson16/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson16/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson16/Grammar" exact render={() => <GrammarLesson16B1 title={Constants.lesson16} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson16/Exercises" exact render={() => <ExercisesLesson16B1 title={Constants.lesson16} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson17" exact render={() => <Lesson17B1 title={Constants.lesson17} subtitle={Constants.ПРИЛЕТÉЛИ} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson17/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson17/Exercises" exact render={() => <ExercisesLesson17B1 title={Constants.lesson17} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson18" exact render={() => <Lesson18B1 title={Constants.lesson18} subtitle={Constants.СЪЕ́ЗДИТЬСЪЕ́ХАТЬИСЪЕЗЖА́ТЬ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson18/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson18/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson18/Grammar" exact render={() => <GrammarLesson18B1 title={Constants.lesson18} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson18/Exercises" exact render={() => <ExercisesLesson18B1 title={Constants.lesson18} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson19" exact render={() => <Lesson19B1 title={Constants.lesson19} subtitle={Constants.СЛЕТА́ТЬНАЛУНУ́} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson19/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson19/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson19/Grammar" exact render={() => <GrammarLesson19B1 title={Constants.lesson19} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson19/Exercises" exact render={() => <ExercisesLesson19B1 title={Constants.lesson19} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson20" exact render={() => <Lesson20B1 title={Constants.lesson20} subtitle={Constants.ЗАЧÉМНАМКÓСМОС} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson20/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson20/Exercises" exact render={() => <ExercisesLesson20B1 title={Constants.lesson20} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson21" exact render={() => <Lesson21B1 title={Constants.lesson21} subtitle={Constants.ИСКУ́ССТВОНАЦЫ́ПОЧКАХ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson21/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson21/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson21/Grammar" exact render={() => <GrammarLesson21B1 title={Constants.lesson21} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson21/Exercises" exact render={() => <ExercisesLesson21B1 title={Constants.lesson21} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson22" exact render={() => <Lesson22B1 title={Constants.lesson22} subtitle={Constants.ИТАКСОЙДЁТ} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson22/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson22/Exercises" exact render={() => <ExercisesLesson22B1 title={Constants.lesson22} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson23" exact render={() => <Lesson23B1 title={Constants.lesson23} subtitle={Constants.БЛАГОДАРЯ́РОДИ́ТЕЛЯМ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson23/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson23/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson23/Grammar" exact render={() => <GrammarLesson23B1 title={Constants.lesson23} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson23/Exercises" exact render={() => <ExercisesLesson23B1 title={Constants.lesson23} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson24" exact render={() => <Lesson24B1 title={Constants.lesson24} subtitle={Constants.КРАСОТА́СПАСЁТМИР} goBack={this.props.history.goBack} goToLessonsB1 route2={["/Levels/B1/Lesson24/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson24/Exercises" exact render={() => <ExercisesLesson24B1 title={Constants.lesson24} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson25" exact render={() => <Lesson25B1 title={Constants.lesson25} subtitle={Constants.ЧЁРНЫЙКОТ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson25/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson25/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson25/Grammar" exact render={() => <GrammarLesson25B1 title={Constants.lesson25} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson25/Exercises" exact render={() => <ExercisesLesson25B1 title={Constants.lesson25} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson26" exact render={() => <Lesson26B1 title={Constants.lesson26} subtitle={Constants.МИМИМИ́ШНЫЙЯЗЫ́Ќ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson26/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson26/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson26/Grammar" exact render={() => <GrammarLesson26B1 title={Constants.lesson26} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson26/Exercises" exact render={() => <ExercisesLesson26B1 title={Constants.lesson26} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson27" exact render={() => <Lesson27B1 title={Constants.lesson27} subtitle={Constants.НАЧТОПОХО́ЖСНЕГ} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson27/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson27/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson27/Grammar" exact render={() => <GrammarLesson27B1 title={Constants.lesson27} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson27/Exercises" exact render={() => <ExercisesLesson27B1 title={Constants.lesson27} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson28" exact render={() => <Lesson28B1 title={Constants.lesson28} subtitle={Constants.ПТИ́ЧИЙЯЗЫ́К} goBack={this.props.history.goBack} goToLessonsB1 route1={["/Levels/B1/Lesson28/Grammar", Constants.grammar]} route2={["/Levels/B1/Lesson28/Exercises", Constants.exercises]} />} />
                            <Route path="/Levels/B1/Lesson28/Grammar" exact render={() => <GrammarLesson28B1 title={Constants.lesson28} subtitle={Constants.grammar} goBack={this.props.history.goBack} goToLessonsB1 />} />
                            <Route path="/Levels/B1/Lesson28/Exercises" exact render={() => <ExercisesLesson28B1 title={Constants.lesson28} subtitle={Constants.exercises} goBack={this.props.history.goBack} goToLessonsB1 />} />
                        </Switch>
                    </CenterColumn>
                </Layout>
            </div>
        )
    };
}

export default RoutingPage;