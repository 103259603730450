import React from 'react';
import classes from './IntroductionA1.css';
import MainHeader from '../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../CenterColumn/CenterColumn.css';
import * as Constants from '../../../../Constants/Constants';
import { Link } from 'react-router-dom';

const introductionA1 = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.IntroductionA1}>
                <div>
                    <Link to="/Levels/A1/Introduction/RussianLanguage">
                        {Constants.russianLanguage}
                    </Link>
                </div>
                <div>
                    <Link to="/Levels/A1/Introduction/Phonetics">
                        {Constants.phonetics}
                    </Link>
                </div>
                <div>
                    <Link to="/Levels/A1/Introduction/Alphabet">
                        {Constants.alphabet}
                    </Link>
                </div>
                <div>
                    <Link to="/Levels/A1/Introduction/InternationalWords">
                        {Constants.internationalWords}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default introductionA1;