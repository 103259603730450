import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'центра', name: 'центра', matcher: 'центра' },
    { id: 'супермаркета', name: 'супермаркета', matcher: 'супермаркета' },
    { id: 'дома', name: 'дома', matcher: 'дома' },
    { id: 'нас', name: 'нас', matcher: 'нас' },
    { id: 'бабушки', name: 'бабушки', matcher: 'бабушки' },
    { id: 'аптеки', name: 'аптеки', matcher: 'аптеки' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escreva as palavras na forma correta" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            Мы живём совсе́м недалеко́ от <input
                                id="центраInput"
                                name="центраInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> (центр). Наш дом стои́т во́зле <input
                                id="супермаркетаInput"
                                name="супермаркетаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> (суперма́ркет), где мы обы́чно де́лаем поку́пки. Я е́ду в шко́лу на авто́бусе, потому́ что шко́ла нахо́дится далеко́ от <input
                                id="домаInput"
                                name="домаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> моего́ (дом). Моя́ ба́бушка живёт недалеко́ от <input
                                id="насInput"
                                name="насInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> (мы). <p></p>Сего́дня я пообе́дал у <input
                                id="бабушкиInput"
                                name="бабушкиInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (ба́бушка). Сейча́с я иду́ в библиоте́ку. Библиоте́ка нахо́дится о́коло <input
                                id="аптекиInput"
                                name="аптекиInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> (апте́ка), где рабо́тает моя́ ма́ма.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;