import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'от', name: 'от', translation: 'от' },
    { id: 'напро́тив', name: 'напро́тив', translation: 'напро́тив' },
    { id: 'по́сле', name: 'по́сле', translation: 'по́сле' },
    { id: 'внутри́', name: 'внутри́', translation: 'внутри́' },
    { id: 'во́зле', name: 'во́зле', translation: 'во́зле' },
    { id: 'для', name: 'для', translation: 'для' },
    { id: 'с', name: 'с', translation: 'с' },
    { id: 'вокру́г', name: 'вокру́г', translation: 'вокру́г' },
    { id: 'из', name: 'из', translation: 'из' },
    { id: 'и́з-за', name: 'и́з-за', translation: 'и́з-за' },
    { id: 'без', name: 'без', translation: 'без' },
    { id: 'до', name: 'до', translation: 'до' },
    { id: 'о́коло', name: 'о́коло', translation: 'о́коло' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <span className={classes.cardWrapper}>
                <span ref={drag} className={classes.dragCard}>
                    {name}
                </span>
            </span>
        );
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <span className={classes.cardWrapper}>
                <span ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <DragCard key={item.id} type={item.id} name={item.name} /> :
                        <span className={classes.dropCard} type={item.id}>largar aqui</span>
                    }
                </span>
            </span>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = (
            <div className={classes.questionsWrapper}>
                <div className={classes.question}>
                    <p>1) Мы вчера́ гуля́ли <DropCard key={dropCards[11].id} item={dropCards[11]} /> утра́!</p>
                    <p>2) Я вы́шла и забы́ла ключи́ <DropCard key={dropCards[3].id} item={dropCards[3]} /> до́ма.</p>
                    <p>3) Я не люблю́ чай <DropCard key={dropCards[10].id} item={dropCards[10]} /> дже́ма.</p>
                    <p>4) Он получи́л смс <DropCard key={dropCards[0].id} item={dropCards[0]} /> однокла́ссника.</p>
                    <p>5) Я встал <DropCard key={dropCards[12].id} item={dropCards[12]} /> девяти́ у́тра.</p>
                    <p>6) Загс нахо́дится <DropCard key={dropCards[1].id} item={dropCards[1]} /> торго́вого це́нтра</p>
                    <p>7) Я иду́ домо́й то́лько <DropCard key={dropCards[2].id} item={dropCards[2]} /> уро́ков ру́сского языка́.</p>
                    <p>8) Он то́лько прие́хал <DropCard key={dropCards[6].id} item={dropCards[6]} /> рабо́ты.</p>
                    <p>9) Ты уже́ верну́лась <DropCard key={dropCards[8].id} item={dropCards[8]} /> Брази́лии?</p>
                    <p>10) Она́ сра́зу его́ узна́ла <DropCard key={dropCards[9].id} item={dropCards[9]} /> его́ улы́бки.</p>
                    <p>11) Ну́жно купи́ть муку́ <DropCard key={dropCards[5].id} item={dropCards[5]} /> блино́в.</p>
                    <p>12) Ба́бушка оберну́ла плато́к <DropCard key={dropCards[7].id} item={dropCards[7]} /> ше́и.</p>
                    <p>13) Я ка́ждый день обе́даю в кафе́ <DropCard key={dropCards[4].id} item={dropCards[4]} /> тренажёрного за́ла.</p>
                </div>
            </div>
        );

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Selecione e arraste a preposição mais adequada para cada frase:" />
                <div className={classes.dragAndDropContainer}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;