import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1 from '../../../../../../Assets/Images/B1/Lesson13/image1exercise.png';
import Image2 from '../../../../../../Assets/Images/B1/Lesson13/image2exercise.png';
import Image3 from '../../../../../../Assets/Images/B1/Lesson13/image3exercise.png';
import Image4 from '../../../../../../Assets/Images/B1/Lesson13/image4exercise.png';
import Image5 from '../../../../../../Assets/Images/B1/Lesson13/image5exercise.png';
import Image6 from '../../../../../../Assets/Images/B1/Lesson13/image6exercise.png';

const Exercises = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Воздушный разговорник: прочитайте фразы, которые вы можете услышать в самолëте" />
                <p className={classes.textContent}>
                    <b>(материал адаптирован из -</b> <a href="https://www.frequentflyers.ru/2016/10/24/captain_phrases/"
                        target="_blank"
                        rel="noopener noreferrer">
                        https://www.frequentflyers.ru/2016/10/24/captain_phrases/
                    </a>)
                </p>
                <div className={classes.imgGrid}>
                    <img id="image1" src={Image1} alt="1" width="350px" />
                    <img id="image2" src={Image2} alt="2" width="350px" />
                    <img id="image3" src={Image3} alt="3" width="350px" />
                    <img id="image4" src={Image4} alt="4" width="350px" />
                    <img id="image5" src={Image5} alt="5" width="350px" />
                    <img id="image6" src={Image6} alt="6" width="350px" />
                </div>
            </div>
        </div>
    );
}

export default Exercises;