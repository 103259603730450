import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'моих', name: 'моих', matcher: 'моих' },
    { id: 'нашего', name: 'нашего', matcher: 'нашего' },
    { id: 'твоего', name: 'твоего', matcher: 'твоего' },
    { id: 'вашу', name: 'вашу', matcher: 'вашу' },
    { id: 'моего́', name: 'моего́', matcher: 'моего́' },
    { id: 'их', name: 'их', matcher: 'их' },
    { id: 'её', name: 'её', matcher: 'её' },
    { id: 'твою', name: 'твою', matcher: 'твою' },
    { id: 'наш', name: 'наш', matcher: 'наш' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Continue a frase, substituindo o pronome pessoal entre parêntesis pelo possessivo na forma correta:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input1"
                                name="input 1"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> (я) друзе́й?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input2"
                                name="input 2"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> (мы) дире́ктора?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input3"
                                name="input 3"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> (ты) бра́та!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input4"
                                name="input 4"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> (вы) дочь!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input5"
                                name="input 5"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (я) му́жа?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input6"
                                name="input 6"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> (они) роди́тели?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input7"
                                name="input 7"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> (она) отца́?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input8"
                                name="input 8"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> (ты) ситуа́цию!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ... <input
                                id="input9"
                                name="input 9"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> (мы) уче́бник?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;