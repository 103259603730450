import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>безопа́сный</b> seguro</p>
                        <p><b>ви́димся</b> (v. ви́деться) encontramo-nos</p>
                        <p><b>вку́сно</b> saboroso</p>
                        <p><b>возду́шный</b> aéreo</p>
                        <p><b>(в) путеше́ствие</b> na viagem</p>
                        <p><b>вы́брать</b> escolher</p>
                        <p><b>давно́</b> há muito (tempo)</p>
                        <p><b>добра́ться</b> chegar (ao destino)</p>
                        <p><b>железнодоро́жный</b> ferroviário</p>
                        <p><b>любу́ется</b> (v. любова́ться) admira (alguém)</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>матери́к</b> continente</p>
                        <p><b>морско́й</b> marítimo</p>
                        <p><b>(на) надéжду</b> (na) esperança</p>
                        <p><b>(на) огни́</b> (para) as luzes</p>
                        <p><b>опа́сный</b> perigoso</p>
                        <p><b>отправля́ясь</b> (v. отправля́ться) indo</p>
                        <p><b>пáхнет</b> (v. па́хнуть) cheira</p>
                        <p><b>полете́ть</b> voar</p>
                        <p><b>прия́тный</b> agradável</p>
                        <p><b>(с) вéрою</b> (com) a fé</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>(с) ви́дом</b> (com) a vista</p>
                        <p><b>(с) кем</b> (com) quem</p>
                        <p><b>(с) ней </b>(com) ela</p>
                        <p><b>(с) прáвом</b> (com) o direito</p>
                        <p><b>(с) тобо́й</b> contigo</p>
                        <p><b>слы́шишь</b> (v. слы́шать) ouves</p>
                        <p><b>(со) мной</b> comigo</p>
                        <p><b>сухопу́тный</b> terrestre</p>
                        <p><b>тако́й же</b> 1. tão… 2. é igual ao/ a…</p>
                        <p><b>удо́бный</b> confortável</p>
                        <p><b>электри́чка</b> comboio elétrico</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;