import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>авто́бус</b> autocarro</p>
                        <p><b>быть</b> ser</p>
                        <p><b>врач</b> médico</p>
                        <p><b>гимна́ст</b>ginasta</p>
                        <p><b>да</b> sim</p>
                        <p><b>зоо́лог</b> zoólogo</p>
                        <p><b>кто</b> quem</p>
                        <p><b>лице́нзия</b> licença</p>
                        <p><b>любо́вь</b> amor</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ма́ма</b> mamã/ mãe</p>
                        <p><b>маши́на</b> carro</p>
                        <p><b>нарко́тик</b> droga</p>
                        <p><b>перево́дчики</b> tradutores</p>
                        <p><b>пи́цца</b> pizza</p>
                        <p><b>портве́йн</b> vinho do Porto</p>
                        <p><b>суп</b> sopa</p>
                        <p><b>та́нец</b> dança</p>
                        <p><b>танцо́р</b> dançarino</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>торт</b> bolo</p>
                        <p><b>фрукт</b> fruta</p>
                        <p><b>футбо́лка</b> t-shirt</p>
                        <p><b>худо́жник</b> 1. pintor 2. artista</p>
                        <p><b>что</b> que</p>
                        <p><b>э́то</b> 1. isso 2. esse 3. isto</p>
                        <p><b>юри́ст</b> jurista</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;