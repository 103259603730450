import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: true },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: true },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: true },
    { id: 16, isCorrect: false },
    { id: 17, isCorrect: true },
    { id: 18, isCorrect: false },
    { id: 19, isCorrect: true },
    { id: 29, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha a forma verbal mais adequada ao contexto:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            1. Я обы́чно <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    выхожу́
                                </button>/<button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    вы́йду
                                </button>
                            </span> с рабо́ты в 5 часо́в, но сего́дня <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    выходи́ла
                                </button>/<button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    вы́шла
                                </button>
                            </span> в 7.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            2. – Сейча́с там ремо́нт, поэ́тому ну́жно всё <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    обходи́ть
                                </button>/<button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    обойти́
                                </button>
                            </span> по друго́й доро́ге.
                            <p>– Пра́вда? Я не заме́тил, что там ремо́нт. Мо́жет быть, потому́ что я <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    обходи́л
                                </button>/<button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    обошёл
                                </button>
                            </span> дом с друго́й стороны́.</p>
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            3. – Почему́ они́ <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    приходи́ли
                                </button>/<button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    пришли́
                                </button>
                            </span> так ра́но?
                            <p>– Они́ нашли́ но́вую доро́гу, так что они́ cейча́с <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    прихóдят
                                </button>/<button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    приду́т
                                </button>
                            </span> в э́то вре́мя.</p>
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            4. Когда́ мне бы́ло 10 лет, я <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    входи́ла
                                </button>/<button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    вошла́
                                </button>
                            </span> в холо́дную во́ду без пробле́м. Сейча́с я понима́ю, что <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    входи́ть
                                </button>/<button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    войти́
                                </button>
                            </span> в холо́дную во́ду без подгото́вки – э́то большо́й стресс для органи́зма.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            5. – Осторо́жно! Нельзя́ <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    подходи́ть
                                </button>/<button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    подойти́
                                </button>
                            </span> к чужо́й соба́ке.
                            <p>– Ничего́ стра́шного! Она́ не куса́ется. Ваш сын мо́жет <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[18].id}
                                    onClick={event => handleColor(event, buttonsEx1[18].isCorrect)}
                                >
                                    подходи́ть
                                </button>/<button
                                    key={buttonsEx1[19].id}
                                    onClick={event => handleColor(event, buttonsEx1[19].isCorrect)}
                                >
                                    подойти́
                                </button>
                            </span> и погла́дить её.</p>
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Exercises;