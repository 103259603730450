import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'голубоe', name: 'голубоe', matcher: 'голубоe' },
    { id: 'зелёная', name: 'зелёная', matcher: 'зелёная' },
    { id: 'белые', name: 'белые', matcher: 'белые' },
    { id: 'чёрная', name: 'чёрная', matcher: 'чёрная' },
    { id: 'синий', name: 'синий', matcher: 'синий' },
    { id: 'красная', name: 'красная', matcher: 'красная' },
    { id: 'жёлтое', name: 'жёлтое', matcher: 'жёлтое' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha a cor que melhor se associa às palavras e escreva o adjetivo na forma correta." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            1) небо <input
                                id="голубоeInput"
                                name="голубоeInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            2) трава́ <input
                                id="зелёнаяInput"
                                name="зелёнаяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            3) облакá <input
                                id="белыеInput"
                                name="белыеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            4) пу́ма <input
                                id="чёрнаяInput"
                                name="чёрнаяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            5) океа́н <input
                                id="синийInput"
                                name="синийInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            6) кровь <input
                                id="краснаяInput"
                                name="краснаяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            7) солнце <input
                                id="жёлтоеInput"
                                name="жёлтоеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;