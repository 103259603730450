import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>блеск</b> brilho de lábios /lip gloss</p>
                        <p><b>брóви</b> sobrancelhas</p>
                        <p><b>(в) А́зии</b> (na) Ásia</p>
                        <p><b>ве́ки</b> pálpebras</p>
                        <p><b>ви́лкой</b> (Instr.) com garfo</p>
                        <p><b>встреча́ться</b> encontrar-se</p>
                        <p><b>гель</b> gel</p>
                        <p><b>глаго́лы</b> verbos</p>
                        <p><b>кра́ска</b> tinta</p>
                        <p><b>ло́жкой</b> (Instr.) com colher</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>мáркер</b> marcador</p>
                        <p><b>мел</b> giz</p>
                        <p><b>мы́ло</b> sabonete</p>
                        <p><b>(на) бума́ге</b> (no) papel</p>
                        <p><b>(на) доске́</b> (no) quadro</p>
                        <p><b>(на) стенé</b> (na) parede</p>
                        <p><b>обнима́ться</b> abraçar-se</p>
                        <p><b>па́лочками</b> (Instr.) com pauzinhos</p>
                        <p><b>пома́да</b> batom</p>
                        <p><b>ре́зать</b> cortar</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ресни́цы</b> pestanas</p>
                        <p><b>ртом</b> (Instr.) com a boca</p>
                        <p><b>рука́ми</b> (Instr.) com as mãos</p>
                        <p><b>румя́на</b> blush</p>
                        <p><b>(с) со́усом</b> (com) molho</p>
                        <p><b>сра́зу</b> imediatamente</p>
                        <p><b>тéни</b> sombra de olhos</p>
                        <p><b>тушь</b> rimel</p>
                        <p><b>целова́ться</b> beijar-se</p>
                        <p><b>чем</b> com que</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;