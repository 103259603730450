import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson2/image1grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ИМЯ СУЩЕСТВИТЕЛЬНОЕ Винительный падеж (мн. ч.)</p>
                    <p>NOMES - Acusativo (pl.)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Os nomes <b>não animados</b> de TODOS OS GÉNEROS têm a mesma forma do <b>Nominativo</b>.</p>
                </div>
                <div className={classes.item2}>
                    <img id="image1Grammar" src={Image1Grammar} alt="who what table" width="500px" />
                </div>
                <div className={classes.item3}>
                    <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> SEMPRE PLURAL (animado)</p>
                    <div className={classes.item3_1}>
                        <p>дéт<span className={classes.orangeHighlight}>и</span>&nbsp;&nbsp;&nbsp;дет<span className={classes.orangeHighlight}>éй</span>&nbsp;&nbsp;&nbsp;"crianças"</p>
                        <p>лю́д<span className={classes.orangeHighlight}>и</span>&nbsp;&nbsp;&nbsp;люд<span className={classes.orangeHighlight}>éй</span>&nbsp;&nbsp;&nbsp; "pessoas”</p>
                    </div >
                </div >
                <div className={classes.item4}>
                    <p>
                        Verbo <span className={classes.orangeHighlight}>СМОТРЕТЬ</span> + (Preposição) ACUSATIVO
                    </p>
                    <p>
                        Dependendo da aceção do verbo, a estrutura da frase e o uso da preposição varia:
                    </p>
                    <div className={classes.spacer}>
                        <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>СМОТРЕТЬ</span> + Вин. п.</b></p>
                        <p><i>Вчера́ но́чью я <b>смотре́л фильм</b>.</i></p>
                        <p>"ver/assistir alguma coisa"</p>
                    </div>
                    <div className={classes.spacer}>
                        <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>СМОТРЕТЬ НА</span> + Вин. п.</b></p>
                        <p><i>Мы <b>смотре́ли на неё</b> с удивле́нием.</i></p>
                        <p>"olhar para alguém/para alguma coisa"</p>
                    </div>
                    <div className={classes.spacer}>
                        <p><b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <span className={classes.orangeHighlight}>СМОТРЕТЬ</span> + Вин. п. <span className={classes.orangeHighlight}>ПО</span> + Дат. п.</b></p>
                        <p><i>Де́ти <b>смотре́ли</b> мультфи́льм <b>по телеви́зору</b>, а не в кинотеа́тре.</i></p>
                        <p>"ver na televisão"</p>
                    </div>
                </div>
            </div >
        </div >);
}

export default Grammar;