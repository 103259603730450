import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import NominativeCaseTable from '../../../../../../Assets/Images/A1/Lesson4/nominativeCaseTable.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>СОЮЗЫ</p>
                    <p>Conjunções</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Em frases onde se usa apenas uma única conjunção em português (conjunção
                        coordenativa e), em russo usa-se duas conjunções: <b><span className={classes.orangeHighlight}>и</span></b> (1б) e <b><span className={classes.greenHighlight}>a</span></b> (2б).
                    </p>
                    <p>1) a. Estes são o meu pai <b><span className={classes.greenHighlight}>e</span></b> a minha mãe.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp; б. Э́то мой папа <b><span className={classes.orangeHighlight}>и</span></b> мoя мама.</p>
                    <p>2) a. Este é o meu pai <b><span className={classes.greenHighlight}>e</span></b> esta é a minha mãe.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp; б. Э́то мой папа, <b><span className={classes.greenHighlight}>а</span></b> э́то мoя мама.</p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Numa situação em que se nega uma circunstância, a conjunção <b><span className={classes.greenHighlight}>а</span></b> traduz-se por <b><i><span className={classes.orangeHighlight}>mas sim</span></i></b>:
                    </p>
                    <p>3) a. Este não é o meu tio, <b><span className={classes.orangeHighlight}>mas sim</span></b> o meu pai!</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp; б. Э́то не мой дя́дя, <b><span className={classes.orangeHighlight}>а</span></b> мой папа!</p>
                </div>
                <GrammarSubtitle>
                    <p>МНО́ЖЕСТВЕННОЕ ЧИСЛО</p>
                    <p>Plural</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <img id="nominativeCaseTable" src={NominativeCaseTable} alt="nominative case table" />
                </div>
            </div>
        </div>
    );
}

export default Grammar;