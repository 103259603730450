import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бегать</b> correr</p>
                        <p><b>бежать</b> correr</p>
                        <p><b>больни́ца</b> hospital</p>
                        <p><b>бою́сь</b> (v. боя́ться) tenho medo</p>
                        <p><b>(в да́нный) моме́нт</b> (neste) momento</p>
                        <p><b>вертолёт</b> helicóptero</p>
                        <p><b>вход</b> entrada</p>
                        <p><b>вы́ход</b> saída</p>
                        <p><b>домо́й</b> (Gen.) a/para casa</p>
                        <p><b>друзья́</b> amigos</p>
                        <p><b>ездить</b> ir num veículo</p>
                        <p><b>из</b> (prep.) de</p>
                        <p><b>ка́тер</b> lancha</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>кора́бль</b> navio</p>
                        <p><b>летать</b> voar</p>
                        <p><b>лететь</b> voar</p>
                        <p><b>лети́т</b> (v. лететь) está a voar</p>
                        <p><b>любо́й</b> qualquer</p>
                        <p><b>(на) круи́зном ла́йнере</b> (no) navio de cruzeiro</p>
                        <p><b>(на) ле́кцию</b> (à) palestra</p>
                        <p><b>опа́здывает</b> (v. опа́здывать) está atrasado/a</p>
                        <p><b>от</b> (prep.) de</p>
                        <p><b>отку́да</b> (prep.) de onde</p>
                        <p><b>(от) о́фиса</b> (do) escritório</p>
                        <p><b>па́мятник</b> monumento</p>
                        <p><b>плавать</b> nadar</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пло́щадь</b> praça</p>
                        <p><b>плыть</b> nadar</p>
                        <p><b>по́нял</b> (v. поня́ть) entendi</p>
                        <p><b>почти́</b> quase</p>
                        <p><b>про́бка</b> trânsito</p>
                        <p><b>проездно́й</b> passe mensal</p>
                        <p><b>родительный падеж</b> caso genitivo</p>
                        <p><b>с</b> (prep.) de</p>
                        <p><b>семина́ра</b> (Gen.) do seminário</p>
                        <p><b>сколько ра́з</b> quantas vezes</p>
                        <p><b>ско́лько часо́в</b> quantas horas</p>
                        <p><b>трамва́й</b> elétrico</p>
                        <p><b>ходить</b> 1. ir a pé 2. andar</p>
                        <p><b>я́хта</b> iate</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;