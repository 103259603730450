import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson28/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson28/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson28/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson28/image4grammar.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ГЛАГОЛЫ ДВИЖЕНИЯ С ПРИСТАВКАМИ</p>
                    <p>Grupo II: СВ</p>
                    <p>Verbos de Movimento C/Prefixos (Imperfetivos)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Ao contrário dos verbos de movimento do grupo I (Aula 27), que passam a ter aspeto perfectivo quando é adicionado o prefixo, os do grupo II, mantêm o aspeto, continuando a ser imperfectivos:</p>
                    </div>
                    <div className={classes.item1_2}>
                        <li><i>подходи́ть</i></li>
                        <li><i>переходи́ть</i></li>
                        <li><i>приходи́ть</i></li>
                        <li><i>входи́ть</i></li>
                        <li><i>походи́ть</i></li>
                        <li><i>уходи́ть</i></li>
                        <li><i>заходи́ть</i></li>
                        <li><i>выходи́ть</i></li>
                        <li><i>проходи́ть</i></li>
                        <li><i>доходи́ть</i></li>
                        <li><i>отходи́ть</i></li>
                        <li><i>обходи́ть</i></li>
                    </div>
                </div>
                <div className={classes.item2}>
                    <div className={classes.item2_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Dependendo do aspeto, a palavra <b><span className={classes.orangeHighlight}>НЕЛЬЗЯ́</span></b> tem uma diferente interpretação (esta regra aplica-se aos verbos em geral, e não apenas aos verbos de movimento.):</p>
                    </div>
                    <div className={classes.item2_2}>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="400px" />
                    </div>
                    <div className={classes.item2_3}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Outra regra associada ao uso do perfetivo ou imperfetivo, no passado, tem que ver com a presença ou ausência do sujeito:</p>
                    </div>
                    <div className={classes.item2_4}>
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="400px" />
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>НИ vs. НЕ́</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Os pronomes que têm a partícula <b><span className={classes.orangeHighlight}>НИ</span></b> são pronomes de negação e os que têm a partícula <b><span className={classes.orangeHighlight}>НЕ́</span></b> são pronomes indefinidos:</p>
                    </div>
                    <div className={classes.item3_2}>
                        <div className={classes.item3_2_1}>
                            <div className={classes.item3_2_1_1}>
                                <p>никто́ (ninguém)</p>
                                <p>ничего́ (nada)</p>
                                <p>никогда́ (nunca)</p>
                                <p>никуда́ (a lado nenhum)</p>
                                <p>нигде́ (em lado nenhum)</p>
                                <p>ниче́м (com nada)</p>
                                <p>ни за чтó (por nada)</p>
                            </div>
                            <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="250px" />
                        </div>
                        <div className={classes.item3_2_2}>
                            <div className={classes.item3_2_2_1}>
                                <p>нéкто (uma pessoa)</p>
                                <p>нéчего (não há nada)</p>
                                <p>нéкогда (não há quando/tempo)</p>
                                <p>нéкуда (não há para onde)</p>
                                <p>нéгде (não há lugar)</p>
                                <p>нéчем (não há com quê)</p>
                                <p>нé за что (não há nada)</p>
                            </div>
                            <img id="image4Grammar" src={Image4Grammar} alt="table 4" width="250px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;