import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson17/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson17/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson17/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson17/image4grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>Formação dos pares aspetuais</p>
                    <p>СВ ⟶ НСВ</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Os pares verbais aspectuais <b><span className={classes.orangeHighlight}>СВ ⟶ НСВ</span></b> podem ser formados por:</p>
                    <div className={classes.item1_1}>
                        <div className={classes.item1_1_1}>
                            <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="100px" height="70px" />
                            <p>показа́ть - пока́з<b>ыва</b>ть</p>
                        </div>
                        <div className={classes.item1_1_2}>
                            <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="100px" height="70px" />
                            <p>встать - вста<b>ва́</b>ть</p>
                        </div>
                        <div className={classes.item1_1_3}>
                            <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="100px" height="70px" />
                            <p>реш<b>и́</b>ть - реш<b>á</b>ть</p>
                        </div>
                        <div className={classes.item1_1_4}>
                            <img id="image4Grammar" src={Image4Grammar} alt="table 4" width="100px" height="70px" />
                            <p>спа<b>сти́</b> -спас<b>а́ть</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;