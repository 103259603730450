import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import audio1 from './Audio/aula24aud1.mp3';

const VerbList = [
    { id: 'сел', name: 'сел', matcher: 'сел' },
    { id: 'рисовать', name: 'рисовать', matcher: 'рисовать' },
    { id: 'тридцать', name: 'тридцать', matcher: 'тридцать' },
    { id: 'пять', name: 'пять', matcher: 'пять' },
    { id: 'белого', name: 'белого', matcher: 'белого' },
    { id: 'взял', name: 'взял', matcher: 'взял' },
    { id: 'поставил', name: 'поставил', matcher: 'поставил' },
    { id: 'гармония', name: 'гармония', matcher: 'гармония' },
    { id: 'сломал', name: 'сломал', matcher: 'сломал' },
    { id: 'чистотой', name: 'чистотой', matcher: 'чистотой' },
    { id: 'поздно', name: 'поздно', matcher: 'поздно' },
    { id: 'давно', name: 'давно', matcher: 'давно' },
    { id: 'слова', name: 'слова', matcher: 'слова' },
];

const Exercises = props => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [player] = useState(new Audio(audio1));

    const playAudio = player => {
        if (isPlaying) {
            player.pause();
            setIsPlaying(false);
        } else {
            player.play();
            setIsPlaying(true);
        }
    }

    const MusicAudio = () => {
        return (
            <div className={classes.musicAudio}>
                <button className={classes.audioButton} onClick={() => playAudio(player)}>
                    Áudio &#9658; &#10074;&#10074;
                </button>
            </div>
        );
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Послушайте песню «Мир спасëт красота» и завершите текст" />
                <MusicAudio />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Я <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> за стол, я хочу <input
                                    id="съездилаInput"
                                    name="съездилаInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[1].matcher)}
                                /></p>
                            <p>Я не делал это годков <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> <input
                                    id="съездилаInput"
                                    name="съездилаInput"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[3].matcher)}
                                /></p>
                            <p>Так вызывающе манит девственность <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> листа</p>
                            <p>Я <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> карандаш со словами:</p>
                            <p>«Мир спасëт красота».</p>
                            <p>Я <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> точку на белом листе,</p>
                            <p>Лишь точку на белом, хлоп, и конец красоте,</p>
                            <p>Одной точкой убита <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> белого листа,</p>
                            <p>Я <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> карандаш со словами:</p>
                            <p>«Мир спасëт красота».</p>
                            <p>Живи, белый лист, живи, дорогой,</p>
                            <p>И радуй глаз своей <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            />.</p>
                            <p>Покуда ты чист, стоит белый свет.</p>
                            <p>Живи, белый лист, живи сотню лет.</p>
                            <p>Мне, граждане, <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            /> в свои тридцать пять</p>
                            <p>На белом листе чëрт-те что рисовать,</p>
                            <p>Я <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[11].matcher)}
                            /> пою песни, тут моя совесть чиста.</p>
                            <p>А <input
                                id="съездилаInput"
                                name="съездилаInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[12].matcher)}
                            /> в этом месте:</p>
                            <p>«Мир спасëт красота»</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;