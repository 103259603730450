import React from 'react';
import classes from './Contacts.css';
import GoogleMaps from './GoogleMaps';
import MainHeader from '../../Header/MainHeader/MainHeader';
import centerColumn from '../../CenterColumn/CenterColumn.css';

class Contacts extends React.Component {
    render() {
        return (
            <div className={centerColumn.CenterColumn}>
            <MainHeader title={this.props.title} />
                <div className={classes.Contacts}>
                    <div className={classes.Map}>
                        <GoogleMaps />
                    </div>
                    <div className={classes.Text}>
                        <p><b> CENTRO DE LÍNGUAS E CULTURAS ESLAVAS </b></p>
                        <p> Faculdade de Letras da Universidade de Lisboa </p>
                        <p> Alameda da Universidade </p>
                        <p> 1600-214 Lisboa, Portugal </p>
                        <p> russoflul@gmail.com </p>
                        <p> telefone: +351 217 920 000 </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contacts;