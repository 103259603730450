import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const DragTypes = [
    { id: '1', type: 'particulaНЕ', name: 'больша́я кварти́ра, a ма́ленькая', translation: 'больша́я кварти́ра, a ма́ленькая' },
    { id: '2', type: 'particulaНЕТ', name: 'си́них кроссо́вок', translation: 'си́них кроссо́вок' },
    { id: '3', type: 'particulaНЕТ', name: 'дете́й', translation: 'дете́й' },
    { id: '4', type: 'particulaНЕ', name: 'тяжёлый чемода́н, а лёгкий', translation: 'тяжёлый чемода́н, а лёгкий' },
    { id: '5', type: 'particulaНЕ', name: 'дочь, а сын', translation: 'дочь, а сын' },
    { id: '6', type: 'particulaНЕТ', name: 'хоро́шей кварти́ры', translation: 'хоро́шей кварти́ры' },
    { id: '7', type: 'particulaНЕ', name: 'торт, а пиро́г, в холоди́льнике', translation: 'торт, а пиро́г, в холоди́льнике' },
    { id: '8', type: 'particulaНЕТ', name: 'ру́чки', translation: 'ру́чки' },
    { id: '9', type: 'particulaНЕ', name: 'си́ние, а бе́лые кроссо́вки', translation: 'си́ние, а бе́лые кроссо́вки' },
    { id: '10', type: 'particulaНЕТ', name: 'то́рта в холоди́льнике', translation: 'то́рта в холоди́льнике' },
    { id: '13', type: 'particulaНЕ', name: 'тяжёлого чемода́на', translation: 'тяжёлого чемода́на' },
    { id: '14', type: 'particulaНЕ', name: 'ру́чка, а каранда́ш.', translation: 'ру́чка, а каранда́ш.' },
];

const DropTypes = [
    { id: '1', type: 'particulaНЕТ', name: 'particulaНЕТ' },
    { id: '2', type: 'particulaНЕ', name: 'particulaНЕ' },
];

const Exercises = props => {
    const [dropCards] = useState(DropTypes);
    const [dragCards] = useState(DragTypes);
    const [droppedListNA, setDroppedListSingular] = useState([]);
    const [droppedListB, setDroppedListPlural] = useState([]);

    const DragCard = ({ item }) => {
        const [, drag] = useDrag({
            type: item.type,
            item: { ...item },
            collect: monitor => ({
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging()
            })
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {item.translation}
                </div>
            </div>);
    }

    const DisplayCard = ({ type }) => {
        const content = type === 'particulaНЕТ' ? (
            <div className={classes.displayCard}>НЕТ</div>
        ) : (
            <div className={classes.displayCard}>НЕ</div>
        );

        return content;
    }

    const DroppedCardsSingular = () => {
        const cards = droppedListNA.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DroppedCardsPlural = () => {
        const cards = droppedListB.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCard = ({ accepts: accept, item }) => {
        const [, drop] = useDrop(() => ({
            accept: item.type,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();
                const dropItem = monitor.getItem();

                if (didDrop) {
                    return;
                }

                if (dropItem.type === 'particulaНЕТ') {
                    if (!droppedListNA.some(({ name }) => name === dropItem.name)) {
                        setDroppedListSingular([...droppedListNA, dropItem]);
                    }
                } else {
                    if (!droppedListB.some(({ name }) => name === dropItem.name)) {
                        setDroppedListPlural([...droppedListB, dropItem]);
                    }
                }
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {item.type === 'particulaНЕТ' ?
                        <div>
                            <p>Preposição</p>
                            <DisplayCard type={item.type} />
                            <DroppedCardsSingular />
                        </div> :
                        <div>
                            <p>Preposição</p>
                            <DisplayCard type={item.type} />
                            <DroppedCardsPlural />
                        </div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort((a, b) => {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const exerciseQuestion = (
        <p>
            Partícula "НЕТ" и́ли "НЕ"? Continue a frase "<span className={classes.orangeHighlight}>У меня...</span>" escolhendo qual das partículas negativas se usa no contexto:
        </p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion}></ExercisesQuestions>
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards className={classes.dropCards} />
                </div>
            </div>
        </div>
    );
}

export default Exercises;