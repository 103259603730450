import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson16/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson16/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson16/image3grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ВИДОВЫЕ ПАРЫ С РАЗНЫМИ КОРНЯМИ</p>
                    <p>Pares verbais com raízes diferentes</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <div className={classes.item1_1_1}>
                            <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="300px" />
                        </div>
                        <div className={classes.item1_1_2}>
                            <div className={classes.item1_1_2_1}>
                                <p>говори́ть</p>
                                <p>лови́ть</p>
                                <p>брать</p>
                                <p>сади́ться</p>
                                <p>ложи́ться</p>
                                <p>находи́ть</p>
                                <p>класть</p>
                                <p>станови́ться</p>
                                <p>начина́ть</p>
                                <p>понима́ть</p>
                                <p>надоеда́ть</p>
                                <p>съеда́ть</p>
                            </div>
                            <div className={classes.item1_1_2_2}>
                                <p>сказа́ть</p>
                                <p>пойма́ть</p>
                                <p>взять</p>
                                <p>сесть</p>
                                <p>лечь</p>
                                <p>найти́</p>
                                <p>положи́ть</p>
                                <p>стать</p>
                                <p>нача́ть</p>
                                <p>поня́ть</p>
                                <p>надоéсть</p>
                                <p>съесть</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.item1_2}>
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="400px" />
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>Oração Temporal</p>
                    <p>КОГДА́...</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> As orações temporais com <b><span className={classes.orangeHighlight}>КОГДА́ (quando)</span></b> podem anteceder estruturas com verbos imperfetivos ou perfetivos, dependendo se a ação da segunda oração é paralela ou sequencial relativamente à oração temporal:</p>
                    <div>
                        <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="500px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;