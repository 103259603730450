import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';
import video_aula_20 from './Video/video_aula_20.mp4';

const ItemTypes = [
    { id: 'Валерий Поляков', name: 'Валерий Поляков', translation: '1. Первый космонавт, который осуществил длительный (437-суточный) космический полёт' },
    { id: 'Валентина Терешкова', name: 'Валентина Терешкова', translation: '2. Первая женщина-космонавт' },
    { id: 'Алексей Леонов', name: 'Алексей Леонов', translation: '3. Первый человек в открытом космосе' },
    { id: 'Луноход-1', name: 'Луноход-1', translation: '4. Первый в мире планетоход, успешно работавший на поверхности другого небесного тела — Луны' },
    { id: 'Юрий Гагарин', name: 'Юрий Гагарин', translation: '5. Первый человек в космосе' },
    { id: 'Елена Кондакова', name: 'Елена Кондакова', translation: '6. Первая женщина в открытом космосе' },
    { id: '«Буран»', name: '«Буран»', translation: '7. Первый и единственный космический полёт в автоматическом режиме, без экипажа на борту' },
    { id: 'Светлана Савицкая', name: 'Светлана Савицкая', translation: '8. Первая женщина-космонавт, которая осуществила длительный (167-суточный) космический полёт' },
    { id: 'Лайка собака-космонавт', name: 'Лайка собака-космонавт', translation: '9. Первое животное, выведенное на орбиту Земли' },
    { id: 'Сергей Крикалёв', name: 'Сергей Крикалёв', translation: '10. Первый рекордсмен по сумме пребывание на орбите (803 суток)' },
    { id: 'Александр Волков', name: 'Александр Волков', translation: '11. Первый в истории человек, который основал династию космонавтов: его сын Сергей тоже выбрал эту профессию.' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.dragCardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true);
            },
        }), [accept]);

        return (
            <div className={classes.dropCardWrapper}>
                <div className={classes.dropCardText}>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const exerciseQuestion = (
        <p>Посмотрите фильм <span className={classes.orangeHighlight}>«Мы первые!»</span> и найдите героя.</p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <a className={classes.downloadVideoLink} href={video_aula_20} download="video_aula_20">Bидео Урок 20 - Мы первые!</a>
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;