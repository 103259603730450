import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson15/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson15/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson15/image3grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРИЛАГАТЕЛЬНОЕ</p>
                    <p>Дательный падеж (мн. ч.)</p>
                    <p>Adjetivos no Dativo (pl.)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="500px" />
                </div>
                <GrammarSubtitle>
                    <p>МЕСТОИМЕНИE</p>
                    <p>Дательный падеж (мн. ч.)</p>
                    <p>Determinantes e Pronomes no Dativo (pl.)</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="500px" />
                </div>
                <GrammarSubtitle>
                    <p>ASPETO</p>
                    <p>Вид</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O aspeto é uma categoria gramatical relativa ao desenvolvimento (início/duração/repetição/conclusão) da ação verbal.</p>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Os verbos perfetivos nunca têm valor de Tempo Presente.</p>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Quase todos os verbos russos têm um par aspetual (perfetivo/imperfetivo), geralmente formado com afixos. Alguns verbos formam um par aspetual a partir de raízes diferentes.</p>
                    <div className={classes.item3_1}>
                        <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="400px" />
                    </div>
                    <div className={classes.item3_2}>
                        <p>− Ты уже <b>сде́лал</b> дома́шнее зада́ние? (Já <b>fizeste</b> o trabalho de casa?)</p>
                    </div>
                    <div className={classes.item3_3}>
                        <p>− Я <b>де́лал</b>, но <b>не сде́лал</b>, потому́ что оно́ о́чень тру́дное. Я не зна́ю, как его́ <b>де́лать</b>.</p>
                        <p>(Eu <b>estive a fazer</b>, mas não <b>fiz até ao fim</b> porque era muito difícil. Não sei como o <b>fazer</b>.)</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;