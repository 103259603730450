import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const DragTypes = [
    { id: '1', type: 'adverbioГДЕ', name: 'сле́ва', translation: 'сле́ва' },
    { id: '2', type: 'adverbioКУДА', name: 'напра́во', translation: 'напра́во' },
    { id: '3', type: 'adverbioКУДА', name: 'нале́во', translation: 'нале́во' },
    { id: '4', type: 'adverbioГДЕ', name: 'спра́ва', translation: 'спра́ва' },
    { id: '5', type: 'adverbioГДЕ', name: 'внизу́', translation: 'внизу́' },
    { id: '6', type: 'adverbioКУДА', name: 'вниз', translation: 'вниз' },
    { id: '7', type: 'adverbioКУДА', name: 'вперёд', translation: 'вперёд' },
    { id: '8', type: 'adverbioГДЕ', name: 'впереди́', translation: 'впереди́' },
    { id: '9', type: 'adverbioГДЕ', name: 'сза́ди', translation: 'сза́ди' },
    { id: '10', type: 'adverbioКУДА', name: 'наза́д', translation: 'наза́д' },
    { id: '11', type: 'adverbioГДЕ', name: 'наверху́', translation: 'наверху́' },
    { id: '12', type: 'adverbioКУДА', name: 'вве́рх', translation: 'вве́рх' },
    { id: '13', type: 'adverbioГДЕ', name: 'све́рху', translation: 'све́рху' },
    { id: '14', type: 'adverbioКУДА', name: 'наве́рх', translation: 'наве́рх' },
    { id: '15', type: 'adverbioГДЕ', name: 'пря́мо', translation: 'пря́мо' },
    { id: '15', type: 'adverbioГДЕ', name: 'сни́зу', translation: 'сни́зу' },
];

const DropTypes = [
    { id: '1', type: 'adverbioКУДА', name: 'adverbioКУДА' },
    { id: '2', type: 'adverbioГДЕ', name: 'adverbioГДЕ' },
];

const Exercises = props => {
    const [dropCards] = useState(DropTypes);
    const [dragCards] = useState(DragTypes);
    const [droppedListNA, setDroppedListSingular] = useState([]);
    const [droppedListB, setDroppedListPlural] = useState([]);

    const DragCard = ({ item }) => {
        const [, drag] = useDrag({
            type: item.type,
            item: { ...item },
            collect: monitor => ({
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging()
            })
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {item.translation}
                </div>
            </div>);
    }

    const DisplayCard = ({ type }) => {
        const content = type === 'adverbioКУДА' ? (
            <div className={classes.displayCard}>КУДА́</div>
        ) : (
            <div className={classes.displayCard}>ГДЕ</div>
        );

        return content;
    }

    const DroppedCardsSingular = () => {
        const cards = droppedListNA.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DroppedCardsPlural = () => {
        const cards = droppedListB.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCard = ({ accepts: accept, item }) => {
        const [, drop] = useDrop(() => ({
            accept: item.type,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();
                const dropItem = monitor.getItem();

                if (didDrop) {
                    return;
                }

                if (dropItem.type === 'adverbioКУДА') {
                    if (!droppedListNA.some(({ name }) => name === dropItem.name)) {
                        setDroppedListSingular([...droppedListNA, dropItem]);
                    }
                } else {
                    if (!droppedListB.some(({ name }) => name === dropItem.name)) {
                        setDroppedListPlural([...droppedListB, dropItem]);
                    }
                }
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {item.type === 'adverbioКУДА' ?
                        <div>
                            <p>Preposição</p>
                            <DisplayCard type={item.type} />
                            <DroppedCardsSingular />
                        </div> :
                        <div>
                            <p>Preposição</p>
                            <DisplayCard type={item.type} />
                            <DroppedCardsPlural />
                        </div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort((a, b) => {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Faça distribuição de advérbios de localização."></ExercisesQuestions>
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards className={classes.dropCards} />
                </div>
            </div>
        </div>
    );
}

export default Exercises;