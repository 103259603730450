import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson7 from '../../../../../../Assets/Images/A1/Lesson7/image1lesson7.png';
import Image2Lesson7 from '../../../../../../Assets/Images/A1/Lesson7/image2lesson7.png';
import Image3Lesson7 from '../../../../../../Assets/Images/A1/Lesson7/image3lesson7.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>I СПРЯЖЕНИЕ: 1. Правильные глаголы</p>
                    <p>I CONJUGAÇÃO: 2. Verbos Irregulares</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="LiveVerbConjugation" src={Image1Lesson7} alt="live verb conjugation" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Verbos п<span className={classes.orangeHighlight}>е</span>ть e п<span className={classes.orangeHighlight}>и</span>ть seguem o mesmo paradigma de <span className={classes.orangeHighlight}>ЖИТЬ</span>, mas com uma diferença de alteração da vogal da raíz para <span className={classes.orangeHighlight}>o</span>, no caso de п<span className={classes.orangeHighlight}>е</span>ть (п<span className={classes.orangeHighlight}>о</span>ю́, п<span className={classes.orangeHighlight}>о</span>ёшь, п<span className={classes.orangeHighlight}>о</span>ёт, ...), e para <span className={classes.orangeHighlight}>ь</span>, no caso de п<span className={classes.orangeHighlight}>и</span>ть (п<span className={classes.orangeHighlight}>ь</span>ю, п<span className={classes.orangeHighlight}>ь</span>ёшь, п<span className={classes.orangeHighlight}>ь</span>ёт, ...)
                            </p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Alguns verbos, apesar de seguirem o paradigma de conjugação regular, sofrem alternâncias entre o infinitivo e a conjugação:
                            </p>
                            <p><b>рис<span className={classes.orangeHighlight}>ова́</span>ть:</b> я рис<span className={classes.orangeHighlight}>у́</span>ю...</p>
                            <p><b>и<span className={classes.orangeHighlight}>ск</span>а́ть:</b> я и<span className={classes.orangeHighlight}>щ</span>у́...</p>
                            <p><b>пи<span className={classes.orangeHighlight}>с</span>а́ть:</b> я пи<span className={classes.orangeHighlight}>ш</span>у́…</p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>НАЦИОНА́ЛЬНОСТЬ</p>
                    <p>Nacionalidade</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="SeveralCountriesWordDiction1" src={Image2Lesson7} alt="several countries word diction 1" width="300px" />
                            <img id="SeveralCountriesWordDiction2" src={Image3Lesson7} alt="several countries word diction 2" width="300px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <div className={classes.content1}>
                                <p>Алба́н<b><span className={classes.orangeHighlight}>ия</span></b> Анго́л<b><span className={classes.orangeHighlight}>а</span></b> Аргенти́н<b><span className={classes.orangeHighlight}>а</span></b> Голланд<b><span className={classes.orangeHighlight}>ия</span></b> Испа́н<b><span className={classes.orangeHighlight}>ия</span></b> Кана́д<b><span className={classes.orangeHighlight}>а</span></b> <del>Португа́л<b><span className={classes.orangeHighlight}>ия</span></b></del> Украи́н<b><span className={classes.orangeHighlight}>а</span></b> Швейца́р<b><span className={classes.orangeHighlight}>ия</span></b> Япо́н<b><span className={classes.orangeHighlight}>ия</span></b></p>
                                <p><font size="5"><span className={classes.greenHighlight}>&#9758;</span></font> <b>Герма́ния</b> -<b> не́м<span className={classes.orangeHighlight}>ец</span></b>
                                    не́м<b><span className={classes.orangeHighlight}>ка</span></b> не́м<b><span className={classes.orangeHighlight}>цы</span></b>
                                </p>
                            </div>
                            <div className={classes.content2}>
                                <p>
                                    <del>Австра́ли<b><span className={classes.orangeHighlight}>я</span></b></del> А́встри<b><span className={classes.orangeHighlight}>я</span></b> Бе́льги<b><span className={classes.orangeHighlight}>я</span></b> Гвинé<b><span className={classes.orangeHighlight}>я</span></b> Ла́тви<b><span className={classes.orangeHighlight}>я</span></b> Ли́ви<b><span className={classes.orangeHighlight}>я</span></b> Ма́льт<b><span className={classes.orangeHighlight}>а</span></b>
                                </p>
                            </div>
                            <div className={classes.content3}>
                                <p>
                                    Аме́рик<b><span className={classes.orangeHighlight}>а</span></b> А́фрик<b><span className={classes.orangeHighlight}>а</span></b> Ме́ксик<b><span className={classes.orangeHighlight}>а</span></b> <del>Мозамби́к</del>
                                </p>
                                <p>
                                    <font size="5"><span className={classes.greenHighlight}>&#9758;</span></font> <b>Ита́ли<span className={classes.orangeHighlight}>я</span></b> италь<b><span className={classes.orangeHighlight}>я́нец</span></b> италь<b><span className={classes.orangeHighlight}>я́нка</span></b> италь<b><span className={classes.orangeHighlight}>я́нцы</span></b>
                                </p>
                            </div>
                            <div className={classes.content4}>
                                <p>Бразил<b><span className={classes.orangeHighlight}>ия</span></b> <del>Коре́<b><span className={classes.orangeHighlight}>я</span></b></del> Кита́<b><span className={classes.orangeHighlight}>й</span></b></p>
                            </div>
                            <div className={classes.content6}>
                                <p>
                                    Да́<b>н<span className={classes.orangeHighlight}>ия</span></b> да<b>т<span className={classes.orangeHighlight}>ча́нин</span></b> да<b>т<span className={classes.orangeHighlight}>ча́нка</span></b> да<b>т<span className={classes.orangeHighlight}>ча́не</span></b>
                                </p>
                                <p>
                                    <font size="5"><span className={classes.greenHighlight}>&#9758;</span></font> <b>Росси́<span className={classes.orangeHighlight}>я</span>*</b> росси<b><span className={classes.orangeHighlight}>я́нин</span></b> росси<b><span className={classes.orangeHighlight}>я́нка</span></b> росси<b><span className={classes.orangeHighlight}>я́не</span></b>
                                </p>
                                <p>
                                    Grupo étnico: <b>Ру</b>сь: ру́сский ру́сская ру́сские
                                </p>
                            </div>
                            <div className={classes.content6}>
                                <p>
                                    <del>Румы́<b><span className={classes.orangeHighlight}>ния</span></b></del> Се́рб<b><span className={classes.orangeHighlight}>ия</span></b> Хорва́т<b><span className={classes.orangeHighlight}>ия</span></b>
                                </p>
                                <p>
                                    <b>Фин<span className={classes.orangeHighlight}>ля́ндия</span></b> финн фи́н<b><span className={classes.orangeHighlight}>ка</span></b> фи́н<b>н<span className={classes.orangeHighlight}>ы</span></b>
                                </p>
                                <p>
                                    <b>Ве́нгр<span className={classes.orangeHighlight}>ия</span></b> венгр венг<b><span className={classes.orangeHighlight}>е́рка</span></b> ве́нгр<b><span className={classes.orangeHighlight}>ы</span></b>
                                </p>
                                <p>
                                    <b>Шве́ц<span className={classes.orangeHighlight}>ия</span></b> шве<b>д</b> шве́<b>д<span className={classes.orangeHighlight}>ка</span></b> шве́<b>д<span className={classes.orangeHighlight}>ы</span></b>
                                </p>
                                <p>
                                    <b>Че́х<span className={classes.orangeHighlight}>ия</span></b> че<b>х</b> че́<b>ш<span className={classes.orangeHighlight}>ка</span></b> че́<b>х<span className={classes.orangeHighlight}>и</span></b>
                                </p>
                            </div>
                            <div className={classes.content7}>
                                <p>НАЦИОНА́ЛЬНОСТЬ = GRUPO ÉTNICO</p>
                                <p>ГРАЖДА́НСТВО = CIDADANIA</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Grammar;