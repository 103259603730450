import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>банки́р</b> banqueiro</p>
                        <p><b>библиоте́карь</b> bibliotecário</p>
                        <p><b>бухга́лтер</b> contabilista</p>
                        <p><b>вáшего</b> (Acus.) seu</p>
                        <p><b>весно́й</b> na primavera</p>
                        <p><b>врач</b> médico</p>
                        <p><b>всё</b> tudo</p>
                        <p><b>гид</b> guia turístico</p>
                        <p><b>гре́ческое</b> (adj) grego</p>
                        <p><b>дождь</b> chuva</p>
                        <p><b>ещё</b> mais</p>
                        <p><b>жизнь</b> vida</p>
                        <p><b>заме́тку</b> (Acus.) artigo/folheto</p>
                        <p><b>здоро́вье</b> saúde</p>
                        <p><b>зимо́й</b> no inverno</p>
                        <p><b>игру́шка</b> brinquedo</p>
                        <p><b>иностра́нный</b> (adj) estrangeiro</p>
                        <p><b>каки́е</b> quais</p>
                        <p><b>карти́ны</b> quadros</p>
                        <p><b>касси́р</b> empregado da caixa</p>
                        <p><b>кинóлог</b> cinólogo</p>
                        <p><b>кинорежисёр</b> realizador de cinema</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>лéтом</b> no verão</p>
                        <p><b>ле́то</b> verão</p>
                        <p><b>лётчик</b> piloto</p>
                        <p><b>мальчика</b> (Acus.) rapaz</p>
                        <p><b>мечта́ете</b>(v. мечта́ть) sonha/-am</p>
                        <p><b>мо́жет</b>(v. мочь) pode</p>
                        <p><b>мяч</b> bola</p>
                        <p><b>нау́ка</b> ciência</p>
                        <p><b>никто́</b> ninguém</p>
                        <p><b>но́вости</b> notícias</p>
                        <p><b>ня́ня</b> ama</p>
                        <p><b>(о) жи́зни</b> (acerca da) vida</p>
                        <p><b>(о) рабо́те</b> (acerca do) trabalho</p>
                        <p><b>(о) семье́</b> (acerca da) família</p>
                        <p><b>(о) соба́ках</b> (sobre) cães</p>
                        <p><b>(об) о́тпуске</b> (acerca) das férias</p>
                        <p><b>огоро́д</b> horta</p>
                        <p><b>означа́ет</b>(v. означа́ть) significa</p>
                        <p><b>о́сенью</b> no outono</p>
                        <p><b>о́тдых</b> lazer</p>
                        <p><b>о́тпуск</b> férias</p>
                        <p><b>офице́р</b> oficial</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>официа́нт</b> empregado de mesa</p>
                        <p><b>певе́ц</b> cantor</p>
                        <p><b>перево́дчик</b> tradutor</p>
                        <p><b>писа́тель</b> escritor</p>
                        <p><b>покупа́тель</b> comprador</p>
                        <p><b>полёт</b> voo</p>
                        <p><b>почтальо́н</b> carteiro</p>
                        <p><b>преподава́тель</b> professor de ensino superior</p>
                        <p><b>принёс</b>(v. принести́) trouxe</p>
                        <p><b>про</b> (preposição) sobre/acerca de</p>
                        <p><b>продаве́ц</b> vendedor</p>
                        <p><b>разгова́ривать</b> conversar</p>
                        <p><b>разгово́р</b> conversa</p>
                        <p><b>расска́зывает</b>(v. расска́зывать) conta</p>
                        <p><b>расска́зывать</b> contar</p>
                        <p><b>реце́пт</b> receita</p>
                        <p><b>слу́шаю</b>(v. слу́шать) ouço</p>
                        <p><b>строи́тель</b> construtor</p>
                        <p><b>сценари́ст</b> argumentista</p>
                        <p><b>тре́нер</b> treinador</p>
                        <p><b>хиру́рг</b> cirurgião</p>
                        <p><b>ци́фры</b> números</p>
                        <p><b>язы́к</b> língua</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;