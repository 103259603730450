import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'я учи́лся в шко́ле', name: 'я учи́лся в шко́ле', translation: '1) Когда́ мне́ бы́ло 8 лет' },
    { id: 'я учи́лся в университе́те', name: 'я учи́лся в университе́те', translation: '2) Когда́ мне́ бы́ло 21 год' },
    { id: 'я рабо́тал в аэропорту́', name: 'я рабо́тал в аэропорту́', translation: '3) Когда́ мне́ бы́ло 27 лет' },
    { id: 'я купи́л дом', name: 'я купи́л дом', translation: '4) Когда́ мне́ бы́ло 45 лет' },
    { id: 'я уже́ был на пе́нсии', name: 'я уже́ был на пе́нсии', translation: '5) Когда́ мне́ бы́ло 65 лет' },
    { id: 'я сейча́с мно́го путеше́ствую', name: 'я сейча́с мно́го путеше́ствую', translation: '6) Сейчас мне́ 70 лет' },
];

const VerbList = [
    { id: 'мне', name: 'мне', matcher: 'мне' },
    { id: 'ему', name: 'ему', matcher: 'ему' },
    { id: 'им', name: 'им', matcher: 'им' },
    { id: 'ей', name: 'ей', matcher: 'ей' },
    { id: 'тебе', name: 'тебе', matcher: 'тебе' },
    { id: 'вам', name: 'вам', matcher: 'вам' },
    { id: 'нам', name: 'нам', matcher: 'нам' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Preencha os espaços com pronomes pessoais na forma correta." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            а) Я родила́сь в 1980 году́. <input
                                id="еёInput"
                                name="еёInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> 36 лет.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            б) Джон роди́лся в 2010 году́. <input
                                id="ихInput"
                                name="ихInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> 6 лет.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            в) Ни́на и Макси́м родили́сь в 1991 году́. <input
                                id="МеняInput"
                                name="МеняInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> 25 лет.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            г) Моя́ ма́ма родила́сь в 1952 году́. <input
                                id="васInput"
                                name="васInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> 64 го́да.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            д) Ты роди́лся в 1995 году́? <input
                                id="TебяInput"
                                name="TебяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> 21 год?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            е) Вы родили́сь в 1977 году́? <input
                                id="егоInput"
                                name="егоInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> 39 лет?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            ё) Мы родили́сь в 1968 году́. <input
                                id="насInput"
                                name="насInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> 48 лет.
                        </div>
                    </div>
                </div>
                <ExercisesQuestions number="2" question="Associe as frases" />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;