import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'farmacia', name: 'farmacia', translation: 'Аптека' },
    { id: 'cafe', name: 'cafe', translation: 'Кафе' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);

    const DragCard = ({ type, translation }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {translation}
                </div>
            </div>);
    }

    const SpeechBallon = ({ type }) => {
        const content = type === 'farmacia' ? (
            <div className={classes.triangleObtuse}>
                <p>– Здра́вствуйте.</p>
                <p>– Здра́вствуйте. Есть аспири́н?</p>
                <p>– Есть.</p>
                <p>– Аспири́н, пожа́луйста.</p>
            </div>
        ) : (
            <div className={classes.triangleObtuse}>
                <p>– Здра́вствуйте!</p>
                <p>– Здра́вствуйте</p>
                <p>– Кóфе, пожа́луйста!</p>
                <p>– Пожа́луйста!</p>
                <p>– Спаси́бо.</p>
            </div>
        );

        return content;
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div ref={drop} className={classes.dropCard}>
                    {hasDropped ?
                        <div>
                            <SpeechBallon type={item.id} />
                            <DragCard key={item.id} type={item.id} translation={item.translation} />
                        </div> :
                        <SpeechBallon type={item.id} />
                    }
                </div>
            </div>);
    }

    const DragCards = () => {
        const cards = dropCards.map(item => {
            return <DragCard key={item.id} type={item.id} translation={item.translation} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const sortedCards = dropCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Leia os diálogos e identifique as situações arrastando as palavras para o balão correspondente" />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;