import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'роди́телями', name: 'роди́телями', matcher: 'роди́телями' },
    { id: 'братьями', name: 'братьями', matcher: 'братьями' },
    { id: 'эмоциями', name: 'эмоциями', matcher: 'эмоциями' },
    { id: 'детьми', name: 'детьми', matcher: 'детьми' },
    { id: 'деньгами', name: 'деньгами', matcher: 'деньгами' },
    { id: 'грибами', name: 'грибами', matcher: 'грибами' },
    { id: 'авторами', name: 'авторами', matcher: 'авторами' },
    { id: 'руками', name: 'руками', matcher: 'руками' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Complete as frases, escrevendo a palavra entre parêntesis na forma correta:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            1. Мари́я пое́хала на пляж с <input
                                id="input1"
                                name="input 1"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />. (роди́тели)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            2. Ме́жду <input
                                id="input2"
                                name="input 2"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />. (бра́тья)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            3. Как управлять <input
                                id="input3"
                                name="input 3"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />? (эмо́ции)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            4. На у́треннике ма́тери танцева́ли с <input
                                id="input4"
                                name="input 4"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />. (де́ти)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            5. Он потеря́л кошелёк с <input
                                id="input5"
                                name="input 5"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />. (де́ньги)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            6. Я обожа́ю пи́ццу с <input
                                id="input6"
                                name="input 6"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />. (грибы́)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            7. Изда́тель не мо́жет связа́ться с <input
                                id="input7"
                                name="input 7"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />. (а́вторы)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            8. В А́зии едя́т рис <input
                                id="input7"
                                name="input 7"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            />. (ру́ки)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;