import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import video_aula_3 from './Video/video_aula_3.mp4';

const VerbList = [
    { id: '3', name: '3', matcher: '3' },
    { id: '2', name: '2', matcher: '2' },
    { id: '1', name: '1', matcher: '1' },
    { id: '4', name: '4', matcher: '4' },
    { id: '7', name: '7', matcher: '7' },
    { id: '5', name: '5', matcher: '5' },
    { id: '8', name: '8', matcher: '8' },
    { id: '6', name: '6', matcher: '6' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
            event.currentTarget.style.background = 'white';
        } else {
            event.currentTarget.style.color = 'red';
            event.currentTarget.style.background = 'white';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Посмотрите сказку “Курочка Ряба” и расставьте предложения по порядку." />
                <a className={classes.downloadVideoLink} href={video_aula_3} download="video_aula_3">Bидео Урок 3 - Kurochka Ryaba</a>
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input1"
                                name="input1"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[0].matcher)} /> Дед бил, бил - не разбил. Баба била, била - не разбила.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input2"
                                name="input2"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[1].matcher)} /> Снесла курочка яичко: яичко непростое, а золотое.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input3"
                                name="input3"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[2].matcher)} /> Жили себе дед да баба, и была у них курочка Ряба.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input4"
                                name="input4"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[3].matcher)} /> Мышка бежала, хвостиком махнула.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input5"
                                name="input5"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[4].matcher)} /> Не плачь, дед, не плачь, баба!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input6"
                                name="input6"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[5].matcher)} /> Яичко упало и разбилось.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input7"
                                name="input7"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[6].matcher)} /> Я снесу вам яичко другое. Не золотое – простое.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <input
                                id="input8"
                                name="input8"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[7].matcher)} /> Дед плачет, баба плачет, а курочка кудахчет:
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;