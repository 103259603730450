import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson18 from '../../../../../../Assets/Images/A1/Lesson18/image1lesson18.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРИЛАГАТЕЛЬНОЕ(Им. п.)</p>
                    <p>Adjetivo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="Image1Lesson18" src={Image1Lesson18} alt="adjectives table" width="500px" />
                </div>
                <div className={classes.item2}>
                    <p>
                        <b><span className={classes.orangeHighlight}>ПРИЛАГАТЕЛЬНОЕ + СУЩЕСТВИТЕЛЬНОЕ</span></b>
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Quando o adjetivo vem antes do nome é um adjetivo qualificativo:
                    </p>
                    <p>
                        <i>В <b>про́шлом году</b> была́ <b>холо́дная зима́</b>. <b>Хорóший сыр</b> продаю́т в магази́не на углу́.</i>
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Quando o adjetivo vem depois do nome é um adjetivo predicativo:
                    </p>
                    <p>
                        <b>Зима́</b> (-) <b>холо́дная</b>. Э́тот <b><u>сыр</u></b> (-) <b>хоро́ший</b>, а тот (-) <b>плохо́й</b>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Grammar;