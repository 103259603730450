import React from 'react';
import MainHeader from '../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../CenterColumn/CenterColumn.css';
import * as Constants from '../../../../Constants/Constants';
import Modules from '../../Modules/Modules';

const Lesson26 = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} />
            <Modules title={Constants.levels} route1={props.route1} route2={props.route2} route3={props.route3} />
        </div>
    );
}

export default Lesson26;