import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson14 from '../../../../../../Assets/Images/A1/Lesson14/image1lesson14.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ЦИ́ФРЫ 1-20</p>
                    <p>Números 1-20</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <div className={classes.gridItem1Item1}>
                                <p>
                                    <b><span className={classes.orangeHighlight}> NÚMERO CARDINAL + NOME </span></b>
                                </p>
                                <p>
                                    <b><span className={classes.orangeHighlight}> 1</span></b> + nome ←Nominativo sg.
                                </p>
                                <p>
                                    <b><span className={classes.orangeHighlight}>2-4</span></b> + nome ← Genitivo sg.
                                </p>
                                <p>
                                    <b><span className={classes.orangeHighlight}>5-…</span></b> + nome ← Genitivo pl.
                                </p>
                            </div>
                            <div className={classes.gridItem1Item2}>
                                <p>
                                    <b><span className={classes.orangeHighlight}> ЧАСЫ́ И МИНУ́ТЫ </span></b>
                                </p>
                                <p>
                                    01:01 оди́н<b><span className={classes.orangeHighlight}></span></b> час<b><span className={classes.orangeHighlight}></span></b> одн<b><span className={classes.orangeHighlight}>а́</span></b> мину́т<b><span className={classes.orangeHighlight}>а</span></b>
                                </p>
                                <p>
                                    02:02 два час<b><span className={classes.orangeHighlight}>á</span></b> две мину́т<b><span className={classes.orangeHighlight}>ы</span></b>
                                </p>
                                <p>
                                    05:05 пять час<b><span className={classes.orangeHighlight}>о́в</span></b> пять мин<b><span className={classes.orangeHighlight}>ýт</span></b>
                                </p>
                            </div>
                            <div className={classes.gridItem1Item3}>
                                <p>
                                    двена́дцать часо́в ро́вно = <b>по́лдень</b>
                                </p>
                                <p>
                                    двáдцать четы́ре ча́са ро́вно = <b>по́лночь</b>
                                </p>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b><span className={classes.orangeHighlight}>1-10</span></b></p>
                            <p>1 оди́н/одна́/одно́</p>
                            <p>2 два /две</p>
                            <p>3 три</p>
                            <p>4 четы́ре</p>
                            <p>5 пять</p>
                            <p>6 шесть</p>
                            <p>7 семь</p>
                            <p>8 во́семь</p>
                            <p>9 де́вять</p>
                            <p>10 де́сять</p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p><b><span className={classes.orangeHighlight}>11-20</span></b></p>
                            <p>11 оди́н<span className={classes.orangeHighlight}>надцать</span></p>
                            <p>12 две<span className={classes.orangeHighlight}>на́дцать</span></p>
                            <p>13 три<span className={classes.orangeHighlight}>на́дцать</span></p>
                            <p>14 четы́р<span className={classes.orangeHighlight}>надцать</span></p>
                            <p>15 пят<span className={classes.orangeHighlight}>на́дцать</span></p>
                            <p>16 шест<span className={classes.orangeHighlight}>на́дцать</span></p>
                            <p>17 сем<span className={classes.orangeHighlight}>на́дцать</span></p>
                            <p>18 восем<span className={classes.orangeHighlight}>на́дцать</span></p>
                            <p>19 девят<span className={classes.orangeHighlight}>на́дцать</span></p>
                            <p>20 два́<span className={classes.orangeHighlight}>дцать</span></p>
                        </div>
                    </div>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <b><span className={classes.orangeHighlight}>ВО СКО́ЛЬКО? </span></b> (A que horas?)
                            </p>
                            <p>
                                В + <b><span className={classes.orangeHighlight}>número ( Вин. п.)</span></b> + hora(s)
                            </p>
                            <p>
                                В <b><span className={classes.orangeHighlight}>ПЯТЬ</span></b> ЧАСО́В
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Para indicar QUE HORAS SÃO, em russo, usa-se apenas o número no nominativo:
                            </p>
                            <p>"Сейчас пять часов."</p>
                            <p>(Agora, são cinco horas)</p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Para indicar A QUE HORAS horas decorre alguma coisa, em Russo, usa-se o número no Caso Acusativo, precedido pela preposição B:
                            </p>
                            <p>"B пять часов"</p>
                            <p>(Às cinco horas)</p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> As desinências do Caso Acusativo dos numerais são iguais às do Caso Nominativo (à excepção de одна́ (nom.)→ одну́ (acus.)).
                            </p>
                        </div>
                    </div>
                </div>

                <GrammarSubtitle>
                    <p>ОТКРЫВА́ТЬСЯ/ЗАКРЫВА́ТЬСЯ</p>
                    <p>abrir/fechar</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <img id="OpenAndCloseVerb" src={Image1Lesson14} alt="open and close verb" width="450px" />
                </div>
            </div>
        </div>
    );
}

export default Grammar;