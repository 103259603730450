import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'нравится', name: 'нравится', matcher: 'нравится' },
    { id: 'нравятся', name: 'нравятся', matcher: 'нравятся' },
    { id: 'нравятся', name: 'нравятся', matcher: 'нравятся' },
    { id: 'нравятся', name: 'нравятся', matcher: 'нравятся' },
    { id: 'нравится', name: 'нравится', matcher: 'нравится' },
    { id: 'нравятся', name: 'нравятся', matcher: 'нравятся' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    const exerciseQuestion = (
        <p>Preencha os espaços com as palavras <i>нравится</i> ou <i>нравятся</i></p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            а) Мне <input
                                id="голубоeInput"
                                name="голубоeInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> “Весна́ свяще́нная” Страви́нского.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            б) Вам <input
                                id="зелёнаяInput"
                                name="зелёнаяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> фи́льмы Тарко́вского?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            в) Ка́рле <input
                                id="белыеInput"
                                name="белыеInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> ру́сские наро́дные пе́сни.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            г) Де́тям <input
                                id="чёрнаяInput"
                                name="чёрнаяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> сла́дости.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            д) Нам <input
                                id="синийInput"
                                name="синийInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> путеше́ствовать.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            е) Тебе́ <input
                                id="краснаяInput"
                                name="краснаяInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> ле́кции по литерату́ре?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;