import React from 'react';
import classes from './MainContent.css';
import { Link } from 'react-router-dom';
import * as Constants from '../Constants/Constants';
import centerColumn from '../CenterColumn/CenterColumn.css';
import MainHeader from '../Header/MainHeader/MainHeader';

const mainContent = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} />
            <div className={classes.MainContent}>
                <div>
                    <Link to="/AboutThisProject">
                        {Constants.aboutThisProject}
                    </Link>
                </div>
                <div>
                    <Link to="/Levels">
                        {Constants.levels}
                    </Link>
                </div>
                <div>
                    <Link to="/Team">
                        {Constants.team}
                    </Link>
                </div>
                <div>
                    <Link to="/Contacts">
                        {Constants.contacts}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default mainContent;