import React from 'react';
import centerColumn from '../../../../CenterColumn/CenterColumn.css'
import MainHeader from '../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Repetition.css';

const VerbList = [
    { id: 'Павел', name: 'Павел', matcher: 'Павел' },
    { id: '36 лет', name: '36 лет', matcher: '36 лет' },
    { id: 'русский', name: 'русский', matcher: 'русский' },
    { id: 'Санкт-Петербург', name: 'Санкт-Петербург', matcher: 'Санкт-Петербург' },
    { id: 'Португалия', name: 'Португалия', matcher: 'Португалия' },
    { id: 'инженер', name: 'инженер', matcher: 'инженер' },
    { id: 'русский  и португальский', name: 'русский  и португальский', matcher: 'русский  и португальский' },
    { id: 'женат', name: 'женат', matcher: 'женат' },
];

const Repetition = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Leia o texto e complete a informação do formulário:" />
                <p>Меня́ зову́т Пáвел.</p>
                <p>Я ру́сский. Я роди́лся в Санкт-Петербу́рге. Мне 36 лет. Живу́ в Португа́лии уже́ 12 лет. Рабо́таю инжене́ром. Я жена́т. Моя́ женá – португáлка. У меня́ два сы́на. Дóма мы говори́м по-рýсски и по-португáльски. Ра́ньше мы жи́ли в Алмáде, а сейча́с живём в Лиссабо́не.</p>
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>И́МЯ: <input
                                id="input1"
                                name="input 1"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />
                            </p>
                            <p>
                                ВО́ЗРАСТ: <input
                                    id="input2"
                                    name="input 2"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[1].matcher)}
                                />
                            </p>
                            <p>
                                НАЦИОНА́ЛЬНОСТЬ: <input
                                    id="input3"
                                    name="input 3"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[2].matcher)}
                                />
                            </p>
                            <p>
                                МЕ́СТО РОЖДЕ́НИЯ (го́род): <input
                                    id="input4"
                                    name="input 4"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[3].matcher)}
                                />
                            </p>
                            <p>
                                МЕ́СТО ЖИ́ТЕЛЬСТВА (го́род): <input
                                    id="input5"
                                    name="input 5"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[4].matcher)}
                                />
                            </p>
                            <p>
                                ПРОФЕ́ССИЯ: <input
                                    id="input6"
                                    name="input 6"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[5].matcher)}
                                />
                            </p>
                            <p>
                                ЯЗЫКИ́: <input
                                    id="input7"
                                    name="input 7"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[6].matcher)}
                                />
                            </p>
                            <p>
                                СЕМЕЙНОЕ ПОЛОЖЕНИЕ: <input
                                    id="input8"
                                    name="input 8"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[7].matcher)}
                                />
                            </p>
                        </div>
                    </div>
                </div>
                <ExercisesQuestions number="2" question="Assista ao vídeo e tente compreender o que é dito:" />
                <p>https://www.youtube.com/watch?v=1ynM7KZOlSI</p>
            </div>
        </div>
    );
}

export default Repetition;