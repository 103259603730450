import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson10/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson10/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson10/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson10/image4grammar.png';
import Image5Grammar from '../../../../../../Assets/Images/A2/Lesson10/image5grammar.png';
import Image6Grammar from '../../../../../../Assets/Images/A2/Lesson10/image6grammar.png';
import Image7Grammar from '../../../../../../Assets/Images/A2/Lesson10/image7grammar.png';
import Image8Grammar from '../../../../../../Assets/Images/A2/Lesson10/image8grammar.png';
import Image9Grammar from '../../../../../../Assets/Images/A2/Lesson10/image9grammar.png';
import Image10Grammar from '../../../../../../Assets/Images/A2/Lesson10/image10grammar.png';
import Image11Grammar from '../../../../../../Assets/Images/A2/Lesson10/image11grammar.png';
import Image12Grammar from '../../../../../../Assets/Images/A2/Lesson10/image12grammar.png';
import Image13Grammar from '../../../../../../Assets/Images/A2/Lesson10/image13grammar.png';
import Image14Grammar from '../../../../../../Assets/Images/A2/Lesson10/image14grammar.png';
import Image15Grammar from '../../../../../../Assets/Images/A2/Lesson10/image15grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>РОДИТЕЛЬНЫЙ ПАДЕЖ С ПРЕДЛОГАМИ</p>
                    <p>Genitivo regido por preposições</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> Para LOCALIZAÇÃO NO ESPAÇO:</span></b></p>
                    </div>
                    <div className={classes.item1_2}>
                        <img id="image1Grammar" src={Image1Grammar} alt="1" width="200px" />
                        <img id="image2Grammar" src={Image2Grammar} alt="2" width="400px" />
                    </div>
                    <div className={classes.item1_3}>
                        <img id="image3Grammar" src={Image3Grammar} alt="3" width="200px" />
                        <img id="image4Grammar" src={Image4Grammar} alt="4" width="100px" />
                    </div>
                    <div className={classes.item1_4}>
                        <p>Университе́т нахо́дится <b>о́коло апте́ки</b>.</p>
                        <p><i>A universidade fica <b>perto da farmácia</b>.</i></p>
                    </div>
                </div>
                <div className={classes.item2}>
                    <div className={classes.item2_1}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> Para LOCALIZAÇÃO NO TEMPO:</span></b></p>
                    </div>
                    <div className={classes.item2_2}>
                        <img id="image5Grammar" src={Image5Grammar} alt="5" width="200px" />
                        <img id="image6Grammar" src={Image6Grammar} alt="6" width="150px" />
                    </div>
                    <div className={classes.item2_3}>
                        <img id="image7Grammar" src={Image7Grammar} alt="7" width="450px" />
                    </div>
                    <div className={classes.item2_4}>
                        <p>До одн<b>ого́</b> год<b>а</b>...</p>
                        <p><i>Até um ano...</i></p>
                    </div>
                    <div className={classes.item2_5}>
                        <img id="image8Grammar" src={Image8Grammar} alt="8" width="450px" />
                    </div>
                </div>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> Numa EXPLICAÇÃO – RAZÃO/CAUSA ou FINALIDADE:</span></b></p>
                    </div>
                    <div className={classes.item3_2}>
                        <div className={classes.item3_2_1}>
                            <img id="image9Grammar" src={Image9Grammar} alt="9" width="150px" />
                            <img id="image12Grammar" src={Image12Grammar} alt="12" width="70px" />
                            <div>
                                <p><b>И́з-за твоего́ опозда́ния</b>, мы пропусти́ли нача́ло конце́рта.</p>
                                <p><b>Por causa do teu atraso</b>, perdemos o início do concerto.</p>
                            </div>
                        </div>
                        <div className={classes.item3_2_2}>
                            <div>
                                <img id="image10Grammar" src={Image10Grammar} alt="10" width="150px" height="150px" />
                                <img id="image11Grammar" src={Image11Grammar} alt="11" width="150px" height="150px" />
                            </div>
                            <img id="image13Grammar" src={Image13Grammar} alt="13" width="70px" />
                            <div className={classes.item3_2_2_1}>
                                <div>
                                    <p><b>Для нас</b> э́то са́мое гла́вное.</p>
                                    <p><b>Para nós</b>, isto é o mais importante.</p>
                                </div>
                                <div>
                                    <p>Ну́жно купи́ть о́вощи <b>для су́па</b>.</p>
                                    <p>É preciso comprar legumes <b>para a sopa</b>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.item4}>
                    <div className={classes.item4_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b> Para indicar <b>MODO (negativa):</b></span></p>
                    </div>
                    <div className={classes.item4_2}>
                        <img id="image14Grammar" src={Image14Grammar} alt="14" width="200px" />
                        <img id="image15Grammar" src={Image15Grammar} alt="15" width="70px" />
                    </div>
                    <div className={classes.item4_3}>
                        <p>Э́тот о́тпуск мы провели́ <b>без дете́й</b>.</p>
                        <p><i>Estas férias, passámos <b>sem as crianças</b>.</i></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;