import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: true },
    { id: 3, isCorrect: true },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: true },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: true },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: true },
    { id: 16, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    const exerciseQuestion = (
        <p>Escolha a preposição certa: <span className={classes.orangeHighlight}>к</span> ou <span className={classes.orangeHighlight}>по</span></p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            1. Он потеря́л интере́с <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    к
                                </button>
                            </span> учёбе.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            2. Леони́д специали́ст <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    к
                                </button>
                            </span> матема́тике.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            3. Ли́за не рабо́тает <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    к
                                </button>
                            </span> понеде́льникам и по вто́рникам.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            4. Я сего́дня идý <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    к
                                </button>
                            </span> врачý. У меня́ консульта́ция.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            5. Студе́нты хорошо́ подгото́вились <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    к
                                </button>
                            </span> к экза́мену.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            6. Ве́ра купи́ла уче́бники <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    к
                                </button>
                            </span> ру́сскому языку́ в интерне́т-магази́не.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            7. Пить во́ду <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    к
                                </button>
                            </span> у́трам – э́то хоро́шая привы́чка!
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            8. Не подходи́те <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    по
                                </button>/<button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    к
                                </button>
                            </span> (живо́тные)!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;