import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';
import Image1exercise from '../../../../../../Assets/Images/A2/Lesson27/image1exercise.png';
import Image2exercise from '../../../../../../Assets/Images/A2/Lesson27/image2exercise.png';
import Image3exercise from '../../../../../../Assets/Images/A2/Lesson27/image3exercise.png';
import Image4exercise from '../../../../../../Assets/Images/A2/Lesson27/image4exercise.png';
import Image5exercise from '../../../../../../Assets/Images/A2/Lesson27/image5exercise.png';
import Image6exercise from '../../../../../../Assets/Images/A2/Lesson27/image6exercise.png';
import Image7exercise from '../../../../../../Assets/Images/A2/Lesson27/image7exercise.png';

const ItemTypes = [
    { id: '1', name: 'Он пошёл по доро́ге.', image: Image4exercise },
    { id: '2', name: 'Он пришёл домо́й.', image: Image3exercise },
    { id: '3', name: 'Он вошёл в дом.', image: Image7exercise },
    { id: '4', name: 'Он вы́шел из до́ма.', image: Image2exercise },
    { id: '5', name: 'Он перешёл доро́гу.', image: Image6exercise },
    { id: '6', name: 'Он обошёл дом.', image: Image1exercise },
    { id: '7', name: 'Он прошёл ми́мо до́ма.', image: Image5exercise },
];


const Exercises = props => {
    const [dropCards, setDropCards] = useState(ItemTypes);
    const [dragCards, setDragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const handleRemoveItem = id => {
        setDragCards(dragCards.filter(item => item.id !== id));
        setDropCards(dropCards.filter(item => item.id !== id));
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const canDrop = monitor.canDrop();
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }

                if (canDrop) {
                    handleRemoveItem(item.id);
                }

                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <img id={item.id} src={item.image} alt={item.name} height="100px" width="150px" type={item.id} />
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <img id={item.id} src={item.image} alt={item.name} height="100px" width="150px" type={item.id} />
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const cards = [].concat(dragCards)
            .sort((a, b) => a.itemM > b.itemM ? 1 : -1)
            .map(item => {
                return <DragCard key={item.id} type={item.id} name={item.name} />
            });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Associe as frases aos diagramas:" />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;