import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>алма́з</b> diamante</p>
                        <p><b>ба́бушка</b> avó</p>
                        <p><b>бельё</b> roupa interior/de cama</p>
                        <p><b>брат</b> irmão</p>
                        <p><b>ваш</b> seu</p>
                        <p><b>вáша</b> sua</p>
                        <p><b>вáше</b> seu (neutro)</p>
                        <p><b>вáши</b> seus/suas</p>
                        <p><b>внук</b> neto</p>
                        <p><b>внýчка</b> neta</p>
                        <p><b>враг</b> inimigo</p>
                        <p><b>вре́мя</b> tempo/ hora</p>
                        <p><b>гóре</b> tristeza</p>
                        <p><b>глаз</b> olho</p>
                        <p><b>да́ма</b> senhora</p>
                        <p><b>де́душка</b> avô</p>
                        <p><b>де́тство</b> infância</p>
                        <p><b>доро́га</b> caminho</p>
                        <p><b>дочь</b> filha</p>
                        <p><b>друг</b> amigo</p>
                        <p><b>дуб</b> carvalho</p>
                        <p><b>дя́дя</b> tio</p>
                        <p><b>его</b> dele</p>
                        <p><b>её</b> dela</p>
                        <p><b>жена́</b> esposa</p>
                        <p><b>женский</b> feminino</p>
                        <p><b>зе́ркало</b> espelho</p>
                        <p><b>зуб</b> dente</p>
                        <p><b>и</b> e</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>и́мя</b> nome</p>
                        <p><b>их</b> deles/ delas</p>
                        <p><b>кино́</b> 1. filme 2. cinema</p>
                        <p><b>ключ</b> chave</p>
                        <p><b>кни́га</b> livro</p>
                        <p><b>кот</b> gato</p>
                        <p><b>край</b> beira</p>
                        <p><b>ла́мпа</b> lâmpada</p>
                        <p><b>любо́вь</b> amor</p>
                        <p><b>мла́дшая</b> mais nova</p>
                        <p><b>моё</b> meu (neutro)</p>
                        <p><b>мои́</b> meus / minhas</p>
                        <p><b>мой</b> meu</p>
                        <p><b>мо́ре</b> mar</p>
                        <p><b>моя</b> minha</p>
                        <p><b>муж</b> marido</p>
                        <p><b>мужской</b> masculino</p>
                        <p><b>мучи́тель</b> atormentador</p>
                        <p><b>мы</b> nós</p>
                        <p><b>наш</b> nosso</p>
                        <p><b>нáша</b> nossa</p>
                        <p><b>нáше</b> nosso (neutro)</p>
                        <p><b>нáши</b> nossos / nossas</p>
                        <p><b>ночь</b> noite</p>
                        <p><b>о́зеро</b> lago</p>
                        <p><b>оконча́ния</b> desinências</p>
                        <p><b>оте́ц</b> pai</p>
                        <p><b>пóле</b> campo</p>
                        <p><b>па́па</b> pai / papá</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пле́мя</b> tribo</p>
                        <p><b>племя́нник</b> sobrinho</p>
                        <p><b>племя́нница</b> sobrinha</p>
                        <p><b>портфе́ль</b> pasta</p>
                        <p><b>Почему́?</b> Por quê?</p>
                        <p><b>праба́бушка</b> bisavó</p>
                        <p><b>пра́внук</b> bisneto</p>
                        <p><b>пра́внучка</b> bisneta</p>
                        <p><b>праде́душка</b> bisavô</p>
                        <p><b>рай</b> paraíso</p>
                        <p><b>роль</b> 1. Papel (interpretação) 2. função</p>
                        <p><b>рот</b> boca</p>
                        <p><b>ружьё</b> espingarda</p>
                        <p><b>семья́</b> família</p>
                        <p><b>сестра́</b> irmã</p>
                        <p><b>скамья́</b> banco de jardim</p>
                        <p><b>слова́рь</b> dicionário</p>
                        <p><b>соль</b> sal</p>
                        <p><b>сре́дний</b> neutro</p>
                        <p><b>ста́ршая</b> mais velha</p>
                        <p><b>сын</b> filho</p>
                        <p><b>твоё</b> teu (neutro)</p>
                        <p><b>твои́</b> teus / tuas</p>
                        <p><b>твой</b> teu</p>
                        <p><b>твоя́</b> tua</p>
                        <p><b>тетра́дь</b> caderno</p>
                        <p><b>тётя</b> tia</p>
                        <p><b>учи́тель</b> professor escolar</p>
                        <p><b>я</b> eu</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;