import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'этого', name: 'этого', matcher: 'этого' },
    { id: 'твоеи', name: 'твоеи', matcher: 'твоеи' },
    { id: 'твоего', name: 'твоего', matcher: 'твоего' },
    { id: 'моеи', name: 'моеи', matcher: 'моеи' },
    { id: 'моих', name: 'моих', matcher: 'моих' },
    { id: 'нашего', name: 'нашего', matcher: 'нашего' },
    { id: 'твоих', name: 'твоих', matcher: 'твоих' },
    { id: 'ваших', name: 'ваших', matcher: 'ваших' },
    { id: 'тех', name: 'тех', matcher: 'тех' },
    { id: 'той', name: 'той', matcher: 'той' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Complete com os possessivos e demonstrativos no caso certo:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>1.</b> Из-за <input
                                id="э́тотInput"
                                name="э́тотInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> (э́тот) дру́га мы опозда́ли на конце́рт.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>2.</b> Без <input
                                id="твоя́Input"
                                name="твоя́Input"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> (твоя́) по́мощи я не смогу́ найти́ лу́чшую доро́гу.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>3.</b> Э́тот пода́рок для <input
                                id="ствойInput"
                                name="твойInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> (твой) отца́, а тот для <input
                                id="моя́Input"
                                name="моя́Input"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> (моя́) сестры́.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx}>
                        <div className={classes.inputWrapper}>
                            <b>4.</b> Я получи́л приглаше́ние от <input
                                id="мои́ьInput"
                                name="мои́Input"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (мои́) друзе́й.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>5.</b> Музе́й нахо́дится о́коло <input
                                id="нашInput"
                                name="нашInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> (наш) до́ма.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>6.</b> Я нашёл игру́шку <input
                                id="твои́Input"
                                name="твои́Input"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> (твои́) дете́й.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>7.</b> У нас ещё нет результа́тов <input
                                id="вашInput"
                                name="вашInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> (ваш) экза́менов.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>8.</b> Без <input
                                id="теInput"
                                name="теInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> (те) де́нег нам бу́дет тяжело́.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>9.</b> Кро́ме <input
                                id="таInput"
                                name="таInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            /> (та) у́лицы, я ничего́ в го́роде не зна́ю.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;