import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson16 from '../../../../../../Assets/Images/A1/Lesson16/image1lesson16.png';
import Image2Lesson16 from '../../../../../../Assets/Images/A1/Lesson16/image2lesson16.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>Глаго́лы движе́ния ИДТИ́, Е́ХАТЬ</p>
                    <p>Verbos de movimento</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Os verbos <b><span className={classes.orangeHighlight}>ИДТИ́ </span></b> e <b><span className={classes.orangeHighlight}>Е́ХАТЬ</span></b> têm significado semelhante. A diferença consiste no meio por que é realizado o movimento. O verbo <b><span className={classes.orangeHighlight}>ИДТИ́ </span></b> significa “ir a pé” e <b><span className={classes.orangeHighlight}>Е́ХАТЬ</span></b> “ir de algum transporte”. Este último pode ser traduzido por “viajar”.
                    </p>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="GoVerb" src={Image1Lesson16} alt="go verb" width="250px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="RidingVerb" src={Image2Lesson16} alt="riding verb" width="250px" />
                        </div>
                    </div>
                </div>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Verbos <b><span className={classes.orangeHighlight}>ЕДИ́ТЕ</span></b> e <b><span className={classes.orangeHighlight}>Е́ДЕТЕ</span></b> têm pronuncia muito parecida, com a diferença na sílaba tónica. <b><span className={classes.orangeHighlight}>! едИ́те</span></b> é do v. <i><b>есть</b></i> (comer) e <b><span className={classes.orangeHighlight}>Е́дете</span></b> é do v. <i><b>е́хать</b></i>
                    </p>
                </div>
                <GrammarSubtitle>
                    <p>КУДА́ vs. ГДЕ</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <b><span className={classes.orangeHighlight}>КУДА́ </span></b>(para onde) + Винительный падеж <b>идти́ <span className={classes.orangeHighlight}>в теа́тр</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>ГДЕ</span></b> (onde) + Предложный падеж <b>быть <span className={classes.orangeHighlight}>в теа́тре</span></b>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <div className={classes.centerAlignement}>
                                <p>
                                    <b><span className={classes.orangeHighlight}>В</span> <i>vs.</i> <span className={classes.orangeHighlight}>НА</span></b>
                                </p>
                            </div>
                            <p><b>Где е́хать?</b> <b><span className={classes.orangeHighlight}>В</span></b> авто́бусе.</p>
                            <p><b>Как/На чём е́хать?</b> <b><span className={classes.orangeHighlight}>НА</span></b> авто́бусе.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;