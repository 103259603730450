import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>благодари́м</b> agradecemos</p>
                        <p><b>благодари́ть за</b> agradecer por…</p>
                        <p><b>Бу́дьте здорóвы!</b> 1.As melhoras! 2. Saúde</p>
                        <p><b>(в) воскресе́нье</b> (no) domingo</p>
                        <p><b>(в) понеде́льник</b> (na) segunda-feira</p>
                        <p><b>ве́чером</b> ao fim do dia/ à noite</p>
                        <p><b>воскресе́нье</b> domingo</p>
                        <p><b>вто́рник</b> terça-feira</p>
                        <p><b>гóсти</b> visitas</p>
                        <p><b>де́вочку</b> (Acus.) menina</p>
                        <p><b>день неде́ли</b> (Gen.) dias de semana</p>
                        <p><b>днём</b> de tarde</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>имени́нник</b> aniversariante</p>
                        <p><b>ка́ждый</b> cada</p>
                        <p><b>личные местоиме́ния</b> pronomes pessoais</p>
                        <p><b>морко́вь</b> cenoura</p>
                        <p><b>но́чью</b> à noite</p>
                        <p><b>пацие́нт</b> paciente</p>
                        <p><b>пи́во</b> cerveja</p>
                        <p><b>по́мощь</b> ajuda</p>
                        <p><b>понеде́льник</b>segunda-feira</p>
                        <p><b>пре́мия</b> prémio</p>
                        <p><b>пя́тница</b> sexta-feira</p>
                        <p><b>рабо́тник</b> trabalhador</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>свида́ние</b> encontro</p>
                        <p><b>сериа́л</b> 1. série 2. novela</p>
                        <p><b>собра́ние</b> reunião</p>
                        <p><b>спаси́бо за</b> obrigado/a por…</p>
                        <p><b>спаси́бо за поку́пку</b> obrigado/a pela (sua) compra</p>
                        <p><b>среда́</b> quarta-feira</p>
                        <p><b>суббо́та</b> sábado</p>
                        <p><b>у́тром</b> de manhã</p>
                        <p><b>цветы́</b> flores</p>
                        <p><b>четве́рг</b> quinta-feira</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;