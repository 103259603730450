import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson26/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson26/image2grammar.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПОВЕЛИТЕЛЬНОЕ НАКЛОНЕНИЕ</p>
                    <p>Imperativo II</p>
                    <p>ПУСТЬ (ПУСКА́Й)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Quando o Imperativo diz respeito a uma terceira pessoa, usa-se a seguinte estrutura:</p>
                    </div>
                    <div className={classes.item1_2}>
                        <p><i>Пусть <b><span className={classes.orangeHighlight}>он</span></b> меня́ <b><span className={classes.orangeHighlight}>ждёт</span></b><sup>Presente: НСВ</sup>у метро́.</i> (Ele que me espere junto ao metro)</p>
                        <p><i>Пусть <b><span className={classes.orangeHighlight}>он</span></b> меня́ <b><span className={classes.orangeHighlight}>подождёт</span></b><sup>Futuro: СВ</sup>у метро́.</i> (Ele que me vá esperar junto ao metro)</p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ПОВЕЛИТЕЛЬНОЕ НАКЛОНЕНИЕ</p>
                    <p>Imperativo III</p>
                    <p>ДАВÁЙ(ТЕ)</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.item2_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Quando o imperativo inclui a 1ª pessoa, usam-se as seguintes estruturas:</p>
                    </div>
                    <div className={classes.item2_2}>
                        <p><b>1. <span className={classes.orangeHighlight}>ДАВА́Й(ТЕ) + глагол CВ (1 лицо мн. ч.)</span></b></p>
                        <p><i><b>Давáйте</b> познакóм<b>имся</b>.</i></p>
                        <p>(Toca a nos conhecermos*)</p>
                    </div>
                    <div className={classes.item2_3}>
                        <p><b>2. <span className={classes.orangeHighlight}>ДАВА́Й(ТЕ) + инфинитив HCВ</span></b></p>
                        <p><i><b>Давáйте</b> знакóм<b>иться</b>.</i></p>
                        <p>(Vamos conhecer-nos.)</p>
                    </div>
                    <div className={classes.item2_4}>
                        <p>* Apesar de, em português, a estrutura em 1. ter um tom mais informal, em russo ambas as frases são neutras.</p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>FUTURO</p>
                    <p>Simples e Composto</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Uma das diferenças entre o Futuro Simples e Futuro Composto é que o Futuro Composto demarca uma intenção/plano ou indica que a ação será iniciada, no futuro, ao passo que o Futuro Simples indica que a ação será efetivamente realizada/ concluída/atingirá o resultado.</p>
                    </div>
                    <div className={classes.item3_2}>
                        <div>
                            <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="200px" height="150px" />
                            <p><i>Зáвтра <b>бýду</b> ему <b>звони́ть</b>.</i></p>
                            <p>(Amanhã vou ligar-lhe.)</p>
                        </div>
                        <div>
                            <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="200px" height="150px" />
                            <p><i>Зáвтра я емý <b>позвóню</b>.</i></p>
                            <p>(Amanhã ligo-lhe sem falta.)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;