import React from 'react';
import classes from './Phonetics.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import PhoneticsPart1 from '../../../../../../Assets/Images/phoneticsPart1.png';
import PhoneticsPart2 from '../../../../../../Assets/Images/phoneticsPart2.png';
import PhoneticsPart3 from '../../../../../../Assets/Images/phoneticsPart3.png';
import PhoneticsPart4 from '../../../../../../Assets/Images/phoneticsPart4.png';
import PhoneticsPart5 from '../../../../../../Assets/Images/phoneticsPart5.png';
import PhoneticsPart6 from '../../../../../../Assets/Images/phoneticsPart6.png';
import PhoneticsPart7 from '../../../../../../Assets/Images/phoneticsPart7.png';


const phonetics = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <img id="phoneticsPart1" src={PhoneticsPart1} alt="phonetics part 1" width="700px" />
                <img id="phoneticsPart2" src={PhoneticsPart2} alt="phonetics part 2" width="700px" />
                <img id="phoneticsPart3" src={PhoneticsPart3} alt="phonetics part 3" width="700px" />
                <img id="phoneticsPart4" src={PhoneticsPart4} alt="phonetics part 4" width="700px" />
                <img id="phoneticsPart5" src={PhoneticsPart5} alt="phonetics part 5" width="700px" />
                <img id="phoneticsPart6" src={PhoneticsPart6} alt="phonetics part 6" width="700px" />
                <img id="phoneticsPart7" src={PhoneticsPart7} alt="phonetics part 7" width="700px" />
            </div>
        </div>
    );
}

export default phonetics;