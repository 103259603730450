import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'Самолёт', name: 'Самолёт', translation: 'в аэропорту́' },
    { id: 'Пикни́к', name: 'Пикни́к', translation: 'в лесу' },
    { id: 'Парке́т', name: 'Парке́т', translation: 'на полу' },
    { id: 'Ро́зы', name: 'Ро́зы', translation: 'в саду́' },
    { id: 'Кни́ги', name: 'Кни́ги', translation: 'в шкафу́' },
    { id: 'Машина', name: 'Машина', translation: 'на мосту́' },
    { id: 'Áнгелы', name: 'Áнгелы', translation: 'в раю' },
    { id: 'Де́моны', name: 'Де́моны', translation: 'в аду́' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort(function (a, b) {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Associe as palavras correspondentes:" />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;