import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Saltar from '../../../../../../Assets/Images/A1/Lesson7/image1exercise.png';
import Costurar from '../../../../../../Assets/Images/A1/Lesson7/image2exercise.png';
import Cantar from '../../../../../../Assets/Images/A1/Lesson7/image3exercise.png';
import Fotografar from '../../../../../../Assets/Images/A1/Lesson7/image4exercise.png';
import Dançar from '../../../../../../Assets/Images/A1/Lesson7/image5exercise.png';
import Chorar from '../../../../../../Assets/Images/A1/Lesson7/image6exercise.png';
import Desenhar from '../../../../../../Assets/Images/A1/Lesson7/image7exercise.png';
import Procurar from '../../../../../../Assets/Images/A1/Lesson7/image8exercise.png';
import Beber from '../../../../../../Assets/Images/A1/Lesson7/image9exercise.png';

const VerbList = [
    { id: 'saltar', name: 'пры́гать', matcher: 'прыгают' },
    { id: 'costurar', name: 'шить', matcher: 'шьёт' },
    { id: 'cantar', name: 'петь', matcher: 'поём' },
    { id: 'fotografar', name: 'фотографи́ровать', matcher: 'фотографирую' },
    { id: 'dançar', name: 'танцева́ть', matcher: 'танцуют' },
    { id: 'chorar', name: 'плачет', matcher: 'плачет' },
    { id: 'desenhar', name: 'рисова́ть', matcher: 'рисуешь' },
    { id: 'procurar', name: 'иска́ть', matcher: 'ищите' },
    { id: 'beber', name: 'пить', matcher: 'пьют' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value === matcher) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Preencha os espaços com os verbos dados (devidamente conjugados)." />
                <div className={classes.verbsList}>рисова́ть, пры́гать, петь, пить, шить, танцева́ть, фотографи́ровать, иска́ть, плакать</div>
                <div className={classes.questionsContainerEx2}>
                    <div className={classes.cardContainerEx2}>
                        <img id="saltar" src={Saltar} alt="saltar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Они́ <input
                                id="saltarInput"
                                name="saltarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="costurar" src={Costurar} alt="costurar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Онá <input
                                id="costurarInput"
                                name="costurarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="cantar" src={Cantar} alt="cantar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Мы <input
                                id="cantarInput"
                                name="cantarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="fotografar" src={Fotografar} alt="fotografar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Я <input
                                id="fotografarInput"
                                name="fotografarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="dançar" src={Dançar} alt="dançar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Они́ <input
                                id="dançarInput"
                                name="dançarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="chorar" src={Chorar} alt="chorar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Он <input
                                id="chorarInput"
                                name="chorarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="desenhar" src={Desenhar} alt="desenhar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Ты хорошо́ <input
                                id="desenharInput"
                                name="desenharInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="procurar" src={Procurar} alt="procurar" width="220px" />
                        <div className={classes.inputWrapper}>
                            Вы <input
                                id="procurarInput"
                                name="procurarInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            />
                            (информа́цию)?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="beber" src={Beber} alt="beber" width="220px" />
                        <div className={classes.inputWrapper}>
                            Они́ <input
                                id="beberInput"
                                name="beberInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;