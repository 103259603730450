import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бо́льше</b> mais</p>
                        <p><b>возвраща́ться</b> voltar</p>
                        <p><b>всего́</b> (Gen.) de tudo</p>
                        <p><b>вы́ше</b> mais alto</p>
                        <p><b>День весны́ и труда́</b> Dia da Primavera e do Trabalhador</p>
                        <p><b>День защи́тника Оте́чества</b> Dia do Defensor da Pátria</p>
                        <p><b>День конститу́ции Росси́и</b> Dia da Constituição da Rússia</p>
                        <p><b>День наро́дного еди́нства</b> Dia da Unidade Nacional</p>
                        <p><b>День Росси́и</b> Dia da Rússia</p>
                        <p><b>День студе́нта</b> Dia do Estudante</p>
                        <p><b>День учи́теля</b> Dia do Professor</p>
                        <p><b>деше́вле</b> mais barato</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>доро́же</b> mais caro</p>
                        <p><b>кани́кулы</b> férias</p>
                        <p><b>коро́ва</b> vaca</p>
                        <p><b>лейтена́нт</b> tenente</p>
                        <p><b>лу́чше</b> melhor</p>
                        <p><b>Междунаро́дный день защи́ты дете́й</b> Dia Internacional da Criança</p>
                        <p><b>Междунаро́дный же́нский день</b> Dia Internacional da Mulher</p>
                        <p><b>ме́ньше</b> menos</p>
                        <p><b>мла́дше</b> mais novo</p>
                        <p><b>моло́же</b> mais novo</p>
                        <p><b>начали́сь</b> (v. нача́ться) começaram</p>
                        <p><b>ни́же</b> mais baixo</p>
                        <p><b>подро́сток</b> adolescente</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>по́зже</b> mais tarde</p>
                        <p><b>пойду́</b> (v. пойти́) irei</p>
                        <p><b>порá</b> está na hora</p>
                        <p><b>проверя́ть</b> corrigir</p>
                        <p><b>путеше́ствовать</b> viajar</p>
                        <p><b>ра́ньше</b> antes</p>
                        <p><b>Рождество́ Христо́во</b> Natal</p>
                        <p><b>слон</b> elefante</p>
                        <p><b>совмеща́ть</b> juntar</p>
                        <p><b>старе́е</b> mais velho</p>
                        <p><b>ста́рше</b> 1. mais velho 2. (patente) mais alta</p>
                        <p><b>ху́же</b> pior</p>
                        <p><b>чем</b> (conjunção) de que</p>
                        <p><b>шесто́е чу́вство</b> sexto sentido</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;