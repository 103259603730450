import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import video_aula_28 from './Video/video_aula_28.mp4';

const VerbList = [
    { id: 'большая-пребольшая', name: 'большая-пребольшая', matcher: 'большая-пребольшая' },
    { id: 'тянет-потянет', name: 'тянет-потянет', matcher: 'тянет-потянет' },
    { id: 'Тянут-потянут', name: 'Тянут-потянут', matcher: 'Тянут-потянут' },
    { id: 'внучку', name: 'внучку', matcher: 'внучку' },
    { id: 'Жучку', name: 'Жучку', matcher: 'Жучку' },
    { id: 'кошку', name: 'кошку', matcher: 'кошку' },
    { id: 'мышку', name: 'мышку', matcher: 'мышку' },
    { id: 'кошку', name: 'кошку', matcher: 'кошку' },
    { id: 'Жучку', name: 'Жучку', matcher: 'Жучку' },
    { id: 'внучку', name: 'внучку', matcher: 'внучку' },
    { id: 'бабку', name: 'бабку', matcher: 'бабку' },
    { id: 'дедку', name: 'дедку', matcher: 'дедку' },
    { id: 'репку', name: 'репку', matcher: 'репку' },
    { id: 'вытянули', name: 'вытянули', matcher: 'вытянули' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    const exerciseQuestion = (
        <div className={classes.exerciseQuestion}>
            Послушайте <span className={classes.orangeHighlight}>сказку про репку</span> и завершите текст
        </div>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <a className={classes.downloadVideoLink} href={video_aula_28} download="video_aula_28">Bидео Урок 28 - Сказка Репка - Русские народные сказки. Развивающее приложение для детей</a>
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Посадил дед репку и говорит:</p>
                            <p>— Расти, расти, репка, сладка!</p>
                            <p>Расти, расти, репка, крепка!</p>
                            <p>Выросла репка сладка, крепка,</p>
                            <p><input
                                id="большая-пребольшаяInput"
                                name="большая-пребольшаяInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />.</p>
                            <p>Пошёл дед репку рвать:</p>
                            <p><input
                                id="тянет-потянетInput"
                                name="тянет-потянетInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />, вытянуть не может.</p>
                            <p>Позвал дед бабку.</p>
                            <p>Бабка за дедку,</p>
                            <p>Дедка за репку —</p>
                            <p><input
                                id="Тянут-потянутInput"
                                name="Тянут-потянутInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />, вытянуть не могут.</p>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Позвала бабка <input
                                id="внучкуInput"
                                name="внучкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />.</p>
                            <p>Внучка за бабку,</p>
                            <p>Бабка за дедку,</p>
                            <p>Дедка за репку —</p>
                            <p>Тянут-потянут, вытянуть не могут.</p>
                            <p>Позвала внучка <input
                                id="ЖучкуInput"
                                name="ЖучкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />.</p>
                            <p>Жучка за внучку,</p>
                            <p>Внучка за бабку,</p>
                            <p>Бабка за дедку,</p>
                            <p>Дедка за репку —</p>
                            <p>Тянут-потянут, вытянуть не могут.</p>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Позвала Жучка <input
                                id="кошкуInput"
                                name="кошкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />.</p>
                            <p>Кошка за Жучку,</p>
                            <p>Жучка за внучку,</p>
                            <p>Внучка за бабку,</p>
                            <p>Бабка за дедку,</p>
                            <p>Дедка за репку —</p>
                            <p>Тянут-потянут, вытянуть</p>
                            <p>не могут.</p>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p>Позвала кошка <input
                                id="мышкуInput"
                                name="мышкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />.</p>
                            <p>Мышка за <input
                                id="кошкуInput"
                                name="кошкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            />,</p>
                            <p>Кошка за <input
                                id="ЖучкуInput"
                                name="ЖучкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            />,</p>
                            <p>Жучка за <input
                                id="внучкуInput"
                                name="внучкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            />,</p>
                            <p>Внучка за <input
                                id="бабкуInput"
                                name="бабкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            />,</p>
                            <p>Бабка за <input
                                id="дедкуInput"
                                name="дедкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[11].matcher)}
                            />,</p>
                            <p>Дедка за <input
                                id="репкуInput"
                                name="репкуInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[12].matcher)}
                            /> —</p>
                            <p>Тянут-потянут — и <input
                                id="вытянулиInput"
                                name="вытянулиInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[13].matcher)}
                            /> репку.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;