import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>(в) пицце́ри́и</b> (na) pizzaria</p>
                        <p><b>(в) столо́вой</b> (na) cantina</p>
                        <p><b>вегетариа́нец</b> vegetariano</p>
                        <p><b>вегетариа́нка</b> vegetariana</p>
                        <p><b>гото́вить</b> preparar</p>
                        <p><b>де́ньги</b> dinheiro</p>
                        <p><b>едя́т</b> (v. есть) comem</p>
                        <p><b>ем</b> (v. есть) como</p>
                        <p><b>есть</b> comer</p>
                        <p><b>есть</b> há</p>
                        <p><b>ка́мни</b> pedras</p>
                        <p><b>ко́шки</b> gatas</p>
                        <p><b>краси́во</b> (adv.) bonito</p>
                        <p><b>креве́тки</b> camarões</p>
                        <p><b>лета́ют</b> (v. лета́ть) voam</p>
                        <p><b>ле́чат</b> (v. лечи́ть) curam</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ле́чит</b> (v. лечи́ть) cura</p>
                        <p><b>лови́ть</b> apanhar</p>
                        <p><b>лягу́шки</b> rãs</p>
                        <p><b>мóрепроду́кты</b> marisco</p>
                        <p><b>му́сорят</b> (v. му́сорить) deitam lixo</p>
                        <p><b>(на) Ма́рсе</b> (em) Marte</p>
                        <p><b>нача́льник</b> patrão/chefe</p>
                        <p><b>(о) нау́ке</b> (acerca da) ciência</p>
                        <p><b>о́вощи</b> legumes</p>
                        <p><b>пенсионе́р</b> reformado</p>
                        <p><b>пла́вать</b> nadar</p>
                        <p><b>поэ́тому</b> por isso</p>
                        <p><b>продаю́т</b> (v. прода́ть) vendem</p>
                        <p><b>пры́гают</b> (v. пры́гать) saltam</p>
                        <p><b>ре́дко</b> raramente</p>
                        <p><b>руса́лки</b> sereias</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ско́лько</b> quanto</p>
                        <p><b>снег</b> neve</p>
                        <p><b>спать</b> dormir</p>
                        <p><b>спи́те</b> (v. спать) dorme/-em</p>
                        <p><b>сплю́</b> (v. спать) durmo</p>
                        <p><b>спряже́ние</b> conjugação</p>
                        <p><b>существу́ют</b> (v. существова́ть) existem</p>
                        <p><b>то́же</b> também</p>
                        <p><b>убира́ют</b> (v. убира́ть) limpam</p>
                        <p><b>ули́ток</b> (Acus. pl) caracóis (animal)</p>
                        <p><b>ходи́ть</b> 1. andar 2. ir</p>
                        <p><b>хожу́</b> vou</p>
                        <p><b>ча́сто</b> frequentemente</p>
                        <p><b>чи́сто</b> (adv.) limpo</p>
                        <p><b>электри́чество</b> eletricidade</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;