import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson14/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson14/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson14/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson14/image4grammar.png';
import Image5Grammar from '../../../../../../Assets/Images/A2/Lesson14/image5grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ИМЯ СУЩЕСТВИТЕЛЬНОЕ</p>
                    <p>Дательный падеж (мн. ч.)</p>
                    <p>Nomes no Dativo (pl.)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="500px" />
                </div>
                <GrammarSubtitle>
                    <p>ПРЕДЛОГ</p>
                    <p>Preposição</p>
                    <p>ПО</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Usa-se a preposição ПО + DATIVO</p>
                    <p>1) com os dias da semana (e as partes do dia), quando nos referimos a uma ação que se repete regularmente:</p>
                    <div className={classes.item2_1}>
                        <div className={classes.item2_1_1}>
                            <p><b>По утра́м</b> он всегда́ чита́ет газе́ту.</p>
                            <p>Ele lê o jornal <b>todas as manhãs</b>.</p>
                        </div>
                        <div className={classes.item2_1_2}>
                            <p>Магази́н не рабо́тает <b>по понеде́льникам</b>.</p>
                            <p>A loja não abre às segundas-feiras.</p>
                        </div>
                        <div className={classes.item2_1_3}>
                            <p>Когда́/<b>По кaки́м дням</b> ты не рабо́таешь?</p>
                            <p>Quando/<b>Em que dias</b> é que tu não trabalhas?</p>
                        </div>
                        <div className={classes.item2_1_4}>
                            <p><b>По суббо́там.</b></p>
                            <p><b>Aos sábados.</b></p>
                        </div>
                    </div>
                    <p>2) para indicar uma actividade, especialidade, etc.</p>
                    <div className={classes.item2_2}>
                        <div className={classes.item2_2_1}>
                            <p>Людмила <b>по профессии</b> врач.</p>
                            <p>A Liudmila é <b>médica de profissão</b>.</p>
                        </div>
                        <div className={classes.item2_2_2}>
                            <p>Надя <b>по специальности</b> хирург.</p>
                            <p>A <b>especialidade</b> da Nádia é cirurgia.</p>
                        </div>
                        <div className={classes.item2_2_3}>
                            <p>У него экзамен <b>по математике</b>.</p>
                            <p>Ele tem exame <b>de matemática</b></p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ПРЕДЛОГ</p>
                    <p>Preposição</p>
                    <p>К</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Usa-se a preposição <b><span className={classes.orangeHighlight}>К</span></b> + DATIVO nas seguintes situações:</p>
                    <p>1) Movimento:</p>
                    <div className={classes.item3_1}>
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="150px" />
                        <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="300px" />
                    </div>
                    <p>2) Sentimento em relação a algo/alguém:</p>
                    <div className={classes.item3_2}>
                        <img id="image4Grammar" src={Image4Grammar} alt="table 4" width="150px" />
                        <img id="image5Grammar" src={Image5Grammar} alt="table 5" width="250px" />
                        <div>
                            <p>...нему</p>
                            <p>...ней</p>
                            <p>...ним</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;