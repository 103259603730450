import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson23 from '../../../../../../Assets/Images/A1/Lesson23/image1lesson23.png';
import Image2Lesson23 from '../../../../../../Assets/Images/A1/Lesson23/image2lesson23.png';
import Image3Lesson23 from '../../../../../../Assets/Images/A1/Lesson23/image3lesson23.png';
import Image4Lesson23 from '../../../../../../Assets/Images/A1/Lesson23/image4lesson23.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ЛИЧНЫЕ МЕСТОИМЕНИЯ</p>
                    <p>дательный падеж</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image1Lesson23" src={Image1Lesson23} alt="lesson 23 illustrative example 1" />
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="Image2Lesson23" src={Image2Lesson23} alt="lesson 23 illustrative example 2" />
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>БЕЗЛИЧНЫЕ ПРЕДЛОЖЕНИЯ</p>
                    <p>Construções Impessoais</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <img id="Image3Lesson23" src={Image3Lesson23} alt="lesson 23 illustrative example 3" />
                </div>
                <GrammarSubtitle>
                    <p>ДАТЕЛЬНЫЙ ПАДЕЖ: предлог ПО</p>
                    <p>Dativo: preposição ПО</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>дви́гаться</p>
                            <p>ходи́ть/идти́</p>
                            <p>бе́гать/бежа́ть</p>
                            <p>пла́вать/плыть</p>
                            <p>лета́ть/лете́ть</p>
                            <p>…</p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b><span className={classes.orangeHighlight}>ПО</span> + nome</b> (Dat.)</p>
                            <p><b><span className={classes.orangeHighlight}>ПО</span> чему́?</b></p>
                            <p><b><span className={classes.orangeHighlight}>ПО</span> доро́ге</b></p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b><span className={classes.orangeHighlight}>ПО чему?</span></b> <i>Por que via/meio?</i>
                            </p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span> </font> <b><span className={classes.orangeHighlight}>Почему?</span></b> <i>Porquê</i>
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ГЛАГОЛЫ ДВИЖЕНИЯ НЕСОВЕРШЕННОГО ВИДА: гру́ппа I</p>
                    <p>прошедшее время</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <img id="Image4Lesson23" src={Image4Lesson23} alt="lesson 23 illustrative example 4" />
                </div>
            </div>
        </div>
    );
}

export default Grammar;