import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson1/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson1/image2grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРИЛАГАТЕЛЬНОЕ – Винительный падеж</p>
                    <p>ADJETIVOS – Caso Acusativo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Os adjetivos do género masculino e neutro que concordam com nomes <b>não animados</b>, no Acusativo, têm a mesma forma do <b>Nominativo</b>.</p>
                </div>
                <div className={classes.item2}>
                    <img id="image1Grammar" src={Image1Grammar} alt="non nominative adjectives table" width="500px" />
                </div>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <img id="image2Grammar" src={Image2Grammar} alt="nominative adjectives table" width="350px" />
                    </div>
                    <div className={classes.item3_2}>
                        <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Os adjetivos, (tal como os demonstrativos e possessivos) do género masculino e neutro que concordam com nomes animados, no Acusativo, têm a mesma forma do GENITIVO.</p>
                    </div>
                </div>
                <div className={classes.item4}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Existem <b>apelidos</b>, em Russo, que têm a <b>forma de
                            adjetivos</b>, normalmente, terminados em <b><span className={classes.orangeHighlight}>-СКИЙ/-СКАЯ</span></b> Фёдор Достоéвск<b><span className={classes.orangeHighlight}>ий</span></b>, Сóфья Ковалéвск<b><span className={classes.orangeHighlight}>ая</span></b>. Estes
                        apelidos seguem as
                        regras de declinação dos adjetivos, e não dos nomes.
                    </p>
                </div>
            </div>
        </div >);
}

export default Grammar;