import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';


const Exercises = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Вы знали, что..." />
                <p className={classes.textContent}>
                    Моско́вское метро́ — настоя́щий палеонтологи́ческий музе́й. Здесь есть обита́тели, во́зраст кото́рых
                    насчи́тывает не́сколько миллио́нов лет — э́то во́все не ба́бушки-контролёры, а дре́вние моллю́ски. Их
                    мо́жно встре́тить в любо́е вре́мя на ста́нциях, сте́ны и коло́нны кото́рых отде́ланы полиро́ванным
                    известняко́м и́ли мра́мором. Са́мая насы́щенная дре́вней фа́уной ста́нция — «Парк Побе́ды». Здесь
                    окамене́лостей не то́лько бо́льше всего́, они́ ещё и са́мые кру́пные — в мра́морных пли́тах мо́жно
                    разгляде́ть аммони́тов диа́метром по полметра.
                </p>
                <p className={classes.textContent}>

                    В кра́сном мра́море «Добрынинской» пря́чутся наути́лусы, в желто́-бе́жевом мра́море перехо́дов на
                    «Ки́евской» мо́жно разгляде́ть фрагме́нты кора́лловых ри́фов, а са́мые интере́сные окамене́лости
                    «Тага́нской» придётся рассма́тривать на четвере́ньках — они́ нахо́дятся в по́лу, во́зле скаме́ек и
                    коло́нн. Всего́ же ста́нций, в отде́лке кото́рых на́йдена доистори́ческая жи́вность, в метро́ Москвы́
                    насчи́тывается бо́льше полусо́тни — по́лный спи́сок обнару́женных здесь дико́вин виси́т на
                    www.paleometro.ru.
                </p>
                <p>
                    Адаптировано: https://www.timeout.ru/msk/feature/4684
                </p>
            </div>
        </div>
    );
}

export default Exercises;