import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'прочитать', name: 'прочитать', matcher: 'прочитать' },
    { id: 'написать', name: 'написать', matcher: 'написать' },
    { id: 'читать', name: 'читать', matcher: 'читать' },
    { id: 'учить', name: 'учить', matcher: 'учить' },
    { id: 'выучить', name: 'выучить', matcher: 'выучить' },
    { id: 'смотреть', name: 'смотреть', matcher: 'смотреть' },
    { id: 'увидеть', name: 'увидеть', matcher: 'увидеть' },
    { id: 'готовить', name: 'готовить', matcher: 'готовить' },
    { id: 'приготовить', name: 'приготовить', matcher: 'приготовить' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escreva um dos verbos entre parêntesis." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            1) Он вчера́ <input
                                id="прочитатьInput"
                                name="прочитатьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> целую кни́гу. (чита́ть/прочита́ть)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            2) Я вчера́ тебе́ <input
                                id="написатьInput"
                                name="написатьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> сто смс! (писа́ть/написа́ть)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            3) Ты уже ра́ньше <input
                                id="читатьInput"
                                name="читатьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> э́тот рома́н? (чита́ть/прочита́ть)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            4) Я <input
                                id="учитьInput"
                                name="учитьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> грамма́тику все выходны́е (учи́ть/вы́учить), но та́к и не <input
                                id="выучитьInput"
                                name="выучитьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />. (учи́ть/вы́учить)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            5) Я давно́ <input
                                id="смотретьInput"
                                name="смотретьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> э́ту коме́дию. Я уже ничего́ не по́мню. (смотре́ть/посмотре́ть)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            6) Она́ <input
                                id="увидетьInput"
                                name="увидетьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> меня́ и сра́зу влюби́лась. (ви́деть/уви́деть)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            7) Ра́ньше я всегда́ <input
                                id="готовитьInput"
                                name="готовитьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> обе́д, а сейча́с мой муж. (гото́вить/пригото́вить)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            8) Я <input
                                id="приготовитьInput"
                                name="приготовитьInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> тебе́ за́втрак в шко́лу. (гото́вить/пригото́вить)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;