import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Alphabet.css';
import audio1 from './Audio/01.mp3';
import audio2 from './Audio/02.mp3';
import audio3 from './Audio/03.mp3';
import audio4 from './Audio/04.mp3';
import audio5 from './Audio/05.mp3';
import audio6 from './Audio/06.mp3';
import audio7 from './Audio/07.mp3';
import audio8 from './Audio/08.mp3';
import audio9 from './Audio/09.mp3';
import audio10 from './Audio/10.mp3';
import audio11 from './Audio/11.mp3';
import audio12 from './Audio/12.mp3';
import audio13 from './Audio/13.mp3';
import audio14 from './Audio/14.mp3';
import audio15 from './Audio/15.mp3';
import audio16 from './Audio/16.mp3';
import audio17 from './Audio/17.mp3';
import audio18 from './Audio/18.mp3';
import audio19 from './Audio/19.mp3';
import audio20 from './Audio/20.mp3';
import audio21 from './Audio/21.mp3';
import audio22 from './Audio/22.mp3';
import audio23 from './Audio/23.mp3';
import audio24 from './Audio/24.mp3';
import audio25 from './Audio/25.mp3';
import audio26 from './Audio/26.mp3';
import audio27 from './Audio/27.mp3';
import audio28 from './Audio/28.mp3';
import audio29 from './Audio/29.mp3';
import audio30 from './Audio/30.mp3';
import audio31 from './Audio/31.mp3';
import audio32 from './Audio/32.mp3';
import audio33 from './Audio/33.mp3';

const playAudio = audio => {
    new Audio(audio).play();
}

const AudioLetter = ({ audio, letter }) => {
    return (
        <div className={classes.audioLetter}>
            <button className={classes.audioButton} onClick={() => playAudio(audio)}>
                <b><u>{letter}</u></b>
            </button>
            <p className={classes.handWrittedLetter}>
                {letter}
            </p>
        </div>
    )
};

const alphabet = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.introText}>
                    <p>O alfabeto russo é uma das variantes do alfabeto cirílico, que surge no século IX d.C. com base no
                        alfabeto grego, e cuja autoria é geralmente atribuída aos irmãos Metódio e Cirilo, dois monges bizantinos em missão na
                        Morávia (atual República Checa) e no Império Búlgaro, onde se dedicaram a traduzir textos gregos para eslavão (eslavo
                        antigo eclesiástico – que está para as línguas eslavas como o latim está para o português, por exemplo).
                        É evidente a associação do nome deste alfabeto ao nome do Monge Cirilo, o que para alguns autores se
                        deve a uma homenagem de um dos discípulos de Cirilo ao seu mestre, e não à sua direta criação. Além do russo, as línguas eslavas que utilizam versões do cirílico são o bielorrusso, bósnio, búlgaro, ucraniano, macedónio
                        e sérvio. Por razões históricas e geográficas, algumas línguas não-eslavas usaram (ex.: o romeno) ou ainda usam (mongol, uzbeque, etc.) este alfabeto.</p>
                </div>
                <div className={classes.instructions}>
                    <p><b>O alfabeto russo tem 33 letras: 10 vogais, 21 consoantes e dois sinais gráficos.</b></p>
                    <p><span role="img" aria-label="Headphones">🎧</span> Clique na letra para ouvir os sons: </p>
                </div>
                <div className={classes.audioContainer}>
                    <div className={classes.audioColumn}>
                        <AudioLetter audio={audio1} letter={'Aa'} />
                        <AudioLetter audio={audio2} letter={'Бб'} />
                        <AudioLetter audio={audio3} letter={'Вв'} />
                        <AudioLetter audio={audio4} letter={'Гг'} />
                        <AudioLetter audio={audio5} letter={'Дд'} />
                        <AudioLetter audio={audio6} letter={'Ее'} />
                        <AudioLetter audio={audio7} letter={'Ёё'} />
                        <AudioLetter audio={audio8} letter={'Жж'} />
                        <AudioLetter audio={audio9} letter={'Зз'} />
                        <AudioLetter audio={audio10} letter={'Ии'} />
                        <AudioLetter audio={audio32} letter={'Йй'} />
                    </div>
                    <div className={classes.audioColumn}>
                        <AudioLetter audio={audio11} letter={'Кк'} />
                        <AudioLetter audio={audio12} letter={'Лл'} />
                        <AudioLetter audio={audio13} letter={'Мм'} />
                        <AudioLetter audio={audio14} letter={'Нн'} />
                        <AudioLetter audio={audio15} letter={'Оо'} />
                        <AudioLetter audio={audio16} letter={'Пп'} />
                        <AudioLetter audio={audio17} letter={'Рр'} />
                        <AudioLetter audio={audio18} letter={'Сс'} />
                        <AudioLetter audio={audio19} letter={'Тт'} />
                        <AudioLetter audio={audio20} letter={'Уу'} />
                        <AudioLetter audio={audio21} letter={'Фф'} />
                    </div>
                    <div className={classes.audioColumn}>
                        <AudioLetter audio={audio22} letter={'Хх'} />
                        <AudioLetter audio={audio23} letter={'Цц'} />
                        <AudioLetter audio={audio24} letter={'Чч'} />
                        <AudioLetter audio={audio25} letter={'Шш'} />
                        <AudioLetter audio={audio26} letter={'Щщ'} />
                        <AudioLetter audio={audio28} letter={'ъ'} />
                        <AudioLetter audio={audio27} letter={'ы'} />
                        <AudioLetter audio={audio30} letter={'ь'} />
                        <AudioLetter audio={audio29} letter={'Ээ'} />
                        <AudioLetter audio={audio31} letter={'Юю'} />
                        <AudioLetter audio={audio33} letter={'Яя'} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default alphabet;