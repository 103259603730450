import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>близнецы́</b> signo Gémeos</p>
                        <p><b>весы́</b> signo Balança</p>
                        <p><b>водоле́й</b> signo Aquário</p>
                        <p><b>вре́дно</b> nocivo</p>
                        <p><b>говори́ть</b> falar</p>
                        <p><b>де́ва</b> signo Virgem</p>
                        <p><b>до́рого</b> caro</p>
                        <p><b>за́нято</b> ocupado (neutro)</p>
                        <p><b>иску́сство</b> arte</p>
                        <p><b>козеро́г</b> signo Capricórnio</p>
                        <p><b>кури́ть</b> fumar</p>
                        <p><b>ку́рят</b>(v. кури́ть) fumam</p>
                        <p><b>лев</b> signo Leão</p>
                        <p><b>лежа́т</b>(v. лежа́ть) estão deitados/as</p>
                        <p><b>лежу́</b>(v. лежа́ть) estou deitado/a</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ма́ло</b> pouco</p>
                        <p><b>мно́го</b> muito</p>
                        <p><b>молча́ть</b> 1. ficar em silêncio 2. calar-se</p>
                        <p><b>молчу́</b>(v. молча́ть) estou calado/a</p>
                        <p><b>(на) парко́вке</b> (no) estacionamento</p>
                        <p><b>непра́вда</b> mentira</p>
                        <p><b>никогда́</b> nunca</p>
                        <p><b>никуда́</b> para lugar nenhum</p>
                        <p><b>(о) де́тях</b> (sobre) crianças</p>
                        <p><b>о ком</b> de quem</p>
                        <p><b>(о) рекла́ме</b> (sobre) publicidade</p>
                        <p><b>ове́н</b> signo Áries</p>
                        <p><b>отве́т</b> resposta</p>
                        <p><b>отпра́вить</b> enviar</p>
                        <p><b>пра́вда</b> verdade</p>
                        <p><b>пра́вить</b> corrigir</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>предпочита́ют</b>(v. предпочита́ть) preferem</p>
                        <p><b>прекра́сно</b> maravilhoso</p>
                        <p><b>про́бку</b> (Acus) cortiça</p>
                        <p><b>по́мнить</b> lembrar-se</p>
                        <p><b>про́сто</b> simplesmente</p>
                        <p><b>рак</b> signo Caranguejo</p>
                        <p><b>ры́бы</b> signo Peixes</p>
                        <p><b>сиде́ть</b> estar sentado</p>
                        <p><b>скорпио́н</b> signo Escorpião</p>
                        <p><b>сообщ.</b>(сообще́ние) mensagem</p>
                        <p><b>спеши́ть</b> apressar-se</p>
                        <p><b>стоя́ть</b> estar parado</p>
                        <p><b>стреле́ц</b> signo Sagitário</p>
                        <p><b>танцу́ет</b>(v. танцева́ть) dança</p>
                        <p><b>телéц</b> signo Touro</p>
                        <p><b>учи́ть</b> aprender</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;