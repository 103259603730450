import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: '5', name: '5', matcher: '5' },
    { id: '6', name: '6', matcher: '6' },
    { id: '4', name: '4', matcher: '4' },
    { id: '1', name: '1', matcher: '1' },
    { id: '3', name: '3', matcher: '3' },
    { id: '2', name: '2', matcher: '2' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
            event.currentTarget.style.background = 'white';
        } else {
            event.currentTarget.style.color = 'red';
            event.currentTarget.style.background = 'white';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Depois de ler o início da lenda &quot;СА́МЫЙ, СА́МЫЙ, СА́МЫЙ, СА́МЫЙ” (pág. 95), seleccione a resposta para cada pergunta:" />
                <div className={classes.questionsContainerEx1}>
                    <div>
                        <p>1. Где пря́тался дре́вний дух?</p>
                        <p>2. Где нахо́дится э́тот коло́дец?</p>
                        <p>3. Что реши́ли живо́тные, кото́рые жи́ли на берега́х о́зера Чад?</p>
                        <p>4. Почему́ они́ вы́брали льва?</p>
                        <p>5. Почему́ лев – царь звере́й?</p>
                        <p>6. Кто сказа́л львёнку, что он царь звере́й?</p>
                    </div >
                    <div className={classes.inputsContainer}>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input1"
                                    name="input1"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[0].matcher)} /> Он са́мый сме́лый, са́мый си́льный, са́мый му́дрый, са́мый краси́вый...
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input2"
                                    name="input2"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[1].matcher)} /> Гие́на.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input3"
                                    name="input3"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[2].matcher)} /> Лев - царь звере́й.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input4"
                                    name="input4"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[3].matcher)} /> На дне коло́дца.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input5"
                                    name="input5"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[4].matcher)} /> Избра́ть себе́ царя́.
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input6"
                                    name="input6"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[5].matcher)} /> В са́мой середи́не А́фрики.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;