import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson7/image1grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРИЛАГАТЕЛЬНОЕ</p>
                    <p>Творительный падеж</p>
                    <p>ADJETIVOS – Caso Instrumental</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="250px" />
                    </div>
                    <div className={classes.item1_2}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ПРИТЯЖАТЕЛЬНЫЕ МЕСТОИМЕНИЯ</span></b></p>
                        <p>м. р./ср.р.: мо<b>и́м</b>, твo<b>и́м</b>, нáш<b>им</b>, вáш<b>им</b>;</p>
                        <p>ж.р.: мо<b>е́й</b>, твo<b>е́й</b>, нáш<b>ей</b>, вáш<b>ей</b>;</p>
                        <div className={classes.spacer}>
                            <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> УКАЗАТЕЛЬНЫЕ МЕСТОИМЕНИЯ</span></b></p>
                            <p>м. р./ср.р.: э́т<b>им</b>, <b>тем</b></p>
                            <p>ж.р.: э́т<b>ой</b>, т<b>ой</b></p>
                        </div>
                        <div className={classes.spacer}>
                            <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> МЕСТОИМЕНИЕ СВОЙ</span></b></p>
                            <p>м. р./ср.р.: сво<b>и́м</b></p>
                            <p>ж.р.: сво<b>е́й</b>/сво<b>е́ю</b></p>
                            <p>мн. ч.: сво<b>и́ми</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;