import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import audio1 from './Audio/aula30aud1.mp3';

const VerbList = [
    { id: 'любви', name: 'любви', matcher: 'любви' },
    { id: 'я', name: 'я', matcher: 'я' },
    { id: 'тобой', name: 'тобой', matcher: 'тобой' },
    { id: 'быть', name: 'быть', matcher: 'быть' },
    { id: 'буду', name: 'буду', matcher: 'буду' },
    { id: 'и́мя', name: 'и́мя', matcher: 'и́мя' },
    { id: 'мне', name: 'мне', matcher: 'мне' },
    { id: 'твой', name: 'твой', matcher: 'твой' },
    { id: 'с', name: 'с', matcher: 'с' },
    { id: 'хочу', name: 'хочу', matcher: 'хочу' },
    { id: 'потолком', name: 'потолком', matcher: 'потолком' },
    { id: 'шоколад', name: 'шоколад', matcher: 'шоколад' },
    { id: 'они', name: 'они', matcher: 'они' },
    { id: 'смотрел', name: 'смотрел', matcher: 'смотрел' },
    { id: 'тебя', name: 'тебя', matcher: 'тебя' },
    { id: 'я хочу́ быть с тобо́й', name: 'я хочу́ быть с тобо́й', matcher: 'я хочу́ быть с тобо́й' },
    { id: 'с', name: 'с', matcher: 'с' },
    { id: 'любовь', name: 'любовь', matcher: 'любовь' },
    { id: 'в ко́мнате', name: 'в ко́мнате', matcher: 'в ко́мнате' },
];

const Exercises = props => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [player] = useState(new Audio(audio1));

    const playAudio = player => {
        if (isPlaying) {
            player.pause();
            setIsPlaying(false);
        } else {
            player.play();
            setIsPlaying(true);
        }
    }

    const MusicAudio = () => {
        return (
            <div className={classes.musicAudio}>
                <button className={classes.audioButton} onClick={() => playAudio(player)}>
                    Áudio &#9658; &#10074;&#10074;
                </button>
            </div>
        );
    };

    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Ouça a canção “Я ХОЧУ́ БЫТЬ С ТОБО́Й“ da banda “НАУТИЛУС ПОМПИЛИУС” e preencha os espaços." />
                <MusicAudio />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            Я пыта́лся уйти́ от <input
                                id="input1"
                                name="input 1"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />,
                            <p>Я брал о́струю бри́тву и правил себя́.</p>
                            <input
                                id="input2"
                                name="input 2"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> укры́лся в подва́ле, я ре́зал
                            <p>Ко́жаные ремни́, стяну́вшие сла́бую грудь.</p>
                            <div className={classes.textContainer}>
                                <div>
                                    Я хочу́ быть с <input
                                        id="input3"
                                        name="input 3"
                                        type="text"
                                        onChange={event => handleChange(event, VerbList[2].matcher)}
                                    />
                                    <p>Я хочу́ быть с тобо́й,</p>
                                    Я так хочу́ <input
                                        id="input4"
                                        name="input 4"
                                        type="text"
                                        onChange={event => handleChange(event, VerbList[3].matcher)}
                                    /> с тобо́й,
                                    <p>Я хочу́ быть с тобо́й</p>
                                    И я <input
                                        id="input5"
                                        name="input 5"
                                        type="text"
                                        onChange={event => handleChange(event, VerbList[4].matcher)}
                                    /> с тобо́й.
                                </div>
                            </div>
                            Твоё <input
                                id="input6"
                                name="input 6"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> давно́ ста́ло други́м,
                            <p>Глаза навсегда́ потеря́ли свой цвет.</p>
                            Пья́ный врач <input
                                id="input7"
                                name="input 7"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> сказа́л - тебя́ бо́льше нет,
                            <p>Пожа́рный вы́дал мне спра́вку,</p>
                            Что дом <input
                                id="input8"
                                name="input 8"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> сгоре́л.
                            <div className={classes.textContainer}>
                                <div>
                                    Но я хочу́ быть с тобо́й,
                                    <p>
                                        Я хочу́ быть <input
                                            id="input9"
                                            name="input 9"
                                            type="text"
                                            onChange={event => handleChange(event, VerbList[8].matcher)}
                                        /> тобо́й,
                                    </p>
                                    Я так хочу́ быть с тобо́й,
                                    <p>
                                        Я <input
                                            id="input10"
                                            name="input 10"
                                            type="text"
                                            onChange={event => handleChange(event, VerbList[9].matcher)}
                                        /> быть с тобо́й
                                    </p>
                                    И я бу́ду с тобо́й.
                                </div>
                            </div>
                            В ко́мнате с бе́лым <input
                                id="input11"
                                name="input 11"
                                type="text"
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            />,
                            <p>С пра́вом на наде́жду.</p>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            Я лома́л стекло́, как <input
                                id="input12"
                                name="input 12"
                                type="text"
                                onChange={event => handleChange(event, VerbList[11].matcher)}
                            /> в руке́,
                            <p>
                                Я ре́зал э́ти па́льцы за то, что <input
                                    id="input13"
                                    name="input 13"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[12].matcher)}
                                />
                            </p>
                            Не мо́гут прикосну́ться к тебе́.
                            <div className={classes.spacer}>
                                Я <input
                                    id="input14"
                                    name="input 14"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[13].matcher)}
                                /> в э́ти лица
                                <p>И не мог им прости́ть</p>
                                Того́, что у них нет <input
                                    id="input15"
                                    name="input 15"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[14].matcher)}
                                />
                                <p>И они́ мо́гут жить.</p>
                            </div>
                            <div className={classes.textContainer}>
                                <div>
                                    Но я хочу́ быть с тобо́й,
                                    <p>
                                        <input
                                            id="input16"
                                            name="input 16"
                                            type="text"
                                            onChange={event => handleChange(event, VerbList[15].matcher)}
                                        />,
                                    </p>
                                    Я так хочу́ быть с тобо́й,
                                    <p>Я хочу́ быть с тобо́й</p>
                                    И я бу́ду с тобо́й.
                                </div>
                            </div>
                            В ко́мнате <input
                                id="input17"
                                name="input 17"
                                type="text"
                                onChange={event => handleChange(event, VerbList[16].matcher)}
                            /> бе́лым потолко́м,
                            <p>С пра́вом на наде́жду.</p>
                            В ко́мнате с ви́дом на огни́,
                            <p></p>
                            С ве́рою в <input
                                id="input18"
                                name="input 18"
                                type="text"
                                onChange={event => handleChange(event, VerbList[17].matcher)}
                            />.
                            <div className={classes.spacer}>
                                <p>В ко́мнате с бе́лым потолко́м,</p>
                                <p>С пра́вом на наде́жду.</p>
                                <input
                                    id="input19"
                                    name="input 19"
                                    type="text"
                                    onChange={event => handleChange(event, VerbList[18].matcher)}
                                /> с ви́дом на огни́,
                                <p>С ве́рою в любо́вь.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;