import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>а</b> mas</p>
                        <p><b>ба́бушка</b> avó</p>
                        <p><b>боль</b> dor</p>
                        <p><b>большáя</b> (fem.) grande</p>
                        <p><b>брат</b> irmão</p>
                        <p><b>брю́ки</b> calças</p>
                        <p><b>буты́лка</b> garrafa</p>
                        <p><b>внук</b> neto</p>
                        <p><b>газе́та</b> jornal</p>
                        <p><b>где</b> onde</p>
                        <p><b>го́род</b> cidade</p>
                        <p><b>да</b> sim</p>
                        <p><b>де́душка</b> avô</p>
                        <p><b>де́ньги</b> dinheiro</p>
                        <p><b>де́рево</b> árvore</p>
                        <p><b>дом</b> casa</p>
                        <p><b>дочь</b> filha</p>
                        <p><b>друг</b> amigo</p>
                        <p><b>дя́дя</b> tio</p>
                        <p><b>её</b> dela</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>жена́</b> esposa</p>
                        <p><b>здесь</b> aqui</p>
                        <p><b>зе́ркало</b> espelho</p>
                        <p><b>и</b> e</p>
                        <p><b>и́мя</b> nome</p>
                        <p><b>каранда́ш</b> lápis</p>
                        <p><b>ке́пка</b> boné</p>
                        <p><b>ключи́</b> chaves</p>
                        <p><b>кни́га</b> livro</p>
                        <p><b>кроссо́вки</b> ténis</p>
                        <p><b>лист</b> folha</p>
                        <p><b>мáркер</b> marcador</p>
                        <p><b>мать</b> mãe</p>
                        <p><b>маши́на</b> carro</p>
                        <p><b>нож</b> faca</p>
                        <p><b>но́жницы</b> tesoura</p>
                        <p><b>ночь</b> noite</p>
                        <p><b>окно́</b> janela</p>
                        <p><b>очки́</b> óculos</p>
                        <p><b>писа́тель</b> escritor</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>плечо́</b> ombro</p>
                        <p><b>ребёнок</b> criança</p>
                        <p><b>роди́тели</b> pais</p>
                        <p><b>родны́е</b> parentes</p>
                        <p><b>ружьë</b> espingarda</p>
                        <p><b>ру́чка</b> caneta</p>
                        <p><b>рюкза́к</b> mochila</p>
                        <p><b>сви́тер</b> camisola de malha</p>
                        <p><b>сок</b> sumo</p>
                        <p><b>сосе́д</b> vizinho</p>
                        <p><b>стёрка</b> borracha</p>
                        <p><b>стул</b> cadeira</p>
                        <p><b>су́мка</b> mala</p>
                        <p><b>тебя́ зову́т</b> chamas-te</p>
                        <p><b>у́хо</b> orelha</p>
                        <p><b>учи́тель</b> professor escolar</p>
                        <p><b>флéшка</b> Pen Drive</p>
                        <p><b>чей</b> de quem</p>
                        <p><b>э́то</b> isto</p>
                        <p><b>я́блоко</b> maçã</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;