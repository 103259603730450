import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: true },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: true },
    { id: 7, isCorrect: true },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: true },
    { id: 16, isCorrect: true },
    { id: 17, isCorrect: false },
    { id: 18, isCorrect: false },
    { id: 19, isCorrect: false },
    { id: 20, isCorrect: true },
    { id: 21, isCorrect: false },
    { id: 22, isCorrect: true },
    { id: 23, isCorrect: false },
    { id: 24, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    const exerciseQuestion = (
        <p>Escolha a preposição certa: <span className={classes.orangeHighlight}>для/ра́ди/за</span></p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            1. Спаси́бо <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    для
                                </button>/<button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    ра́ди
                                </button>/<button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    за
                                </button>
                            </span> по́мощь.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            2. Я опозда́ла и он написа́л докла́д <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    для
                                </button>/<button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    ра́ди
                                </button>/<button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    за
                                </button>
                            </span> меня́.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            3. Моя́ жена́ пи́шет кни́ги <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    для
                                </button>/<button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    ра́ди
                                </button>/<button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    за
                                </button>
                            </span> дете́й.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            4. <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    Для
                                </button>/<button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    Ра́ди
                                </button>/<button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    За
                                </button>
                            </span> Бо́га! Переста́нь на меня́ крича́ть!
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            5. У тебя́ но́вая маши́на? A ско́лько ты заплати́л <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    для
                                </button>/<button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    ра́ди
                                </button>/<button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    за
                                </button>
                            </span> неё.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            6. Я купи́л ми́ску <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    для
                                </button>/<button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    ра́ди
                                </button>/<button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    за
                                </button>
                            </span> сала́та.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            7. Oн бро́сил рабо́ту <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[18].id}
                                    onClick={event => handleColor(event, buttonsEx1[18].isCorrect)}
                                >
                                    для
                                </button>/<button
                                    key={buttonsEx1[19].id}
                                    onClick={event => handleColor(event, buttonsEx1[19].isCorrect)}
                                >
                                    ра́ди
                                </button>/<button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[20].isCorrect)}
                                >
                                    за
                                </button>
                            </span> жены́.
                        </p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <p className={classes.buttonsAndTextContainer}>
                            8. Мне ну́жно купи́ть муку́ <span className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[21].isCorrect)}
                                >
                                    для
                                </button>/<button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[22].isCorrect)}
                                >
                                    ра́ди
                                </button>/<button
                                    key={buttonsEx1[23].id}
                                    onClick={event => handleColor(event, buttonsEx1[23].isCorrect)}
                                >
                                    за
                                </button>
                            </span> блино́в.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;