import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Avatar1 from '../../../../../../Assets/Images/A1/Lesson19/image1exercise.png';
import Avatar2 from '../../../../../../Assets/Images/A1/Lesson19/image2exercise.png';
import Avatar3 from '../../../../../../Assets/Images/A1/Lesson19/image3exercise.png';
import Avatar4 from '../../../../../../Assets/Images/A1/Lesson19/image4exercise.png';
import Avatar5 from '../../../../../../Assets/Images/A1/Lesson19/image5exercise.png';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: true },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: false },
    { id: 12, isCorrect: true },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha a descrição apropriada às figuras." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <img id="avatar1" src={Avatar1} alt="Avatar 1" width="150px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    Она́ брюне́тка
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    Она́ блонди́нка
                                </button>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    Она́ ры́жая
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="avatar2" src={Avatar2} alt="Avatar 2" width="150px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    У неё прямы́е во́лосы.
                                </button>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    У неё кудря́вые во́лосы.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="avatar3" src={Avatar3} alt="Avatar 3" width="150px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    У него́ большо́й нос.
                                </button>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    У него́ ма́ленький нос.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="avatar4" src={Avatar4} alt="Avatar 4" width="150px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    У него́ чёрные глаза.
                                </button>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    У него́ зелёные гла́за.
                                </button>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    У него́ голубы́е глаза.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <img id="avatar5" src={Avatar5} alt="Avatar 5" width="150px" />
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    У неё то́нкие губы.
                                </button>
                                <button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    У неё по́лные губы.
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;