import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: true },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: true },
    { id: 9, isCorrect: true },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: false },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: false },
    { id: 15, isCorrect: true },
    { id: 15, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Прочитайте тексты и выберите время года, о котором идёт речь." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Листопад, листопад,</p>
                            <p>Всё звено примчалось в сад,</p>
                            <p>Прибежала Шурочка.</p>
                            <p>Листья (слышите?) шуршат:</p>
                            <p>Шурочка, Шурочка…</p>
                            <p>Ливень листьев кружевной</p>
                            <p>Шелестит о ней одной:</p>
                            <p>Шурочка, Шурочка…</p>
                            <p>Три листочка подмела,</p>
                            <p>Подошла к учителю:</p>
                            <p>— Хорошо идут дела!</p>
                            <p>(Я тружусь, учтите, мол,</p>
                            <p>Похвалите Шурочку,</p>
                            <p>Шурочку, Шурочку…)</p>
                            <p>Как работает звено,</p>
                            <p>Это Шуре всё равно,</p>
                            <p>Только бы отметили,</p>
                            <p>В классе ли, в газете ли,</p>
                            <p>Шурочку, Шурочку…</p>
                            <p>Листопад, листопад,</p>
                            <p>Утопает в листьях сад,</p>
                            <p>Листья грустно шелестят:</p>
                            <p>Шурочка, Шурочка…</p>
                            <p><b>(Агния Барто)</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    Весна
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    Лето
                                </button>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    Осень
                                </button>
                                <button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    Зима
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Резкий, морозный воздух так и охватит всего, когда выйдешь из дому. За садом ещё холодно краснеет заря. Солнце только что выкатилось огнистым шаром из-за снежного поля; но вся картина села уже сверкает яркими и удивительно нежными, чистыми красками северного утра. Клубы дыма алеют и медленно расходятся над белыми крышами. Сад — в серебряном инее… Туда-то мне и нужно!</p>
                            <p><b>(Иван Алексеевич Бунин)</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    Весна
                                </button>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    Лето
                                </button>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    Осень
                                </button>
                                <button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    Зима
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Над землёю воздух дышит</p>
                            <p>День от дня теплее;</p>
                            <p>Стали утром зорьки ярче,</p>
                            <p>На небе светлее.</p>
                            <p>Всходит солнце над землёю</p>
                            <p>С каждым днём всё выше.</p>
                            <p>И весь день, кружась, воркуют</p>
                            <p>Голуби на крыше.</p>
                            <p>В небе тучка набежала,</p>
                            <p>Мелкий дождик сеет…</p>
                            <p>В поле травка показалась,</p>
                            <p>Поле зеленеет.</p>
                            <p><b>(Иван Суриков)</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    Весна
                                </button>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    Лето
                                </button>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    Осень
                                </button>
                                <button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    Зима
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>Пахнет грибами, листом перепрелым,</p>
                            <p>Пахнет и чем-то другим,</p>
                            <p>Точно горелым.</p>
                            <p>В синей дали наползающий медленный дым.</p>
                            <p>Дым и ползёт, и как будто бы ждёт он чего-то,</p>
                            <p>Будто бы он говорит:</p>
                            <p>Вот я, идите же.</p>
                            <p>Это горит</p>
                            <p>Торфяное болото.</p>
                            <p><b>(Константин Бальмонт)</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    Весна
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    Лето
                                </button>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    Осень
                                </button>
                                <button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    Зима
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;