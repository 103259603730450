import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бегу́т</b> (v. бежа́ть) correm</p>
                        <p><b>безве́тренно</b> sem vento</p>
                        <p><b>безо́блачно</b> sem nuvens</p>
                        <p><b>бланк</b> formulário</p>
                        <p><b>блу́зку</b> (Acus.) blusa</p>
                        <p><b>больно́й</b> doente (m.)</p>
                        <p><b>боти́нки</b> botas</p>
                        <p><b>вéтрено</b> (adv.) ventoso</p>
                        <p><b>высо́кое</b> alto (n.)</p>
                        <p><b>вы́ше нуля́</b> acima de zero</p>
                        <p><b>гру́стная</b> triste (f.)</p>
                        <p><b>дава́л</b> (v. дава́ть) dei (m.)</p>
                        <p><b>дава́ть</b> dar</p>
                        <p><b>да́рит</b> (v. дари́ть) oferece</p>
                        <p><b>дари́ть</b> oferecer</p>
                        <p><b>дождли́во</b> (adv.) chuvoso</p>
                        <p><b>дома́шнее зада́ние</b> trabalho de casa</p>
                        <p><b>драгоце́нности</b> jóias</p>
                        <p><b>ей</b> (Dat.) a ela/ lhe</p>
                        <p><b>жа́рко</b> (adv.) calor</p>
                        <p><b>(за) де́ньги</b> (por) dinheiro</p>
                        <p><b>зави́сит</b> (v. зави́сеть) depende</p>
                        <p><b>звони́ла</b> (v. звони́ть) telefonava (f.)</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>звони́ть</b> telefonar</p>
                        <p><b>здоро́вый</b> saudável (m.)</p>
                        <p><b>зна́чит</b> (v. зна́чить) significa</p>
                        <p><b>коне́ц</b> final/ fim</p>
                        <p><b>кот</b> gato</p>
                        <p><b>ломба́рда</b> (Gen.) da casa de penhores</p>
                        <p><b>лу́чшая</b> melhor (f.)</p>
                        <p><b>мо́крый</b> húmido</p>
                        <p><b>молоды́е</b> jovens</p>
                        <p><b>моро́зно</b> (dia) gelado</p>
                        <p><b>(на) вре́мя</b> (por) pouco tempo</p>
                        <p><b>(на) коро́ткий срок</b> por um curto período de tempo</p>
                        <p><b>надо́лго</b> por muito tempo</p>
                        <p><b>нача́ло</b> início</p>
                        <p><b>ненадо́лго</b> por pouco tempo</p>
                        <p><b>ни́же нуля́</b> abaixo de zero</p>
                        <p><b>ни́зкий</b> (adj.) baixo</p>
                        <p><b>Но́вый Год</b> Ano Novo</p>
                        <p><b>о́блачно</b> (adv.) nublado</p>
                        <p><b>опя́ть</b> outra vez</p>
                        <p><b>па́смурно</b> (adv.) (céu) encoberto</p>
                        <p><b>пе́нсия</b> reforma</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пода́рки</b> prendas</p>
                        <p><b>помога́ет</b> (v. помога́ть) ajuda</p>
                        <p><b>помо́чь</b> ajudar</p>
                        <p><b>потеря́л</b> (v. потеря́ть) perdeu (m.)</p>
                        <p><b>привы́чка</b> hábito</p>
                        <p><b>прохла́дно</b> (adv.) (dia) fresco</p>
                        <p><b>пухо́вый плато́к</b> xaile de lã</p>
                        <p><b>рассказа́ли</b> (v. рассказа́ть) contaram</p>
                        <p><b>расска́зывать</b> contar</p>
                        <p><b>смеётся</b> (v. смея́ться) está a rir</p>
                        <p><b>смешно́й</b> (adj.) engraçado</p>
                        <p><b>сне́жно</b> (adv.) (dia) nevoso</p>
                        <p><b>cо́лнечно</b> (adv.) ensolarado</p>
                        <p><b>сон</b> sonho</p>
                        <p><b>счастли́вый</b> feliz (m.)</p>
                        <p><b>така́я</b> assim (f.)</p>
                        <p><b>тепло́</b> (adv.) (dia) quente</p>
                        <p><b>тума́нно</b> (adv.) (dia) nevoento</p>
                        <p><b>тяжело́</b> (adv.) difícil</p>
                        <p><b>утю́г</b> ferro de engomar</p>
                        <p><b>хо́лодно</b> (adv.) frio</p>
                        <p><b>хоти́м</b> (v. хоте́ть) queremos</p>
                        <p><b>я́сно</b> (adv.) (céu) limpo</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;