import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бeрём</b>(v. брать) juntamos</p>
                        <p><b>блины</b> crepes</p>
                        <p><b>(в) магази́не</b> (na) loja</p>
                        <p><b>ви́деть</b> ver</p>
                        <p><b>ви́дишь</b>(v. ви́деть) vês</p>
                        <p><b>ви́жу</b>(v. ви́деть) vejo</p>
                        <p><b>вини́тельный падеж</b> caso acusativo</p>
                        <p><b>гре́ем</b>(v. греть) aquecemos</p>
                        <p><b>гре́чка</b> trigo sarraceno</p>
                        <p><b>добавля́ем</b>(v. добавля́ть) adicionamos</p>
                        <p><b>докла́д</b> comunicação</p>
                        <p><b>жа́рим</b>(v. жа́рить) fritamos</p>
                        <p><b>жа́рко</b> (adv.) quente</p>
                        <p><b>журна́л</b> revista</p>
                        <p><b>за́втра</b> amanhã</p>
                        <p><b>звать</b> chamar</p>
                        <p><b>зе́ркало</b> espelho</p>
                        <p><b>зна́ю</b>(v. зна́ть) sei</p>
                        <p><b>игра́ет</b>(v. игра́ть) fazer papel de</p>
                        <p><b>(из) актёров</b> (Gen.) (dos) atores</p>
                        <p><b>и́ли</b> ou</p>
                        <p><b>ка́ша</b> papa</p>
                        <p><b>кого́</b> (Acus.) quem</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>компо́т</b> bebida feita à base da cozedura de frutas e bagas</p>
                        <p><b>ку́рица</b> galinha</p>
                        <p><b>легко́</b> (adv.) fácil</p>
                        <p><b>люби́мая</b> 1. querida 2. amada</p>
                        <p><b>лю́бишь</b>(v. лю́бить) gostas</p>
                        <p><b>люблю́</b>(v. лю́бить) gosto</p>
                        <p><b>ма́сло</b> óleo</p>
                        <p><b>меша́ем</b>(v. меша́ть) mexemos</p>
                        <p><b>мо́жет быть</b> talvez</p>
                        <p><b>мо́жешь</b>(v. мочь) podes</p>
                        <p><b>молоко́</b> leite</p>
                        <p><b>моро́женое</b> gelado</p>
                        <p><b>(на) столе́</b> (na) mesa</p>
                        <p><b>налива́ем</b>(v. налива́ть) pomos</p>
                        <p><b>ненави́жу</b>(v. ненави́деть) odeio</p>
                        <p><b>но́мер оди́н</b> número um</p>
                        <p><b>обожа́ю</b>(v. обожа́ть) adoro</p>
                        <p><b>объясни́ть</b> explicar</p>
                        <p><b>опя́ть</b> novamente</p>
                        <p><b>осо́бенно</b> (adv.) especialmente</p>
                        <p><b>перевора́чиваем</b>(v. перевора́чивать) viramos</p>
                        <p><b>писа́телей</b> (Acus.) escritores</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пишу́</b>(v. писа́ть) escrevo</p>
                        <p><b>повторя́ет</b>(v. повторя́ть) repete</p>
                        <p><b>покупа́ет</b>(v. покупа́ть) compra</p>
                        <p><b>понима́ю</b>(v. понима́ть) entendo</p>
                        <p><b>пото́м</b> depois</p>
                        <p><b>са́хар</b> açúcar</p>
                        <p><b>сейча́с</b> agora</p>
                        <p><b>селёдка</b> arenque</p>
                        <p><b>сковоро́дка</b> frigideira</p>
                        <p><b>слу́шаете</b>(v. слу́шать) ouve/-em</p>
                        <p><b>смотрю́</b>(v. смотре́ть) vejo</p>
                        <p><b>совсе́м</b> de todo</p>
                        <p><b>соль</b> sal</p>
                        <p><b>те́сто</b> massa</p>
                        <p><b>упражне́ние</b> exercício</p>
                        <p><b>уро́к</b> aula</p>
                        <p><b>хлеб</b> pão</p>
                        <p><b>хоти́те</b>(v. хоте́ть) quer/-em</p>
                        <p><b>хо́чешь</b>(v. хоте́ть) queres</p>
                        <p><b>чуть-чуть</b> um pouco</p>
                        <p><b>шаг</b> passo</p>
                        <p><b>шампу́нь</b> champô</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;