import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'стоя', name: 'стоя', translation: 'стоя' },
    { id: 'благодаря', name: 'благодаря', translation: 'благодаря' },
    { id: 'смотря', name: 'смотря', translation: 'смотря' },
    { id: 'спустя', name: 'спустя', translation: 'спустя' },
    { id: 'не включая', name: 'не включая', translation: 'не включая' },
    { id: 'судя', name: 'судя', translation: 'судя' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <span className={classes.cardWrapper}>
                <span ref={drag} className={classes.dragCard}>
                    {name}
                </span>
            </span>
        );
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <span className={classes.cardWrapper}>
                <span ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <DragCard key={item.id} type={item.id} name={item.name} /> :
                        <span className={classes.dropCard} type={item.id}>largar aqui</span>
                    }
                </span>
            </span>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = (
            <div className={classes.questionsWrapper}>
                <div className={classes.question}>
                    <p>1. Они работали <DropCard key={dropCards[3].id} item={dropCards[3]} /> рукава.</p>
                    <p>2. Он вошёл на цыпочках, <DropCard key={dropCards[4].id} item={dropCards[4]} /> свет.</p>
                    <p>3. ... <DropCard key={dropCards[1].id} item={dropCards[1]} /> и улыбаясь, он попрощался со мной.</p>
                    <p>4. Зачастую люди считают меня высокомерной,  <DropCard key={dropCards[5].id} item={dropCards[5]} /> по социальным сетям, но пообщавшись со мной они понимают, что это не так.</p>
                    <p>5. Она проводит целый день, <DropCard key={dropCards[2].id} item={dropCards[2]} /> в окно.</p>
                    <p>6. ... <DropCard key={dropCards[0].id} item={dropCards[0]} /> на табуретке, он доставал книги с верхней полки.</p>
                </div>
            </div>
        );

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Выберите деепричастие." />
                <div className={classes.dragAndDropContainer}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;