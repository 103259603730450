import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson17 from '../../../../../../Assets/Images/A1/Lesson17/image1lesson17.png';
import Image2Lesson17 from '../../../../../../Assets/Images/A1/Lesson17/image2lesson17.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>Глаго́лы движе́ния ИДТИ́, Е́ХАТЬ</p>
                    <p>Verbos de movimento</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <div className={classes.gridItem1Item1}>
                                <p><b>СУЩЕСТВИ́ТЕЛЬНОЕ</b></p>
                            </div>
                            <div className={classes.gridItem1Item2}>
                                <p><b>Португа́л</b>ия</p>
                                <p>Росси́я (<b>Рус</b>ь)</p>
                                <p><b>Кита́й</b></p>
                                <p><b>А́нгли</b>я</p>
                                <p><b>Испа́н</b>ия</p>
                                <p><b>Украи́н</b>а</p>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b>+ СК</b></p>
                        </div>
                        <div className={classes.gridItem3}>
                            <div className={classes.gridItem3Item1}>
                                <p><b>ПРИЛАГА́ТЕЛЬНОЕ</b></p>
                            </div>
                            <div className={classes.gridItem3Item2}>
                                <p><b>португа́ль<span className={classes.orangeHighlight}>СК</span>иЙ</b></p>
                                <p><b>ру́с<span className={classes.orangeHighlight}>СК</span>иЙ</b></p>
                                <p><b>кита́й<span className={classes.orangeHighlight}>СК</span>иЙ</b></p>
                                <p><b>англи́й<span className={classes.orangeHighlight}>СК</span>иЙ</b></p>
                                <p><b>испа́н<span className={classes.orangeHighlight}>СК</span>иЙ</b></p>
                                <p><b>украи́н<span className={classes.orangeHighlight}>СК</span>иЙ</b></p>
                            </div>
                        </div>
                        <div className={classes.gridItem4}>
                            <p><b>+ ПО-</b></p>
                        </div>
                        <div className={classes.gridItem5}>
                            <div className={classes.gridItem5Item1}>
                                <p><b>НАРЕЧИЕ</b></p>
                            </div>
                            <div className={classes.gridItem5Item2}>
                                <p><b><span className={classes.orangeHighlight}>по-</span>португа́льски</b></p>
                                <p><b><span className={classes.orangeHighlight}>по-</span>ру́сски</b></p>
                                <p><b><span className={classes.orangeHighlight}>по-</span>кита́йски</b></p>
                                <p><b><span className={classes.orangeHighlight}>по-</span>англи́йски</b></p>
                                <p><b><span className={classes.orangeHighlight}>по-</span>испа́нски</b></p>
                                <p><b><span className={classes.orangeHighlight}>по-</span>украи́нски</b></p>
                            </div>
                        </div>
                        <div className={classes.gridItem6}>
                            <p><b> - й</b></p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ТО́ЖЕ vs. ТА́КЖЕ</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b><span className={classes.orangeHighlight}> ТО́ЖЕ</span></b> (também) – Emprega-se quando há uma comparação entre dois sujeitos:
                            </p>
                            <p>
                                <i><b><span className={classes.orangeHighlight}>Я</span></b> люблю́ фэ́нтэзи, и  <b><span className={classes.orangeHighlight}>моя́ ма́ма</span> то́же.</b></i>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b><span className={classes.orangeHighlight}> ТА́КЖЕ</span></b> ((e) também/ainda) – Emprega-se quando se acrescenta informação adicional:
                            </p>
                            <p>
                                <i><b><span className={classes.orangeHighlight}>Я</span></b> люблю́ фэ́нтэзи, а <b>та́кже</b> кла́ссику.</i>
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ПОРЯДОК СЛОВ "О́ЧЕНЬ"</p>
                    <p>Ordem das Palavras</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <img id="Image1Lesson17" src={Image1Lesson17} alt="go verb" width="300px" />
                </div>
                <GrammarSubtitle>
                    <p>"О́ЧЕНЬ" vs. "МНО́ГО"</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <div>
                                <p>
                                    MUITO
                                </p>
                            </div>
                            <div>
                                <p>
                                    =
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span className={classes.orangeHighlight}>1. О́ЧЕНЬ</span>
                                </p>
                                <p>
                                    <span className={classes.orangeHighlight}>2. МНО́ГО</span>
                                </p>
                                <p>
                                    <span className={classes.orangeHighlight}>3. О́ЧЕНЬ МНО́ГО</span>
                                </p>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="Image2Lesson17" src={Image2Lesson17} alt="different ways of pronouncing a lot" width="250px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;