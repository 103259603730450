import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>гря́зная</b> suja</p>
                        <p><b>“Джентельме́ны уда́чи”</b> “Cavalheiros da sorte”</p>
                        <p><b>игра́</b> jogo</p>
                        <p><b>имени́тельный паде́ж</b> caso nominativo</p>
                        <p><b>кошелёк</b> carteira</p>
                        <p><b>краси́вый</b> bonito</p>
                        <p><b>купи́ла</b> (v. купи́ть) compraste (f.)</p>
                        <p><b>мне́ние</b> opinião</p>
                        <p><b>молодо́й челове́к</b> jovem (m.)</p>
                        <p><b>(на) день рожде́ния</b> (no) dia de aniversário</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>но́мер</b> número</p>
                        <p><b>означа́ет</b> (v. означа́ть) significa</p>
                        <p><b>пла́тье</b> vestido</p>
                        <p><b>подари́ли</b> (v. подари́ть) ofereceram</p>
                        <p><b>потеря́л</b> (v. потеря́ть) perdi (m.)</p>
                        <p><b>ру́сское лото́</b> loto russo</p>
                        <p><b>солёный</b> salgado</p>
                        <p><b>споко́йные цветá</b> cores calmas</p>
                        <p><b>тa</b> aquela/essa</p>
                        <p><b>тe</b> aqueles/ aquelas/esses/essas</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>то</b> aquele/esse (n.)</p>
                        <p><b>тот</b> aquele/esse</p>
                        <p><b>цвет</b> cor</p>
                        <p><b>часы́</b> relógio</p>
                        <p><b>“Что это такóе?”</b> “O que é isto?”</p>
                        <p><b>э́тa</b> esta</p>
                        <p><b>э́ти</b> estes/estas</p>
                        <p><b>э́то</b> 1. este 2. isto (n.)</p>
                        <p><b>э́тот</b> este (m.)</p>
                        <p><b>я́ркий</b> brilhante</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;