import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>акушéр</b> obstetra (m.)</p>
                        <p><b>(без) неё</b> (sem) ela</p>
                        <p><b>боли́т</b> (v. боле́ть) dói</p>
                        <p><b>боля́т</b> (v. боле́ть) doem</p>
                        <p><b>ге́ны</b> genes</p>
                        <p><b>гýбы</b> lábios</p>
                        <p><b>гаймори́т</b> um dos tipos de sinusite</p>
                        <p><b>глазá</b> olhos</p>
                        <p><b>голова́</b> cabeça</p>
                        <p><b>го́рло</b> garganta</p>
                        <p><b>грудь</b> peito</p>
                        <p><b>дви́гаемся</b> (v. дви́гаться) movemos</p>
                        <p><b>же́нский врач</b> ginecologista</p>
                        <p><b>живо́т</b> 1. abdómen 2. barriga</p>
                        <p><b>зу́бы</b> dentes</p>
                        <p><b>Как ты себя́ чу́вствуешь?</b> Como te sentes?</p>
                        <p><b>ка́рие глазá</b> olhos castanhos</p>
                        <p><b>ки́сти</b> mãos</p>
                        <p><b>ко́жа</b> pele</p>
                        <p><b>коле́но</b> joelho</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>кудря́вые</b> encaracolados/as</p>
                        <p><b>леворýкость</b> canhotismo</p>
                        <p><b>ле́чит</b> (v. лечи́ть) cura</p>
                        <p><b>лечи́ть</b> curar</p>
                        <p><b>лоб</b> testa</p>
                        <p><b>ло́кти</b> cotovelos</p>
                        <p><b>лор</b> otorrinolaringologista</p>
                        <p><b>могу́</b> (v. мочь) posso</p>
                        <p><b>нóги</b> pernas</p>
                        <p><b>не о́чень</b> mais ou menos</p>
                        <p><b>нева́жно</b> nada grave</p>
                        <p><b>нос</b> nariz</p>
                        <p><b>о́коло</b> perto</p>
                        <p><b>па́льцы</b> dedos</p>
                        <p><b>пле́чи</b> ombros</p>
                        <p><b>подборо́док</b> queixo</p>
                        <p><b>по́лные</b> grossos/as</p>
                        <p><b>по́чки</b> rins</p>
                        <p><b>принима́ет</b> (v. принима́ть) <b>рóды</b> realiza o parto</p>
                        <p><b>прямы́е во́лосы</b> cabelos lisos</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пя́тки</b> calcanhares</p>
                        <p><b>рот</b> boca</p>
                        <p><b>ру́ки</b> braços</p>
                        <p><b>све́тлая</b> clara</p>
                        <p><b>се́рдце</b> coração</p>
                        <p><b>слома́л</b> (v. слома́ть) partiu</p>
                        <p><b>сму́глая</b> de pele morena</p>
                        <p><b>спина́</b> costas</p>
                        <p><b>ступни́</b> pés</p>
                        <p><b>та́лия</b> cintura</p>
                        <p><b>те́ло</b> corpo</p>
                        <p><b>терапе́вт</b> terapeuta</p>
                        <p><b>у вас</b> (Gen.) você tem/ vocês têm</p>
                        <p><b>у нас</b> (Gen.) nós temos</p>
                        <p><b>у него́</b> (Gen.) ele tem</p>
                        <p><b>у неё</b> (Gen.) ela tem</p>
                        <p><b>у тебя́</b> (Gen.) tu tens</p>
                        <p><b>ужа́сно</b> terrívelmente</p>
                        <p><b>ху́же не́куда</b> pior do que nunca</p>
                        <p><b>ше́я</b> pescoço</p>
                        <p><b>щёки</b> bochechas</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;