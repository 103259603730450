import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Lesson33 from '../../../../../../Assets/Images/A1/Lesson33/image1lesson33.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПОВЕЛИТЕЛЬНОЕ НАКЛОНЕНИЕ</p>
                    <p>Imperativo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O imperativo forma-se a partir do radical da forma da 1.ª pess. sg, à qual se adiciona a terminação. Há três terminações de infinitivo (dependendo do verbo): <b><span className={classes.orangeHighlight}>- И́(ТЕ)/ -Й(ТЕ)/ - (ТЕ)/ -Ь(ТЕ)</span></b>
                    </p>
                </div>
                <div className={classes.item2}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> As partículas reflexivas <b><span className={classes.orangeHighlight}>СЬ/СЯ</span></b> são pós-fixas à terminação de infinitivo e seguem as mesmas regras do Presente. (vogal <b><span className={classes.orangeHighlight}>+СЬ</span></b>/cons<b><span className={classes.orangeHighlight}>.+СЯ</span></b>)
                    </p>
                </div>
                <div className={classes.item3}>
                    <img id="Image1Lesson33" src={Image1Lesson33} alt="lesson 33 illustrative example 1" width="600" />
                </div>
                <GrammarSubtitle>
                    <p>ВЫРАЖЕНИЯ НАПРАВЛЕНИЯ Е МЕСТОНАХОЖДЕНИЯ</p>
                    <p>Expressões de direção</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><b>МЕ́СТОНАХОЖДЕ́НИЕ</b></p>
                            <div>
                                <div className={classes.gridItem1Item1}>
                                    <p><b><span className={classes.orangeHighlight}>ГДЕ</span></b></p>
                                </div>
                                <div className={classes.gridItem1Item2}>
                                    <p>быть</p><p>находи́ться</p><p>ви́деть</p>
                                </div>
                                <div className={classes.gridItem1Item3}>
                                    <p><b>сле́ва</b></p>
                                    <p><b>спра́ва</b></p>
                                    <p><b>*све́рху</b></p>
                                    <p><b>*сни́зу</b></p>
                                    <p><b>пря́мо</b></p>
                                    <p><b>впереди́</b></p>
                                    <p><b>сза́ди</b></p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b><span className={classes.orangeHighlight}>!</span></b></p>
                            <p><b>СВЕ́РХУ = НАВЕРХУ́</b></p><p><b>СНИ́ЗУ = ВНИЗУ́</b></p><p><b>НАВЕ́РХ = ВВЕ́РХ</b></p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p><b>НАПРАВЛЕ́НИЕ</b></p>
                            <div>
                                <div className={classes.gridItem1Item1}>
                                    <p><b><span className={classes.greenHighlight}>КУДА́</span></b></p>
                                </div>
                                <div className={classes.gridItem1Item2}>
                                    <p>идти́</p><p>е́хать</p><p>поверну́ть</p><p>посмотре́ть</p>
                                </div>
                                <div className={classes.gridItem1Item3}>
                                    <p><b>нале́во</b></p>
                                    <p><b>напра́во</b></p>
                                    <p><b>*наве́рх</b></p>
                                    <p><b>вниз</b></p>
                                    <p><b>пря́мо</b></p>
                                    <p><b>вперёд</b></p>
                                    <p><b>наза́д</b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.item5}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b><span className={classes.orangeHighlight}>СПРА́ВА/СЛЕ́ВА ОТ</span> <i>+ Род. п.</i></b>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <i>Йогу́рты нахо́дятся <b><span className={classes.orangeHighlight}>спра́ва от</span></b> молок<b><span className={classes.orangeHighlight}>а́</span></b>.</i>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;