import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import video_aula_21 from './Video/video_aula_21.mp4';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: true },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: false },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: true },
    { id: 10, isCorrect: false },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: true },
    { id: 15, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    const exerciseQuestion = (
        <p>Посмотрите фильм <span className={classes.orangeHighlight}>&quot;Вацлав Нижинский. Бог танца&quot;</span> и ответьте на вопросы</p>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <a className={classes.downloadVideoLink} href={video_aula_21} download="video_aula_21">Bидео Урок 21 - Вацлав Нижинский. Бог танца</a>
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>1) Современники называли Вацлава Нижинского &quot;Богом танца&quot;. А ещё как?</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    искусством на цыпочках
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    чудо-птицей
                                </button>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    восьмым чудом света
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>2) Его также называли &quot;Человеком-птицей&quot;, потому что он перелeтал…</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    ...полсцены
                                </button>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    ...над сценой
                                </button>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    ...целые сцены
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>3) Сколько ему было лет, когда он поставил свой первый балет?</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    21 год
                                </button>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    22 года
                                </button>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    23 года
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>4) С кем Нижинский сравнивал Дягилева?</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    С Ван Гогом
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    С Леонардо да Винчи
                                </button>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    C Микеланджело
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p>5) Что стало синонимом танца?</p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    Движения Нижинского
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    Имя Нижинского
                                </button>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    ноги Нижинского
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;