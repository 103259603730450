import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>(в) го́роде</b> (Prepos.) (na) cidade</p>
                        <p><b>(в) дере́вне</b> (Prepos.) (na) aldeia</p>
                        <p><b>(в) до́ме- музе́е</b> (Prepos.) (na) casa-museu</p>
                        <p><b>(в) океа́не</b> (Prepos.) (no/ dentro do) oceano</p>
                        <p><b>(в) по́езде</b> (Prepos.) (no) comboio</p>
                        <p><b>(в) реке́</b> (Prepos.) (no/ dentro de) rio</p>
                        <p><b>(в) са́дике</b> (Prepos.) (no) infantário</p>
                        <p><b>(в) самолёте</b> (Prepos.) (no/ dentro do) avião</p>
                        <p><b>(в) стране</b>́ (Prepos.) (no) país</p>
                        <p><b>(в) я́щике</b> (Prepos.) (na) caixa/ (no) caixote</p>
                        <p><b>(на) велосипе́де</b> (Prepos.) (de) bicicleta</p>
                        <p><b>(на) вокза́ле</b> (Prepos.) (na) estacão</p>
                        <p><b>(на) голове́</b> (Prepos.) (na/ em cima da) cabeça</p>
                        <p><b>(на) земле́</b> (Prepos.) (na) terra</p>
                        <p><b>(на) о́строве</b> (Prepos.) (na) ilha</p>
                        <p><b>(на) по́езде</b> (Prepos.) (de) comboio</p>
                        <p><b>(на) по́чте</b> (Prepos.) (no) correio</p>
                        <p><b>(на) рабо́те</b> (Prepos.) (no) trabalho</p>
                        <p><b>(на) фа́брике</b> (Prepos.) (na) fábrica</p>
                        <p><b>(на) ю́ге</b> (Prepos.) (no) sul</p>
                        <p><b>а́дрес</b> morada</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ва́нна</b> banheira</p>
                        <p><b>ва́нная</b> casa de banho</p>
                        <p><b>вода́</b> água</p>
                        <p><b>вот</b> aqui/eis</p>
                        <p><b>все</b> todos/ todas</p>
                        <p><b>встре́ча</b> encontro</p>
                        <p><b>где</b> onde</p>
                        <p><b>горя́чая</b> (fem.) quente</p>
                        <p><b>гости́ная</b> sala de estar</p>
                        <p><b>дере́вня</b> aldeia</p>
                        <p><b>до́ма</b> (Prepos.) em casa</p>
                        <p><b>е́дем</b>(v. е́хать) vamos</p>
                        <p><b>живём</b>(v. жить) vivemos</p>
                        <p><b>заходи́те</b>(v. заходи́ть) entre (Imperativo)</p>
                        <p><b>зда́ние</b> prédio</p>
                        <p><b>зоопа́рк</b> jardim zoológico</p>
                        <p><b>карма́н</b> bolso</p>
                        <p><b>кварти́ра</b> apartamento</p>
                        <p><b>колóнка</b> esquentador</p>
                        <p><b>коне́чно</b> claro/com certeza</p>
                        <p><b>кре́сло</b> poltrona</p>
                        <p><b>крова́ть</b> cama</p>
                        <p><b>ку́хня</b> cozinha</p>
                        <p><b>на столе́</b>(Prepos.) (na) mesa</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>нале́во</b> à esquerda</p>
                        <p><b>напра́во</b> à direita</p>
                        <p><b>но́вые</b> novos</p>
                        <p><b>мне нра́вится</b>(v. нра́виться) eu gosto</p>
                        <p><b>отдыха́ем</b>(v. отдыха́ть) estamos a descansar</p>
                        <p><b>отли́чно</b> ótimo</p>
                        <p><b>письмо́</b> carta</p>
                        <p><b>пла́ваем</b>(v. пла́вать) nadamos</p>
                        <p><b>плита́</b> fogão</p>
                        <p><b>пляж</b> praia</p>
                        <p><b>посу́да</b> loiça</p>
                        <p><b>рабо́та</b> trabalho</p>
                        <p><b>рука́</b> mão</p>
                        <p><b>се́вер</b> norte</p>
                        <p><b>слова́</b> palavras</p>
                        <p><b>слова́рь</b> dicionário</p>
                        <p><b>спа́льня</b> quarto de dormir</p>
                        <p><b>стол</b> mesa</p>
                        <p><b>сту́лья</b> cadeiras</p>
                        <p><b>то́же</b> também</p>
                        <p><b>то́лько</b> só</p>
                        <p><b>холоди́льник</b> frigorifico</p>
                        <p><b>шкаф</b> armário</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;