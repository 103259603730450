import React from 'react';
import Aux from '../Aux/Aux';
import classes from './Layout.css';

export default class Layout extends React.Component {
    state = {
        showSideDrawer: true
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => { return { showSideDrawer: !prevState.showSideDrawer } });
    }

    render() {
        return (<Aux>
            {/*<Toolbar drawerToggleClicked={this.sideDrawerToggleHandler}/>
            <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler} />*/}
            <main className={classes.Content}>{this.props.children}</main>
        </Aux>
        );
    }
}