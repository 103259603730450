import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson25/image1grammar.png';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>СПОВЕЛИТЕЛЬНОЕ НАКЛОНЕНИЕ</p>
                    <p>Imperativo I</p>
                    <p>НСВ vs. СВ</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> No imperativo, os verbos conjugam-se na 2ª pessoa (singular/plural), <b>дéлай <sup>2ºp.sg.</sup> /дéлайте <sup>2ºp.pl.</sup></b> (faz/façam), e são agrupados em pares aspectuais (imperfetivo/perfetivo): <b><i><span className={classes.orangeHighlight}>(c)дéлай(те)</span></i></b>.</p>
                    </div>
                    <div className={classes.item1_2}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Os verbos perfetivos no Imperativo apontam para uma ação pontual e os imperfetivos para uma ação contínua ou que se repete:</p>
                    </div>
                    <div className={classes.item1_3}>
                        <p><i><b><span className={classes.orangeHighlight}>Закро́й</span></b> дверь, пожа́луйста! (Fecha a porta, por favor!)</i></p>
                        <p><i>Всегда́ <b><span className={classes.orangeHighlight}>закрыва́й</span></b> дверь, пожа́луйста! (Fecha sempre a porta, por favor!)</i></p>
                    </div>
                    <div className={classes.item1_4}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Quando precedidos por um elemento de negação, em geral, os verbos no Imperativo têm a forma imperfetiva.</p>
                    </div>
                    <div className={classes.item1_5}>
                        <p><i><b><span className={classes.orangeHighlight}>Не закрыва́й</span></b> дверь, пожа́луйста! <strike>Не закро́й дверь, пожа́луйста!</strike></i></p>
                        <p><i>(Não feches a porta, por favor)</i></p>
                    </div>
                </div>
                <div className={classes.item2}>
                    <table className={classes.item2_table}>
                        <thead>
                            <tr>
                                <th><p>инфинитив</p><p>НСВ</p></th>
                                <th><p>императив</p><p>НСВ</p></th>
                                <th><p>инфинитив</p><p>СВ</p></th>
                                <th><p>императив</p><p>СВ</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>писа́ть</td>
                                <td>пиши́(те)</td>
                                <td>написа́ть</td>
                                <td>напиши́(те)</td>
                            </tr>
                            <tr>
                                <td>cмотре́ть</td>
                                <td>смотри́(те)</td>
                                <td>посмотре́ть</td>
                                <td>посмотри́(те)</td>
                            </tr>
                            <tr>
                                <td>чита́ть</td>
                                <td>чита́й(те)</td>
                                <td>прочита́ть</td>
                                <td>прочита́й(те)</td>
                            </tr>
                            <tr>
                                <td>учи́ть</td>
                                <td>учи́(те)</td>
                                <td>вы́учить</td>
                                <td>вы́учи(те)</td>
                            </tr>

                            <tr>
                                <td>идти́</td>
                                <td>иди́</td>
                                <td>пойти́</td>
                                <td>пойди́</td>
                            </tr>
                            <tr>
                                <td>гото́вить</td>
                                <td>гото́вь(те)</td>
                                <td>пригото́вит ь</td>
                                <td>пригото́вь(те)</td>
                            </tr>
                            <tr>
                                <td>быть</td>
                                <td>бýдь(те)</td>
                                <td>---</td>
                                <td>---</td>
                            </tr>
                            <tr>
                                <td>кури́ть</td>
                                <td>кури́(те)</td>
                                <td>покури́ть</td>
                                <td>покури́(те)</td>
                            </tr>
                            <tr>
                                <td>игра́ть</td>
                                <td>игра́й(те)</td>
                                <td>поигра́ть</td>
                                <td>поигра́й(те)</td>
                            </tr>
                            <tr>
                                <td>есть</td>
                                <td>éшь(те)</td>
                                <td>поéсть</td>
                                <td>поéшь(те)</td>
                            </tr>
                            <tr>
                                <td>спра́шивать</td>
                                <td>спра́шивай(те)</td>
                                <td>спроси́ть</td>
                                <td>спроси́(те)</td>
                            </tr>
                            <tr>
                                <td>помога́ть</td>
                                <td>помога́й(те)</td>
                                <td>помóчь</td>
                                <td>помоги́(те)</td>
                            </tr>
                            <tr>
                                <td>купи́ть</td>
                                <td>купи́(те)</td>
                                <td>покупа́ть</td>
                                <td>покупа́й(те)</td>
                            </tr>
                            <tr>
                                <td>дава́ть</td>
                                <td>дава́й(те)</td>
                                <td>дать</td>
                                <td>дáй(те)</td>
                            </tr>
                            <tr>
                                <td>говори́ть</td>
                                <td>говори́(те)</td>
                                <td>сказáть</td>
                                <td>скажи́(те)</td>
                            </tr>
                            <tr>
                                <td>сади́ться</td>
                                <td>сади́(те)сь</td>
                                <td>сесть</td>
                                <td>ся́дь(те)</td>
                            </tr>
                            <tr>
                                <td>закрывáть</td>
                                <td>закрывáй(те)</td>
                                <td>закры́ть</td>
                                <td>закро́й(те)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Quando não se trata de um referente/destinatário específico a quem é dirigido o pedido, em vez do imperativo, pode ser usada a forma infinitiva do verbo:</p>
                    </div>
                    <div className={classes.item3_2}>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="50px" />
                        <p>Не <b><i><span className={classes.orangeHighlight}>курить</span></i></b>! (Não fumar!)</p>
                    </div>
                    <div className={classes.item3_3}>
                        <p>(Este tipo de uso também ocorre frequentemente nas instruções ou receitas culinárias.)</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;