import React from 'react';
import HomePage from '../../../Assets/Images/home-page.png';
import Return from '../../../Assets/Images/return.png';
import Lessons from '../../../Assets/Images/indice.png';
import StBasilsCathedral from '../../../Assets/Images/st-basils-cathedral.png';
import classes from './MainHeader.css';
import { Link } from 'react-router-dom';

const mainHeader = props => {
    const homePage = <div className={classes.HomePageDiv}>
        <Link to="/">
            <img
                className={classes.HomePageImg}
                alt="home page img"
                src={HomePage}
                title="Página Inicial"
            />
        </Link>
    </div>;

    const goBack = props.goBack ? <div className={classes.GoBackDiv}>
        <button className={classes.GoBackButton} onClick={props.goBack}>
            <img
                className={classes.GoBackImg}
                alt="go back img"
                src={Return}
            />
        </button>
    </div> : null;

    let lessons = null;

    if (props.goToLessons) {
        lessons = <div className={classes.GoToLessonsDiv}>
            <Link to="/Levels/A1">
                <img
                    className={classes.GoToLessonsImg}
                    alt="go to lessons img"
                    src={Lessons}
                />
            </Link>
        </div>
    } else if (props.goToLessonsA2) {
        lessons = <div className={classes.GoToLessonsDiv}>
            <Link to="/Levels/A2">
                <img
                    className={classes.GoToLessonsImg}
                    alt="go to lessons img"
                    src={Lessons}
                />
            </Link>
        </div>
    } else if (props.goToLessonsB1) {
        lessons = <div className={classes.GoToLessonsDiv}>
            <Link to="/Levels/B1">
                <img
                    className={classes.GoToLessonsImg}
                    alt="go to lessons img"
                    src={Lessons}
                />
            </Link>
        </div>
    }

    const stBasilsCathedral = <div className={classes.StBasilsCathedral}>
        <img
            className={classes.StBasilsImg}
            src={StBasilsCathedral}
            alt="St.Basils Cathedral" />
    </div>;

    const subtitle = props.subtitle ? <p className={classes.Subtitle}>{props.subtitle}</p> : null;

    return (
        <div className={classes.Header}>
            <div className={classes.NavigationIcons}>{homePage}{lessons}{goBack}</div>
            <div className={classes.Titles}>
                <div><h2 className={classes.Title}>{props.title}</h2></div>
                <div>{subtitle}</div>
            </div>
            <div className={classes.StBasilsCathedralDiv}>{stBasilsCathedral}</div>
        </div>);
}

export default mainHeader;