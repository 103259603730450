import React from 'react';
import MainHeader from '../../Header/MainHeader/MainHeader';
import centerColumn from '../../CenterColumn/CenterColumn.css';
import Modules from './Modules/Modules';

const levels = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} />
            <Modules route1={props.route1} route2={props.route2} route3={props.route3} />
        </div>
    );
}

export default levels;