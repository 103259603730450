import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson22 from '../../../../../../Assets/Images/A1/Lesson22/image1lesson22.png';
import Image2Lesson22 from '../../../../../../Assets/Images/A1/Lesson22/image2lesson22.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>НАРЕЧИЕ</p>
                    <p>Advérbio</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="Image1Lesson22" src={Image1Lesson22} alt="lesson 22 illustrative example 1" />
                </div>
                <GrammarSubtitle>
                    <p>ДАТЕЛЬНЫЙ ПАДЕЖ: косвенное дополнение</p>
                    <p>Caso Dativo: Objeto Indireto</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div>
                        <img id="Image2Lesson22" src={Image2Lesson22} alt="lesson 22 illustrative example 2" />
                    </div>
                    <p><b><span className={classes.greenHighlight}>FUNÇÕES:</span></b></p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O Caso Dativo expressa geralmente a função de Objeto Indireto,
                        regido por verbos como:
                    </p>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <b><span className={classes.orangeHighlight}>звони́ть</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>дава́ть</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>дари́ть</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>говори́ть</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>расска́зывать</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>помога́ть</span></b>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                Мы звони́м <b>ма́м<span className={classes.orangeHighlight}>е</span></b> ка́ждый день.
                            </p>
                            <p>
                                Миша даёт <b>сы́н<span className={classes.orangeHighlight}>у</span></b> де́ньги на обе́д.
                            </p>
                            <p>
                                Он ча́сто да́рит <b>подру́г<span className={classes.orangeHighlight}>е</span></b> цветы.
                            </p>
                            <p>
                                Она́ всегда́ говори́т <b>па́п<span className={classes.orangeHighlight}>е</span></b> пра́вду.
                            </p>
                            <p>
                                Что́ вы рассказа́ли <b>врач<span className={classes.orangeHighlight}>у́</span></b>?
                            </p>
                            <p>
                                Внук помога́ет <b>ба́бушк<span className={classes.orangeHighlight}>е</span></b> гото́вить обе́д.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;