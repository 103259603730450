import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';
import Image1exercise from '../../../../../../Assets/Images/A2/Lesson18/image1exercise.png';
import Image2exercise from '../../../../../../Assets/Images/A2/Lesson18/image2exercise.png';
import Image3exercise from '../../../../../../Assets/Images/A2/Lesson18/image3exercise.png';
import Image4exercise from '../../../../../../Assets/Images/A2/Lesson18/image4exercise.png';

const ItemTypes = [
    { id: 'холе́рик', name: '…э́то энерги́чные, общи́тельные, насто́йчивые и ги́бкие лю́ди.', image: Image1exercise },
    { id: 'Флегма́тики', name: '…реши́тельные лю́ди. Они́ спосо́бны довести́ своё де́ло до конца́.', image: Image2exercise },
    { id: 'Меланхо́лики', name: '…быва́ют осо́бо чувстви́тельными. Сочу́вствовать – э́то их осо́бенность харáктeра.', image: Image3exercise },
    { id: 'Сангви́ники', name: '…уме́ют рабо́тать до́лго и напряжённо, им не тру́дно меня́ть свою́ профе́ссию и́ли де́ятельность на другу́ю.', image: Image4exercise },
];


const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <img id={item.id} src={item.image} alt={item.name} height="150px" width="150px" type={item.id} />
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <img id={item.id} src={item.image} alt={item.name} height="150px" width="150px" type={item.id} />
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const cards = [].concat(dragCards)
            .sort((a, b) => a.itemM > b.itemM ? 1 : -1)
            .map(item => {
                return <DragCard key={item.id} type={item.id} name={item.name} />
            });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="De acordo com o texto lido na aula, &quot;Психолóгия человéка&quot;, tente recordar que descrição melhor se adequa a cada temperamento (arraste as caixas para a tabela):" />
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;