import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бо́льше</b> mais</p>
                        <p><b>(в) изоля́ции</b> (em) isolamento</p>
                        <p><b>(в) после́днее вре́мя</b> (nos) últimos tempos/ultimamente</p>
                        <p><b>весно́й</b> (Instr.) na primavera</p>
                        <p><b>влюбля́ются</b> (v. влюбля́ться) apaixonam-se</p>
                        <p><b>вчера́</b> ontem</p>
                        <p><b>выхóдят</b> (v. выходи́ть) saem</p>
                        <p><b>год наза́д</b> há um ano</p>
                        <p><b>далекó</b> longe</p>
                        <p><b>до́лго</b> durante muito tempo</p>
                        <p><b>е́здят в о́тпуск</b> vão de férias</p>
                        <p><b>ел</b> (v. есть) comi (m.)</p>
                        <p><b>е́ла</b> (v. есть) comi (f.)</p>
                        <p><b>ещё</b> ainda</p>
                        <p><b>загора́ют</b> (v. загора́ть) apanham sol</p>
                        <p><b>звони́ли</b> (v. звони́ть) telefonámos</p>
                        <p><b>звони́ть</b> telefonar</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>зимо́й</b> (Instr.) no inverno</p>
                        <p><b>игра́л</b> (v. игра́ть) joguei (m.)</p>
                        <p><b>испо́льзовали</b> (v. испо́льзовать) usavam</p>
                        <p><b>ката́ются на лы́жах</b> esquiam</p>
                        <p><b>лати́нский</b> latim</p>
                        <p><b>лéтом</b> (Instr.) no verão</p>
                        <p><b>ме́ньше</b> menos</p>
                        <p><b>ме́сяц</b> наза́д há um mês</p>
                        <p><b>миллио́н раз</b> um milhão de vezes</p>
                        <p><b>на про́шлой неде́ле</b> na semana passada</p>
                        <p><b>неде́лю наза́д</b> há uma semana</p>
                        <p><b>недо́лго</b> não por muito tempo</p>
                        <p><b>нельзя́</b> não (se) pode/ não é permitido</p>
                        <p><b>немно́го</b> um pouco</p>
                        <p><b>обе́д</b> almoço</p>
                        <p><b>обогрева́тели</b> aquecedores</p>
                        <p><b>обща́юсь</b> (v. обща́ться) convivo</p>
                        <p><b>оли́вки</b> azeitonas</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>о́сенью</b> (Instr.) no outono</p>
                        <p><b>отвеча́ть</b> responder</p>
                        <p><b>перево́д</b> tradução</p>
                        <p><b>пла́кала</b> (v. пла́кать) chorava (f.)</p>
                        <p><b>планше́т</b> tablet</p>
                        <p><b>пла́чет</b> (v. пла́кать) está a chorar</p>
                        <p><b>позавчера́</b> anteontem</p>
                        <p><b>пра́зднуют</b> (v. пра́здновать) celebram</p>
                        <p><b>пуга́ть</b> assustar</p>
                        <p><b>ра́ньше</b> antes</p>
                        <p><b>случи́лось</b> (v. случи́ться) aconteceu (n.)</p>
                        <p><b>слы́шать</b> ouvir</p>
                        <p><b>социа́льные сéти</b> redes sociais</p>
                        <p><b>спать</b> dormir</p>
                        <p><b>спал</b> (v. спать) dormi (m.)</p>
                        <p><b>так</b> assim</p>
                        <p><b>тебе́</b> (Dat.) te</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;