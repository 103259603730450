import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ТВОРИТЕЛЬНЫЙ ПАДЕЖ</p>
                    <p>Instrumental</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Verbos que se usam com o Instrumental
                    </p>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><b>рабо́тать</b></p>
                            <p><b>быть</b></p>
                            <p><b>стать</b></p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b>КЕМ</b></p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p><b>врач<span className={classes.orangeHighlight}>о́м</span></b></p>
                            <p><b>студе́нт<span className={classes.orangeHighlight}>ом</span></b></p>
                            <p><b>профе́ссор<span className={classes.orangeHighlight}>ом</span></b></p>
                        </div>
                    </div>
                </div>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><b>увлека́ться</b></p>
                            <p><b>занима́ться</b></p>
                            <p><b>интересова́ться</b></p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b>ЧЕМ</b></p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p><b>му́зык<span className={classes.orangeHighlight}>ой</span></b></p>
                            <p><b>спо́рт<span className={classes.orangeHighlight}>ом</span></b></p>
                            <p><b>жи́вопись<span className={classes.orangeHighlight}>ю</span></b></p>
                        </div>
                    </div>
                </div>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><b>встреча́ться</b></p>
                            <p><b>обнима́ться</b></p>
                            <p><b>целова́ться</b></p>
                            <p><b>разгова́ривать</b></p>
                            <p><b>боро́ться</b></p>
                            <p><b>жить</b></p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b>C КЕМ</b></p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p><b>с дру́г<span className={classes.orangeHighlight}>ом</span></b></p>
                            <p><b>с бра́т<span className={classes.orangeHighlight}>ом</span></b></p>
                            <p><b>c жен<span className={classes.orangeHighlight}>о́й</span></b></p>
                            <p><b>с сосе́дк<span className={classes.orangeHighlight}>ой</span></b></p>
                            <p><b>с чемпио́н<span className={classes.orangeHighlight}>ом</span></b></p>
                            <p><b>с сестр<span className={classes.orangeHighlight}>о́й</span></b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;