import React from 'react';
import classes from './RussianLanguage.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import Subtitle from '../../../../../Shared/Subtitle/Subtitle';
import RussianLanguageOrigins from '../../../../../../Assets/Images/russianLanguageOrigins.jpg';
import RussianLanguageTree from '../../../../../../Assets/Images/russianLanguageTree.jpg';


const russianLanguage = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <Subtitle className={classes.subtitle}>ORIGENS</Subtitle>
            <div className={classes.container}>
                <div className={classes.gridItem}>
                    O russo pertence à família etno-linguística indo-europeia (imagem 1), a qual, de acordo com linguistas e arqueólogos, terá
                    começado a ramificar-se cerca de dois mil anos a.C, como consequência de uma expansão geográfica, dando origem
                    à diversificação das línguas actuais. (como mostra a imagem: fonte do mapa: <a className={classes.link} href="http://www.hjholm.de/SLRD-map.jpg">http://www.hjholm.de/SLRD-map.jpg</a>). O período entre os séculos V e X D.C. é o da expansão dos povos eslavos,
                    atualmente classificados geográfica e linguisticamente como eslavos ocidentais, eslavos do sul, e eslavos orientais
                    – pertencendo o russo a este último ramo.
                </div>
                <div className={classes.gridItem}>
                    <img id="russianLanguageOrigins" src={RussianLanguageOrigins} alt="russian language origins" />
                </div>
                <div className={classes.gridItem}>
                    <img id="russianLanguageTree" src={RussianLanguageTree} alt="russian language family tree" />
                </div>
                <div className={classes.gridItem}>
                    <p>É falada por cerca de 250 milhões de pessoas em todo o mundo e é uma das línguas oficiais das Nações Unidas e
                        da Unesco. Mais de 25% da literatura científica mundial está escrita em russo.</p>
                    <p>No russo encontramos influências do grego, do latim, do alemão, do francês, etc. Nos últimos tempos, a língua
                        tem adquirido muito léxico do inglês.</p>
                    <p>O russo é uma língua com declinações, ou seja, a terminação de um substantivo, pronome, particípio ou adjetivo,
                        varia consoante a sua função numa frase – ou seja, varia em caso: это Марина (esta é a Marina); я люблю Марину
                        (eu amo a Marina); я говорю о Марине (eu estou a falar sobre a Marina); позвони Марине (telefona à Marina);
                        это дом Марины (esta é a casa da Marina); я говорю с Мариной (eu estou a falar com a Marina). Há, portanto,
                        seis casos na língua russa: o nominativo, o acusativo, o prepositivo, o dativo, o genitivo e o instrumental,
                        e três géneros: masculino, feminino e neutro.</p>
                    <p>Em russo há apenas três tempos verbais: presente, passado e futuro, no entanto, os verbos dividem-se em pares
                        aspetuais (perfetivo/imperfetivo), que são usados consoante as propriedades de duração e telicidade de uma
                        ação. Em termos morfológicos, isto torna a língua sintética, ou seja, uma só forma verbal imperfetiva no
                        pretérito, por exemplo, do verbo “ler”, читал, pode representar várias formas em português: “li (muito);
                        costumava ler; tenho estado a ler; estive a ler; estava a ler”; etc. O verbo copulativo “ser/estar”, быть,
                        raramente se emprega no presente. A conjugação no pretérito não flexiona em pessoa, mas em género e número.</p>
                </div>
            </div>
        </div>
    );
}

export default russianLanguage;