import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>НÝЖНO/НÁДО + ДАТЕЛЬНЫЙ ПАДЕЖ</p>
                    <p>Construções dativas</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b><span className={classes.orangeHighlight}>НÝЖНO</span></b> e <b><span className={classes.orangeHighlight}>НÁДО</span></b> (é preciso/é necessário) são palavras predicativas invariáveis e são usadas em construções com o Caso Dativo.
                    </p>
                </div>
                <div className={classes.item2}>
                    <div className={classes.item2Title}><p>DATIVO + НÝЖНO/НÁДО + INFINITIVO</p></div>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <b>Мне</b>
                            </p>
                            <p>Мне</p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <b>ну́жно</b>
                            </p>
                            <p>Мнена́доде́лать</p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <b>купи́ть смета́ну.</b>
                            </p>
                            <p>де́лать уро́ки.</p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ПРИЛАГАТЕЛЬНОЕ НУЖНЫЙ</p>
                    <p>Adjetivo NECESSÁRIO</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A palavra predicativa <b><span className={classes.orangeHighlight}>НÝЖНO</span></b> pode ter também a forma de adjetivo completo ou curto que concorda com o substantivo que o segue.
                    </p>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <b>O adjetivo completo</b> é um adjectivo qualificativo e pode ocorrer em posição de sujeito ou complemento (direto/indireto),
                                precedendo sempre o nome.
                            </p>
                            <p>
                                <b><i><span className={classes.orangeHighlight}>Ну́жная</span> кни́га была́ в библиоте́ка.</i></b>
                            </p>
                            <p>
                                <b><i>Друг всегда́ в <span className={classes.orangeHighlight}>ну́жном</span> ме́сте в <span className={classes.orangeHighlight}>ну́жный</span> час.</i></b>
                            </p>
                            <p>
                                <b>O adjetivo curto</b> é um adjectivo predicativo que é acompanhado por um sujeito lógico no Caso Dativo.
                            </p>
                            <p>
                                <b><i>Мне <span className={classes.orangeHighlight}>нужна́</span> кни́га. = (Eu) preciso do livro./O livro é-me necessário.</i></b>
                            </p>
                            <p>
                                <b><i>Ей <span className={classes.orangeHighlight}>ну́жен</span> о́тдых. = Ela precisa do descanso./O descanso é-lhe necessário.</i></b>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b>Adjetivo completo</b></p>
                            <p><b>НУ́ЖНЫЙ</b> (masc.)</p>
                            <p><b>НУ́ЖНАЯ</b> (fem.)</p>
                            <p><b>НУ́ЖНОЕ</b> (neutro)</p>
                            <p><b>НУ́ЖНЫЕ</b> (plural)</p>
                        </div>
                        <div className={classes.gridItem3}>
                            <p><b>Adjetivo curto</b></p>
                            <p><b>НУ́ЖEН</b> (masc.)</p>
                            <p><b>НУЖНÁ</b> (fem.)</p>
                            <p><b>НУ́ЖНО</b> (neutro)</p>
                            <p><b>НУЖНЫ́</b> (plural)</p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>КРАТКИЕ ПРИЛАГАТЕЛЬНЫЕ</p>
                    <p>Adjetivos Curtos</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A maior parte de adjetivos russos tem uma forma curta que é formada através do adjetivo completo.
                    </p>
                    <p>Por exemplo:</p>
                    <p><b>краси́в<span className={classes.orangeHighlight}>ый</span> – красив</b></p>
                    <p><b>краси́в <span className={classes.orangeHighlight}>ая</span> – краси́в<span className={classes.orangeHighlight}>а</span></b></p>
                    <p><b>краси́в<span className={classes.orangeHighlight}>ое</span> – краси́в<span className={classes.orangeHighlight}>о</span></b></p>
                    <p><b>краси́в<span className={classes.orangeHighlight}>ые</span> – краси́в<span className={classes.orangeHighlight}>ы</span></b></p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Os adjetivos curtos:
                    </p>
                    <p>1) são normalmente usados como predicativo de sujeito.</p>
                    <p><b><i>Э́та вещь (была́/бу́дет) нужна́ мне. <span className={classes.orangeHighlight}>vs.</span> Ну́жная вещь </i></b></p>
                    <p>2) mudam em género e número, mas não em Caso.</p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O adjetivo curto <b><i>рад</i></b> não tem forma plena.
                    </p>
                </div>
                <GrammarSubtitle>
                    <p>КРАТКОЕ ПРИЛАГАТЕЛЬНОЕ ДОЛЖЕН</p>
                    <p>Adjetivo curto</p>
                </GrammarSubtitle>
                <div className={classes.item5}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O adjetivo <b><span className={classes.orangeHighlight}>ДОЛЖЕН</span>
                        </b> tem um uso equivalente à expressão ter/precisar de ou ao verbo dever, com essa aceção. O adjectivo russo concorda com o nome
                        a que se refere em género e número.
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Ao contrário do adjetivo <b><span className={classes.orangeHighlight}>НУ́ЖЕН</span></b>, <b><span className={classes.orangeHighlight}>ДОЛЖЕН</span></b> não ocorre com o sujeito Dativo, mas com o sujeito Nominativo.
                    </p>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> À semelhança das palavras predicativas <b><span className={classes.orangeHighlight}>НÝЖНO</span></b> e <b><span className={classes.orangeHighlight}>НÁДО</span></b>, o adjetivo <b><span className={classes.orangeHighlight}>ДОЛЖЕН</span></b> coocorre com infinitivos.
                    </p>
                    <div className={classes.item5Table}>
                        <div className={classes.item5Title}><p>NOMINATIVO + ДОЛЖЕН + INFINITIVO</p></div>
                        <div className={classes.gridItems}>
                            <div className={classes.gridItem1}>
                                <p>
                                    <b>Я</b>
                                </p>
                                <p>Eu</p>
                            </div>
                            <div className={classes.gridItem2}>
                                <p>
                                    <b>должен</b>
                                </p>
                                <p>tenho de</p>
                            </div>
                            <div className={classes.gridItem2}>
                                <p>
                                    <b>купить книги.</b>
                                </p>
                                <p>comprar livros.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;