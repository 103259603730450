import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson6/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson6/image2grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ИМЯ СУЩЕСТВИТЕЛЬНОЕ: Творительный падеж (мн. ч.)</p>
                    <p>Nomes: Instrumental plural</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> No Instrumental plural, os nomes de todos os géneros têm a terminação <b><span className={classes.orangeHighlight}>-АМИ</span></b>, quando a raiz do nome termina em <b>consoante dura</b> ou em <b>к/г/х/ш/щ/ч/ж</b>, ou <b><span className={classes.orangeHighlight}>-ЯМИ</span></b>, quando se trata de um nome cuja raiz termina em <b>consoante branda</b> ou cuja terminação no singular é <b>я</b>:
                    </p>
                </div>
                <div className={classes.item2}>
                    <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="500px" />
                </div>
                <div className={classes.item3}>
                    <p>
                        <b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> Palavras irregulares: (instrumental plural em <b><span className={classes.orangeHighlight}>-ЬМИ́</span></b> e <b><span className={classes.orangeHighlight}>-Я́МИ</span></b>)
                    </p>
                </div>
                <div className={classes.item4}>
                    <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="500px" />
                </div>
                <GrammarSubtitle>
                    <p>МОДАЛЬНЫЕ КОНСТРУКЦИИ</p>
                    <p>Construções Modais</p>
                </GrammarSubtitle>
                <div className={classes.item5}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font> Им. п. + ХОТÉТЬ + инфинитив</b> (в сов. виде)</span> (exprime desejo/intenção)</p>
                    <p><i>Я <b>хочу́ прочита́ть</b> &quot;А́нну Карéнину&quot; на ру́сском языке́.</i></p>
                    <p><i>Eu <b>quero ler</b> a “Anna Karenina” em língua russa.</i></p>
                </div>
                <div className={classes.item6}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font> Дат. п. + НÁДО/НУ́ЖНО + инфинитив</b></span> (exprime necessidade/obrigação)</p>
                    <p><i><b>Мне на́до</b> заплати́ть за кварти́ру.</i></p>
                    <p><i><b>Preciso de</b> pagar a renda.</i></p>
                </div>
                <div className={classes.item7}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font> Им. п. + ДÓЛЖЕН + инфинитив</b></span> (exprime dever)</p>
                    <p><i><b>Я до́лжен</b> поговори́ть с ним.</i></p>
                    <p><i><b>Tenho de</b> falar com ele.</i></p>
                </div>
            </div>
        </div>
    );
}

export default Grammar;