import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';


const Exercises = props => {
    const [toggleText, setToggleText] = useState(false);

    const handleOnClick = () => {
        setToggleText(!toggleText);
    };

    const exerciseQuestion = (
        <div className={classes.exerciseQuestion}>
            Прочитайте текст. Проверьте, правильно ли вы расставили ударение <button onClick={handleOnClick}>здесь</button>.
        </div>
    );

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                {!toggleText && <div className={classes.textContent}>
                    <p>Дорогoй Карл, как твои делa?</p>
                    <p>
                        Пишу тебе из Москвы . Какой огромный город! Почти как вся Португалия. Вчера я сел в метро на станции «Бнинская аллея»
                        и проехал до станции «Алтyфьево». Полтора часa! В метро можно выспаться, познакомиться с девушкой, жениться,
                        состариться и умереть. Шучу. Я был на Красной площади. Она маленькая, меньше, чем я думал, но очень нарядная. Ты
                        спрашивал меня про медведей. Медведи здесь только в зоопарке. Шапки-ушанки лeтом не носят, и снег лежит только
                        зимой. Лето, вообще, очень жaркое. Вчера было 30 градусов, а в Сибири, говорят, бывает 50. Ты только подумай! Не минус
                        50, а плюс! И это север. А есть же ещё юг, Чёрное море, Азовское море, Каспийское море. На Кавказе, в Сoчи или
                        Геленджике погода почти как в Португалии.
                    </p>
                    <p>
                        Сегодня я лечу на полуостров Камчатку. Это девять часов на самолёте! Я поеду в долину гейзеров. Гейзеров, Карл! Можешь
                        начинать мне завидовать. Потoм я поеду во Владивосток, там на побережье моя подруга Лoра лечит и выкармливает
                        тюленей, а потoм выпускает их в океан. Потoм я поеду на озеро Байкал. А дальше не знаю. Я хотел бы объехать всю
                        Россию, но это невозможно. Нужна целая жизнь. Тут такие расстояния! Едешь в поезде и всё время видишь в окно один и
                        тот же пейзаж. Устанешь, ляжешь спать, проснёшься – и опять всё то же сaмое. А потoм вдруг меняется. Кончается степь,
                        начинается лес. Или гoры. Или бeрег мoря. Или что-нибyдь ещё. Я хочу увидеть тайгу и тундру. Хочу увидеть, как чукчи
                        пасут оленей. И как эскимосы стрoят иглу – дом из снега. Ты знал, что в России есть эскимосы? А они есть. Ты удивляешься?
                        Ты думал, в России живут только русские? В России больше ста народностей. В тайге есть русские индейцы – эвенки. Есть
                        китайцы-мусульмане, называются уйгуры. А помнишь, мы смотрели фильм «Дерсy Узалa»? Главный герoй там – удэгеец.
                        Попробуй произнести это слово! Я два дня учился. А сколько народов на юге! Только в маленькой республике Дагестан –
                        сoрок девять! Аварцы, кумыки, лезгины, таты... И у всех свои традиции, свой язык, своя религия.
                    </p>
                    <p>А теперь мне порa на самолёт. Напишу тебе с Камчатки.</p>
                    <p>Твой друг Пeдру.</p>
                </div>
                }
                {toggleText && <div className={classes.textContent}>
                    <p>Дорогóй Карл, как твои́ делá?</p>
                    <p>
                        Пишу́ тебе́ из Москвы́ . Како́й огро́мный го́род! Почти́ как вся Португа́лия. Вчера́ я сел в метро́ на ста́нции «Бýнинская
                        алле́я» и прое́хал до ста́нции «Алтýфьево». Полтора́ часá! В метро́ мо́жно вы́спаться, познако́миться с де́вушкой,
                        жени́ться, соста́риться и умере́ть. Шучу́. Я был на Кра́сной пло́щади. Она́ ма́ленькая, ме́ньше, чем я ду́мал, но о́чень
                        наря́дная. Ты спра́шивал меня́ про медве́дей. Медве́ди здесь то́лько в зоопа́рке. Ша́пки-уша́нки лéтом не но́сят, и снег
                        лежи́т то́лько зимо́й. Ле́то, вообще́, о́чень жáркое. Вчера́ бы́ло 30 гра́дусов, а в Сиби́ри, говоря́т, быва́ет 50. Ты то́лько
                        поду́май! Не ми́нус 50, а плюс! И э́то се́вер. А есть же ещё юг, Чёрное мо́ре, Азо́вское мо́ре, Каспи́йское мо́ре. На Кавка́зе,
                        в Сóчи и́ли Геленджи́ке пого́да почти́ как в Португа́лии.
                    </p>
                    <p>
                        Сего́дня я лечу́ на полуо́стров Камча́тку. Э́то де́вять часо́в на самолёте! Я пое́ду в доли́ну ге́йзеров. Ге́йзеров, Карл!
                        Мо́жешь начина́ть мне зави́довать. Потóм я пое́ду во Владивосто́к, там на побере́жье моя́ подру́га Лóра ле́чит и
                        выка́рмливает тюле́ней, а потóм выпуска́ет их в океа́н. Потóм я пое́ду на о́зеро Байка́л. А да́льше не зна́ю. Я хоте́л бы
                        объе́хать всю Росси́ю, но э́то невозмо́жно. Нужна́ це́лая жизнь. Тут таки́е расстоя́ния! Е́дешь в по́езде и всё вре́мя ви́дишь
                        в окно́ оди́н и тот же пейза́ж. Уста́нешь, ля́жешь спать, проснёшься – и опя́ть всё то же сáмое. А потóм вдруг меня́ется.
                        Конча́ется степь, начина́ется лес. И́ли гóры. И́ли бéрег мóря. И́ли что-нибýдь ещё. Я хочу́ уви́деть тайгу́ и ту́ндру. Хочу́
                        уви́деть, как чу́кчи пасу́т оле́ней. И как эскимо́сы стрóят и́глу – дом из сне́га. Ты знал, что в Росси́и есть эскимо́сы? А они́
                        есть. Ты удивля́ешься? Ты ду́мал, в Росси́и живу́т то́лько ру́сские? В Росси́и бо́льше ста наро́дностей. В тайге́ есть ру́сские
                        инде́йцы – эве́нки. Есть кита́йцы-мусульма́не, называ́ются уйгу́ры. А по́мнишь, мы смотре́ли фильм «Дерсý Узалá»?
                        Гла́вный герóй там – удэге́ец. Попро́буй произнести́ э́то сло́во! Я два дня учи́лся. А ско́лько наро́дов на ю́ге! То́лько в
                        ма́ленькой респу́блике Дагеста́н – сóрок де́вять! Ава́рцы, кумы́ки, лезги́ны, та́ты... И у всех свои́ тради́ции, свой язы́к, своя́
                        рели́гия.
                    </p>
                    <p>А тепе́рь мне порá на самолёт. Напишу́ тебе́ с Камча́тки.</p>
                    <p>Твой друг Пeдру.</p>
                </div>
                }
            </div>
        </div>
    );
}

export default Exercises;