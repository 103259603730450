import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>апте́ка</b> farmácia</p>
                        <p><b>бассе́йн</b> piscina</p>
                        <p><b>бу́дние дни</b> dias de semana</p>
                        <p><b>(в) 10 часо́в</b> (às) 10 horas</p>
                        <p><b>(в) 11 вéчера</b> (às) 11 da noite</p>
                        <p><b>(в) 8 утрá</b> (às) 8 da manhã</p>
                        <p><b>(в) нáшy гости́ницу</b> (Acus.) (ao) nosso hotel</p>
                        <p><b>(в) пя́тницу</b> (na) sexta-feira</p>
                        <p><b>вéчера</b> (Gen.) da tarde/noite</p>
                        <p><b>(во) ско́лько?</b> a que horas?</p>
                        <p><b>вопро́с</b> pergunta</p>
                        <p><b>вре́мя рабо́ты</b> horário de funcionamento</p>
                        <p><b>встава́ть</b> levantar-se</p>
                        <p><b>встаю́</b> (v. встава́ть) levanto-me</p>
                        <p><b>встаёт</b> (v. встава́ть) levanta-se</p>
                        <p><b>встре́ча</b> encontro</p>
                        <p><b>выходно́й</b> folga</p>
                        <p><b>выходны́е дни</b> fim de semana</p>
                        <p><b>где́-то</b> por volta de</p>
                        <p><b>гра́фик рабо́ты</b> horário de funcionamento</p>
                        <p><b>гуля́ть</b> passear</p>
                        <p><b>да́же</b> até</p>
                        <p><b>двáдцать четы́ре ча́са ро́вно</b> vinte e quatro horas em ponto</p>
                        <p><b>два часá</b> duas horas</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>две мину́ты</b> dois minutos</p>
                        <p><b>двена́дцать часо́в ро́вно</b> doze horas em ponto</p>
                        <p><b>До встре́чи!</b> Até a vista!</p>
                        <p><b>éду</b> (v. е́хать) vou</p>
                        <p><b>е́хать</b> ir (de transporte)</p>
                        <p><b>жа́воронок</b> cotovia</p>
                        <p><b>забы́л</b> (v. забы́ть) esqueci-me (m.)</p>
                        <p><b>за́втракать</b> tomar o pequeno-almoço</p>
                        <p><b>за́втракаю</b> (v. за́втракать) tomo o pequeno-almoço</p>
                        <p><b>зака́нчивается</b> (v. зака́нчиваться) acaba</p>
                        <p><b>закрыва́ется</b> (v. закрыва́ться) fecha-se</p>
                        <p><b>идý</b> (v. идти́) vou</p>
                        <p><b>идёшь</b> (v. идти́) vais</p>
                        <p><b>ка́ждые 2 часа́</b> de duas em duas horas</p>
                        <p><b>ка́ждый час</b> cada hora</p>
                        <p><b>котóрый час</b> que horas são</p>
                        <p><b>ложи́тся</b> (v. ложи́ться) deita-se</p>
                        <p><b>Моско́вский Метрополите́н</b> Metropolitano de Moscovo</p>
                        <p><b>местá</b> lugares</p>
                        <p><b>нóчи</b> (Gen.) da noite</p>
                        <p><b>наве́рное</b> talvez</p>
                        <p><b>на́до</b> é necessário</p>
                        <p><b>наоборо́т</b> ao contrário</p>
                        <p><b>настоя́щий</b> verdadeiro</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>нахо́дится</b> (v. нахо́диться) situa-se</p>
                        <p><b>начина́ется</b> (v. начина́ться) começa</p>
                        <p><b>оди́н час</b> uma hora</p>
                        <p><b>одна́ мину́та</b> um minuto</p>
                        <p><b>организу́ем</b> (v. организова́ть) organizamos</p>
                        <p><b>открыва́ется</b> (v. открыва́ться) abre</p>
                        <p><b>по́здно</b> (adv.) tarde</p>
                        <p><b>по́лдень</b> meio-dia</p>
                        <p><b>по́лночь</b> meia-noite</p>
                        <p><b>по-мо́ему</b> na minha opinião</p>
                        <p><b>по́чта</b> correio</p>
                        <p><b>привы́чка</b> hábito</p>
                        <p><b>пять минýт</b> cinco minutos</p>
                        <p><b>пять часо́в</b> cinco horas</p>
                        <p><b>ра́но</b> cedo</p>
                        <p><b>скóлько врéмени</b> que horas são</p>
                        <p><b>собра́ние</b> reunião</p>
                        <p><b>сова́</b> coruja</p>
                        <p><b>сра́зу</b> imediatamente</p>
                        <p><b>ста́нция</b> estação</p>
                        <p><b>та́кже</b> também</p>
                        <p><b>то́чно</b> exatamente</p>
                        <p><b>тренажёрный зал</b> ginásio</p>
                        <p><b>у́тра</b> (Gen.) da manhã</p>
                        <p><b>часы́ рабо́ты</b> horário de funcionamento</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;