import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Passport from '../../../../../../Assets/Images/A1/Lesson2/passport.png';
import Escola from '../../../../../../Assets/Images/A1/Lesson2/escola.png';
import Autocarro from '../../../../../../Assets/Images/A1/Lesson2/autocarro.png';
import Wiski from '../../../../../../Assets/Images/A1/Lesson2/wiski.png';
import Sopa from '../../../../../../Assets/Images/A1/Lesson2/sopa.png';
import Laranja from '../../../../../../Assets/Images/A1/Lesson2/laranja.png';
import Hoquei from '../../../../../../Assets/Images/A1/Lesson2/hoquei.png';
import Rock from '../../../../../../Assets/Images/A1/Lesson2/rock.png';

const questions = [
    {
        text: "Э́то кот.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то мáма.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то рóза.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: false },
            { id: 1, text: "Что это?", isCorrect: true },
        ],
    },
    {
        text: "Э́то студе́нтка.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то винó.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: false },
            { id: 1, text: "Что это?", isCorrect: true },
        ],
    },
    {
        text: "Э́то гимнáстика.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: false },
            { id: 1, text: "Что это?", isCorrect: true },
        ],
    },
    {
        text: "Э́то филóсоф.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то футбóлка.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: false },
            { id: 1, text: "Что это?", isCorrect: true },
        ],
    },
    {
        text: "Э́то автóбус.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: false },
            { id: 1, text: "Что это?", isCorrect: true },
        ],
    },
    {
        text: "Э́то дирéктор.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то па́па.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то Ири́на.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то профéссор.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то актриса.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то Мари́я.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то Пётр.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то торт.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: false },
            { id: 1, text: "Что это?", isCorrect: true },
        ],
    },
    {
        text: "Э́то Анто́н.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то таксист.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
    {
        text: "Э́то пантера.",
        options: [
            { id: 0, text: "Кто это?", isCorrect: true },
            { id: 1, text: "Что это?", isCorrect: false },
        ],
    },
];

const buttonsEx2 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: true },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: true },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: true },
    { id: 15, isCorrect: true },
    { id: 16, isCorrect: false },
];

const Exercises = props => {
    const [showResults, setShowResults] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);

    const optionClicked = (isCorrect) => {
        // Increment the score
        if (isCorrect) {
            setScore(score + 1);
        }

        if (currentQuestion + 1 < questions.length) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowResults(true);
        }
    };

    /* Resets the game back to default */
    const restartGame = () => {
        setScore(0);
        setCurrentQuestion(0);
        setShowResults(false);
    };

    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.backgroundColor = 'green' : event.currentTarget.style.backgroundColor = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha a pergunta apropriada ao contexto:" />
                <h2 className={classes.score}>Pontuação: {score}</h2>
                {showResults ? (
                    /* 4. Final Results */
                    <div className={classes.finalResults}>
                        <h1>Resultado Final</h1>
                        <h2>
                            {score} de {questions.length} corretas - (
                            {(score / questions.length) * 100}%)
                        </h2>
                        <button className={classes.resetGame} onClick={() => restartGame()}>Reiniciar jogo</button>
                    </div>
                ) : (
                    /* 5. Question Card  */
                    <div className={classes.questionCard}>
                        {/* Current Question  */}
                        <h2>
                            Pergunta: {currentQuestion + 1} de {questions.length}
                        </h2>
                        <h3 className={classes.questionText}>{questions[currentQuestion].text}</h3>

                        {/* List of possible answers  */}
                        <ul className={classes.listStyleUl}>
                            {questions[currentQuestion].options.map((option) => {
                                return (
                                    <li
                                        key={option.id}
                                        onClick={() => optionClicked(option.isCorrect)}
                                        className={classes.listStyleLi}
                                    >
                                        {option.text}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                <ExercisesQuestions number="2" question='Responda: "Да" или "Hет"?' />
                <div className={classes.questionsContainerEx2}>
                    <div className={classes.cardContainerEx2}>
                        <img id="passport" src={Passport} alt="passport" width="220px" />
                        <p>Пáспорт - э́то докумéнт?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[0].id}
                                onClick={event => handleColor(event, buttonsEx2[0].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[1].id}
                                onClick={event => handleColor(event, buttonsEx2[1].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="escola" src={Escola} alt="escola" width="220px" />
                        <p>Шкóлa - э́то спорт?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[2].id}
                                onClick={event => handleColor(event, buttonsEx2[2].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[3].id}
                                onClick={event => handleColor(event, buttonsEx2[3].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="autocarro" src={Autocarro} alt="autocarro" width="220px" />
                        <p>Aвтóбус - это трáнспорт?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[4].id}
                                onClick={event => handleColor(event, buttonsEx2[4].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[5].id}
                                onClick={event => handleColor(event, buttonsEx2[5].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="wiski" src={Wiski} alt="wiski" width="220px" />
                        <p>Bи́ски - э́то алкого́ль?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[6].id}
                                onClick={event => handleColor(event, buttonsEx2[6].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[7].id}
                                onClick={event => handleColor(event, buttonsEx2[7].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="sopa" src={Sopa} alt="sopa" width="220px" />
                        <p>Cуп - э́то фрукт?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[6].id}
                                onClick={event => handleColor(event, buttonsEx2[8].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[7].id}
                                onClick={event => handleColor(event, buttonsEx2[9].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="laranja" src={Laranja} alt="laranja" width="220px" />
                        <p>Апельси́н - э́то витами́н С?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[6].id}
                                onClick={event => handleColor(event, buttonsEx2[10].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[7].id}
                                onClick={event => handleColor(event, buttonsEx2[11].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="hoquei" src={Hoquei} alt="hoquei" width="220px" />
                        <p>Хоккéй - э́то тáнец?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[6].id}
                                onClick={event => handleColor(event, buttonsEx2[12].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[7].id}
                                onClick={event => handleColor(event, buttonsEx2[13].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="rock" src={Rock} alt="rock" width="220px" />
                        <p>Рок - э́то музыка?</p>
                        <div className={classes.buttonsContainerEx2}>
                            <button
                                key={buttonsEx2[6].id}
                                onClick={event => handleColor(event, buttonsEx2[14].isCorrect)}
                            >
                                ДА
                            </button>
                            <button
                                key={buttonsEx2[7].id}
                                onClick={event => handleColor(event, buttonsEx2[15].isCorrect)}
                            >
                                НЕТ
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;