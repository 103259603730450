import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';


const Exercises = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <GrammarSubtitle>
                    <p>Preposições</p>
                    <p>ДЛЯ, РÁДИ, ЗА</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> As preposições <b><span className={classes.orangeHighlight}><i>для</i></span></b> e <b><span className={classes.orangeHighlight}><i>рáди</i></span></b> podem ser usadas como sinónimos, quando têm o significado <b>por causa de...</b></p>
                    </div>
                    <div className={classes.item1_2}>
                        <p><i>Я купи́л хлеб тóлько <b><span className={classes.orangeHighlight}>для/рáди</span></b> тебя́. Бóльше егó никтó не ест.</i></p>
                        <p><i>Comprei o pão só <b>para ti/por tua causa</b>. Ninguém o come mais.</i></p>
                    </div>
                    <div className={classes.item1_3}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> A preposição <b><i><span className={classes.orangeHighlight}>для</span> usa-se como finalidade: “para”</i></b></p>
                        <p><i>Тетрáдь <b><span className={classes.orangeHighlight}><strike>для</strike></span></b>/ради рисовáния.</i></p>
                        <p><i>Caderno <b>para</b> desenho.</i></p>
                    </div>
                    <div className={classes.item1_4}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> A preposição <b><i><span className={classes.orangeHighlight}>рáди</span> usa-se como explicação: “por causa de”</i></b></p>
                        <p><i>Я там рабóтаю <strike>для</strike>/<b>рáди</b> зарплáты.</i></p>
                        <p><i>Trabalho lá <b>por causa do</b> ordenado.</i></p>
                    </div>
                    <div className={classes.item1_5}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> A preposição <b><i><span className={classes.orangeHighlight}>за</span> usa-se como motivação/mote: “por..”</i></b></p>
                        <p><i>Она́ всегда́ де́лает дома́шние зада́ния <b>за</b> них.</i></p>
                        <p><i>Ela sempre faz os trabalhos de casa <b>por</b> eles.</i></p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>СЛОЖНОЕ ПРЕДЛОЖЕНИЕ С СОЮЗОМ</p>
                    <p>для того́, что́бы...</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.item2_1}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> A conjunção <b><span className={classes.orangeHighlight}>(ДЛЯ ТОГО) ЧТОБЫ</span></b> é equivalente à conjunção portuguesa <b>“para”</b>, que inicia uma frase subordinada final, antecedendo um verbo ou um nome:</p>
                    </div>
                    <div className={classes.item2_2}>
                        <p><b>(Для того́) что́бы купи́ть</b> газе́ту, я иду́ в кио́ск. = <b>Для поку́пки</b> газе́ты я иду́ в кио́ск.</p>
                        <p><i><b>Para comprar</b> um jornal, vou a um quiosque. = Para a compra de um jornal, vou a um quiosque.</i></p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ОТРИЦАТЕЛЬНЫЕ МЕСТОИМЕНИЯ</p>
                    <p>+ НЕ + глагол</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> Ao contrário do português, em russo, depois de um pronome negativo e antes de um verbo, usa-se sempre a partícula de negação <b><span className={classes.orangeHighlight}>не</span></b>, independentemente de o pronome ocorrer antes (posição não marcada) ou depois do verbo:</p>
                    </div>
                    <div className={classes.item3_2}>
                        <p>Сего́дня я <b>ничего́ не</b> купи́л. Я <b>не</b> купи́л <b>ничего́</b> сего́дня.</p>
                        <p><i>Hoje <b>não</b> comprei <b>nada</b>.</i></p>
                    </div>
                    <div className={classes.item3_3}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> Quando o pronome negativo se usa com uma preposição, esta separa a particula <b><span className={classes.orangeHighlight}>ни</span></b> do resto da palavra:</p>
                    </div>
                    <div className={classes.item3_4}>
                        <p>– <b>За кого́</b> ты боле́ешь: за Бенфику и́ли за Спортинг? – <b>Ни за кого́</b>, я не люблю́ футбо́л.</p>
                        <p><i>– <b>Por quem</b> torces: pelo Benfica ou pelo Sporting? – <b>Por nenhum</b>, não gosto de futebol</i></p>
                    </div>
                    <div className={classes.item3_5}>
                        <p>– <b>С чем</b> ты бу́дешь пить чай: с варе́ньем и́ли с конфе́тами? – <b>Ни с чем.</b></p>
                        <p><i>– <b>Com que</b> é que bebes o chá: com compota ou com bombons? – <b>Com nada.</b></i></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;