import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: '4', name: '4', matcher: '4' },
    { id: '3', name: '3', matcher: '3' },
    { id: '1', name: '1', matcher: '1' },
    { id: '5', name: '5', matcher: '5' },
    { id: '2', name: '2', matcher: '2' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
            event.currentTarget.style.background = 'white';
        } else {
            event.currentTarget.style.color = 'red';
            event.currentTarget.style.background = 'white';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Подберите синонимы к выделенному выражению." />
                <div className={classes.questionsContainerEx1}>
                    <div>
                        <p>1. Этот план предусматривает также специальные программы для молодых людей, которые хотели бы <b><u>сойти с</u></b> преступного пути.</p>
                        <p>2. Люди говорили, что они могли бы <b><u>сойти за</u></b> братьев.</p>
                        <p>3. У вас проблемы с документами; извольте <b><u>сойти на</u></b> ближайшей станции.</p>
                        <p>4. Тебе всё может <b><u>сойти с рук</u></b>.</p>
                        <p>5. Можно <b><u>сойти с ума</u></b>, оставаясь здесь.</p>
                    </div >
                    <div className={classes.inputsContainer}>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input1"
                                    name="input1"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[0].matcher)} /> избежать наказания...
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input2"
                                    name="input2"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[1].matcher)} /> выйти, удалиться, покинуть поезд...
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input3"
                                    name="input3"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[2].matcher)} /> отказаться от, бросить, покинуть...
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input4"
                                    name="input4"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[3].matcher)} /> потерять разум...
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input5"
                                    name="input5"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[4].matcher)} /> быть похожими, как братья...
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </div >
    );
}

export default Exercises;