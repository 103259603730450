import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>УСЛОВНОЕ НАКЛОНЕНИЕ</p>
                    <p><i>Condicional Real e Irreal</i></p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.item1_1}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O <b>Condicional Real</b> usa-se com a conjunção <b><span className={classes.orangeHighlight}><i>ÉСЛИ</i></span></b> (conjunção <b>se</b>, em português) e pode ocorrer com os verbos no Presente, Passado ou Futuro. No caso do Presente e Passado, trata-se de uma interpretação real (factual) e, no caso do Futuro, de uma interpretação hipotética.</p>
                    </div>
                    <div className={classes.item1_2}>
                        <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O <b>Condicional Irreal</b> é formado pela conjunção <b><span className={classes.orangeHighlight}><i>ÉСЛИ</i></span></b> e a partícula modal <b><span className={classes.orangeHighlight}><i>БЫ</i></span></b> (<b><span className={classes.orangeHighlight}>éсли бы</span></b>) e ocorre só e apenas com os verbos que têm a desinência que coincide com a desinência do Passado, sem fixar, no entanto, a semântica do Passado.</p>
                    </div>
                    <div className={classes.item1_3}>
                        <div className={classes.item1_3_1}>
                            ЕСЛИ
                        </div>
                        <div>
                            <p><i><b>Е́сли</b> за́втра бу́дет дождь, (то) я не пое́ду на конце́рт.</i></p>
                            <p><b>Se</b> amanhã chover, (então) eu não vou ao concerto</p>
                        </div>
                    </div>
                    <div className={classes.item1_4}>
                        <div className={classes.item1_4_1}>
                            ЕСЛИ = КОГДА
                        </div>
                        <div>
                            <p><i>Хорошо́, <b>е́сли</b> де́ти лю́бят матема́тику!</i></p>
                            <p>É bom <b>quando</b> as crianças gostam de matemática!</p>
                        </div>
                    </div>
                    <div className={classes.item1_5}>
                        <div className={classes.item1_5_1}>
                            ЕСЛИ БЫ
                        </div>
                        <div>
                            <p><i>Мы поéхали бы в Австра́лию в о́тпуск, <b>е́сли бы</b> у нас бы́ли де́ньги.</i></p>
                            <p>Nós iríamos para a Austrália de férias, <b>se</b> tivéssemos dinheiro.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;