import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'международных проектах', name: 'международных проектах', matcher: 'международных проектах' },
    { id: 'синих джинсах', name: 'синих джинсах', matcher: 'синих джинсах' },
    { id: 'жёлтых скамейках', name: 'жёлтых скамейках', matcher: 'жёлтых скамейках' },
    { id: 'моих проблемах', name: 'моих проблемах', matcher: 'моих проблемах' },
    { id: 'дорогих ресторанах', name: 'дорогих ресторанах', matcher: 'дорогих ресторанах' },
    { id: 'интересных путешествиях', name: 'интересных путешествиях', matcher: 'интересных путешествиях' },
    { id: 'yральских горах', name: 'yральских горах', matcher: 'yральских горах' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Complete as frases, escrevendo a palavra entre parêntesis na forma correta:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            1. Они́ мно́го зна́ют о <input
                                id="input1"
                                name="input 1"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />. (междунаро́дные прое́кты)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            2. Она́ всегда́ хо́дит в <input
                                id="input2"
                                name="input 2"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />. (си́ние джи́нсы)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            3. Студе́нты сидя́т на <input
                                id="input3"
                                name="input 3"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />. (жёлтые скаме́йки)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            4. Почему́ ты говори́шь о <input
                                id="input4"
                                name="input 4"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />? (мои́ пробле́мы)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            5. Она́ привы́кла питáться в <input
                                id="input5"
                                name="input 5"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (дороги́е рестора́ны)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            6. Я сейча́с вам расскажу́ об <input
                                id="input6"
                                name="input 6"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />. (интере́сные путеше́ствия)
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            7. Они́ отдыхáют на <input
                                id="input7"
                                name="input 7"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />. (Ура́льские го́ры)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;