import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: 'твоему сыну', name: 'твоему сыну', matcher: 'твоему сыну' },
    { id: 'чужой женщине', name: 'чужой женщине', matcher: 'чужой женщине' },
    { id: 'этому номеру', name: 'этому номеру', matcher: 'этому номеру' },
    { id: 'Моей сестре', name: 'Моей сестре', matcher: 'Моей сестре' },
    { id: 'своим друзьям', name: 'своим друзьям', matcher: 'своим друзьям' },
    { id: 'новым преподавателям', name: 'новым преподавателям', matcher: 'новым преподавателям' },
    { id: 'моим родителям', name: 'моим родителям', matcher: 'моим родителям' },
    { id: 'Нашим студентам', name: 'Нашим студентам', matcher: 'Нашим студентам' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escreva as palavras na forma correta" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>1.</b> Ско́лько лет <input
                                id="э́тотInput"
                                name="э́тотInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> (твой сын)?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>2.</b> Почему́ он подари́л цветы́ <input
                                id="твоя́Input"
                                name="твоя́Input"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> (чужа́я же́нщина)?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>3.</b> Он уже́ звони́л по <input
                                id="ствойInput"
                                name="твойInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> (э́тот но́мер)? Но́мер не обслу́живается!
                        </div>
                    </div>
                    <div className={classes.cardContainerEx}>
                        <div className={classes.inputWrapper}>
                            <b>4.</b> <input
                                id="мои́ьInput"
                                name="мои́Input"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> (Моя́ сестра́) подари́ли но́вый велосипе́д.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>5.</b> Он всегда́ помога́ет <input
                                id="нашInput"
                                name="нашInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> (свои́ друзья́).
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>6.</b> Что ты сказа́л <input
                                id="твои́Input"
                                name="твои́Input"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> (но́вые преподава́тели)?
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>7.</b> Покажи́ на́шу фотогра́фию <input
                                id="вашInput"
                                name="вашInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> (мои́ роди́тели).
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <b>8.</b> Без <input
                                id="теInput"
                                name="теInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> (Наш студенты́) на́до бо́льше чита́ть.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;