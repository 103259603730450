import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: '1', type: 'Минусы частного дома', name: '1. Частный дом требует от нас много времени, ведь нужно поддерживать порядок не только внутри, но и снаружи помещения.' },
    { id: '2', type: 'Плюсы квартиры', name: '2. Квартира безопаснее, потому что большинство современных подъездов оснащены домофонами, или в них работают консьержи.' },
    { id: '3', type: 'Плюсы частного дома', name: '3. Жизнь в гармонии с природой – «глоток свежего воздуха» – это огромное счастье.' },
    { id: '4', type: 'Минусы частного дома', name: '4. Если вы хотите понаблюдать за миром с высоты птичьего полета – в частном доме это невозможно.' },
    { id: '5', type: 'Плюсы частного дома', name: '5. Преимущество частного дома в том, что здесь нет постоянного контакта с соседями, как в квартире. Вы можете и вовсе о них не беспокоиться. Высокий забор, хорошая охранная система – и вы полностью ограждены от неприятного соседства.' },
    { id: '6', type: 'Плюсы квартиры', name: '6. Это отличный вариант для тех, кто не хочет или не имеет возможности работать на приусадебном участке.' },
    { id: '7', type: 'Минусы квартиры', name: '7. В субботнее утро вас всегда могут разбудить соседи, которые решили прибить полку, а в пятницу вечером не получится устроить вечеринку, так как громкая музыка вряд ли понравится пожилым жильцам соседней квартиры.' },
    { id: '8', type: 'Минусы квартиры', name: '8. Внизу за окном вы будете видеть окна соседей или стены еще одной многоэтажки.' },
    { id: '9', type: 'Минусы частного дома', name: '9. Если в доме протечет крыша или, например, прорвёт трубу с водой - это ваши заботы; нет коммунальщиков, которые могли бы помочь решить эти и другие типичные проблемы.' },
    { id: '10', type: 'Плюсы квартиры', name: '10. Чаще всего возле многоквартирных домов довольно развита инфраструктура. Само собой, чем ближе квартира расположена к центру города, тем проще от нее добраться до торгового центра, садика или больницы.' },
    { id: '11', type: 'Плюсы частного дома', name: '11. Иметь небольшой земельный участок для выращивания цветов или овощей.' },
];

const DropTypes = [
    { id: '1', type: 'Плюсы частного дома', name: 'Плюсы частного дома' },
    { id: '2', type: 'Минусы частного дома', name: 'Минусы частного дома' },
    { id: '3', type: 'Плюсы квартиры', name: 'Плюсы квартиры' },
    { id: '4', type: 'Минусы квартиры', name: 'Минусы квартиры' },
];

const Exercises = props => {
    const [dropCards] = useState(DropTypes);
    const [dragCards, setDragCards] = useState(ItemTypes);

    const DragCard = ({ item }) => {
        const [, drag] = useDrag({
            type: item.type,
            item: { ...item },
            collect: monitor => ({
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging()
            })
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {item.name}
                </div>
            </div>
        );
    }

    const handleRemoveItem = name => {
        setDragCards(dragCards.filter(item => item.name !== name));
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [, drop] = useDrop(() => ({
            accept: item.type,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const canDrop = monitor.canDrop();
                const dragItem = monitor.getItem();

                if (canDrop) {
                    handleRemoveItem(dragItem.name);
                }
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                <div ref={drop} className={classes.dropContainer}>
                    <div className={classes.dropCard} type={item.id}>{item.name}</div>
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard item={item} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Дом или квартира? Добавьте фразы в &quot;плюсы&quot; и &quot;минусы&quot;." />
                <div className={classes.gridItems}>
                    <DropCards />
                    <DragCards />
                </div>
                <p><i>Fonte do texto: https://gethom.com/blog/дом-или-квартира/</i></p>
            </div>
        </div>
    );
}

export default Exercises;