import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import NominativeCase from '../../../../../../Assets/Images/A1/Lesson2/nominativeCase.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>Вопроси́тельные местоиме́ния</p>
                    <p>Pronomes Interrogativos</p>
                </GrammarSubtitle>
                <div className={classes.flexItems}>
                    <div className={classes.item1}>
                        <img id="nominativeCase" src={NominativeCase} alt="nominative case" width="220px" />
                    </div>
                    <div className={classes.item2}>
                        <p>O pronome interrogativo <b>кто</b> é usado para “seres animados” (pessoas ou animais), enquanto o pronome <b>что</b> é usado para coisas inanimadas.</p>
                        <div className={classes.gridItems}>
                            <div className={classes.gridItem1}>
                                <p><b> КТО Э́ТО?</b></p>
                                <p>Quem é este/esta?</p>
                                <p><b><span className={classes.orangeHighlight}>Э́то</span> Томас</b>.</p>
                                <p><b><span className={classes.orangeHighlight}>Э́то</span> сосе́дка</b>.</p>
                            </div>
                            <div className={classes.gridItem2}>&#8594;</div>
                            <div className={classes.gridItem3}>
                                <p><b>КТО ОН/OНÁ?</b></p>
                                <p>Quem é ele?/O que faz?</p>
                                <p><b><span className={classes.orangeHighlight}>Он</span> фи́зик</b>.</p>
                                <p><b><span className={classes.orangeHighlight}>Она́ </span>студе́нтка</b>.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ОТРИЦАНИЕ</p>
                    <p>Negação</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p>Em russo há duas partículas de negação. Uma é usada para denotar a negação em pergunta ou resposta a “<u>sim</u> ou <u>não</u>?” <b>(нет)</b>, a outra é usada para negar algo concreto “não é…/ não faz…” <b>(не)</b> (nunca aparece sozinho, precedendo imediatamente um nome, pronome, verbo ou adjetivo).</p>
                </div>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <div>
                                <p><b>да ≠ нет</b></p>
                            </div>
                            <div className={classes.gridItemsGridInItem1}>
                                <div>
                                    <p>Э́то ты, Кáтя?</p>
                                    <p><b><span className={classes.orangeHighlight}>Да</span></b>, э́то я.</p>
                                </div>
                                <div>
                                    <p>Э́то вы, Серге́й?</p>
                                    <p><b><span className={classes.orangeHighlight}>Нет.</span></b>, э́то <b><span className={classes.orangeHighlight}>не</span></b> Серге́й.</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <div>
                                <p><b>нет ≠ не</b></p>
                            </div>
                            <div className={classes.gridItemsGridInItem2}>
                                <div>
                                    <p>Это Мари́я? <b><span className={classes.orangeHighlight}>Нет.</span></b></p>
                                    <p>Это студéнтка? <b><span className={classes.orangeHighlight}>Нет.</span></b></p>
                                    <p>Это oнá? <b><span className={classes.orangeHighlight}>Нет.</span></b></p>
                                </div>
                                <div>
                                    <p>Это <b><span className={classes.orangeHighlight}>не</span></b> Мари́я!</p>
                                    <p>Это <b><span className={classes.orangeHighlight}>не</span></b> студéнтка!</p>
                                    <p>Это <b><span className={classes.orangeHighlight}>не</span></b> oнá?</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default Grammar;