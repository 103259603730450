import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1 from '../../../../../../Assets/Images/B1/Lesson8/image1exercise.png';

const VerbList = [
    { id: 'Поп-Арт', name: 'Поп-Арт', matcher: 'Поп-Арт' },
    { id: 'Классицизм', name: 'Классицизм', matcher: 'Классицизм' },
    { id: 'Сюрреализм', name: 'Сюрреализм', matcher: 'Сюрреализм' },
    { id: 'Абстракционизм', name: 'Абстракционизм', matcher: 'Абстракционизм' },
    { id: 'Модерн', name: 'Модерн', matcher: 'Модерн' },
    { id: 'Импрессионизм', name: 'Импрессионизм', matcher: 'Импрессионизм' },
    { id: 'Конструтивизм', name: 'Конструтивизм', matcher: 'Конструтивизм' },
    { id: 'Примитивизм', name: 'Примитивизм', matcher: 'Примитивизм' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Подберите подходящее направление" />
                <div className={classes.questionsContainerEx1}>
                    <img id="image1" src={Image1} alt="1" width="350px" />
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.inputWrapper}>
                            <p><input
                                id="Поп-АртаInput"
                                name="Поп-АртInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            />: Направление в изобразительном искусстве Западной Европы и США конца 1950—1960-х годов, возникшее как реакция отрицания на абстрактный экспрессионизм.
                            </p>
                            <p><input
                                id="КлассицизмInput"
                                name="КлассицизмInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            />: Направление в искусстве XVII — начала XIX в., основанное на подражании античным образцам, античной поэтике.
                            </p>
                            <p><input
                                id="СюрреализмInput"
                                name="СюрреализмInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            />: Направление в искусстве XX в., принципиально отказавшееся от изображения реальных предметов и явлений в живописи, скульптуре, графике и т. п. и заменяющее их сочетаниями геометрических фигур, линий или цветовых пятен.
                            </p>
                            <p><input
                                id="АбстракционизмInput"
                                name="АбстракционизмInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            />: Направление в искусстве 20 века, в котором вместо изображения реальности используется система чисто формальных элементов, таких как линия, плоскость, цветовое пятно, отвлечённая конфигурация.
                            </p>
                            <p><input
                                id="МодернInput"
                                name="МодернInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            />: Направление в прикладном искусстве и в архитектуре конца XIX — нач. XX в., стремившееся к конструктивности, чистоте линий, к лаконизму и целостности форм.
                            </p>
                            <p><input
                                id="ИмпрессионизмInput"
                                name="ИмпрессионизмInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            />: Направление в искусстве, стремящееся к воспроизведению личных переживаний, настроений и впечатлений художника в отрыве от действительности.
                            </p>
                            <p><input
                                id="КонструтивизмInput"
                                name="КонструтивизмInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            />: Направление в искусстве, являющееся выразителем крайнего формализма и техницизма и отвергающее идеологическое содержание искусства.
                            </p>
                            <p><input
                                id="ПримитивизмInput"
                                name="ПримитивизмInput"
                                type="text"
                                className={classes.inputComponent}
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            />: Направление в искусстве, использующее в своём творчестве формы первобытных, ранних стилей.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;