import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: false },
    { id: 2, isCorrect: true },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: false },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: true },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: false },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: true },
    { id: 15, isCorrect: false },
    { id: 16, isCorrect: false },
    { id: 17, isCorrect: false },
    { id: 18, isCorrect: true },
    { id: 19, isCorrect: false },
    { id: 20, isCorrect: true },
    { id: 21, isCorrect: false },
    { id: 22, isCorrect: false },
    { id: 23, isCorrect: true },
    { id: 24, isCorrect: false },
    { id: 25, isCorrect: true },
    { id: 26, isCorrect: false },
    { id: 27, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Escolha a frase que se adapta ao contexto." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Извини́те, я пришла ра́ньше.</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    Вы стоите пе́редо мной.
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    Вы стоите за мной.
                                </button>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    Вы стоите на́до мной.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Сдаётся дом.</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    Большая гости́ная с ками́ном.
                                </button>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    Большая гости́ная ря́дом с ками́ном.
                                </button>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    Большая гости́ная за ками́ном.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Откро́йте кры́шку телефо́на.</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    Под кры́шкой вы уви́дите сим-ка́рту.
                                </button>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    Пе́ред кры́шкой вы уви́дите сим-ка́рту.
                                </button>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    Ря́дом с кры́шкой вы уви́дите сим-ка́рту.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>– Извини́те, где нахо́дится музе́й?</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    Музе́й нахо́дится ря́дом с метро́.
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    Музе́й нахо́дится над метро́.
                                </button>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    Музе́й нахо́дится под метро́.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Где мы сейча́с лети́м?</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    Мы сейча́с лети́м за Испа́нией.
                                </button>
                                <button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    Мы сейча́с лети́м над Испа́нией.
                                </button>
                                <button
                                    key={buttonsEx1[18].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    Мы сейча́с лети́м ря́дом с Испа́нией.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Похо́же, что бу́дет дождь.</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    Ме́жду го́родом – большая чёрная ту́ча.
                                </button>
                                <button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    За го́родом – большая чёрная ту́ча.
                                </button>
                                <button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    Над го́родом – большая чёрная ту́ча.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Что тако́е подзе́мный тра́нспорт?</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[18].isCorrect)}
                                >
                                    Э́то тра́нспорт, кото́рый хо́дит пе́ред землёй.
                                </button>
                                <button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[19].isCorrect)}
                                >
                                    Э́то тра́нспорт, кото́рый хо́дит под землёй.
                                </button>
                                <button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[20].isCorrect)}
                                >
                                    Э́то тра́нспорт, кото́рый хо́дит ме́жду землёй.
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Внима́ние!</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[21].isCorrect)}
                                >
                                    Паркова́ть маши́ну над гаражо́м запрещено́!
                                </button>
                                <button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[22].isCorrect)}
                                >
                                    Паркова́ть маши́ну пе́ред гаражо́м запрещено́!
                                </button>
                                <button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[23].isCorrect)}
                                >
                                    Паркова́ть маши́ну под гаражо́м запрещено́!
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <p><b>Посмотрите на э́ту карти́ну.</b></p>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[20].id}
                                    onClick={event => handleColor(event, buttonsEx1[24].isCorrect)}
                                >
                                    Пе́ред ва́ми Малевич.
                                </button>
                                <button
                                    key={buttonsEx1[21].id}
                                    onClick={event => handleColor(event, buttonsEx1[25].isCorrect)}
                                >
                                    Под ва́ми Малевич.
                                </button>
                                <button
                                    key={buttonsEx1[22].id}
                                    onClick={event => handleColor(event, buttonsEx1[26].isCorrect)}
                                >
                                    За ва́ми Малевич.
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;