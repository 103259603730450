import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson24 from '../../../../../../Assets/Images/A1/Lesson24/image1lesson24.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>Сложные предложения (причина)</p>
                    <p>Frases complexas (causais)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> As orações subordinadas causais do russo são construídas com a conjunção composta <b><span className={classes.orangeHighlight}>ПОТОМУ ЧТО</span></b>.
                    </p>
                    <p>
                        <b><i><span className={classes.orangeHighlight}>Почему́</span> пти́цы лета́ют и не па́дают?</i></b>
                    </p>
                    <p>
                        <b><i><span className={classes.orangeHighlight}>Porque</span> os pássaros voam e não caem?</i></b>
                    </p>
                    <p>
                        <b><i>Пти́цы лета́ют и не па́дают, <span className={classes.orangeHighlight}>потому́ что</span> у них есть кры́лья.</i></b>
                    </p>
                    <p>
                        <b><i>Os pássaros voam e não caem <span className={classes.orangeHighlight}>porque</span> eles têm asas.</i></b>
                    </p>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b><span className={classes.orangeHighlight}>ПОЧЕМУ́</span></b></p>
                            <p>por que razão</p>
                            <p>
                                <b><i><span className={classes.orangeHighlight}>Почему́</span> облакá плыву́т по нéбу? <span className={classes.orangeHighlight}>Потому́ что...</span></i></b>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            vs.
                        </div>
                        <div className={classes.gridItem3}>
                            <p><b><span className={classes.orangeHighlight}>ПО ЧЕМУ́ </span></b></p>
                            <p>por onde</p>
                            <p>
                                <b><i>Облакá плыву́т <span className={classes.orangeHighlight}>по чему́</span>? <span className={classes.orangeHighlight}>По нéбу</span>.</i></b>
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ГЛАГОЛЫ ДВИЖЕНИЯ НЕСОВЕРШЕННОГО ВИДА: группа II</p>
                    <p>прошедшее время</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <img id="Image1Lesson24" src={Image1Lesson24} alt="lesson 24 illustrative example 1" />
                </div>
                <GrammarSubtitle>
                    <p>ДАТЕЛЬНЫЙ ПАДЕЖ: предлог K</p>
                    <p>Dativo: preposição K</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> A preposição K usa-se com verbos de movimento e rege o Caso Dativo.
                    </p>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>дви́гаться</p>
                            <p>ходи́ть/идти́</p>
                            <p>бе́гать/бежа́ть</p>
                            <p>пла́вать/плыть</p>
                            <p>лета́ть/лете́ть</p>
                            <p>…</p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b><span className={classes.orangeHighlight}>K</span> + nome/pronome</b> (Dat.)</p>
                            <p><b><span className={classes.orangeHighlight}>K</span> комý? K чему́?</b></p>
                            <p><b><span className={classes.orangeHighlight}>К</span> бра́ту. K до́му.</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Grammar;