import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'ктó-то', name: 'ктó-то', translation: 'ктó-то' },
    { id: 'кóе-чтó', name: 'кóе-чтó', translation: 'кóе-чтó' },
    { id: 'чтó-нибу́дь', name: 'чтó-нибу́дь', translation: 'чтó-нибу́дь' },
    { id: 'ктó-нибу́дь', name: 'ктó-нибу́дь', translation: 'ктó-нибу́дь' },
    { id: 'кóе-ктó', name: 'кóе-ктó', translation: 'кóе-ктó' },
    { id: 'чтó- то', name: 'судя', translation: 'судя' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <span className={classes.cardWrapper}>
                <span ref={drag} className={classes.dragCard}>
                    {name}
                </span>
            </span>
        );
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <span className={classes.cardWrapper}>
                <span ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <DragCard key={item.id} type={item.id} name={item.name} /> :
                        <span className={classes.dropCard} type={item.id}>largar aqui</span>
                    }
                </span>
            </span>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = (
            <div className={classes.questionsWrapper}>
                <div className={classes.question}>
                    <p>1. Я не бу́ду пока́зывать па́льцем, но <DropCard key={dropCards[4].id} item={dropCards[4]} /> не сде́лал дома́шнее зада́ние...</p>
                    <p>2. <DropCard key={dropCards[0].id} item={dropCards[0]} /> не сде́лал рефера́т! Сдано то́лько 10.</p>
                    <p>3. <DropCard key={dropCards[3].id} item={dropCards[3]} /> сде́лал дома́шнее зада́ние?</p>
                    <p>4. <DropCard key={dropCards[5].id} item={dropCards[5]} /> случи́лось с телефо́ном, он не включа́ется!</p>
                    <p>5. Когда́ пойдёшь в бар, купи́ мне <DropCard key={dropCards[2].id} item={dropCards[2]} /> пое́сть.</p>
                    <p>6. Иди́ сюда́! Я хочу́ тебе́ <DropCard key={dropCards[1].id} item={dropCards[1]} /> показа́ть.</p>
                </div>
            </div>
        );

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Arraste o pronome indefinido para a frase correspondente:" />
                <div className={classes.dragAndDropContainer}>
                    <DropCards />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;