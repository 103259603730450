import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>алле́и</b> alamedas</p>
                        <p><b>апельси́ны</b> laranjas</p>
                        <p><b>блонди́н</b> loiro</p>
                        <p><b>борода́</b> barba</p>
                        <p><b>брюне́тка</b> morena</p>
                        <p><b>весёлый</b> (adj.) alegre (m.)</p>
                        <p><b>ви́дела</b> (v. ви́деть) viste (f.)</p>
                        <p><b>вне́шность</b> aparência</p>
                        <p><b>во́лосы</b> cabelos</p>
                        <p><b>высо́кий</b> alto</p>
                        <p><b>гва́рдия</b> guarda</p>
                        <p><b>глу́пый</b> palerma (m.)</p>
                        <p><b>голубóй</b> azul claro</p>
                        <p><b>горя́чий</b> (adj.) quente (m.)</p>
                        <p><b>Грана́товый брасле́т</b> Pulseira de granates</p>
                        <p><b>де́вочки</b> meninas</p>
                        <p><b>дешёвые</b> baratos/as</p>
                        <p><b>дли́нная</b> comprida</p>
                        <p><b>дороги́е</b> caros/as</p>
                        <p><b>жир</b> gordura</p>
                        <p><b>жук</b> besouro</p>
                        <p><b>закры́тый</b> fechado</p>
                        <p><b>злой</b> mau</p>
                        <p><b>знако́мые</b> conhecidos/as</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>золото́й</b> dourado</p>
                        <p><b>и́менно</b> precisamente</p>
                        <p><b>кото́рый</b> (pron.) 1.que 2. qual</p>
                        <p><b>кра́сные</b> vermelhos/as</p>
                        <p><b>ла́дно</b> ok/certo</p>
                        <p><b>лёгкий</b> leve (m.)</p>
                        <p><b>ле́нты</b> fitas</p>
                        <p><b>лососёвый</b> de salmão/salmonídeo</p>
                        <p><b>ло́тос</b> lótus</p>
                        <p><b>люби́мый</b> favorito</p>
                        <p><b>ми́ля</b> milha</p>
                        <p><b>молодо́й</b> 1. jovem 2. novo</p>
                        <p><b>неприя́тный</b> desagradável</p>
                        <p><b>ну́жен</b> (adj). necessário</p>
                        <p><b>обели́ск</b> obelisco</p>
                        <p><b>обожа́ют</b> (v. обожа́ть) adoram</p>
                        <p><b>обыкнове́нный</b> vulgar (m.)</p>
                        <p><b>ого́нь</b> fogo</p>
                        <p><b>огро́мное</b> enorme (n.)</p>
                        <p><b>ора́нжевый</b> cor de laranja (m.)</p>
                        <p><b>откры́тый</b> aberto</p>
                        <p><b>пло́тный</b> encorpado/corpulento</p>
                        <p><b>плохо́й</b> mau</p>
                        <p><b>пожило́й</b> idoso</p>
                        <p><b>прекра́сный</b> lindo/ maravilhoso</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>пу́рпу́рный</b> roxo</p>
                        <p><b>ра́зница</b> diferença</p>
                        <p><b>ру́сые</b> de cabelo castanho claro (pl.)</p>
                        <p><b>ры́жий</b> ruivo</p>
                        <p><b>(с) кра́пинками</b> (Instr.) (com) pintinhas</p>
                        <p><b>све́тлые</b> claros/as</p>
                        <p><b>се́рые</b> cinzentos/as</p>
                        <p><b>си́льный</b> forte (m.)</p>
                        <p><b>си́ний</b> azul (m.)</p>
                        <p><b>сказа́ла</b> (v. сказа́ть) disse (f.)</p>
                        <p><b>скоре́е</b> antes</p>
                        <p><b>сла́бый</b> (adj.) frágil (m.)</p>
                        <p><b>счита́ешь</b> (v. счита́ть) consideras</p>
                        <p><b>то́лстый</b> gordo</p>
                        <p><b>тюльпа́ны</b> tulipas</p>
                        <p><b>тяжёлый</b> pesado</p>
                        <p><b>ужа́сный</b> horrível (m.)</p>
                        <p><b>у́зкие</b> estreitos/as</p>
                        <p><b>у́мный</b> inteligente (m.)</p>
                        <p><b>худо́й</b> magro</p>
                        <p><b>чёлка</b> franja</p>
                        <p><b>чёрные</b> pretos/as</p>
                        <p><b>ша́почка</b> capuchinho</p>
                        <p><b>широ́кие</b> largos/as</p>
                        <p><b>я́ркие</b> claros/as</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;