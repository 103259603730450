import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson5/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson5/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson5/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson5/image4grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ИМЯ СУЩЕСТВИТЕЛЬНОЕ: Предложный падеж (мн. ч.)</p>
                    <p>Nomes: Prepositivo plural</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> No Prepositivo plural, os nomes de todos os géneros têm a terminação <b><span className={classes.orangeHighlight}>–АХ</span></b>, quando a raiz do nome termina em <b>consoante dura</b> ou em <b>к/г/х/ш/щ/ч/ж</b>, ou <b><span className={classes.orangeHighlight}>-ЯХ</span></b>, quando se trata de um nome cuja raiz termina em <b>consoante branda</b>:
                    </p>
                </div>
                <div className={classes.item2}>
                    <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="450px" />
                </div>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="300px" />
                    </div>
                    <div className={classes.item3_2}>
                        <p>
                            <b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> Nos Casos Prepositivo, Genitivo, Instrumental e Dativo, os adjetivos do género feminino, no singular, têm a mesma terminação: <b><span className={classes.orangeHighlight}>-ОЙ/-ЕЙ.</span></b>
                        </p>
                        <p>
                            <b><span className={classes.orangeHighlight}><font size="5">&#9758;</font></span></b> Nos Casos Prepositivo, Genitivo e Acusativo (animado), no plural, todos os adjectivos têm a mesma terminação: <b><span className={classes.orangeHighlight}>-ЫХ/-ИХ</span></b>.
                        </p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ПРИЛАГАТЕЛЬНОЕ: Предложный падеж</p>
                    <p>Adjetivos: Prepositivo</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <img id="image3Grammar" src={Image3Grammar} alt="table 3" width="400px" />
                    <img id="image4Grammar" src={Image4Grammar} alt="table 4" width="200px" />
                </div>
                <div className={classes.item5}>
                    <div className={classes.item5_1}>
                        <b><span className={classes.orangeHighlight}>ÉСЛИ…</span></b>
                    </div>
                    <div className={classes.item5_2}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ÉСЛИ</span></b> é uma conjunção condicional equivalente à conjunção portuguesa <b>se</b>:</p>
                        <p><i>Что вы де́лаете, <b>е́сли</b> у вас мно́го свобо́дного вре́мени?</i></p>
                        <p><i>O que é que fazes, <b>se</b> tiveres muito tempo livre?</i></p>
                        <p><i>Е́сли у меня́ мно́го свобо́дного вре́мени, (<b>то</b>) я иду́ гуля́ть.</i></p>
                        <p><i><b>Se</b> eu tiver muito tempo livre, (<b>então</b>) vou passear.</i></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;