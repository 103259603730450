import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПАДЕЖ С ПРЕДЛОГАМИ</p>
                    <p>Caso regido por preposições</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ЗАМЕНИ́ТЬ Вин. п. + Тв. п.</span></b></p>
                    <p><b><span className={classes.orangeHighlight}>(substituir)</span></b></p>
                    <p><i><b>Замени́ть</b> ста́рые часы́ но́выми.</i></p>
                    <p><i><b>Substituir</b> o relógio velho por novo.</i></p>
                    <div className={classes.spacer}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ПОМЕНЯТЬ Вин. п. + НА + Вин. п.</span></b></p>
                        <p><b><span className={classes.orangeHighlight}>(trocar)</span></b></p>
                        <p><i><b>Поменя́ть</b> ста́рые часы́ на но́вые.</i></p>
                        <p><i><b>Trocar</b> o relógio velho por novo.</i></p>
                    </div>
                    <div className={classes.spacer}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ВМÉСТО + Род. п.</span></b></p>
                        <p><b><span className={classes.orangeHighlight}>(em vez de)</span></b></p>
                        <p><i><b>Купи́ть</b> кроссо́вки вме́сто боти́нок.</i></p>
                        <p><i><b>Comprar</b> ténis em vez de botas.</i></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;