import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson8 from '../../../../../../Assets/Images/A1/Lesson8/image1lesson8.png';
import Image2Lesson8 from '../../../../../../Assets/Images/A1/Lesson8/image2lesson8.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРЕДЛОЖНЫЙ ПАДЕЖ</p>
                    <p>Caso Prepositivo (Assertivo)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="WhereAboutWhoAboutWhat" src={Image1Lesson8} alt="Where about who about what" width="250px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.greenHighlight}>&#9758;</span></font> <b><span className={classes.orangeHighlight}>о</span></b>, <b><span className={classes.orangeHighlight}>об</span></b>                ou <b><span className={classes.orangeHighlight}>обо</span></b>
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>o</span></b> + uma consoante: о <b>р</b>або́те
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>o</span></b> + е/ё/ю/я: о <b>Е</b>врóпе
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>об</span></b> + a/o/у/и/ы: об <b>о́</b>тпуске
                            </p>
                            <p>
                                <b><span className={classes.orangeHighlight}>обо</span></b>: обо <b>в</b>сём, обо <b>в</b>сёх, обо <b>мне</b>
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ПРЕДЛОЖНЫЙ ПАДЕЖ</p>
                    <p>Caso Prepositivo: Pronomes Pessoais</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <img id="NominativePrepositve" src={Image2Lesson8} alt="nominative prepositve" width="250px" />
                </div>
                <div className={classes.item3}>
                    <div className={classes.flexItem}>
                        <p>
                            <b><font className={classes.orangeHighlight}> ДУ́МАТЬ О …</font>  vs. <font className={classes.orangeHighlight}>ДУ́МАТЬ, ЧТО … </font></b>
                        </p>
                    </div>
                </div>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><b><span className={classes.orangeHighlight}>О чём</span>/<span className={classes.orangeHighlight}>о ком </span>вы ду́маете?</b></p>
                            <p><b>Я ду́маю <span className={classes.orangeHighlight}>o</span></b> + Caso Prepositivo</p>
                            <p>Я ду́маю <span className={classes.orangeHighlight}>о Мари́и</span>. /
                                Я ду́маю <span className={classes.orangeHighlight}>о ней</span>.
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <b><span className={classes.orangeHighlight}>Что́ </span>вы ду́маете о ...? </b>
                            </p>
                            <p>
                                <b>Я ду́маю, <span className={classes.orangeHighlight}>(что)</span></b> + frase
                            </p>
                            <p>
                                Я ду́маю, <span className={classes.orangeHighlight}>(что) Мари́я живёт в Москве́. </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Grammar;