import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: false },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
    { id: 7, isCorrect: true },
    { id: 8, isCorrect: false },
    { id: 9, isCorrect: false },
    { id: 10, isCorrect: true },
    { id: 11, isCorrect: false },
    { id: 12, isCorrect: false },
    { id: 13, isCorrect: false },
    { id: 14, isCorrect: true },
    { id: 15, isCorrect: false },
    { id: 16, isCorrect: false },
    { id: 17, isCorrect: false },
    { id: 18, isCorrect: true },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Selecione a opção correta." />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[0].id}
                                    onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                                >
                                    <b>Нам</b>
                                </button>
                                <button
                                    key={buttonsEx1[1].id}
                                    onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                                >
                                    <b>Мы</b> ... на́до сде́лать ремо́нт.
                                </button>
                                <button
                                    key={buttonsEx1[2].id}
                                    onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                                >
                                    <b>Я</b>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[3].id}
                                    onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                                >
                                    <b>Он</b>
                                </button>
                                <button
                                    key={buttonsEx1[4].id}
                                    onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                                >
                                    <b>Она́</b> ... должнá верну́ться в 5 часо́в.
                                </button>
                                <button
                                    key={buttonsEx1[5].id}
                                    onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                                >
                                    <b>Они́</b>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[6].id}
                                    onClick={event => handleColor(event, buttonsEx1[6].isCorrect)}
                                >
                                    <b>Ему́</b>
                                </button>
                                <button
                                    key={buttonsEx1[7].id}
                                    onClick={event => handleColor(event, buttonsEx1[7].isCorrect)}
                                >
                                    <b>Его</b> ... нужны э́ти кни́ги.
                                </button>
                                <button
                                    key={buttonsEx1[8].id}
                                    onClick={event => handleColor(event, buttonsEx1[8].isCorrect)}
                                >
                                    <b>Oни́</b>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[9].id}
                                    onClick={event => handleColor(event, buttonsEx1[9].isCorrect)}
                                >
                                    <b>Вам</b>
                                </button>
                                <button
                                    key={buttonsEx1[10].id}
                                    onClick={event => handleColor(event, buttonsEx1[10].isCorrect)}
                                >
                                    <b>Вы</b> ... ну́жно купи́ть слова́рь.
                                </button>
                                <button
                                    key={buttonsEx1[11].id}
                                    onClick={event => handleColor(event, buttonsEx1[11].isCorrect)}
                                >
                                    <b>Ты</b>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[12].id}
                                    onClick={event => handleColor(event, buttonsEx1[12].isCorrect)}
                                >
                                    <b>Я</b>
                                </button>
                                <button
                                    key={buttonsEx1[13].id}
                                    onClick={event => handleColor(event, buttonsEx1[13].isCorrect)}
                                >
                                    <b>Мне</b> ... ну́жно идти́ на рабо́ту.
                                </button>
                                <button
                                    key={buttonsEx1[14].id}
                                    onClick={event => handleColor(event, buttonsEx1[14].isCorrect)}
                                >
                                    <b>Мы</b>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsAndTextContainer}>
                            <div className={classes.buttonsContainerEx1}>
                                <button
                                    key={buttonsEx1[15].id}
                                    onClick={event => handleColor(event, buttonsEx1[15].isCorrect)}
                                >
                                    <b>Mеня</b>
                                </button>
                                <button
                                    key={buttonsEx1[16].id}
                                    onClick={event => handleColor(event, buttonsEx1[16].isCorrect)}
                                >
                                    <b>Oна́</b> ... нужна́ по́мощь?
                                </button>
                                <button
                                    key={buttonsEx1[17].id}
                                    onClick={event => handleColor(event, buttonsEx1[17].isCorrect)}
                                >
                                    <b>Вам</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;