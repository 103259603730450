import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson25 from '../../../../../../Assets/Images/A1/Lesson25/image1lesson25.png';
import Image2Lesson25 from '../../../../../../Assets/Images/A1/Lesson25/image2lesson25.png';
import Image3Lesson25 from '../../../../../../Assets/Images/A1/Lesson25/image3lesson25.png';
import Image4Lesson25 from '../../../../../../Assets/Images/A1/Lesson25/image4lesson25.png';
import Image5Lesson25 from '../../../../../../Assets/Images/A1/Lesson25/image5lesson25.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ВОЗВРАТНЫЕ ГЛАГОЛЫ</p>
                    <p>1. Verbos Reflexivos</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image1Lesson25" src={Image1Lesson25} alt="lesson 25 illustrative example 1" />
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="Image2Lesson25" src={Image2Lesson25} alt="lesson 25 illustrative example 2" />
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ВОЗВРАТНОЕ МЕСТОИМЕНИЕ</p>
                    <p>Pronome Reflexivo</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Há verbos em que o valor reflexivo é dado apenas pelo pronome <b><span className={classes.orangeHighlight}>СЕБЯ́</span></b>.
                            </p>
                            <p><b>чу́вствовать себя́, вести́ себя́, люби́ть себя́, ненави́деть себя́</b></p>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Ao contrário do português, neste tipo de construções, o advérbio ocorre preferencialmente antes do verbo.
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="Image3Lesson25" src={Image3Lesson25} alt="lesson 25 illustrative example 3" />
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ВОЗВРАТНЫЕ ГЛАГОЛЫ</p>
                    <p>2. Verbos Reflexivos</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Grupo de verbos reflexivos que se usam sempre com <b><span className={classes.orangeHighlight}>-СЯ</span></b>:
                    </p>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image5Lesson25" src={Image5Lesson25} alt="lesson 25 illustrative example 5" />
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="Image4Lesson25" src={Image4Lesson25} alt="lesson 25 illustrative example 4" />
                        </div>
                    </div>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O verbo <b><span className={classes.orangeHighlight}>БОЯ́ТЬСЯ</span></b> usa-se sempre com o Caso genitivo:
                    </p>
                    <p>
                        <b><i><span className={classes.orangeHighlight}>Чего́</span> вы бои́тесь? Я бою́сь хóлод<span className={classes.orangeHighlight}>а</span>.</i></b>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Grammar;