import React from 'react';
import classes from './AboutThisProject.css';
import MainHeader from '../../Header/MainHeader/MainHeader';
import centerColumn from '../../CenterColumn/CenterColumn.css';

const aboutThisProject = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} />
            <div className={classes.AboutThisProject}>
                <p>
                    <b>DIALOG</b> é um projeto para ensino do russo como língua estrangeira, para falantes de português em contexto académico, implementado
                    pela Faculdade de Letras da Universidade de Lisboa (FLUL), com o apoio da Fundação <i>Russkiy Mir</i>. A falta de manuais de
                    língua russa atualizados e pensados para o público lusófono impulsionou o desenvolvimento do projeto DIALOG. Por outro
                    lado, este complexo didático adapta-se à realidade e contexto de ensino nas universidades de língua portuguesa, cumprindo
                    uma série de requisitos, reunidos num questionário realizado a cerca de 50 alunos dos diferentes níveis de russo da FLUL,
                    onde a língua russa é ensinada desde 1974.
                </p>
                <p>
                    O Projeto engloba três níveis de língua; respetivamente, os níveis elementar (A1), base (A2) e limiar (B1). Para cada nível
                    está disponível um manual impresso, acompanhado de uma <i>pen USB</i> com complemento áudio. Este site complementa os três manuais
                    impressos com materiais didáticos audiovisuais, estimulando a aprendizagem, enriquecendo a dinâmica da aula, mas também
                    incentivando o estudo autónomo do aluno.
                </p>
                <p>
                    Os materiais didáticos do projeto DIALOG destinam-se a cobrir os principais tipos de atividades: conversação, leitura,
                    audição e escrita, não deixando de parte a componente gramatical. As componentes temáticas e linguísticas dos manuais
                    e dos seus complementos incluem tarefas criativas e de natureza comunicativa, adaptadas às esferas profissionais e sociais
                    dos alunos.
                </p>
                <p>
                    Os níveis de competência de língua estrangeira estão escalonados em conformidade com o Quadro Europeu Comum de Referência
                    para as línguas e com a norma estatal para o ensino de língua russa como língua estrangeira (Государственный стандарт
                    по русскому язьку как иностранному).
                </p>
            </div>
        </div>
    );
}

export default aboutThisProject;