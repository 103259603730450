import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const DragTypes = [
    { id: '1', type: 'РАЗРЕШЕНО', name: 'духи', translation: 'духи' },
    { id: '2', type: 'РАЗРЕШЕНО', name: 'блендер', translation: 'блендер' },
    { id: '3', type: 'РАЗРЕШЕНО', name: 'шампунь', translation: 'шампунь' },
    { id: '4', type: 'РАЗРЕШЕНО', name: 'кондиционер', translation: 'кондиционер' },
    { id: '5', type: 'РАЗРЕШЕНО', name: 'сыр', translation: 'сыр' },
    { id: '6', type: 'РАЗРЕШЕНО', name: 'шоколад', translation: 'шоколад' },
    { id: '7', type: 'РАЗРЕШЕНО', name: 'фотоаппарат', translation: 'фотоаппарат' },
    { id: '8', type: 'РАЗРЕШЕНО', name: 'видеокамера', translation: 'видеокамера' },
    { id: '9', type: 'РАЗРЕШЕНО', name: 'пинцет', translation: 'пинцет' },
    { id: '10', type: 'РАЗРЕШЕНО', name: 'мёд', translation: 'мёд' },
    { id: '11', type: 'РАЗРЕШЕНО', name: 'коляска', translation: 'коляска' },
    { id: '12', type: 'РАЗРЕШЕНО', name: 'планшет', translation: 'планшет' },
    { id: '13', type: 'РАЗРЕШЕНО', name: 'аспирин', translation: 'аспирин' },
    { id: '14', type: 'РАЗРЕШЕНО', name: 'батарейки', translation: 'батарейки' },
    { id: '15', type: 'РАЗРЕШЕНО', name: 'колбаса', translation: 'колбаса' },
    { id: '16', type: 'РАЗРЕШЕНО', name: 'мяч', translation: 'мяч' },
    { id: '17', type: 'РАЗРЕШЕНО', name: 'йод', translation: 'йод' },
    { id: '18', type: 'ЗАПРЕЩЕНО', name: 'алкоголь', translation: 'алкоголь' },
    { id: '19', type: 'ЗАПРЕЩЕНО', name: 'бритва', translation: 'бритва' },
    { id: '20', type: 'ЗАПРЕЩЕНО', name: 'градусник', translation: 'градусник' },
    { id: '21', type: 'ЗАПРЕЩЕНО', name: 'спицы', translation: 'спицы' },
    { id: '22', type: 'ЗАПРЕЩЕНО', name: 'дезодорант', translation: 'дезодорант' },
    { id: '23', type: 'ЗАПРЕЩЕНО', name: 'аэрозоли', translation: 'аэрозоли' },
    { id: '24', type: 'ЗАПРЕЩЕНО', name: 'вино', translation: 'вино' },
    { id: '25', type: 'ЗАПРЕЩЕНО', name: 'топор', translation: 'топор' },
    { id: '26', type: 'ЗАПРЕЩЕНО', name: 'варенье', translation: 'варенье' },
    { id: '27', type: 'ЗАПРЕЩЕНО', name: 'ножницы', translation: 'ножницы' },
    { id: '28', type: 'ЗАПРЕЩЕНО', name: 'оружие', translation: 'оружие' },
    { id: '29', type: 'ЗАПРЕЩЕНО', name: 'спички', translation: 'спички' },
    { id: '30', type: 'ЗАПРЕЩЕНО', name: 'бензин', translation: 'бензин' },
    { id: '31', type: 'ЗАПРЕЩЕНО', name: 'золото', translation: 'золото' },
    { id: '32', type: 'ЗАПРЕЩЕНО', name: 'кусачки', translation: 'кусачки' },
    { id: '33', type: 'ЗАПРЕЩЕНО', name: 'бриллианты', translation: 'бриллианты' },
];

const DropTypes = [
    { id: '1', type: 'ЗАПРЕЩЕНО', name: 'ЗАПРЕЩЕНО' },
    { id: '2', type: 'РАЗРЕШЕНО', name: 'РАЗРЕШЕНО' },
];

const Exercises = props => {
    const [dropCards] = useState(DropTypes);
    const [dragCards, setDragCards] = useState(DragTypes);
    const [droppedListЗАПРЕЩЕНО, setDroppedListЗАПРЕЩЕНО] = useState([]);
    const [droppedListРАЗРЕШЕНО, setDroppedListРАЗРЕШЕНО] = useState([]);

    const DragCard = ({ item }) => {
        const [, drag] = useDrag({
            type: item.type,
            item: { ...item },
            collect: monitor => ({
                item: monitor.getItem(),
                isDragging: !!monitor.isDragging()
            })
        });

        return (
            <div className={classes.cardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {item.translation}
                </div>
            </div>);
    }

    const DisplayCard = ({ type }) => {
        const content = type === 'ЗАПРЕЩЕНО' ? (
            <div className={classes.displayCardRed}>ЗАПРЕЩЕНО</div>
        ) : (
            <div className={classes.displayCardGreen}>РАЗРЕШЕНО</div>
        );

        return content;
    }

    const DroppedCardsЗАПРЕЩЕНО = () => {
        const cards = droppedListЗАПРЕЩЕНО.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DroppedCardsРАЗРЕШЕНО = () => {
        const cards = droppedListРАЗРЕШЕНО.map(item => {
            return <div className={classes.droppedCard} key={item.id} item={item}>{item.translation}</div>
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCard = ({ accepts: accept, item }) => {
        const [, drop] = useDrop(() => ({
            accept: item.type,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();
                const canDrop = monitor.canDrop();
                const dropItem = monitor.getItem();

                if (didDrop) {
                    return;
                }

                if (canDrop) {
                    setDragCards(dragCards.filter(item => item.name !== dropItem.name))
                }

                if (dropItem.type === 'ЗАПРЕЩЕНО') {
                    if (!droppedListЗАПРЕЩЕНО.some(({ name }) => name === dropItem.name)) {
                        setDroppedListЗАПРЕЩЕНО([...droppedListЗАПРЕЩЕНО, dropItem]);
                    }
                } else {
                    if (!droppedListРАЗРЕШЕНО.some(({ name }) => name === dropItem.name)) {
                        setDroppedListРАЗРЕШЕНО([...droppedListРАЗРЕШЕНО, dropItem]);
                    }
                }
            },
        }), [accept]);

        return (
            <div className={classes.cardWrapper}>
                <div>{item.translation}</div>
                {item.type === 'ЗАПРЕЩЕНО' ?
                    <div ref={drop} className={classes.dropContainer}>
                        <DisplayCard type={item.type} />
                        <DroppedCardsЗАПРЕЩЕНО /></div> :
                    <div ref={drop} className={classes.dropContainer}>
                        <DisplayCard type={item.type} />
                        <DroppedCardsРАЗРЕШЕНО />
                    </div>
                }
            </div>
        );
    }

    const DragCards = () => {
        const sortedCards = dragCards.sort((a, b) => {
            return a.id.localeCompare(b.id)
        });

        const cards = sortedCards.map(item => {
            return <DragCard key={item.id} item={item} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = dropCards.map(item => {
            return <DropCard key={item.id} item={item} />
        });

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Найдите, что разрешено, а что запрещено провозить в ручной клади"></ExercisesQuestions>
                <div className={classes.gridItems}>
                    <DropCards className={classes.dropCards} />
                    <DragCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;