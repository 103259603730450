import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson12 from '../../../../../../Assets/Images/A1/Lesson12/image1lesson12.png';
import Image2Lesson12 from '../../../../../../Assets/Images/A1/Lesson12/image2lesson12.png';
import Image3Lesson12 from '../../../../../../Assets/Images/A1/Lesson12/image3lesson12.png';
import Image4Lesson12 from '../../../../../../Assets/Images/A1/Lesson12/image4lesson12.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ВИНИТЕЛЬНЫЙ ПАДЕЖ: предло́г В</p>
                    <p>Caso Acusativo: Preposição B</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="WeekDaysTable" src={Image1Lesson12} alt="week days table" width="250px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> <b>КОГДА́?</b>
                            </p>
                            <p>
                                O Caso Acusativo é usado também para indicar o dia de semana. Neste caso, o substantivo ocorre com a preposição <b><span className={classes.orangeHighlight}>B</span></b>.
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ВИНИТЕЛЬНЫЙ ПАДЕЖ: предло́г ЗА</p>
                    <p>Caso Acusativo: Preposição ЗА</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="AccusativeCase" src={Image2Lesson12} alt="accusative case" width="250px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Às formas da 3.ª p. sg. e pl. é acrescendada a letra <b><span className={classes.orangeHighlight}>H</span></b>,
                                quando o pronome pessoal ocorre com uma preposição:
                            </p>
                            <p>
                                <b>за <span className={classes.orangeHighlight}>н</span>её / в <span className={classes.orangeHighlight}>н</span>её, за <span className={classes.orangeHighlight}>н</span>его́ / в <span className={classes.orangeHighlight}>н</span>его́, за <span className={classes.orangeHighlight}>н</span>их /в <span className={classes.orangeHighlight}>н</span>их</b>
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>Verbo</p>
                    <p>ЗВАТЬ</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="CallVerbExample" src={Image3Lesson12} alt="call verb example" width="250px" />
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="ToCallSomeoneVerbExample" src={Image4Lesson12} alt="to call someone verb example" width="250px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;