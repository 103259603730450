import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1exercise from '../../../../../../Assets/Images/A2/Lesson17/image1exercise.png';
import Image2exercise from '../../../../../../Assets/Images/A2/Lesson17/image2exercise.png';
import Image3exercise from '../../../../../../Assets/Images/A2/Lesson17/image3exercise.png';
import Image4exercise from '../../../../../../Assets/Images/A2/Lesson17/image4exercise.png';
import Image5exercise from '../../../../../../Assets/Images/A2/Lesson17/image5exercise.png';
import Image6exercise from '../../../../../../Assets/Images/A2/Lesson17/image6exercise.png';
import Image7exercise from '../../../../../../Assets/Images/A2/Lesson17/image7exercise.png';
import Image8exercise from '../../../../../../Assets/Images/A2/Lesson17/image8exercise.png';
import Image9exercise from '../../../../../../Assets/Images/A2/Lesson17/image9exercise.png';
import Image10exercise from '../../../../../../Assets/Images/A2/Lesson17/image10exercise.png';
import Image11exercise from '../../../../../../Assets/Images/A2/Lesson17/image11exercise.png';


const VerbList = [
    { id: 'постирала', name: 'постирала', matcher: 'постирала' },
    { id: 'погладила', name: 'погладила', matcher: 'погладила' },
    { id: 'вымыл', name: 'вымыл', matcher: 'вымыл' },
    { id: 'вымылa', name: 'вымылa', matcher: 'вымылa' },
    { id: 'купила', name: 'купила', matcher: 'купила' },
    { id: 'завернула', name: 'завернула', matcher: 'завернула' },
    { id: 'нарядили', name: 'нарядили', matcher: 'нарядили' },
    { id: 'испекли', name: 'испекли', matcher: 'испекли' },
    { id: 'поиграли', name: 'поиграли', matcher: 'поиграли' },
    { id: 'приготовила', name: 'приготовила', matcher: 'приготовила' },
    { id: 'заснул', name: 'заснул', matcher: 'заснул' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value === matcher) {
            event.currentTarget.style.color = 'green';
        } else {
            event.currentTarget.style.color = 'red';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Preencha os espaços, descrevendo como correu o dia desta família, de acordo as imagens (passado perfetivo):" />
                <div className={classes.questionsContainerEx2}>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image1exercise" src={Image1exercise} alt="1" width="220px" />
                        <div className={classes.inputWrapper}>
                            1. Ма́ма <input
                                id="постиралаInput"
                                name="постиралаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[0].matcher)}
                            /> бельё.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image2exercise" src={Image2exercise} alt="2" width="220px" />
                        <div className={classes.inputWrapper}>
                            2. Она́ <input
                                id="погладилаInput"
                                name="погладилаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[1].matcher)}
                            /> все руба́шки.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image3exercise" src={Image3exercise} alt="3" width="220px" />
                        <div className={classes.inputWrapper}>
                            3. Па́па <input
                                id="вымылInput"
                                name="вымылInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[2].matcher)}
                            /> полы́.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image4exercise" src={Image4exercise} alt="4" width="220px" />
                        <div className={classes.inputWrapper}>
                            4. Ма́ма <input
                                id="вымылaInput"
                                name="вымылaInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[3].matcher)}
                            /> óкна.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image5exercise" src={Image5exercise} alt="5" width="220px" />
                        <div className={classes.inputWrapper}>
                            5. Она́ <input
                                id="купилаInput"
                                name="купилаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[4].matcher)}
                            /> подáрки.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image6exercise" src={Image6exercise} alt="6" width="220px" />
                        <div className={classes.inputWrapper}>
                            6. Она́ <input
                                id="завернулаInput"
                                name="завернулаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[5].matcher)}
                            /> подáрки.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image7exercise" src={Image7exercise} alt="7" width="220px" />
                        <div className={classes.inputWrapper}>
                            7. Они́ <input
                                id="нарядилиInput"
                                name="нарядилиInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[6].matcher)}
                            /> ёлку.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image8exercise" src={Image8exercise} alt="8" width="220px" />
                        <div className={classes.inputWrapper}>
                            8. Они́ <input
                                id="испеклиInput"
                                name="испеклиInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[7].matcher)}
                            /> пироги́.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image9exercise" src={Image9exercise} alt="9" width="220px" />
                        <div className={classes.inputWrapper}>
                            9. Они́ <input
                                id="поигралиInput"
                                name="поигралиInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[8].matcher)}
                            /> с детьми́.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image10exercise" src={Image10exercise} alt="10" width="220px" />
                        <div className={classes.inputWrapper}>
                            10. Ма́ма <input
                                id="приготовилаInput"
                                name="приготовилаInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[9].matcher)}
                            /> пра́здничный стол.
                        </div>
                    </div>
                    <div className={classes.cardContainerEx2}>
                        <img id="Image11exercise" src={Image11exercise} alt="11" width="150px" />
                        <div className={classes.inputWrapper}>
                            11. Ребëнок <input
                                id="заснулInput"
                                name="заснулInput"
                                type="text"
                                onChange={event => handleChange(event, VerbList[10].matcher)}
                            />.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;