import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson15 from '../../../../../../Assets/Images/A1/Lesson15/image1lesson15.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>УКАЗА́ТЕЛЬНЫЕ МЕСТОИМЕНИЯ</p>
                    <p>Pronomes Demonstrativos</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p>
                        <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> Em português há dois pronomes para localizar um objeto/pessoa afastado do locutor: <b>esse/aquele</b>. Em russo, para expressar essas duas localizações, temos apenas um pronome: <b><span className={classes.orangeHighlight}>ТОТ</span></b>, que varia em género, número e caso.
                    </p>
                </div>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p>
                                <span className={classes.orangeHighlight}><font size="5">&#9758;</font> <b>ЗДЕСЬ</b></span>
                            </p>
                        </div>
                        <div className={classes.gridItem2}>
                            <img id="DemonstrativePronounTable" src={Image1Lesson15} alt="Demonstrative Pronoun" width="250px" />
                        </div>
                        <div className={classes.gridItem3}>
                            <p>
                                <span className={classes.orangeHighlight}><b>ТАМ</b> <font size="5">&#9758;</font></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.item3}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <div className={classes.centerAlignement}>
                                <p><b><span className={classes.greenHighlight}>Э́ТО</span></b></p>
                                <p>(invariável)</p>
                            </div>
                            <p>Não há concordância entre <b>Э́ТО</b> e a palavra a que se refere. É uma palavra que não muda em género e número. Tem sempre a mesma forma. É semelhante a “isto” em português.</p>
                            <p>–<b>Что э́то (такóе)?</b></p>
                            <p>– <b>Э́то</b> щи. <b>Э́то</b> pу́сский суп.</p>
                            <p> – <b>Кто э́то?</b></p>
                            <p>–<b>Э́то</b> мои́ друзья́. Они́ ру́сские.</p>
                            <p><b><span className={classes.greenHighlight}>Э́ТО</span></b> invariável aparece em posição de sujeito em frases copulativas (ser/estar/…).</p>
                            <p><b><span className={classes.greenHighlight}>Э́то</span> [- ] краси́вый дом.</b></p>
                            <p>Isto é uma casa bonita.</p>
                            <p><b><span className={classes.greenHighlight}>Э́то</span> [-] краси́вое пла́тье.</b></p>
                        </div>
                        <div className={classes.gridItem2}>
                            <div className={classes.centerAlignement}>
                                <p><b><span className={classes.greyHighlight}>Э́ТОТ/</span><span className={classes.orangeHighlight}>Э́ТО</span><span className={classes.greyHighlight}>/Э́ТА/Э́ТИ</span></b></p>
                                <p>(pronome demonstrativo variável)</p>
                            </div>
                            <p>Há concordância entre o pronome demonstrativo e o nome em número e género. Pode ser dado como resposta a pergunta “како́й / какáя / како́е / каки́е”.</p>
                            <p><b>– Какóe</b> ты хо́чешь моро́женое?</p>
                            <p><b>– Э́то.</b></p>
                            <p>–<b>Какóe</b> пла́тье тебе́ нра́вится?</p>
                            <p>–<b>Тo.</b></p>
                            <p><b><span className={classes.orangeHighlight}>Э́ТО</span></b> variável aparece antes do nome.</p>
                            <p><b><span className={classes.orangeHighlight}>Э́тот</span> дом [-] краси́вый.</b></p>
                            <p>Esta casa é bonita.</p>
                            <p><b><span className={classes.orangeHighlight}>Э́то</span> пла́тье [-] краси́вое.</b></p>
                            <p>Este vestido é bonito.</p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ЦИ́ФРЫ 30-100</p>
                    <p>Números 30-100</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <p><b>30</b> – три́<b><span className={classes.orangeHighlight}>дцать</span></b></p>
                            <p><b>40</b> – <b><span className={classes.orangeHighlight}>сóрок</span></b></p>
                            <p><b>50</b> – пять<b><span className={classes.orangeHighlight}>деся́т</span></b></p>
                            <p><b>60</b> – шесть<b><span className={classes.orangeHighlight}>деся́т</span></b></p>
                        </div>
                        <div className={classes.gridItem2}>
                            <p><b>70</b> – семь<b><span className={classes.orangeHighlight}>деся́т</span></b></p>
                            <p><b>80</b> – во́семь<b><span className={classes.orangeHighlight}>деся́т</span></b></p>
                            <p><b>90</b> – девяно́<b><span className={classes.orangeHighlight}>сто</span></b></p>
                            <p><b>100</b> – <b><span className={classes.orangeHighlight}>сто</span></b></p>
                        </div>
                    </div>
                    <p>
                        <b><font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font></b> As desinências dos Casos Nominativo e Acusativo dos numerais são iguais (à excepção de de одна́ (nom.)→ одну́ (acus.).
                    </p>
                    <p><b><span className={classes.orangeHighlight}>a)	Одна́</span></b><sub>(Nom.)</sub> же́нщина купи́ла <b><span className={classes.orangeHighlight}>одну́</span></b><sub>(Acus.)</sub> кни́гу.</p>
                    <p><b><span className={classes.orangeHighlight}>b)	Со́рок</span></b><sub>(Nom.)</sub> – э́то ци́фра.</p>
                    <p><b><span className={classes.orangeHighlight}>c)</span></b> Он написа́л кни́гу в <b><span className={classes.orangeHighlight}>сорок</span></b><sub>(Acus.)</sub> лет.</p>
                    <div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Grammar;