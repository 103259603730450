import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>во́здух</b> ar</p>
                        <p><b>грозо́й</b> (Instr.) trovoada</p>
                        <p><b>дойти́</b> chegar (até)</p>
                        <p><b>едо́й</b> (Instr.) comida</p>
                        <p><b>жёсткий диск</b> disco rígido</p>
                        <p><b>журна́льный сто́лик</b> mesa de apoio</p>
                        <p><b>за</b> (prep.) atrás</p>
                        <p><b>(за) кре́слом</b> (atrás da) cadeira</p>
                        <p><b>(за) крова́тью</b> (atrás da) cama</p>
                        <p><b>забо́р</b> cerca</p>
                        <p><b>запреща́ется</b> (v. запреща́ться) é proibido</p>
                        <p><b>землёй</b> (Instr.) terra</p>
                        <p><b>и́щет</b> (v. иска́ть) procura</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ковёр</b> tapete</p>
                        <p><b>кре́сло</b> poltrona</p>
                        <p><b>кры́шку</b> (Acus.) tampa</p>
                        <p><b>ме́жду</b> (prep.) entre</p>
                        <p><b>мо́йте</b> (v. мыть) lavem</p>
                        <p><b>над</b> (prep.) 1. por cima 2. sobre</p>
                        <p><b>наприме́р</b> por exemplo</p>
                        <p><b>перево́дов</b> (Gen.) de traduções</p>
                        <p><b>(пе́ред) ками́ном</b> (em frente) da lareira</p>
                        <p><b>пе́ред</b> (prep.) 1. em frente 2. antes</p>
                        <p><b>под</b> (prep.) por baixo</p>
                        <p><b>(под) столо́м</b> (por baixo da) mesa</p>
                        <p><b>(под) сту́лом</b> (por baixo da) cadeira</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>подзе́мный</b> subterrâneo</p>
                        <p><b>по́зже</b> 1. mais tarde 2. depois</p>
                        <p><b>посо́льство</b> embaixada</p>
                        <p><b>пришли́</b> (v. прийти́) vieram</p>
                        <p><b>рабо́тником</b> (instr.) empregado</p>
                        <p><b>работода́телем</b> (instr.) empregador</p>
                        <p><b>ря́дом с</b> (prep.) 1. próximo de 2. ao lado de</p>
                        <p><b>ря́дом с окнóм</b> ao lado da janela</p>
                        <p><b>свéчи</b> velas</p>
                        <p><b>сдаётся</b> (v. сдава́ться) arrenda-se</p>
                        <p><b>стои́те</b> (v. стоя́ть) está (numa fila)</p>
                        <p><b>торше́р</b> candeeiro de pé</p>
                        <p><b>ту́ча</b> nuvem</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;