import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1 from '../../../../../../Assets/Images/B1/Lesson17/image1exercise.png';

const buttonsEx1 = [
    { id: 1, isCorrect: true },
    { id: 2, isCorrect: false },
    { id: 3, isCorrect: false },
    { id: 4, isCorrect: true },
    { id: 5, isCorrect: true },
    { id: 6, isCorrect: false },
];

const Exercises = props => {
    const handleColor = (event, isCorrect) => {
        const color = isCorrect ? event.currentTarget.style.color = 'green' : event.currentTarget.style.color = 'red';

        return color;
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Выберите правильный вариант:" />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.gridContainer1}>
                        “В СООТВЕТСТВИИ С ТРЕБОВАНИЯМИ ПО ТРАНСПОРТНОЙ БЕЗОПАСНОСТИ…
                        <img id="image1" src={Image1} alt="1" width="300px" />
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[0].id}
                                onClick={event => handleColor(event, buttonsEx1[0].isCorrect)}
                            >
                                ПАССАЖИРАМ ЗАПРЕЩАЕТСЯ
                            </button>
                            <button
                                key={buttonsEx1[1].id}
                                onClick={event => handleColor(event, buttonsEx1[1].isCorrect)}
                            >
                                ПАССАЖИРЫ ОБЯЗАНЫ
                            </button>
                        </div>
                        <p>осуществлять проход в зону транспортной безопасности аэропорта или воздушного судна вне (в обход) установленных проходов</p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[0].id}
                                onClick={event => handleColor(event, buttonsEx1[2].isCorrect)}
                            >
                                ПАССАЖИРАМ ЗАПРЕЩАЕТСЯ
                            </button>
                            <button
                                key={buttonsEx1[1].id}
                                onClick={event => handleColor(event, buttonsEx1[3].isCorrect)}
                            >
                                ПАССАЖИРЫ ОБЯЗАНЫ
                            </button>
                        </div>
                        <p>информировать силы обеспечения транспортной безопасности о событиях или действиях, создающих угрозу транспортной безопасности аэропорта или воздушного судна</p>
                    </div>
                    <div className={classes.cardContainerEx1}>
                        <div className={classes.buttonsContainerEx1}>
                            <button
                                key={buttonsEx1[0].id}
                                onClick={event => handleColor(event, buttonsEx1[4].isCorrect)}
                            >
                                ПАССАЖИРАМ ЗАПРЕЩАЕТСЯ
                            </button>
                            <button
                                key={buttonsEx1[1].id}
                                onClick={event => handleColor(event, buttonsEx1[5].isCorrect)}
                            >
                                ПАССАЖИРЫ ОБЯЗАНЫ
                            </button>
                        </div>
                        <p>использовать пиротехнические изделия без разрешения лица, ответственного за обеспечение транспортной безопасности аэропорта или воздушного судна</p>
                    </div>
                    <p className={classes.textLink}>Текст адаптирован из: <a href="https://www.s7.ru/files/magazine/2016/s7-magazine-06-2016.pdf"
                        target="_blank"
                        rel="noopener noreferrer">
                        https://www.s7.ru/files/magazine/2016/s7-magazine-06-2016.pdf</a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Exercises;