import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = [
    { id: 'реку', name: 'реку', translation: 'реку' },
    { id: 'пройтись', name: 'пройтись', translation: 'пройтись' },
    { id: 'парламентские', name: 'парламентские', translation: 'парламентские' },
    { id: 'восхитительного', name: 'восхитительного', translation: 'восхитительного' },
    { id: 'улицах', name: 'улицах', translation: 'улицах' },
    { id: 'астролябию', name: 'астролябию', translation: 'астролябию' },
    { id: 'Национальной', name: 'Национальной', translation: 'Национальной' },
    { id: 'города', name: 'города', translation: 'города' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <span className={classes.cardWrapper}>
                <span ref={drag} className={classes.dragCard}>
                    {name}
                </span>
            </span>
        );
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true)
            },
        }), [accept]);

        return (
            <span className={classes.cardWrapper}>
                <span ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <DragCard key={item.id} type={item.id} name={item.name} /> :
                        <span className={classes.dropCard} type={item.id}>largar aqui</span>
                    }
                </span>
            </span>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.dragCards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = (
            <div className={classes.questionsWrapper}>
                <div className={classes.question}>
                    <span>Я ИЩУ ПРИКЛЮЧЕНИЙ в центре <DropCard key={dropCards[7].id} item={dropCards[7]} />, на <DropCard key={dropCards[4].id} item={dropCards[4]} /> Риду и Суссекс. Здесь можно наблюдать, как разгораются <DropCard key={dropCards[2].id} item={dropCards[2]} /> дебаты, или стать свидетелем демонстрации, исследовать лабиринт коридоров <DropCard key={dropCards[3].id} item={dropCards[3]} /> отеля «Шато- Лорье», <DropCard key={dropCards[1].id} item={dropCards[1]} /> по<DropCard key={dropCards[6].id} item={dropCards[6]} /> галерее и закончить на мысе Нипиан, где памятник путешественнику Самюэлю де Шамплену смотрит на<DropCard key={dropCards[0].id} item={dropCards[0]} />Оттаву и где, по легенде, он потерял свою <DropCard key={dropCards[5].id} item={dropCards[5]} />.</span>
                </div>
            </div>
        );

        return <div className={classes.dropCards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="ЗАВЕРШИТЕ ТЕКСТ ПОДХОДЯЩИМИ СЛОВАМИ" />
                <div className={classes.gridItems}>
                    <DragCards />
                    <p><i>(National Gallery of Canada 380 Sussex Drive, Ottawa, Ontario Canada K1N 9N)</i></p>
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;