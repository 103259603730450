import React from 'react';
import classes from './Book.css';
import MainHeader from '../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../CenterColumn/CenterColumn.css';

const bookA2 = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={'Livro A2'} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.containerA2Book}>
                <div className={classes.containerA2Book_1}>
                    <p className={classes.a2BookSubtitles}>Manual</p>
                    <a href="https://livraria.ruigracio.com/produto/dialog-a2-manual-de-russo/" target="_blank" rel="noopener noreferrer">Comprar livro A2</a>
                </div>
                <div className={classes.containerA2Book_2}>
                    <p className={classes.a2BookSubtitles}>Áudio e vídeo</p>
                    <a href='../../../../../../public/Assets/AudioZipFiles/AudioExerciciosA2.zip' download>Ficheiros de áudio e vídeo (.zip)</a>
                </div>
            </div>
        </div>
    );
}

export default bookA2;