import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>беру́</b> (v. брать) levo</p>
                        <p><b>(в) магази́н</b> à loja</p>
                        <p><b>(в) о́тпуск</b> (de) férias</p>
                        <p><b>(в) пала́тке</b> (na) tenda</p>
                        <p><b>воспита́тель</b> educador</p>
                        <p><b>выбирáем</b> (v. выбира́ть) escolhemos</p>
                        <p><b>дверь</b> porta</p>
                        <p><b>до́лго</b> durante muito tempo</p>
                        <p><b>достопримеча́тельности</b> lugares de interesse</p>
                        <p><b>е́дешь</b> (v. е́хать) vais</p>
                        <p><b>éду</b> (v. е́хать) vou</p>
                        <p><b>е́хал</b> (v. е́хать) ia (m.)</p>
                        <p><b>е́хала</b> (v. е́хать) ia (f.)</p>
                        <p><b>е́хало</b>(v. е́хать) ia (n.)</p>
                        <p><b>е́хать</b> ir (de transporte)</p>
                        <p><b>жду</b> (v. ждать) espero</p>
                        <p><b>жела́ния</b> desejos</p>
                        <p><b>(за) грани́цу</b> (ao) estrangeiro</p>
                        <p><b>иди́</b> (v. идти́) vai</p>
                        <p><b>идти́</b> ir (a pé)</p>
                        <p><b>карто́шку</b> (Acus.) batata</p>
                        <p><b>Кита́йскую сте́ну</b> (Acus.) Muralha da China</p>
                        <p><b>Кра́сную пло́щадь</b> (Acus) para a Praça Vermelha</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>куда́</b> para onde</p>
                        <p><b>ку́кла</b> boneca</p>
                        <p><b>купа́льник</b> fato de banho</p>
                        <p><b>купа́ться</b> tomar banho</p>
                        <p><b>купи́</b> (v. купи́ть) compra (imperativo)</p>
                        <p><b>лекáрства</b> medicamentos</p>
                        <p><b>лете́ть</b> voar</p>
                        <p><b>лы́жи</b> esquis</p>
                        <p><b>маршру́т</b> rota/percurso</p>
                        <p><b>ме́сто</b> lugar</p>
                        <p><b>(на) черепа́хе</b> (de) tartaruga</p>
                        <p><b>недалекó</b> perto</p>
                        <p><b>обще́ственный тра́нспорт</b> transporte público</p>
                        <p><b>оде́жду</b> (Acus.) roupa</p>
                        <p><b>Па́пу Ри́мского</b> (Acus. e Gen.) o Papa</p>
                        <p><b>перча́тки</b> luvas</p>
                        <p><b>пешко́м</b> a pé</p>
                        <p><b>плани́руем</b> (v. плани́ровать) planeamos</p>
                        <p><b>плыть</b> nadar</p>
                        <p><b>полоте́нце</b> toalha</p>
                        <p><b>потóм</b> depois</p>
                        <p><b>путеше́ствие</b> viagem</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>путеше́ствовать</b> viajar</p>
                        <p><b>путеше́ствую</b> (v. путеше́ствовать) viajo</p>
                        <p><b>сапоги́</b> botas</p>
                        <p><b>снача́ла</b> no início</p>
                        <p><b>стои́т</b> (v. стоя́ть) está parado</p>
                        <p><b>страну́</b> (Acus.) país</p>
                        <p><b>сюда́</b> para aqui/cá</p>
                        <p><b>трусы́</b> cuecas</p>
                        <p><b>туда́</b> para ali/lá</p>
                        <p><b>туда́-сюда́</b> 1. assim-assim 2. mais ou menos</p>
                        <p><b>(у) друзе́й</b> (em casa de) amigos</p>
                        <p><b>уви́деть</b> ver</p>
                        <p><b>фо́тоаппара́т</b> máquina fotográfica</p>
                        <p><b>ча́йник</b> chaleira</p>
                        <p><b>чемода́н</b> mala</p>
                        <p><b>чемода́нное настрое́ние</b> disposição de viajante</p>
                        <p><b>ша́пка</b> chapéu</p>
                        <p><b>шёл</b> (v. идти́) ia (m.)</p>
                        <p><b>шла</b> (v. идти́) ia (f.)</p>
                        <p><b>шли</b> (v. идти́) iam</p>
                        <p><b>шло</b> (v. идти́) ia (n.)</p>
                        <p><b>шо́рты</b> calções</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;