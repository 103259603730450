import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson26 from '../../../../../../Assets/Images/A1/Lesson26/image1lesson26.png';
import Image2Lesson26 from '../../../../../../Assets/Images/A1/Lesson26/image2lesson26.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ГЛАГОЛ: НРАВИТЬСЯ</p>
                    <p>Verbos: agradar/gostar de</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image1Lesson26" src={Image1Lesson26} alt="lesson 26 illustrative example 1" />
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O verbo <b><span className={classes.orangeHighlight}>НРАВИТЬСЯ</span> </b> pertence à 2ª. Conjugação. É um verbo reflexivo transitivo indireto, i.e., pede um complemento indireto no Caso Dativo, semelhante à estrutura do verbo “agradar” em português, no entanto, traduz-se quase sempre por “gostar de”.</p>
                            <p className={classes.paragraphTitle}>
                                <b><span className={classes.orangeHighlight}>SUJEITO NOMINATIVO + НРАВИТЬСЯ + DATIVO</span></b>
                            </p>
                            <p>
                                <b>1.</b> <i>Ты нра́вишься Анто́ну.</i> = O Anton gosta de ti. (Tu agradas ao Anton)
                            </p>
                            <p>
                                <b>2.</b> <i>Вы нра́витесь Еле́не.</i> = A Helena gosta de si. (Você(s) agrada(m) à Helena)
                            </p>
                            <p className={classes.paragraphTitle}>
                                <b><span className={classes.orangeHighlight}>SUJEITO DATIVO + НРАВИТЬСЯ + NOMINATIVO</span></b>
                            </p>
                            <p>
                                <b>1.</b> <i>Тебе́<sub>Dat.</sub> нра́вится Анто́н<sub>Nom.</sub>.</i> = Tu gostas do Anton. (O Anton agrada-te)
                            </p>
                            <p>
                                <b>2.</b> <i>Вам<sub>Dat.</sub>  нра́вятся стихи<sub>Nom.</sub>?</i> = Você(s) gosta(m) de poemas? (Poemas agradam-vos?)
                            </p>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ГЛАГОЛ: КАЗАТЬСЯ</p>
                    <p>Verbo: parecer</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <div className={classes.gridItems}>
                        <div className={classes.gridItem1}>
                            <img id="Image2Lesson26" src={Image2Lesson26} alt="lesson 26 illustrative example 2" />
                            <div>
                                <p>
                                    <b>МНЕ КА́ЖЕТСЯ = ПО-МО́ЕМУ</b>
                                </p>
                                <p>
                                    <b>ТЕБЕ́ КА́ЖЕТСЯ = ПО-ТВО́ЕМУ</b>
                                </p>
                            </div>
                        </div>
                        <div className={classes.gridItem2}>
                            <p>
                                <font size="5"><span className={classes.orangeHighlight}>&#9758;</span></font> O verbo reflexivo <b><span className={classes.orangeHighlight}>КАЗАТЬСЯ</span></b> pertence à 1ª. Conjugação e pede um complemento no Caso Instrumental (cf. Уро́к 29).
                            </p>
                            <p className={classes.paragraphTitle}>
                                <b><span className={classes.orangeHighlight}>SUJEITO NOMINATIVO + КАЗАТЬСЯ + INSTRUMENTAL</span></b>
                            </p>
                            <p>
                                <b>1.</b> <i>Я кажу́сь то́лстой.</i> = (Eu) pareço gorda.</p>
                            <p>
                                <b>2.</b> <i>Ты ка́жешься уста́лой.</i> = (Tu) pareces cansada.</p>
                            <p className={classes.paragraphTitle}>
                                <b><span className={classes.orangeHighlight}>SUJEITO DATIVO + КАЗАТЬСЯ + frase completiva</span></b>
                            </p>
                            <p>
                                Neste caso, tal como em português, é usada apenas a forma verbal de 3ª.p.sg.
                            </p>
                            <p>
                                <b>1.</b> <i>Мне<sub>Dat.</sub> ка́жется, что бу́дет дождь.</i> = (Eu) acho/Parece-me que vai chover.
                            </p>
                            <p>
                                <b>2.</b> <i>Сестре́<sub>Dat.</sub>  ка́жется, что́ ты не прав.</i> = A irmã acha/Parece-lhe (à irmã) que tu não tens razão.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;