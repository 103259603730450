import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import classes from './Vocabulary.css';

const Vocabulary = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <div className={classes.vocabularyContainer}>
                    <div className={classes.vocabularyColumn}>
                        <p><b>бить</b> bater</p>
                        <p><b>ближа́йшая</b> a mais perto (f.)</p>
                        <p><b>(в) бюро́ нахо́док</b> (na) agência de perdidos e achados</p>
                        <p><b>вниз</b> para baixo</p>
                        <p><b>вперёд</b> à frente</p>
                        <p><b>впереди́</b> em frente</p>
                        <p><b>движе́ние</b> movimento</p>
                        <p><b>дворе́ц</b> palácio</p>
                        <p><b>(за) угло́м</b> (Instr.) (virar) a esquina</p>
                        <p><b>знак</b> símbolo</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>ме́стонахожде́ние</b> 1. localização 2. posição</p>
                        <p><b>наве́рх</b> para cima</p>
                        <p><b>наза́д</b> para trás</p>
                        <p><b>(на) кры́ше</b> (no) telhado</p>
                        <p><b>нале́во</b> à esquerda</p>
                        <p><b>направле́ние</b> direção</p>
                        <p><b>напра́во</b> à direita</p>
                        <p><b>отделе́ние</b> (поли́ции) esquadra</p>
                        <p><b>(пе́ред) ва́ми</b> (Instr.) (à) sua frente</p>
                        <p><b>перекрёсток</b> cruzamento</p>
                        <p><b>повели́тельное наклоне́ние</b> imperativo</p>
                    </div>
                    <div className={classes.vocabularyColumn}>
                        <p><b>поверни́те</b> (v. поверну́ть) vire/ virem</p>
                        <p><b>потеря́ла</b> (v. потеря́ть) perdi (f.)</p>
                        <p><b>прав</b> (adj. curto) (tenho) razão (m.)</p>
                        <p><b>пройди́те</b> (v. пройти́) passe/passem</p>
                        <p><b>ремо́нт</b> 1. reparação 2. conserto</p>
                        <p><b>све́рху</b> em cima</p>
                        <p><b>сза́ди</b> atrás</p>
                        <p><b>сле́ва</b> à esquerda</p>
                        <p><b>сни́зу</b> por baixo</p>
                        <p><b>согла́сна</b>(adj. curto) (estar) de acordo (f.)</p>
                        <p><b>спра́ва</b> à direita</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vocabulary;