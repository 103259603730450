import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const VerbList = [
    { id: '4', name: '4', matcher: '4' },
    { id: '3', name: '3', matcher: '3' },
    { id: '1', name: '1', matcher: '1' },
    { id: '2', name: '2', matcher: '2' },
    { id: '5', name: '5', matcher: '5' },
    { id: '6', name: '6', matcher: '6' },
];

const Exercises = props => {
    const handleChange = (event, matcher) => {
        if (event.target.value.toLowerCase() === matcher.toLowerCase()) {
            event.currentTarget.style.color = 'green';
            event.currentTarget.style.background = 'white';
        } else {
            event.currentTarget.style.color = 'red';
            event.currentTarget.style.background = 'white';
        }
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Угадайте страну по своей традиции" />
                <div className={classes.questionsContainerEx1}>
                    <div>
                        <p>1) В этой стране снимать обувь в гостях считается слишком фамильярным.</p>
                        <p>2) Розы в качестве подарка — отличный способ выразить эмоции практически в
                            любой точке мира. Однако в некоторых странах придают особое значение их цвету.
                            В этой стране лучше отдать предпочтение классической красной розе, а не жёлтой,
                            так как там этот цвет связывают со смертью.</p>
                        <p>3) Не сдерживать отрыжку, если вам понравилась еда.</p>
                        <p>4) Жители этой страны кивают головой, когда хотят сказать «нет» и качают головой
                            влево-вправо, когда хотят сказать «да». Для европейцев или любой другой нации
                            это немного непривычно.</p>
                        <p>5) Если человек остается без пары до 25 лет, то в свой день рождения, друзья
                            засыпают его корицей. Это отсылка к торговцам специями, которым часто было не
                            до заключения брака из-за путешествий по континенту.</p>
                        <p>6) Показывайте язык прохожим! Это норма для людей, что проживают тут. Таким
                            образом они просто здороваются друг с другом.</p>
                    </div>
                    <div className={classes.inputsContainer}>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input1"
                                    name="input1"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[0].matcher)} /> Болгария
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input2"
                                    name="input2"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[1].matcher)} /> Китай
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input3"
                                    name="input3"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[2].matcher)} /> Португалия
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input4"
                                    name="input4"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[3].matcher)} /> Мексика
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input5"
                                    name="input5"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[4].matcher)} /> Дания
                            </div>
                        </div>
                        <div className={classes.cardContainerEx1}>
                            <div className={classes.inputWrapper}>
                                <input
                                    id="input6"
                                    name="input6"
                                    type="text"
                                    className={classes.inputComponent}
                                    onChange={event => handleChange(event, VerbList[5].matcher)} /> Тибет
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;