import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson20/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson20/image2grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРЕВОСХОДНАЯ СТЕПЕНЬ:</p>
                    <p><i>САМЫЙ</i></p>
                    <p>Superlativo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> O superlativo em russo pode ser formado através do adjetivo <b><span className={classes.orangeHighlight}>CÁМЫЙ</span></b>, que deve concordar em número, género e caso com o adjetivo que o segue.</p>
                    <div className={classes.item1_1}>
                        <p>Э́то са́м<b><span className={classes.orangeHighlight}>ый</span></b> краси́в<b><span className={classes.orangeHighlight}>ый</span></b> го́род. (Esta é a cidade mais bonita.)</p>
                        <p>Э́то са́м<b><span className={classes.orangeHighlight}>ая</span></b> надёжн<b><span className={classes.orangeHighlight}>ая</span></b > маши́на. (Este é o carro mais fiável.)</p >
                        <p>Э́то са́м<b><span className={classes.orangeHighlight}>ое</span></b> стра́шн<b><span className={classes.orangeHighlight}>ое</span></b> кино́. (Este é o filme mais assustador)</p>
                        <p>Э́то са́м<b><span className={classes.orangeHighlight}>ые</span></b> бли́зк<b><span className={classes.orangeHighlight}>ие</span></b> лю́ди. (Estas são as pessoas mais próximas.)</p>
                    </div>
                    <div className={classes.item1_2}>
                        <p>са́мый хоро́ший = лу́чший (o melhor)</p>
                        <p>са́мый плохо́й = ху́дший (o pior)</p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>КОСВЕННЫЙ ВОПРОС</p>
                    <p><i>ЛИ</i></p>
                    <p>Pergunta Indireta</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <p><span className={classes.orangeHighlight}><b><font size="5">&#9758;</font></b></span> Existem duas formas para construir perguntas sem usar uma palavra interrogativa: direta e indireta:</p>
                    <div className={classes.item2_1}>
                        <img id="image1Grammar" src={Image1Grammar} alt="table 1" width="150px" />
                        <img id="image2Grammar" src={Image2Grammar} alt="table 2" width="400px" />
                    </div>
                </div>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <div className={classes.item3_1_1}>
                            <b><span className={classes.orangeHighlight}>ЕСЛИ</span></b>
                            <p>↓</p>
                            <p>условие</p>
                        </div>
                        <div>
                            vs.
                        </div>
                        <div className={classes.item3_1_2}>
                            <b><span className={classes.orangeHighlight}>ЛИ</span></b>
                            <p>↓</p>
                            <p>вопрос</p>
                        </div>
                    </div>
                    <div className={classes.item3_2}>
                        <div>
                            <p><b><span className={classes.orangeHighlight}>ЕСЛИ</span></b> usa-se em frases condicionais e <b><span className={classes.orangeHighlight}>ЛИ</span></b> usa-se em frases interrogativas:</p>
                        </div>
                        <div>
                            <p>Не зна́ю, чита́ла <b><span className={classes.orangeHighlight}>ЛИ</span></b> она́ Ди́ккенса.</p>
                            <p>Não sei se ela leu Dickens. (= terá lido?)</p>
                        </div>
                        <div>
                            <p>Мы за́втра пойдём на пляж, <b><span className={classes.orangeHighlight}>ЕСЛИ</span></b> бу́дет хоро́шая пого́да.</p>
                            <p>Amanhã vamos à praia, se estiver bom tempo. (=na condição de bom tempo)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;