import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Grammar from '../../../../../../Assets/Images/A2/Lesson11/image1grammar.png';
import Image2Grammar from '../../../../../../Assets/Images/A2/Lesson11/image2grammar.png';
import Image3Grammar from '../../../../../../Assets/Images/A2/Lesson11/image3grammar.png';
import Image4Grammar from '../../../../../../Assets/Images/A2/Lesson11/image4grammar.png';
import Image5Grammar from '../../../../../../Assets/Images/A2/Lesson11/image5grammar.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>ПРИЛАГАТЕЛЬНОЕ (РОДИТЕЛЬНЫЙ ПАДЕЖ)</p>
                    <p>Adjetivos (Genitivo)</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="image1Grammar" src={Image1Grammar} alt="1" width="400px" />
                </div>
                <GrammarSubtitle>
                    <p>СКÓЛЬКО ВРЕМЕНИ?</p>
                </GrammarSubtitle>
                <div className={classes.item2}>
                    <img id="image2Grammar" src={Image2Grammar} alt="2" width="200px" />
                    <img id="image3Grammar" src={Image3Grammar} alt="3" width="200px" />
                </div>
                <GrammarSubtitle>
                    <p>ГЛАГОЛ</p>
                    <p><i>БЫТЬ</i></p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div className={classes.item3_1}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ФАКТ НАЛИ́ЧИЯ ЧЕГО́-ЛИ́БО:</span></b></p>
                        <p><b><span className={classes.orangeHighlight}>(Constatação de um facto/existência)</span></b></p>
                        <div className={classes.item3_1_1}>
                            <p><b>Кто есть?/ Что́ есть?</b></p>
                        </div>
                        <div className={classes.item3_1_2}>
                            <div>
                                <p>У него</p>
                                <p>У нас</p>
                                <p>У них</p>
                            </div>
                            <div className={classes.item3_1_2_1}>
                                <p><b><span className={classes.orangeHighlight}>ЕСТЬ</span></b></p>
                            </div>
                            <div>
                                <p><b>сын.</b></p>
                                <p><b>де́ньги.</b></p>
                                <p><b>хоро́шие друзья́.</b></p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.item3_2}>
                        <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ОПРЕДЕЛÉНИЕ:</span></b></p>
                        <p><b><span className={classes.orangeHighlight}>(Descrição)</span></b></p>
                        <div className={classes.item3_2_1}>
                            <p><b>У кого/Где есть?</b></p>
                            <p><b>Каки́е? Чьи?</b></p>
                        </div>
                        <div className={classes.item3_2_2}>
                            <div>
                                <p>У него</p>
                                <p>У нас</p>
                                <p>У них</p>
                            </div>
                            <div className={classes.item3_2_2_1}>
                                <p><b><span className={classes.orangeHighlight}>-</span></b></p>
                            </div>
                            <div>
                                <p>мáленький <b>сын.</b></p>
                                <p>Твои́ <b>де́ньги.</b></p>
                                <p><b>хоро́шие</b> друзья́.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ОТРИЦАНИЕ</p>
                    <p><span className={classes.orangeHighlight}>НЕТ</span> vs. <span className={classes.orangeHighlight}>НЕ</span></p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div className={classes.item4_1}>
                        <div>
                            <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> ФАКТ ОТСУ́ТСТВИЯ</span></b></p>
                            <p><b><span className={classes.orangeHighlight}>(Constatação da não existência/ausência)</span></b></p>
                        </div>
                        <img className={classes.alignCenter} id="image4Grammar" src={Image4Grammar} alt="4" width="300px" />
                    </div>
                    <div className={classes.item4_2}>
                        <div>
                            <p><b><span className={classes.orangeHighlight}><font size="5">&#9758;</font> НЕ Э́ТО, А ТО</span></b></p>
                            <p><b><span className={classes.orangeHighlight}>(Descrição na negativa/negação de um facto, detalhe ou ação)</span></b></p>
                        </div>
                        <img className={classes.alignCenter} id="image5Grammar" src={Image5Grammar} alt="5" width="300px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;