import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';

const Exercises = props => {
    const exerciseQuestion = (
        <p>Veja o videoclip <a className={classes.exerciseQuestionLink} href="https://www.youtube.com/watch?v=GEKOB2n-rKI&ab_channel=%D0%9F%D0%B8%D0%BA%D0%BD%D0%B8%D0%BA" target="_blank" rel="noopener noreferrer">Пикник – Игла</a> e encontre as 7 palavras no Imperativo (clique nas palavras para validar a sua escolha).</p>
    );

    const handleColor = event => {
        return event.currentTarget.style.color = 'green';
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsA2 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question={exerciseQuestion} />
                <div className={classes.questionsContainerEx1}>
                    <div className={classes.cardContainerEx1}>
                        <div>
                            <p>Не стальна́я игла́, а грусть</p>
                            <p>Мне проби́ла сего́дня грудь,</p>
                            <p>Оттолкну́сь от земли́ и в путь,</p>
                            <p>Не <button
                                className={classes.buttonStyle}
                                onClick={event => handleColor(event)}
                            >
                                забу́дь
                            </button> меня́, не <button
                                className={classes.buttonStyle}
                                onClick={event => handleColor(event)}
                            >
                                    забу́дь
                                </button>.</p>
                        </div>
                        <div>
                            <p>Уж не чу́дится ль э́то мне,</p>
                            <p>Э́то не́бо и дождь в окне́?</p>
                            <p>Жаль, не гре́ет в пути́ звезда́,</p>
                            <p>Нарисо́вана, что ли? Да.</p>
                        </div>
                        <div>
                            <p><button
                                className={classes.buttonStyle}
                                onClick={event => handleColor(event)}
                            >
                                Пусть
                            </button> же ве́тер мота́ет, <button
                                className={classes.buttonStyle}
                                onClick={event => handleColor(event)}
                            >
                                    пусть
                                </button>,</p>
                            <p>Сам не зна́ю, за что держу́сь.</p>
                            <p>Жаль, не све́тит в пути́ звезда́,</p>
                            <p>Нарисо́вана, ви́дно. Да.</p>
                        </div>
                        <div>
                            <p>Говори́шь: «<button
                                className={classes.buttonStyle}
                                onClick={event => handleColor(event)}
                            >
                                Подожди́
                            </button>, вот-во́т,</p>
                            <p>Со́лнце на не́бе да взойдёт».</p>
                            <p>То́лько е́сли ещё оно́?</p>
                            <p>Да не всё ли, не всё ли равно́?</p>
                        </div>
                        <div>
                            <p>Не стальна́я игла́, а грусть</p>
                            <p>Мне проби́ла сего́дня грудь,</p>
                            <p>Оттолкну́сь от земли́ и в путь,</p>
                            <p>Не <button
                                className={classes.buttonStyle}
                                onClick={event => handleColor(event)}
                            >
                                забу́дь
                            </button> меня́, не <button
                                className={classes.buttonStyle}
                                onClick={event => handleColor(event)}
                            >
                                    забу́дь
                                </button>.</p>
                        </div>
                        <div>
                            <p>Как случи́лось, что мир осты́л,</p>
                            <p>Мир тене́й и доро́г пусты́х?</p>
                            <p>Жаль не све́тит в пути́ звезда́.</p>
                            <p>Нарисо́вана что ли,</p>
                            <p>Нарисо́вана что ли? Да.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Exercises;