import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import PersonalPronouns from '../../../../../../Assets/Images/A1/Lesson1/personalPronouns.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>Личные местоимения</p>
                    <p>Pronomes Pessoais</p>
                </GrammarSubtitle>
                <div className={classes.flexItems}>
                    <div className={classes.item1}>
                        <img id="personalPronouns" src={PersonalPronouns} alt="personal pronouns" width="220px" />
                    </div>
                    <div className={classes.item2}>
                        <p> A expressão <span className={classes.orangeHighlight}><b>МЕНЯ́</b> ЗОВУ́Т…</span>equivale a “eu chamo-me”.
                        </p>
                        <p>Literalmente traduz-se por <span className={classes.orangeHighlight}><b>ME</b> CHAMAM…</span>(com
                            o verbo sempre na 3.ªpess. do plural.)</p>
                        <p> Em alternativa a <span className={classes.orangeHighlight}><b>МЕНЯ́</b> ЗОВУ́Т…</span>, poderá também ouvir:</p>
                        <p><b> Я Мария. </b></p>
                        <p> Eu (sou a) Maria. </p>
                        <p><span className={classes.orangeHighlight}><b>!</b></span> O pronome usado em situações formais é o <span className={classes.orangeHighlight}><b>вы</b></span>(vós/o senhor/a senhora). Esta mesma forma é também usada para o plural (vocês). O contexto desambigua o significado.</p>
                        <p>Exemplo: </p>
                        <p>1. Как <b>вас</b> зову́т? (Como é que se chama?)</p>
                        <p> Меня зовут Эдуардо.</p>
                        <p>2. Как <b>вас</b> зову́т? (Como é que vocês se chamam?)</p>
                        <p> Меня зовут Эдуардо, a это Мария.</p>
                    </div>
                </div>
                <div className={classes.flexItems}>
                    <div className={classes.item3}>
                        <p><span className={classes.orangeHighlight}><b>!</b></span> A estrutura <span className={classes.orangeHighlight}><b>ЕГО́ ЗОВУ́Т</b></span> no género
                            neutro usa-se apenas com substantivos animados: дитя́, ча́до, чу́до (como epíteto), созда́ние.</p>
                        <p><b><span className={classes.orangeHighlight}>!</span></b> Os pronomes pessoais <b><span className={classes.orangeHighlight}>НАС</span></b> e <b><span className={classes.orangeHighlight}>ИХ</span></b>				não se usam em construções afirmativas como os outos pronomes, mas podem aparecer em perguntas, por exemplo: <b>Как их зовут?</b></p>
                    </div>
                    <div className={classes.item4}>
                        <p> Os pronomes pessoais podem substituir os nomes (próprios ou comuns).</p>
                        <p> Exemplo:</p>
                        <p> 1. Это <b><span className={classes.orangeHighlight}> Мария?</span></b> / Это <b><span className={classes.orangeHighlight}>она </span></b> ? Да,
                            это <b><span className={classes.orangeHighlight}>она</span></b>.</p>
                        <p> 2. Это <b><span className={classes.orangeHighlight}>Мария и Лев?</span></b> / Это <b><span className={classes.orangeHighlight}> они</span></b> ?
                            Да, это <b><span className={classes.orangeHighlight}> они</span></b>.</p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ГЛАГОЛ "БЫТЬ"</p>
                    <p>Verbo “ser/estar/haver”</p>
                </GrammarSubtitle>
                <div className={classes.item5}>
                    <p> O verbo copulativo <b><span className={classes.orangeHighlight}>БЫТЬ</span></b> (ser/estar/ haver) raramente se emprega no Presente
                        (ex. 1, 2). Este verbo é defetivo, existindo apenas a forma <b>есть</b> para todas as pessoas do Presente. Só no Passado
                        e no Futuro existe conjugação plena. </p>
                    <p>A forma <b>есть</b>, com o sentido de <b>ser/estar</b> ainda existe na língua, mas usa-se cada vez menos, na maior parte
                        dos casos, em definições (ex.:<i> Прямая линия есть… Uma linha reta é</i>… (ex. 3).</p>
                </div>
                <div className={classes.gridItems}>
                    <div>
                        <p><b>1. </b></p>
                        <p>– Кто там?</p>
                        <p>– Сосе́д.</p>
                    </div>
                    <div>
                        <p><b>2. </b></p>
                        <p>– Кто э́то? </p>
                        <p>– Кристи́на.</p>
                    </div>
                    <div>
                        <p><b>3. </b></p>
                        <p>– Зако́н есть зако́н.</p>
                        <p> – Lei é lei.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;