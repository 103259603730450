import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';


const Exercises = props => {
    const handleClick = (event) => {
        event.currentTarget.style.backgroundColor = 'red';
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Найдите слова без уменьшительно-ласкательного суффикса." />
                <div className={classes.questionsContainerEx1}>
                    <p>1. сынуля, <span onClick={event => handleClick(event)}>пуля</span>, дедуля, мамуля</p>
                    <p>2. <span onClick={event => handleClick(event)}>пачка</span>, кошечка, птичка, дочка</p>
                    <p>3. бантик, <span onClick={event => handleClick(event)}>работник</span>, домик, ключик</p>
                    <p>4. мышка, пятнышко, <span onClick={event => handleClick(event)}>матрëшка</span>, солнышко</p>
                    <p>5. статуэ́тка, <span onClick={event => handleClick(event)}>студентка</span>, планетка, лапка</p>
                </div>
            </div>
        </div>
    );
}

export default Exercises;