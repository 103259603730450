import React from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import Image1 from '../../../../../../Assets/Images/B1/Lesson10/image1exercise.png';


const Exercises = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Посмотрите трейлер фильма Александра Сокурова &quot;Русский ковчег&quot; и ответьте:" />
                <div className={classes.ex1Container}>
                    <p>ВИРТУАЛЬНЫЙ ТУР ПО УСАДЬБЕ ЯСНАЯ ПОЛЯНА: <a href="http://yap.vm.culture.ru/main/"
                        target="_blank"
                        rel="noopener noreferrer">
                        http://yap.vm.culture.ru/main/
                    </a>
                    </p>
                    <img id="image1" src={Image1} alt="1" width="350px" />
                </div>
            </div>
        </div>
    );
}

export default Exercises;