import React, { useState } from 'react';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import ExercisesQuestions from '../../../../../Shared/ExercisesQuestions/ExercisesQuestions';
import classes from './Exercises.css';
import { useDrag, useDrop } from 'react-dnd';
import video_aula_9 from './Video/video_aula_9.mp4';

const ItemTypes = [
    { id: 'Три', name: 'Три', translation: 'Три' },
    { id: 'Тридцать три', name: 'Тридцать три', translation: 'Тридцать три' },
    { id: 'Oдин', name: 'Oдин', translation: 'Oдин' },
    { id: 'Триста', name: 'Триста', translation: 'Триста' },
    { id: 'Две тысячи', name: 'Две тысячи', translation: 'Две тысячи' },
];

const Exercises = props => {
    const [dropCards] = useState(ItemTypes);
    const [dragCards] = useState(ItemTypes);

    const DragCard = ({ type, name }) => {
        const [, drag] = useDrag({
            type: type,
        });

        return (
            <div className={classes.dragCardWrapper}>
                <div ref={drag} className={classes.dragCard}>
                    {name}
                </div>
            </div>);
    }

    const DropCard = ({ accepts: accept, item }) => {
        const [hasDropped, setHasDropped] = useState(false);

        const [, drop] = useDrop(() => ({
            accept: item.id,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                didDrop: monitor.didDrop(),
            }),
            drop(_item, monitor) {
                const didDrop = monitor.didDrop();

                if (didDrop) {
                    return
                }
                setHasDropped(true);
            },
        }), [accept]);

        return (
            <div className={classes.dropCardWrapper}>
                <div ref={drop} className={classes.dropContainer}>
                    {hasDropped ?
                        <div>
                            <DragCard key={item.id} type={item.id} name={item.name} />
                        </div> :
                        <div className={classes.dropCard} type={item.id}>largar aqui</div>
                    }
                </div>
            </div>
        );
    }

    const DragCards = () => {
        const cards = dragCards.map(item => {
            return <DragCard key={item.id} type={item.id} name={item.name} />
        });

        return <div className={classes.cards}>{cards}</div>
    };

    const DropCards = () => {
        const cards = (
            <div className={classes.questionsWrapper}>
                <div className={classes.question}>1) Сколько лет русской истории изображено в фильме? <DropCard key={dropCards[3].id} item={dropCards[3]} /></div>
                <div className={classes.question}>2) Сколько актëров играет в фильме? <DropCard key={dropCards[4].id} item={dropCards[4]} /></div>
                <div className={classes.question}>3) Сколько комнат Эрмитажа появилось в фильме? <DropCard key={dropCards[1].id} item={dropCards[1]} /></div>
                <div className={classes.question}>4) Сколько дублей в фильме? <DropCard key={dropCards[2].id} item={dropCards[2]} /></div>
                <div className={classes.question}>5) Сколько оркестров играло в фильме? <DropCard key={dropCards[0].id} item={dropCards[0]} /></div>
            </div>
        );

        return <div className={classes.cards}>{cards}</div>
    };

    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessonsB1 />
            <div className={classes.mainContent}>
                <ExercisesQuestions number="1" question="Посмотрите трейлер фильма Александра Сокурова &quot;Русский ковчег&quot; и ответьте:" />
                <a className={classes.downloadVideoLink} href={video_aula_9} download="video_aula_9">Bидео Урок 9 - Russian Ark</a>
                <div className={classes.gridItems}>
                    <DragCards />
                    <DropCards />
                </div>
            </div>
        </div>
    );
}

export default Exercises;