import React from 'react';
import MainHeader from '../../../../../Header/MainHeader/MainHeader';
import centerColumn from '../../../../../CenterColumn/CenterColumn.css';
import classes from './Grammar.css';
import Image1Lesson20 from '../../../../../../Assets/Images/A1/Lesson20/image1lesson20.png';
import Image2Lesson20 from '../../../../../../Assets/Images/A1/Lesson20/image2lesson20.png';
import GrammarSubtitle from '../../../../../Shared/GrammarSubtitle/GrammarSubtitle';

const Grammar = props => {
    return (
        <div className={centerColumn.CenterColumn}>
            <MainHeader title={props.title} subtitle={props.subtitle} goBack={props.goBack} goToLessons />
            <div className={classes.container}>
                <GrammarSubtitle>
                    <p>РОДИТЕЛЬНЫЙ ПАДЕЖ</p>
                    <p>Caso Genitivo</p>
                </GrammarSubtitle>
                <div className={classes.item1}>
                    <img id="Image1Lesson20" src={Image1Lesson20} alt="genitive case table example" width="650px" />
                </div>
                <div className={classes.item2}>
                    <img id="Image2Lesson20" src={Image2Lesson20} alt="genitive case phrases example" width="650px" />
                </div>
                <GrammarSubtitle>
                    <p>ЧИСЛИ́ТЕЛЬНЫЕ + РОДИТЕЛЬНЫЙ ПАДЕЖ</p>
                    <p>NUMERAIS + CASO GENITIVO</p>
                </GrammarSubtitle>
                <div className={classes.item3}>
                    <div>
                        <p><b><span className={classes.orangeHighlight}>1 &nbsp;&nbsp;&nbsp;оди́н</span></b> рубл<b><span className={classes.orangeHighlight}>ь</span></b> &nbsp;&nbsp;&nbsp;&nbsp;<b><span className={classes.orangeHighlight}>одна́</span></b> копе́йк<b><span className={classes.orangeHighlight}>а</span></b></p>
                        <p><b><span className={classes.orangeHighlight}>2</span></b>-4 &nbsp;&nbsp;&nbsp;<b><span className={classes.orangeHighlight}>два</span></b> рубл<b><span className={classes.orangeHighlight}>я́</span></b> &nbsp;&nbsp;&nbsp;&nbsp;<b><span className={classes.orangeHighlight}>две</span></b> копе́йк<b><span className={classes.orangeHighlight}>и</span></b></p>
                        <p><b><span className={classes.orangeHighlight}>5</span></b> &nbsp;&nbsp;&nbsp;-… <b><span className={classes.orangeHighlight}>пять</span></b> рубл<b><span className={classes.orangeHighlight}>е́й</span></b> &nbsp;&nbsp;&nbsp;&nbsp;<b><span className={classes.orangeHighlight}>пять</span></b> копе́<b><span className={classes.orangeHighlight}>ек</span></b></p>
                    </div>
                </div>
                <GrammarSubtitle>
                    <p>ЧИСЛИ́ТЕЛЬНЫЕ: 200-1000</p>
                    <p>NUMERAIS : 200-1000</p>
                </GrammarSubtitle>
                <div className={classes.item4}>
                    <div>
                        <p>200 &nbsp;&nbsp;&nbsp;&nbsp; две́<b><span className={classes.orangeHighlight}>сти</span></b></p>
                        <p>300 &nbsp;&nbsp;&nbsp;&nbsp; три́<b><span className={classes.orangeHighlight}>ста</span></b></p>
                        <p>400 &nbsp;&nbsp;&nbsp;&nbsp; четы́ре<b><span className={classes.orangeHighlight}>ста</span></b></p>
                        <p>500 &nbsp;&nbsp;&nbsp;&nbsp; пять<b><span className={classes.orangeHighlight}>со́т</span></b></p>
                        <p>600 &nbsp;&nbsp;&nbsp;&nbsp; шесть<b><span className={classes.orangeHighlight}>со́т</span></b></p>
                        <p>700 &nbsp;&nbsp;&nbsp;&nbsp; семь<b><span className={classes.orangeHighlight}>сóт</span></b></p>
                        <p>800 &nbsp;&nbsp;&nbsp;&nbsp; во́семь<b><span className={classes.orangeHighlight}>сóт</span></b></p>
                        <p>900 &nbsp;&nbsp;&nbsp;&nbsp; де́вять<b><span className={classes.orangeHighlight}>сóт</span></b></p>
                        <p>1000 &nbsp;&nbsp;&nbsp;&nbsp; <b><span className={classes.orangeHighlight}>ты́сяча</span></b></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grammar;